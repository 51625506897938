import React, { useContext } from 'react';
import { SecurityContext } from '../../../../../services/SecurityManager';
import UserInfo from '../UserInfo/UserInfo';
import UserPermissionsForm from '../UserPermissionsForm/UserPermissionsForm';
import './UserMainDetails.scss';

const UserMainDetails = ({ user, handleEdit }) => {
  const security = useContext(SecurityContext);

  return (
    <div className="UserMainDetails">
      <UserInfo user={user} handleEdit={handleEdit} />
      { security && security.canUpdateUserPermissionGroups() &&
        <UserPermissionsForm user={user} />
      }
    </div>
  );
}

export default UserMainDetails;
