import _ from "lodash";
import { getEmptyQuotationRequestProduct, prepareProductForServer } from "./quotationRequestProductsUtils";
import { copyPropertyIdOrThrow, copyPropertyOrThrow } from "../formUtils";
import { faBoxArchive, faFolderOpen } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { paths } from "../routes/appRoutes";
import moment from "moment";

export const getEmptyQuotationRequest = ()=>({
    products:[ getEmptyQuotationRequestProduct() ],
    iva: true,
    status: { value: quotationRequestStatus.PENDING, label: quotationRequestStatusTrans.PENDING }
});

export const quotationRequestStatus = {
    PENDING: "PENDING",
    APPROVED:"APPROVED",
    REJECTED: "REJECTED",
};

export const quotationRequestStatusTrans = {
    PENDING: "Pendiente",
    APPROVED: "Aprobada",
    REJECTED: "Rechazada",
};

export const quotationRequestStatusOptions = _.map(quotationRequestStatus, (value) => {
    return { value: value, label: quotationRequestStatusTrans[value] };
});

export const prepareQuotationRequestForServer = (form)=>{
    let quotationRequest = {}
    
    copyPropertyOrThrow( form, quotationRequest, "title", "Por favor llena el campo de título" );
    copyPropertyIdOrThrow( form, quotationRequest, "client", "Por favor selecciona una compañía" );
    copyPropertyIdOrThrow( form, quotationRequest, "project", "Por favor selecciona un proyecto" );
    copyPropertyOrThrow( form, quotationRequest, "status", "Por favor llena el campo de estado" );
    //copyPropertyOrThrow( form, quotationRequest, "folio", "Por favor escribe el número de folio" );

    const products = _.map( form.products, (product)=>prepareProductForServer(product, form?.client?.id) );
    quotationRequest.quotationRequestProducts = products;

    return quotationRequest;
}

export const archiveStatus = (quotationRequest) =>{
    if(quotationRequest?.isArchived) return { icon: faFolderOpen, tooltip: "Desarchivar solicitud" };

    return { icon: faBoxArchive, tooltip: "Archivar solicitud" };
}

export const convertQuoteRequestToForm = (quotationRequest)=>{
    if(!quotationRequest)
        return getEmptyQuotationRequest();
    return {
        ...quotationRequest,
        client: quotationRequest.project?.client,
        products: quotationRequest.quotationRequestProducts,
        status: { value: quotationRequest.status, label: quotationRequestStatusTrans[quotationRequest.status] }
    };
}

export const quotationRequestListSGroups = [
    "quotation_request_read_id",
    "quotation_request_read_title",
    "quotation_request_read_created_date",
    "read_created_by",
        "user_read_name",
        "user_read_full_name",
    "read_created_date"
]

export const quotationRequestSGroups = [
    "quotation_request_read",
    "quotation_request_read_id",
    "quotation_request_read_title",
    "quotation_request_read_project",
        "project_read",
            "project_read_client",
                "client_read",
    "quotation_request_read_quotation_request_products",
        "quotation_request_product_read",
    "quote_read",
    'read_created_by',
    'user_read_full_name',
    "read_created_date"
]

export const quotationRequestTableColumns = [
    {Header:"Título", id: 'title', 
        accessor: m => 
            <Link to={paths.quotationRequestDetail.replace(":id", m.id)} data-tooltip="Ver detalles de la solicitud" data-test-id="see-quote-request-detail-link">
                {m.title}
            </Link>
    },
    {Header:"Creador", accessor:'createdBy.fullName'},
    {Header:"Proyecto", id: 'project', 
        accessor: m => 
            <Link to={paths.projectDetail.replace(":id", m.project?.id)} data-tooltip="Ver detalles del proyecto" data-test-id="see-quote-request-detail-link">
                {m.project?.name}
            </Link>,
    },
    {Header:"Creación", id: 'createdDate', accessor: (q) => (q.createdDate? moment(q.createdDate).format('DD/MM/YYYY'):null)},
]; 