
import React from 'react'
import CommentDetail from '../CommentDetail/CommentDetail';

import './CommentsList.scss';

const CommentsList = ({comments, reloadConversation}) => {  
  return (
    <div className='CommentsList'>
      {comments && comments.map((comment, index) => 
        <CommentDetail 
          key={index} 
          comment={comment} 
          reloadConversation={reloadConversation} 
        />)}
    </div>
  )
}

export default CommentsList;