import React, {useContext} from 'react';
import {ApiContext} from "../../../../../../services/api/api-config";

const PdfPreview = ({appFile}) => {

    const api = useContext(ApiContext);
    const url = api.appFiles.getUrl(appFile);

    return (
        <div className={"PdfPreview"}>
            <iframe src={url} className='preview-window' title={appFile.originalName}/>
        </div>
    );
};

export default PdfPreview;
