import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import './MemorandumDetail.scss';
import TopBar from "../../../components/utility/TopBar/TopBar";
import ToolBar from "../../../components/utility/ToolBar/ToolBar";
import {faTrashCan, faPencil, faFilePdf} from "@fortawesome/pro-light-svg-icons";
import SceneTitle from "../../../components/layout/SceneTitle/SceneTitle";
import SimpleCard from "../../../components/layout/SimpleCard/SimpleCard";
import {paths} from "../../../services/routes/appRoutes";
import InfoPair from "../../../components/layout/InfoPair/InfoPair";
import {ApiContext} from "../../../services/api/api-config";
import {Link, useHistory, useParams} from "react-router-dom";
import { getNotifier } from '../../../services/notifier';
import useBoolean from '../../../hooks/useBoolean';
import { memorandumDetailSGroups } from '../../../services/modelUtils/memorandumUtils';
import Modal from '../../../components/utility/Modal/Modal';
import RichTextEditor from '../../../components/formComponents/RichTextEditor/RichTextEditor';
import moment from 'moment';

const MemorandumDetail = () => {
  const api = useContext(ApiContext);
  const history = useHistory();
  const { id } = useParams();
  const [ deleteMemorandumModal, deleteMemorandumOn , deleteMemorandumOff, ] = useBoolean();
  const customProp = 'Memorandum.Detail.get.'+id;

  // ----- Load memorandum data
  const [memorandum, setMemorandum] = useState();
  const apiConfig = useMemo(()=>({ id, params:{ sGroups: memorandumDetailSGroups } }), [id]);
  
  useEffect(()=>{
      api.memoranda.get(apiConfig).then(setMemorandum);
  },[api, apiConfig]);

  const redirectToEditMemorandum = useCallback(()=>history.push(paths.memorandumEdit.replace(':id', id)), [history, id]);

  const downloadPdf = useCallback(()=>{
      if(memorandum)
          window.location.href = api.memoranda.getDownloadPdfUrl(memorandum);
      }, [memorandum, api]);

  const tools = useMemo(()=>[
    { icon: faPencil, callback: redirectToEditMemorandum, text: "Editar minuta", testId: "edit-memo-button" },
    { icon: faTrashCan, callback: deleteMemorandumOn, text: "Eliminar minuta", testId: "delete-memo-button" },
      { icon: faFilePdf, callback: downloadPdf, text: "Descargar PDF", testId: "download-pdf-button" },
  ],[redirectToEditMemorandum, deleteMemorandumOn, downloadPdf]);

  // ----- Delete memorandum -----
  const deleteMemorandum = useCallback(() => {
    api.memoranda.delete({ id, customProp })
      .then(() => {
        getNotifier().success('Minuta eliminada.');
        history.replace( paths.projectDetail.replace(':id', memorandum.project.id) );
      })
      .catch(() => getNotifier().error('No se pudo eliminar la minuta.'));
  }, [api, id, history, customProp, memorandum]);

  return (
    <div className={"MemorandumDetail wind-scene"}>

      { deleteMemorandumModal && 
        <Modal
          className={"MemorandumDetail__confirmModal"}
          title={"Eliminar minuta"}
          onClose={() => deleteMemorandumOff()}

          mainButtonText='Eliminar'
          mainButtonColor='danger'
          mainButtonAction={deleteMemorandum}

          secondaryButtonColor='success'
          secondaryButtonAction={() => deleteMemorandumOff()}
        >
          <div className="RemoveQuotationRequestModal__content">
            ¿Estás seguro de que deseas eliminar esta minuta?
          </div>
        </Modal>
      }
      
      <TopBar
        title="Minuta"
        titleLinkBack
      />

      <ToolBar tools={tools} />
      
      <div className='center-container'>
        <div className='memorandum-header' >
          <SceneTitle secondary>{memorandum?.title}</SceneTitle>
        </div>

        {memorandum && <>
          <SimpleCard className='general-info' title="Información general">
            <div className={"info-block"}>
                <Link to={paths.clientDetail.replace(":id", memorandum.project?.client?.id)} data-tooltip={"Ver perfil del cliente"}>
                    <InfoPair value={memorandum.project?.client?.fullName} title={"Cliente"}/>
                </Link>
                
                <Link to={paths.projectDetail.replace(":id", memorandum.project?.id)} data-tooltip={"Ver projecto"}>
                    <InfoPair value={memorandum.project?.name} title={"Proyecto"}/>
                </Link>

                <InfoPair value={memorandum.folio} title={"Folio"}/>

                <InfoPair value={moment(memorandum.createdDate).format('DD/MM/YYYY')} title={"Creación"}/>
            </div>
          </SimpleCard></>
        }
        
          {memorandum && 
          <SimpleCard title='Contenido' className='main-info'>
            <RichTextEditor
              html={memorandum?.content}
              readonly={true}
            />
          </SimpleCard>}
      </div>
    </div>
  )
}

export default MemorandumDetail;