import React, {useCallback, useMemo} from 'react';
import WindSelect from "../../../../../../formComponents/WindSelect/WindSelect";
import _ from 'lodash';

/**
 *
 * @param value string
 * @param onChange
 * @param config { label: string, options: [ string | {value: string, label: string} ] }
 * @param config.options [ string | {value: string, label: string} ]
 * @param config.label string
 * @returns {JSX.Element}
 * @constructor
 */
const SearchBarSelectFilter = ({value, onChange, config}) => {

    const selectOptions = useMemo( ()=>config.options?.map( opt=>{
        if(typeof opt === 'string')
            return {value: opt, label: opt};
        else
            return opt;
    } ),[config.options] );

    const isMulti = config?.isMulti;
    const actualValue = useMemo(()=>{
        if(!isMulti) {
            const option = _.find(config.options, opt => (typeof opt === 'string' && opt === value) || (typeof opt === 'object' && opt?.value === value));
            if (!option)
                return null;
            if (typeof option === 'string')
                return {value: option, label: option};
            return option;
        }
        else if(value?.length) {
            const options = _.filter( config.options, opt => _.includes( value, opt.value ) );
            if(options.length)
                return options;
        }
        else{
            return null;
        }
    },[config.options, value, isMulti]);

    const handleChange = useCallback((option)=>{
        if(option?.value) {
            return onChange(option?.value);
        }
        if( option?.length ){
            return onChange(option.map( opt=>opt.value ));
        }
        return onChange();

    },[onChange]);

    return (
        <div className={'SearchBarSelectFilter SearchBarFilter'} onClick={(e) => e.stopPropagation()}>
            <WindSelect
                isClearable={true}
                placeholder={config.label}
                {...config}
                value={actualValue}
                onChange={handleChange}
                options={selectOptions}
            />
        </div>
    );
};

export default SearchBarSelectFilter;
