import React from 'react'
import WindInput from '../../../../../components/formComponents/WindInput/WindInput';
import TideEntitySelect from '../../../../../components/utility/TideEntitySelect/TideEntitySelect';
import { satCatalogSGroups } from '../../../../../services/modelUtils/taxDocumentUtils';
import classNames from "classnames";

const FiscalInfo = ({ setForm, fiscalData, className }) => {
    const onChangeFiscalData = (key, value) => {
        setForm(prev => ({
            ...prev,
            fiscalData: {
                ...prev.fiscalData,
                [key]: value
            }
        }));
    }

    return (
        <div className="ClientForm">
            <div className="fields-row">
                <WindInput 
                    className='fields-field fields-field-double'
                    placeholder={"Nombre Fiscal"}                    
                    inputProps={{maxLength:100}}
                    value={fiscalData?.businessName||''}
                    onChange={(value) => onChangeFiscalData('businessName', value)}
                />
                <WindInput 
                    className='fields-field'
                    placeholder={"Email de facturación"}
                    inputProps={{maxLength:60}}
                    value={fiscalData?.email||''}
                    onChange={(value) => onChangeFiscalData('email', value)}
                />


                <div>
                    <label>Régimen Fiscal</label>
                    <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Regimen Fiscal'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_RegimenFiscal"
                        }}
                        preload
                        filterBy={'description'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        value={JSON.parse(fiscalData?.taxRegime || `{"catalogKey": "-", "description": "-"}`)}
                        onChange={(value) => onChangeFiscalData('taxRegime', JSON.stringify(value))}
                    />
                </div>                
            </div>
            
            <div className="fields-row">

                <WindInput 
                    className='fields-field'
                    placeholder={"RFC"}
                    inputProps={{maxLength:100}}
                    value={fiscalData?.rfc||''}
                    onChange={(value) => onChangeFiscalData('rfc', value)}
                />
                <WindInput 
                    className='fields-field'
                    placeholder={"Nombre Comercial"}
                    inputProps={{maxLength:20}}
                    value={fiscalData?.comercialName||''}
                    onChange={(value) => onChangeFiscalData('comercialName', value)}
                />
                <WindInput
                    className='fields-field'
                    placeholder={"Calle"}
                    inputProps={{maxLength:255}}
                    value={fiscalData?.street||''}
                    onChange={(value) => onChangeFiscalData('street', value)}
                />
            </div>

            <div className="fields-row">
                
                <WindInput
                    className='fields-field fields-field-half'
                    placeholder={"Número Externo"}
                    inputProps={{maxLength:255}}
                    value={fiscalData?.externalNumber||''}
                    onChange={(value) => onChangeFiscalData('externalNumber', value)}
                />
                <WindInput
                    className='fields-field fields-field-half'
                    placeholder={"Número Interno"}
                    inputProps={{maxLength:255}}
                    value={fiscalData?.internalNumber||''}
                    onChange={(value) => onChangeFiscalData('internalNumber', value)}
                />
                <WindInput
                    className='fields-field'
                    placeholder={"Colonia"}
                    inputProps={{maxLength:255}}
                    value={fiscalData?.neighborhood||''}
                    onChange={(value) => onChangeFiscalData('neighborhood', value)}
                />

                <WindInput
                    className='fields-field'
                    placeholder={"Delegacion o Municipio"}
                    inputProps={{maxLength:255}}
                    value={fiscalData?.delegation||''}
                    onChange={(value) => onChangeFiscalData('delegation', value)}
                />

            </div>

            <div className={classNames("ContactInfo ContactInfo--mt", className)}>
                
                <WindInput
                    className='fields-field'
                    placeholder={"Ciudad"}
                    inputProps={{maxLength:255}}
                    value={fiscalData?.city||''}
                    onChange={(value) => onChangeFiscalData('city', value)}
                />
                <WindInput
                    className='fields-field'
                    placeholder={"Estado"}
                    inputProps={{maxLength:255}}
                    value={fiscalData?.state||''}
                    onChange={(value) => onChangeFiscalData('state', value)}
                />
                <WindInput
                    className='fields-field'
                    placeholder={"País"}
                    inputProps={{maxLength:255}}
                    value={fiscalData?.country||''}
                    onChange={(value) => onChangeFiscalData('country', value)}
                />
                <WindInput
                    className='fields-field'
                    placeholder={"Código Postal"}
                    inputProps={{maxLength:5}}
                    value={fiscalData?.zip||''}
                    onChange={(value) => onChangeFiscalData('zip', value)}
                />
            </div>
        </div>
    )
}

export default FiscalInfo;