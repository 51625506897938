import React, {useCallback, useContext} from 'react';
import {ApiContext} from "../../services/api/api-config";
import useFormState from "../../hooks/useFormState";
import {LOGIN_STATE, LOGIN_LOADING_ID} from "tide-api";
import {useSelector} from "react-redux";
import WindInput from "../formComponents/WindInput/WindInput";
import Button from "../utility/Button/Button";
import useBodyClass from "../../hooks/useBodyClass";
import './LoginModalForm.scss'

const LoginModalForm = () =>{

    useBodyClass('clear-background');
    const api = useContext(ApiContext);
    
    const {form, handleSimpleChange} = useFormState({remember: localStorage.getItem('remember')==='true'});
    
    const login = useCallback((e) => {
        e.preventDefault();
    
        // Set remember in local storage
        localStorage.setItem('remember', form.remember);
    
        if (!form.username || !form.pass)
            return;
        
        api.login(form.username, form.pass).catch(console.log);
    }, [api, form]);
    
    // --- login state message ------
    const loginState = useSelector(({api}) => api.loggedIn);
    const loading = useSelector(({loadingIds}) => loadingIds[LOGIN_LOADING_ID] || loadingIds['Login.me']);
    
    let loginMsg;
    switch (loginState) {
        case LOGIN_STATE.LOGIN_ERROR:
            loginMsg = 'Lo sentimos, hubo un error al iniciar sesión.';
            break;
        case LOGIN_STATE.BAD_CREDENTIALS:
            loginMsg = 'El usuario y contraseña no coinciden';
            break;
        default:
            loginMsg = null;
            break;
    }

    return (
            <form onSubmit={login}>
                <h2>Inicia sesión</h2>
                <WindInput className="modalInput" placeholder="Username" value={form.username} onChange={handleSimpleChange('username')}/>
                <WindInput className="modalInput" placeholder="Password" type='password' onChange={handleSimpleChange('pass')} value={form.pass} /> 
                <Button className="modalButton" loading={loading} type='submit' disabled={loading}>Log in</Button>
                {loginMsg && <p className='error'>{loginMsg}</p>}
            </form>
    );
}

export default LoginModalForm