
export const getEmptyTaxDocumentItem = () => ({
    id: null,
    productName: null,
    price: 0,
    taxes: [],
    tax: 0,
    quantity: 0,
    total: 0,
    iva: false,
    ieps: 0
})