// - poner el tipo de documento con descripción
// - 

import { getEmptyTaxDocumentItem } from "./taxDocumentItemUtils";
import _ from 'lodash';
import {copyPropertyIdOrNull,  copyPropertyOrNull, copyPropertyOrThrow, copyPropertyValueOrThrow} from "../formUtils";
import { ivaPercentage } from "../taxesConfig";
import { moneyFormatter } from "../currencyUtils";
import moment from "moment";
import { Link } from "react-router-dom";
import { paths } from "../routes/appRoutes";
import SwitchIgnore from "../../scenes/crm/InvoicesList/components/Ignore/SwitchIgnore";

export const taxDocumentsTableColumns = [
    {
        Header:"Folio",
        id: 'folio',
        accessor: (td) => {
            return(<Link to={paths.invoicesDetail.replace(':id', td.id)} data-tooltip={'Ver factura'} >
                    { (td.folio?td.folio:"Sin folio") }
                </Link>)
        },
        
    },
    {
        Header: "Tipo",
        id: 'documentType',
        accessor: td => <span>{transTaxDocumentTypes[td.documentType]||td.documentType}</span>
    },
    {
        Header:"Emisor",
        accessor: (td) => {
            return <span>{td.issuerName}</span>
        }
    },
    {
        Header:"Receptor",
        accessor: (td) => {
            return <span>{td.receiverName}</span>
        }
    },
    {
        Header:"Clase",
        accessor: (td) => {
            let type = "RECIBIDA";
            if ( td.isIssuer === true ) type = "ENVIADA";
            return ( <span>{type}</span> );
        }
    },
    {
        Header:"Cotizaciones",
        id: 'quotes',
        accessor: td => {
            let quotes = td.quotes.map( quote => quote.folio ).join(', ')||"- - -";
            return ( <span>{quotes}</span> );
        } 
    },
    {
        Header: "Total",
        id: 'Total',
        accessor: td => {
            return ( <span>{`$${moneyFormatter(td.total)}`}</span> );
        },
        Cell: ({ value }) => <div className="text-right">{value}</div>
    },
    {
        Header: 'Emitida',
        id: 'issuedAt',
        accessor: (td) => {
            return ( <span>{ (td.issuedAt? moment(td.issuedAt).format('DD/MM/YYYY HH:mm'):null) }</span> );
        } 
    },
    {
        Header:"Status",
        accessor: td => {
            return ( <span>{ taxDocumentStatusTrans[td.status]||'' }</span> );
        },
    },
    {
        Header:"Ignorar",
        Cell: (td) => { return (<SwitchIgnore taxDocument={td.row?.original} row={td.row} />) },
    }
];

export const taxDocumentStatus = {
    ACTIVE: "ACTIVE",
    RECEIVED: "RECEIVED",
    CANCELLED: "CANCELLED",
};

export const taxDocumentStatusTrans = {
    RECEIVED: "Recibida",
    CANCELLED: "Cancelada",
    ACTIVE: "Activa",
};

export const taxDocumentStatusOptions = _.map(taxDocumentStatus, (value, key) => {
    return { value: key, label: taxDocumentStatusTrans[key]||'' };
});

export const taxDocumentIsIssued = [
    { value: "0", label: 'RECIBIDA' },
    { value: "1", label: 'EMITIDA' },
];

export const taxDocumentStatusSearch = [
    { value: "ACTIVE", label: 'Activa' },
    { value: "CANCELLED", label: 'Cancelada' },
];

export const taxDocumentTableListSGroups = [
    'tax_document_read',
    'tax_document_item_read',
    'tax_document_read_tax_document_items',
    'tax_document_read_quotes',
    'client_read',
    'tax_document_read_bank_transactions',
];

export const taxDocumentSelectorSGroups = [
    'tax_document_read',
    'company_read',
    'client_read',
    'quote_read_folio',
];

export const taxDocumentDetailSGroups = [
    'tax_document_read',
    'tax_document_read_tax_document_items',
    'tax_document_read_pdf_file',
    'tax_document_read_xml_file',
    'tax_document_item_read',
    'company_read',
    'client_read',
    'quote_read_folio',
    'quote_read_total',
    'quote_read_project',
    'project_read_name',
    'app_file_read'
];

export const satCatalogSGroups = [
    'sat_catalog_read_catalog_key',
    'sat_catalog_read_description'
]    

export const taxDocumentMonths = [
    {month: 'Enero', id: '01'},
    {month: 'Febrero', id: '02'},
    {month: 'Marzo', id: '03'},
    {month: 'Abril', id: '04'},
    {month: 'Mayo', id: '05'},
    {month: 'Junio', id: '06'},
    {month: 'Julio', id: '07'},
    {month: 'Agosto', id: '08'},
    {month: 'Septiembre', id: '09'},
    {month: 'Octubre', id: '10'},
    {month: 'Noviembre', id: '11'},
    {month: 'Diciembre', id: '12'},
]

//Just for develop
/*export const getEmptyInvoice = () => {
    return{
        receiverRfc: "MASO451221PM4",
        receiverTaxRegime: {
            catalogKey: "612",
            description: "Personas Físicas con Actividades Empresariales y Profesionales"
        },
        receiverZipCode: "80290",
        receiverCompanyName: "MARIA OLIVIA MARTINEZ SAGAZ",
        cfdiUsage: {
            catalogKey: "G03",
            description: "Gastos en general."
        },
        tenantRfcId:100,
        issuerCompanyName: "ESCUELA KEMPER URGATE",
        issuerRfc: "EKU9003173C9",
        issuerTaxRegime: {
            catalogKey: "601",
            description: "General de Ley Personas Morales"
        },
        issuerZipCode: "64000",
        issueDate:new Date(),
        receiptType: {
            catalogKey: "I",
            description: "Ingreso"
        },
        paymentType: {
            "catalogKey": "03",
            "description": "Transferencia electrónica de fondos"
        },
        paymentMethod: {
            "catalogKey": "PUE",
            "description": "Pago en una sola exhibición"
        },
        export: {
            "catalogKey": "01",
            "description": "No aplica"
        },
        serie: null,
        folio: null,
        subtotal: null,
        transferredTax: null,
        withheldTax: null,
        total: null,
        exchangeRate: null,
        currency: {catalogKey: 'MXN', description: 'Peso Mexicano'},
        invoiceProducts: [getEmptyInvoiceProduct()],
    }
} */
//

export const updateInvoiceWithQuote = (quote) => {
    let issuerTaxRegime = '';
    let receiverTaxRegime = '';
    try {
        issuerTaxRegime = JSON.parse(quote.tenant?.tenantMainFiscalData?.taxRegime) || '';
    } catch {
        issuerTaxRegime = '';
    }
    try{
        receiverTaxRegime = JSON.parse(quote.client?.fiscalData?.taxRegime) || '';
    }catch{
        receiverTaxRegime = ''
    }

    return {
        issuerRfc: quote.tenant?.tenantMainFiscalData?.rfc || '',
        issuerZipCode: quote.tenant?.tenantMainFiscalData?.zip || '',
        issuerCompanyName: quote.tenant?.tenantMainFiscalData?.businessName || '',
        issuerTaxRegime: issuerTaxRegime,
        receiverRfc: quote.client?.fiscalData?.rfc || '',
        receiverZipCode: quote.client?.fiscalData?.zip || '',
        receiverCompanyName: quote.client?.fiscalData?.businessName || '',
        receiverTaxRegime: receiverTaxRegime,
        subtotal: quote.subtotal || 0,
        total: quote.total || 0,
        folio: quote.folio || '',
        receiptType: { catalogKey: "I", description: "Ingreso" },
        export: { catalogKey: "01", description: "No aplica" },
        invoiceProducts: quote.quoteProducts?.map((item) => ({
            description: item.name || '',
            unitValue: item.price || 0,
            quantity: item.quantity || 1,
            total: (item.price || 0) * (item.quantity || 1),
            applyTaxes: quote.iva
                ? { catalogKey: "02", description: "Sí objeto de impuesto." }
                : { catalogKey: "01", description: "No objeto de impuesto." },
            taxes: quote.iva
                ? [
                    {
                        taxType: { label: "Trasladado", value: "Trasladado" },
                        tax: { catalogKey: "002", description: "IVA" },
                        factor: { catalogKey: "Tasa", description: "44562" },
                        rate: "0.160000",
                        base: item.price || 0,
                        amount: ((item.price || 0) * 0.160000).toFixed(2),
                    },
                ]
                : [],
        })) || [],
    };
}

export const getEmptyInvoice = () => {
    return{
        receiverRfc: null,
        receiverTaxRegime: null,
        receiverZipCode: null,
        receiverCompanyName: null,
        cfdiUsage: null,
        tenantRfcId:null,
        issuerCompanyName: null,
        issuerRfc: null,
        issuerTaxRegime: null,
        issuerZipCode: null,
        issueDate:new Date(),
        receiptType: null,
        paymentType: null,
        paymentMethod: null,
        serie: null,
        folio: null,
        export: null,
        subtotal: null,
        transferredTax: null,
        withheldTax: null,
        total: null,
        exchangeRate: null,
        currency: {catalogKey: 'MXN', description: 'Peso Mexicano'},
        invoiceProducts: [getEmptyInvoiceProduct()],
    }
}

export const getEmptyInvoiceProduct = () => ({
    productKey: null,
    unitKey: null,
    total: 0,
    unitValue: 0,
    quantity: 0,
    applyTaxes: null,
    description: null,
    taxes:[getEmptyTaxes()]
})

export const getEmptyTaxes = () => ({
    tax:null,
    taxType:null,
    factor: null,
    rate:null,
    base: null,
    amount: null
})

export const getEmptyTaxDocument = () => {
    return {
        id: null,
        client: null,
        project: null,
        invoice_number: null,
        invoice_date: null,
        due_date: null,
        amount: null,
        currency: null,
        tax: null,
        tax_amount: null,
        total_amount: null,
        status: { value: taxDocumentStatus.RECEIVED, label: taxDocumentStatusTrans[taxDocumentStatus.RECEIVED]},
        notes: null,
        attachments: [],
        created_at: null,
        updated_at: null,
        taxDocumentItems: [
            getEmptyTaxDocumentItem()
        ],
        quotes: []
    };
}

export const taxDocumentTypes = {
    I: 'INVOICE', // income
    E: 'CREDIT_NOTE', // outcome
    P: 'PAYMENT',
    N: 'PAYROLL',
    T: 'MERCHANDISE_TRANSFER' // Merchandise Transfer (Traslado)
}

export const transTaxDocumentTypes = {
    I: 'Factura',
    E: 'Nota de crédito',
    P: 'Pago',
    N: 'Nómina',
    T: 'Traslado de mercancía'
}

export const transTaxDocumentTypesColor = {
    I: 'success',
    E: 'danger',
    P: 'danger',
    N: 'danger',
    T: 'warning'
}

export const taxDocumentTypesOptions = _.map(taxDocumentTypes, (value, key) => {
    return { value: key, label: value };
});

export const paymentTypes = {
    PUE: 'PUE',
    PPD: 'PPD'
}

export const paymentTypesTrans = {
    PUE: 'Pago en una sola exhibición',
    PPD: 'Pago en parcialidades'
}

export const paymentTypesOptions = _.map(paymentTypes, (value, key) => {
    return { value: key, label: paymentTypesTrans[key]||'' };
});


export const paymentMethods = {
    1:	'Cash',
    2:	'Payroll check',
    3:	'Electronic transfer',
    4:	'Credit card',
    5:	'Electronic wallet',
    6:	'Digital money',
    8:	'Pantry vouchers',
    12:	'Settlement',
    13:	'Payment by subrogation',
    14:	'Payment by consignment',
    15:	'Condonation',
    17:	'Compensation',
    23:	'Novation',
    24:	'Confusion',
    25:	'Debt remittance',
    26:	'Prescription or expiration',
    27:	'To creditor satisfaction',
    28:	'Debit card',
    29:	'Service card',
    99:	'To define'
}

export const paymentMethodsTrans = {
    1:	'Efectivo',
    2:	'Cheque nominativo',
    3:	'Transferencia electrónica de fondos',
    4:	'Tarjeta de crédito',
    5:	'Monedero electrónico',
    6:	'Dinero electrónico',
    8:	'Vales de despensa',
    12:	'Dación en pago',
    13:	'Pago por subrogación',
    14:	'Pago por consignación',
    15:	'Condonación',
    17:	'Compensación',
    23:	'Novación',
    24:	'Confusión',
    25:	'Remisión de deuda',
    26:	'Prescripción o caducidad',
    27:	'Satisfacción del acreedor',
    28:	'Tarjeta de débito',
    29:	'Tarjeta de servicios',
    99:	'Por definir'
}

export const paymentMethodsOptions = _.map(paymentMethods, (value, key) => {
    return { value: key, label: paymentMethodsTrans[key]||'' };
});


export const currency = {
    MXN: 'Mexican pesos',
    USD: 'US dollar',
    EUR: 'Euro',
}

export const currencyTrans = {
    MXN: 'Pesos mexicanos',
    USD: 'Dólar estadounidense',
    EUR: 'Euro'
}

export const currencyOptions = _.map(currency, (value, key) => {
    return { value: key, label: currencyTrans[key]||'' };
});


export const unitCode = {
    H87: 'Pieza',
    EA: 'Elemento',
    E48: 'Unidad de Servicio',
    ACT: 'Actividad',
    KGM: 'Kilogramo',
    E51: 'Trabajo',
    A9: 'Tarifa',
    MTR: 'Metro',
    AB: 'Paquete a granel',
    BB: 'Caja base',
    KT: 'Kit',
    SET: 'Conjunto',
    LTR: 'Litro',
    XBX: 'Caja',
    MON: 'Mes',
    HUR: 'Hora',
    MTK: 'Metro cuadrado',
    11: 'Equipos',
    MGM: 'Miligramo',
    XPK: 'Paquete',
    XKI: 'Kit (Conjunto de piezas)',
    AS: 'Variedad',
    GRM: 'Gramo',
    PR: 'Par',
    DPC: 'Docenas de piezas',
    xun: 'Unidad',
    DAY: 'Día',
    XLT: 'Lote',
    10: 'Grupos',
    MLT: 'Mililitro',
    E54: 'Viaje',
}

export const unitCodeTrans = {
    H87: 'Pieza',
    EA: 'Elemento',
    E48: 'Unidad de Servicio',
    ACT: 'Actividad',
    KGM: 'Kilogramo',
    E51: 'Trabajo',
    A9: 'Tarifa',
    MTR: 'Metro',
    AB: 'Paquete a granel',
    BB: 'Caja base',
    KT: 'Kit',
    SET: 'Conjunto',
    LTR: 'Litro',
    XBX: 'Caja',
    MON: 'Mes',
    HUR: 'Hora',
    MTK: 'Metro cuadrado',
    11: 'Equipos',
    MGM: 'Miligramo',
    XPK: 'Paquete',
    XKI: 'Kit (Conjunto de piezas)',
    AS: 'Variedad',
    GRM: 'Gramo',
    PR: 'Par',
    DPC: 'Docenas de piezas',
    xun: 'Unidad',
    DAY: 'Día',
    XLT: 'Lote',
    10: 'Grupos',
    MLT: 'Mililitro',
    E54: 'Viaje',
}

export const unitCodeOptions = _.map(unitCode, (value, key) => {
    return { value: key, label: unitCodeTrans[key]||'' };
});

export const getTotalFromDocumentItems = (taxDocumentItems) => {
    let total = 0;

    taxDocumentItems.forEach( (item) => {
        const itemAmount = Number(item.price) * Number(item.unitAmount);
        const discountAmount = item.discount ? item.discount : 0;
        const ivaAmount = item.iva ? item.iva * (itemAmount - discountAmount) : 0;        

        total += itemAmount - discountAmount + ivaAmount;
    });

    return total;
};

export const getDocumentTaxesTotal = (taxDocument) => {
    let total = 0;

    if(taxDocument.taxDocumentItems) {
        taxDocument.taxDocumentItems.forEach( (item) => {
            const itemAmount = item.price * item.unitAmount;
            const discountAmount = item.discount ? item.discount : 0;
            const ivaAmount = item.iva ? item.iva * (itemAmount - discountAmount) : 0;
            const iepsAmount = item.ieps ? item.ieps * (itemAmount - discountAmount) : 0;

            total += (ivaAmount + iepsAmount);
        });
    }

    return total;
}

export const getDocumentTotal = (document) => {
    let total = 0;

    if(document.taxDocumentItems) {
        total = getTotalFromDocumentItems(document.taxDocumentItems);
    }

    if(document.discount) {
        total = total * (1 - document.discount);
    }

    return total;
};

export const prepareInvoicePreferencesForServer = (form) => {
    let preferences = {}

    copyPropertyOrThrow(form, preferences, "cfdiUsage", "Por favor ingresa un uso de CFDI");
    copyPropertyOrThrow(form, preferences, "receiptType", "Por favor ingresa tipo de comprobante");
    copyPropertyOrThrow(form, preferences, "paymentType", "Por favor ingresa una forma de pago");
    copyPropertyOrThrow(form, preferences, "paymentMethod", "Por favor ingresa un método de pago");
    copyPropertyOrThrow(form, preferences, "export", "Por favor ingresa si es aplicale a exportación");

    return preferences
}

export const prepareTaxDocumentForServer = (form) => {
    let taxDocument = {}

    if(form.taxDocumentItems.length === 0) {
        throw new Error("El documento debe tener al menos un elemento de detalle");
    }

    copyPropertyValueOrThrow( form, taxDocument, "documentType", "Por favor selecciona el tipo de documento" );
    copyPropertyOrNull( form, taxDocument, "folio" );
    copyPropertyOrNull( form, taxDocument, "uuidFolioFiscal" );
    copyPropertyOrThrow( form, taxDocument, "issuedAt", "Por favor selecciona la fecha del documento" );
    copyPropertyIdOrNull( form, taxDocument, "client" );
    copyPropertyOrNull( form, taxDocument, "rfc" );
    copyPropertyValueOrThrow( form, taxDocument, "paymentType", "Por favor selecciona el tipo de pago" );
    copyPropertyValueOrThrow( form, taxDocument, "paymentMethod", "Por favor selecciona el método de pago" );
    copyPropertyValueOrThrow( form, taxDocument, "currency", "Por favor selecciona la moneda" );
    
    if(form.discount) {
        taxDocument.discount = form.discount;
    }

    const taxesTotal = getDocumentTaxesTotal(form);
    const subTotalDocument = getTotalFromDocumentItems(form.taxDocumentItems) - taxesTotal; 
    const discount = form.discount ?  form.discount : 0;
    const totalDocument = subTotalDocument + taxesTotal - discount;

    

    // if form has quotes validate sum of quotes is equal to total
    if(form.quotes && form.quotes.length > 0) {
        const quotesTotal = form.quotes.reduce( (total, quote) => {
            return total + parseFloat(quote.total);
        }, 0);

        if(totalDocument < quotesTotal) {
            throw new Error("El total la factura debe ser por lo menos igual a la suma de las cotizaciones");
        }
    }

    taxDocument.subtotal = subTotalDocument.toString();
    taxDocument.total = totalDocument.toString();
    taxDocument.discount = discount.toString();
    taxDocument.tax = taxesTotal.toString();
    taxDocument.isIssuer = true;
    taxDocument.retainedTaxes = '0';
    taxDocument.transferredTaxes = '0';
    taxDocument.paidAmount = totalDocument.toString();
    taxDocument.dueAmount = '0';    

    taxDocument.taxDocumentItems = form.taxDocumentItems.map( (item) => {
        let taxDocumentItem = {};

        copyPropertyOrNull( item, taxDocumentItem, "productIdentification" );
        copyPropertyOrThrow( item, taxDocumentItem, "description", "Por favor ingresa la descripción del producto" );
        copyPropertyValueOrThrow( item, taxDocumentItem, "unitCode", "Por favor selecciona la unidad de medida" );
        copyPropertyOrThrow( item, taxDocumentItem, "unitAmount", "Por favor ingresa la cantidad" );
        copyPropertyOrThrow( item, taxDocumentItem, "price", "Por favor ingresa el precio unitario" );
        copyPropertyOrThrow( item, taxDocumentItem, "discount", "Por favor ingresa el descuento" );
        copyPropertyOrNull( item, taxDocumentItem, "iva" );
        copyPropertyOrNull( item, taxDocumentItem, "ieps" );

        taxDocumentItem.ieps = (item.ieps ? item.ieps : 0).toString();
        taxDocumentItem.subtotal = taxDocumentItem.unitAmount * taxDocumentItem.price;
        taxDocumentItem.discountAmount = (taxDocumentItem.discount||0).toString();
        taxDocumentItem.ivaAmount = taxDocumentItem.iva ? ivaPercentage * (taxDocumentItem.subtotal - taxDocumentItem.discountAmount) : 0;
        taxDocumentItem.total = taxDocumentItem.subtotal - taxDocumentItem.discountAmount + taxDocumentItem.ivaAmount;
        taxDocumentItem.iva = (item.iva ? item.iva : 0).toString();
        taxDocumentItem.taxAmount = taxDocumentItem.ivaAmount.toString();
        
        return taxDocumentItem;
    });

    if(form.quotes && form.quotes.length > 0)
        taxDocument.quotes = form.quotes.map(q => q.id);
    
    return taxDocument;
}

export const taxesList = [
    { value: 'IVA', label: 'IVA', code: '002' },
    { value: 'IEPS', label: 'IEPS', code: '003' },
    { value: 'ISR', label: 'ISR', code: '001' },
];

export const xmlTextToObject = async(xmlText) => {
    const taxDocumentObject = {};

    const xmlContent = await xmlText.text();
    const parser = new DOMParser();
    const xml = parser.parseFromString(xmlContent, 'text/xml');

    const errorNode = xml.querySelector("parsererror");

    if (!errorNode) {
        const rootElement = xml.documentElement;

        //if(!rootElement)
        //    return;

        taxDocumentObject.documentType = taxDocumentTypesOptions.find( (option) => option.value === rootElement.getAttribute("TipoDeComprobante") );
        taxDocumentObject.issuedAt = rootElement.getAttribute("Fecha");
        taxDocumentObject.paymentType = paymentTypesOptions.find( (option) => option.value === rootElement.getAttribute("MetodoPago") );
        taxDocumentObject.uuidFolioFiscal = rootElement.getElementsByTagName("cfdi:Complemento")[0].getElementsByTagName("tfd:TimbreFiscalDigital")[0].getAttribute("UUID");
        taxDocumentObject.folio = rootElement.getAttribute("Folio");
        taxDocumentObject.paymentMethod = paymentMethodsOptions.find( (option) => parseInt(option.value) === parseInt(rootElement.getAttribute("FormaPago")) );
        taxDocumentObject.currency = currencyOptions.find( (option) => option.value === rootElement.getAttribute("Moneda") );
        taxDocumentObject.subtotal = rootElement.getAttribute("SubTotal");
        taxDocumentObject.discount = rootElement.getAttribute("Descuento");
        taxDocumentObject.total = rootElement.getAttribute("Total");
        taxDocumentObject.rfc = rootElement.getElementsByTagName("cfdi:Receptor") ? rootElement.getElementsByTagName("cfdi:Receptor")[0].getAttribute("Rfc") : '';

        // parse tax document items
        taxDocumentObject.taxDocumentItems = [];

        const conceptos = rootElement.getElementsByTagName("cfdi:Conceptos")[0].getElementsByTagName("cfdi:Concepto");
        for(let i = 0; i < conceptos.length; i++) {
            const concepto = conceptos[i];
            const taxDocumentItem = {};
            const taxes = {};
            const nodeImpuestos = concepto.getElementsByTagName("cfdi:Impuestos")[0];
            const nodeTraslados = nodeImpuestos ? nodeImpuestos.getElementsByTagName("cfdi:Traslados")[0] : null;

            // search taxes
            if(nodeTraslados) {
                const traslados = nodeTraslados.getElementsByTagName("cfdi:Traslado");
                for(let j = 0; j < traslados.length; j++) {
                    const traslado = traslados[j];
                    const tax = taxesList.find( (tax) => tax.code === traslado.getAttribute("Impuesto") );

                    if(tax) {
                        taxes[tax.value.toLowerCase()] = parseFloat( traslado.getAttribute("TasaOCuota") ).toFixed(2);
                    } else {
                        taxes[tax.value.toLowerCase()] = 0;
                    }
                }
            }
            
            taxDocumentItem.productIdentification = concepto.getAttribute("ClaveProdServ");
            taxDocumentItem.description = concepto.getAttribute("Descripcion");
            taxDocumentItem.unitCode = unitCodeOptions.find( (option) => option.value === concepto.getAttribute("ClaveUnidad") );
            taxDocumentItem.unitAmount = concepto.getAttribute("Cantidad");
            taxDocumentItem.price = parseFloat(concepto.getAttribute("ValorUnitario")).toFixed(2);
            taxDocumentItem.discount = concepto.getAttribute("Descuento")||0;
            taxDocumentItem.iva = taxes.iva ? taxes.iva : 0;
            taxDocumentItem.ieps = taxes.ieps ? taxes.ieps : 0;
            taxDocumentItem.ivaAmount = taxes.iva ? taxDocumentItem.unitAmount*taxDocumentItem.price*taxes.iva : 0;
            taxDocumentItem.iepsAmount = taxes.ieps ? taxDocumentItem.unitAmount*taxDocumentItem.price*taxes.ieps : 0;

            taxDocumentItem.subtotal = concepto.getAttribute("Importe");
            taxDocumentItem.discountAmount = concepto.getAttribute("Descuento");
            taxDocumentItem.total = concepto.getAttribute("Importe") + taxDocumentItem.ivaAmount;
            taxDocumentObject.taxDocumentItems.push(taxDocumentItem);
        }
    }

    return taxDocumentObject;
}

export const convertTaxDocumentToForm = (taxDocument) => {
    return {
        ...taxDocument,
        documentType: taxDocumentTypesOptions.find( (option) => option.value === taxDocument.documentType ),
        paymentType: paymentTypesOptions.find( (option) => option.value === taxDocument.paymentType ),
        paymentMethod: paymentMethodsOptions.find( (option) => parseInt(option.value) === parseInt(taxDocument.paymentMethod) ),
        currency: currencyOptions.find( (option) => option.value === taxDocument.currency ),
        status: taxDocumentStatusOptions.find( (option) => option.value === taxDocument.status ),        
        taxDocumentItems: taxDocument.taxDocumentItems.map( (taxDocumentItem) => {
            return {
                ...taxDocumentItem,
                discount: taxDocumentItem.discountAmount||0,
                unitCode: unitCodeOptions.find( (option) => option.value === taxDocumentItem.unitCode ),
            }
        }),
    }
}