import React, { useContext,  useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import TopBar from '../../../components/utility/TopBar/TopBar';
import { ApiContext } from '../../../services/api/api-config';
import { usersDetailSGroups } from '../../../services/modelUtils/userUtils';
import { getNotifier } from '../../../services/notifier';
import { paths } from '../../../services/routes/appRoutes';
import UserMainDetails from './components/UserMainDetails/UserMainDetails';
import SceneTitle from '../../../components/layout/SceneTitle/SceneTitle';

const UserDetail = () => {
  const api = useContext(ApiContext);
  const history = useHistory();

  const { id } = useParams();

  // ----- Load User data
  const customProp = 'UserDetail.get.'+id;

  useEffect(()=>{
    api.users.get({ id, customProp, params: { sGroups: usersDetailSGroups } })
      .catch(() => getNotifier().error('No se encontró el usuario solicitado'));
  },[api, id, customProp]);

  const user = useSelector(s=>s.api[customProp])||[];

  /* const handleAddUser = useCallback(() => history.push(paths.usersNew), [history]); */
/*
  const resendCredential = useCallback(  () => {
    let params = {
      params: { userId: id }
    }
    api.resendCredentials['create'](params).then( (data) => {
      getNotifier().success('Se han reenviado las credenciales para ' + data + '.');
    } );
  }, [api, id]); */

  const handleEdit = () => {
    history.push( paths.usersEdit.replace(':id', id) );
  }

  return (
    <div className={"UserDetail wind-scene"}>

      <TopBar />
      <SceneTitle description="Perfil de usuario">{user?.fullName}</SceneTitle>

      <UserMainDetails user={user} handleEdit={handleEdit} />
        
    </div>
  );
}

export default UserDetail;
