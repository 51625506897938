import { faCheck } from '@fortawesome/pro-light-svg-icons';
import React, { useCallback, useEffect } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import TopBar from '../../../components/utility/TopBar/TopBar';
import useFormState from '../../../hooks/useFormState';
import { ApiContext } from '../../../services/api/api-config';
import { getEmptyUser, prepareUserForServer, usersDetailSGroups } from '../../../services/modelUtils/userUtils';
import { getNotifier } from '../../../services/notifier';
import { paths } from '../../../services/routes/appRoutes';
import UserInfo from './components/UserInfo/UserInfo';
import { useParams } from 'react-router-dom';
import DeactivateUserConfirmModal from './components/DeactivateUserConfirmModal/DeactivateUserConfirmModal';
import useBoolean from '../../../hooks/useBoolean';
import UserAvatar from './components/UserAvatar/UserAvatar';
import UserPermissionsForm from '../UserDetail/components/UserPermissionsForm/UserPermissionsForm';
import Button from '../../../components/utility/Button/Button';
import { SecurityContext } from '../../../services/SecurityManager';

import './UserForm.scss';

const UserForm = () => {
  const { form, setForm, bindSimple } = useFormState(() =>getEmptyUser());
  const [ deactivateUserModal, deactivateUserModalOn , deactivateUserModalOff, ] = useBoolean(false);
  const history = useHistory();
  const api = useContext(ApiContext);

  const security = useContext(SecurityContext);
  const canAdminPermissions = security.canUpdateUserPermissionGroups();
  const urlParams = window.location.search;
  const parsedParams = new URLSearchParams(urlParams);

  const clientId = parsedParams.get('client');

  const { id } = useParams();

  // ----- Load User data
  const loadUserData = 'UserDetail.get.'+id;

  const reloadUser = useCallback(() => {
    api.users.get({ id, loadingId: loadUserData, params: { sGroups: usersDetailSGroups } })
      .then(user => {
        setForm(user);
      })
      .catch(() => getNotifier().error('No se encontró el usuario solicitado'));
  }, [api, id, loadUserData, setForm]);

  const loadClient = useCallback(() => {
    api.clients.get({ id: clientId }).then(client => setForm( form => ({...form, client})));
  }, [api, clientId, setForm]);

  useEffect(()=>{ if(id) { reloadUser() } },[id, reloadUser]);

  useEffect(()=> { if(clientId) { loadClient() }}, [loadClient, clientId]);

  // ----- Save User data
  const loadingId = 'UserForm.create';

  const handleSave = useCallback(()=>{
    const { id } = form;
    const apiMethod = id ? 'update' : 'create';

    let user;
    try{
        user = prepareUserForServer({...form });
    }
    catch (e){
        return getNotifier().warning(e.message);
    }

    const params = { id, params: user, loadingId };
    api.users[apiMethod](params)
      .then((user)=>{
        getNotifier().success(`El usuario se ha ${id?'editado':'creado'} correctamente`);
        history.replace( paths.usersDetail.replace(':id', user.id) );
      })
      .catch()
  }, [form, api, history]);

  
  const Avatar = <UserAvatar user={form} className="avatar" reloadUser={reloadUser} />;

  return (
    <div className={"UserForm  wind-scene"}>

      <TopBar />

      <div className="userform-header">
        <div className="single-title">Información general</div>
        <div>
          <Button onClick={!form.isActive?() => deactivateUserModalOn():handleSave} leftIcon={faCheck} >
            Guardar cambios
          </Button>
        </div>
      </div>
      <div className="userform-header">
        <div className="">{Avatar}</div>
        <UserInfo bindSimple={bindSimple} userId={id} />
      </div>
      
      

      {
        id &&  canAdminPermissions && <UserPermissionsForm user={form} />
      }

      {deactivateUserModal && <DeactivateUserConfirmModal onConfirm={handleSave} onClose={deactivateUserModalOff} />}
    </div>
  );
}

export default UserForm;
