import React from 'react'
import WindDatePicker from '../../../../../../formComponents/WindDatePicker/WindDatePicker';

const SearchBarDateFilter = ({ value, onChange, config }) => {
return (
        <div className={"SearchBarDateFilter SearchBarFilter"}>
            <WindDatePicker 
                className='searchbar-date-filter'
                value={value} 
                onChange={onChange} 
                label={config?.label}
            />
        </div>
    )
}

export default SearchBarDateFilter;