// - poner el tipo de documento con descripción
// - 

import { copyPropertyOrThrow, copyPropertyOrNull } from "../formUtils";

export const satWsStatus = {
    PENDING: "pending",
    VALID: "valid",
    INVALID: "invalid",
    DEACTIVATED: "deactivated",
    ERROR: "error",
};


export const prepareTenantRfcForServer = (form, update=false) => {
    let tenantRfc = {}
    if ( !update ) {
        copyPropertyOrThrow( form, tenantRfc, "rfc", "Por favor escribe un RFC" );
    }
    else {
        copyPropertyOrNull( form, tenantRfc, "rfc", "rfc" );
    }
    copyPropertyOrThrow( form, tenantRfc, "ciec", "Por favor escribe tu clave CIEC" );
    
    return tenantRfc;
}

export const convertTenantRfcToForm = (rfcData) => {
    return {
        ...rfcData,
    }
}

export const prepareRfcForServer = (form) =>{

    let tenantRfc={}
    let fiscalData={}

    copyPropertyOrThrow( form, tenantRfc, "rfc", "Por favor escribe un RFC" );
    copyPropertyOrNull( form, tenantRfc, "is_sat_ws_configured", "is_sat_ws_configured" );
    copyPropertyOrNull( form, tenantRfc, "is_first_data_extracted", "is_first_data_extracted" );


    copyPropertyOrThrow( form, fiscalData, "rfc", "Por favor escribe un RFC" );
    copyPropertyOrThrow( form, fiscalData, "zip", "Por favor escribe un código postal" );
    copyPropertyOrThrow( form, fiscalData, "businessName", "Por favor escribe una razón social" );
    copyPropertyOrThrow( form, fiscalData, "taxRegime", "Por favor selecciona un régimen fiscal" );
    copyPropertyOrThrow( form, fiscalData, "email", "Por favor escribe un email" );
    copyPropertyOrNull( form, fiscalData, "street", "street" );
    copyPropertyOrNull( form, fiscalData, "externalNumber", "externalNumber" );
    copyPropertyOrNull( form, fiscalData, "internalNumber", "internalNumber" );
    copyPropertyOrNull( form, fiscalData, "neighborhood", "neighborhood" );
    copyPropertyOrNull( form, fiscalData, "delegation", "delegation" );
    copyPropertyOrNull( form, fiscalData, "city", "city" );
    copyPropertyOrNull( form, fiscalData, "state", "state" );

    return {tenantRfc: tenantRfc, fiscalData:fiscalData}
}


export const getEmptyRfc = () => {

    return {
        rfc: null,
        businessName: null,
        zip: null,
        street: null,
        externalNumber: null,
        internalNumber: null,
        neighborhood: null,
        delegation: null,
        city: null,
        state: null,
        email:null,
        taxRegime:null,
        is_sat_ws_configured: false,
        is_first_data_extracted: false,
        is_main_rfc: false,
    }
}


export const getEmptyTenantRfc = () => {
    return {
        id: null,
        rfc: null,
        ciec: null,
    };
}