import React from 'react';
import Modal from '../../../../../components/utility/Modal/Modal';

const DeactivateUserConfirmModal = ({ onConfirm, onClose }) => {
  return (
    <Modal
      className={"DeactivateUserConfirmModal"}
      title={"Editar usuario"}
      onClose={() => onClose()}

      mainButtonText='Aceptar'
      mainButtonColor='success'
      mainButtonAction={onConfirm}

      secondaryButtonColor='danger'
      secondaryButtonAction={() => onClose()}
    >
      <div className="DeactivateUserConfirmModal__content">
        Al realizar esta acción, el usuario estará inactivo y no podrá iniciar sesión. ¿Estás seguro de que deseas continuar?
      </div>
  </Modal>
  );
}
 
export default DeactivateUserConfirmModal;
