import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useParams} from "react-router-dom";
import './BankAccountDetail.scss'
import {ApiContext} from "../../../services/api/api-config";
import TopBar from "../../../components/utility/TopBar/TopBar";
import {bankAccountDetailSGroups} from "../../../services/modelUtils/bankAccountUtils";
import { moneyFormatter } from '../../../services/currencyUtils';
import {bankTransactionSGroups, transactionStatus, TRANSACTION_TYPE } from '../../../services/modelUtils/transactionUtils';
import LineChart from './components/LineChart';
import moment from 'moment';
import Button from '../../../components/utility/Button/Button';
import { useHistory } from 'react-router-dom';
import { paths } from '../../../services/routes/appRoutes';
import { SecurityContext } from '../../../services/SecurityManager';

const firstDayOfThisMonth = new Date();
firstDayOfThisMonth.setDate(1);
firstDayOfThisMonth.setHours(0, 0, 0, 0);

//const currentMonth = ( new Date() ).toLocaleString('default', { month: 'short' });
//const currentMonthName = currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
//const arrayOfDaysOfCurrentMonth = Array.from({length: moment().daysInMonth()}, (v, k) => k + 1)
//        .map( day => `${currentMonthName} ${day}` );

const lastMonthDate = moment().subtract(30, 'days');
const todayDate = moment();

const arrayOfDaysFromLastMonthToToday = Array.from({length: todayDate.diff(lastMonthDate, 'days') + 1}, (_, i) => 
    lastMonthDate.clone().add(i, 'days').format('MMM D')
);

const BankAccountDetail = () => {

    const api = useContext(ApiContext);
    const {id} = useParams();
    const history = useHistory();
    const security = useContext(SecurityContext);
    // ----- Load project data
    const [bankAccount, setBankAccount] = useState();
    const [bankTransactions, setBankTransactions] = useState([]);

    useEffect( ()=>{
        const bankAccountFilters = {id, params: {sGroups: bankAccountDetailSGroups}};
        api.bankAccounts.get(bankAccountFilters).then(setBankAccount);

        const transactionsFilters = {  params: {"bankAccount.id": id, 'date[after]': lastMonthDate.format('YYYY-MM-DD'), 'pagination': false, sGroups: bankTransactionSGroups}};
        api.bankTransactions.get(transactionsFilters).then(transactions => transactions? setBankTransactions(transactions) : setBankTransactions([]) )        
    },[api, id])

    const approvedTransactions = useMemo(() =>
        bankTransactions?.filter(transaction => transaction.status === "PROCESSED")
    , [bankTransactions]);

    const amountApprovedTransactionsGroupedByDay = useMemo(() => 
        arrayOfDaysFromLastMonthToToday.map(day => {
            const transactionsByDay = approvedTransactions.filter(transaction => 
                moment(transaction.date).format('MMM D') === day
            );
            return transactionsByDay.reduce((acc, transaction) => acc + parseFloat(transaction.amount), 0);
        })
    , [approvedTransactions]);

    
    const pendingExpenseTransactionsAmount = useMemo(() =>
        bankTransactions?.filter(transaction => transaction.status === transactionStatus.PENDING && transaction.transactionType === TRANSACTION_TYPE.EXPENSE)
            .reduce((acc, transaction) => acc + parseFloat(transaction.amount), 0)
    , [bankTransactions]);

 /*   const pendingOrApprovedTransactions = useMemo(() =>
        bankTransactions?.filter(transaction => transaction.status === transactionStatus.PENDING || transaction.status === transactionStatus.APPROVED)
    , [bankTransactions]); */

    const incomesTransactionsTotal = useMemo(() =>
        approvedTransactions?.filter(transaction => transaction.transactionType === TRANSACTION_TYPE.INCOME)
            .reduce((acc, transaction) => acc + parseFloat(transaction.amount), 0)
    , [approvedTransactions]);
    
    /*const amountApprovedAndPendingTransactionsGroupedByDay = useMemo(() => Array.from({length: moment().daysInMonth()}, (v, k) => k + 1)
        .map( day => {
            const transactionsByDay = pendingOrApprovedTransactions.filter(transaction => moment(transaction.transactionDate).date() === day);
            return transactionsByDay.reduce((acc, transaction) => acc + parseFloat(transaction.amount), 0);
        })
    , [pendingOrApprovedTransactions]); */

    const showTransactions = () => {
        history.push( paths.financeAccountTransactions.replace(":bankAccount", bankAccount.id) );
    }
    
    return (
        <div className={"BankAccountDetail wind-scene"}>
            <TopBar
                title="Cuenta"
                titleLinkBack
            />

            <div className='center-container'>
                <div className='bank-account-stats shadow-bordered'>
                    <h2 className='name SceneTitle secondary'>
                        <span className='title-content'>{bankAccount?.bank?.name} - {bankAccount?.number}</span>
                    </h2>
                    <div className='bank-account-stat-grid'>
                        <div className='stat'>
                            <p className='money'>${ moneyFormatter(bankAccount?.balance) }</p>
                            <p className='stat-name'>Saldo disponible</p>
                        </div>
                        <div className='stat'>
                            <p className='money'>${ moneyFormatter(Math.abs(pendingExpenseTransactionsAmount)) }</p>
                            <p className='stat-name'>Retiros pendientes del mes</p>
                        </div>
                        <div className='stat'>
                            <p className='money'>${ moneyFormatter(parseFloat(bankAccount?.balance) + pendingExpenseTransactionsAmount) }</p>
                            <p className='stat-name'>Saldo real</p>
                        </div>
                        <div className='stat'>
                            <p className='money'>$ { moneyFormatter( incomesTransactionsTotal ) } </p>
                            <p className='stat-name'>Depósitos del mes</p>
                        </div>
                    </div>
                    {
                    security.canAdminBankTransactions() && (bankAccount?.belvoLink || bankAccount?.syncfyCredential) ? 
                    <div className={"bank-account-stat-grid"}>
                        <div className="stat"></div><div className="stat"></div><div className="stat"></div>
                        <div className="stat">
                            <Button onClick={showTransactions}>
                                Ver transacciones
                            </Button>
                        </div>
                    </div>
                    :
                    <p></p>
                }
                </div>

                
                

                <div className='bank-account-history shadow-bordered'>
                    <h2 className='SceneTitle secondary'>
                        <span className='title-content'>Historial</span>
                    </h2>
                    <LineChart dataX={arrayOfDaysFromLastMonthToToday} dataY={amountApprovedTransactionsGroupedByDay} />
                </div>
                {/** 
                    <div className='bank-account-projection shadow-bordered'>
                        <h2 className='SceneTitle secondary'>
                            <span className='title-content'>Proyección</span>
                        </h2>
                        <LineChart dataX={arrayOfDaysOfCurrentMonth} dataY={amountApprovedAndPendingTransactionsGroupedByDay} />
                    </div>
                */}
            </div>
        </div>
    );
};

export default BankAccountDetail;
