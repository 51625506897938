import React, { useEffect, useContext, useState, useMemo } from "react";
import { ApiContext } from "../../../../../services/api/api-config";
import { useParams } from "react-router-dom";
import { projectDetailSGroups } from "../../../../../services/modelUtils/projectUtils";
import AclPermissionsForm from "../../../../../components/crmComponents/AclPermissionsForm/AclPermissionsForm";

const ProjectPermission = () => { 
    const api = useContext(ApiContext);
    const { id } = useParams();

    const [project, setProject] = useState();
    const apiConfig = useMemo( () => ( { id, params:{ sGroups: projectDetailSGroups } } ),
        [id]);
    useEffect(()=>{
        api.projects.get(apiConfig).then(setProject);
    },[api, apiConfig]);
    
    return (
        <div className="ProjectInfo">
            {
                project ?
                <AclPermissionsForm entity="project" entityObject={project} hideGeneral={true} />
                : null
            }
        </div>
    );

}

export default ProjectPermission;
