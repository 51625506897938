import React from 'react';
import './UserAvatar.scss';
import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import { ApiContext } from '../../../../../services/api/api-config';
import { getUserAvatarUrl } from '../../../../../services/modelUtils/userUtils';
import {isImage} from "../../../../../services/fileUtils";
import { getNotifier } from '../../../../../services/notifier';

const UserAvatar = ({ user, className, reloadUser }) => {
  const api = useContext(ApiContext);
  
  const style = useMemo(() => {
    return { backgroundImage: `url(${getUserAvatarUrl(user, api)})` }
  }, [user, api]);

  const handleImageChange = (e) => {
    if(!e?.target?.files?.length)
      return;

    const file = e.target.files[0];
    if(!isImage(file.name))
        return getNotifier().error('Debes seleccionar un archivo de tipo imagen');

    api.users.update({
      id: user.id, 
      params:{},
      files: {avatar: file}
    }).then(reloadUser);
  };

  return (
    <div className={classNames("UserAvatar", className)} style={user && style}>
      <div className='image-container'>
        <div className='click-overlay'>
            Click para cambiar
        </div>
        <input type='file' className='file-input' onChange={handleImageChange} key={Math.random()}/>
      </div>
    </div>
  );
}
 
export default UserAvatar;