import React, {useState, useRef} from "react";
import { faFileImport } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Button/Button";
import fileIcon from "../../../assets/images/file-icon.png";
import './DragAndDrop.scss'

const DragAndDrop = ({onSave, setOuterFile=null, allowedFilesLabel="PNG, JPG, SVG"}) =>{

    const [droppedFiles, setDroppedFiles] = useState(null);
    const [file, setFile] = useState(null);
    const [dragOver, setDragOver] = useState(false); 
    const fileInputRef = useRef(null)

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true); 
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragOver(true); 
    };

    const handleDragLeave = (e) => {
        setDragOver(false);  
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragOver(false)
        const files = e.dataTransfer.files[0];
        previewFile(files);
    };

    const handleFileSelect = (e) => {
        const files = e.target.files[0];
        previewFile(files);
    };

    const previewFile = (file) => {

        if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg')) {
            if(setOuterFile){
                setOuterFile(file)            
            }
            setFile(file)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setDroppedFiles({ dataUrl: reader.result, name: file.name });
            };
        } else {
            if(setOuterFile){
                setOuterFile(file)            
            }
            setFile(file)
            setDroppedFiles({ dataUrl: fileIcon, name: 'file' });
        }
    };

    const deleteImage = () => {
        setDroppedFiles(null)
        setDragOver(false)
        if(setOuterFile){
            setOuterFile(null)            
        }
        setFile(null)
    } 

    const handleClick = () => {
        fileInputRef.current.click();
    };
    return (
        <div>
            <div 
            onClick={handleClick}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={`drop-area ${dragOver ? 'drag-over' : ''}`}
            >

                {!droppedFiles && (
                    <div>
                        <FontAwesomeIcon icon={faFileImport} className="drop-icon"/>
                        <p className="drop-files-text">{allowedFilesLabel}</p>
                    </div>
                )}            

                {droppedFiles && (
                    <div className="drop-image-container">
                        <img src={droppedFiles.dataUrl} alt={droppedFiles.name} className="drop-image"/>
                                            
                    </div>
                )}
            </div>

            {droppedFiles && (
                <div className="action-btn-container">
                    <Button onClick={deleteImage} outline color={'danger'}>Eliminar</Button>
                    {!setOuterFile && <Button className="action-btn" onClick={()=>{onSave(file, setDroppedFiles)}}>Guardar</Button>}
                </div>
            )}

            <input 
                type="file" 
                ref={fileInputRef} 
                style={{ display: 'none' }} 
                onChange={handleFileSelect} 
            />
        </div>
    )
    
}

export default DragAndDrop