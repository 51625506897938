import React, {useContext} from "react";
import MoneyFlow from "../../../components/widgets/MoneyFlow/MoneyFlow";
import InvoicesOverview from "../../../components/widgets/InvoicesOverview/InvoicesOverview";
import AccountsOverview from "../../../components/widgets/AccountsOverview/AccountsOverview";
import SectionHeader from '../../../components/utility/SectionHeader/SectionHeader';
import { FinanceHeaderInfo } from '../../../constants/SectionHeaderInfo';
import { SecurityContext } from "../../../services/SecurityManager";
import logo from "../../../assets/images/wind-logo-hr.png"
import "./FinanceHome.scss"

const FinanceHome = () => {

    const security = useContext(SecurityContext);
    const canAdminTaxDocuments = security?.canAdminTaxDocuments()    

    return(
        <div className="FinanceHome wind-scene">

            <SectionHeader pageInfo={FinanceHeaderInfo}/>    
            {canAdminTaxDocuments && 
                <div className="finance-content">
                    <div className="widgets-left">
                        <MoneyFlow />
                    </div>
                    <div className="widgets-right">
                        
                        <InvoicesOverview />
                        <AccountsOverview />
                    </div>
                </div>
            }
            
            {!canAdminTaxDocuments &&
                          
                <div className="logo-content">
                    <img src={logo} alt="Wind" className='wind-logo'/>
                </div>
                
            }
        </div>
    );

}

export default FinanceHome;