import React, {useContext, useEffect} from 'react'
import WindInput from '../../../../../components/formComponents/WindInput/WindInput';
import WindSwitch from '../../../../../components/formComponents/WindSwitch/WindSwitch';
import useFormState from '../../../../../hooks/useFormState';
import Modal from '../../../../../components/utility/Modal/Modal';
import { ApiContext } from '../../../../../services/api/api-config';
import { prepareUserForServer, getEmptyUser } from '../../../../../services/modelUtils/userUtils';
import { getNotifier } from '../../../../../services/notifier';
import { useCallback } from 'react';
import './ContactInfo.scss';

const ContactModal = ({client, onClose, onSaveSuccess}) => {

    const {form, bindSimple, setForm} = useFormState(() =>getEmptyUser());
    const api = useContext(ApiContext);
 
    useEffect(() => {
        setForm(currentForm => ({ ...currentForm, client: client }));
      }, [client, setForm]);

    const close = useCallback(() => {
        if (onClose) onClose()
    }, [onClose]);

    const loadingId = 'UserForm.create';
    const saveContact = () => {
        let user;
        try{
            user = prepareUserForServer({...form})
        } catch (e){
            return getNotifier().warning(e.message);
        }
        const params = { params: user, loadingId };
        api.users.create(params).then((user)=>{
            getNotifier().success(`El usuario se ha creado correctamente`);
            if (onSaveSuccess) onSaveSuccess(); 
            close();
          })
          .catch()
    }
    
    return (
        <Modal title="Nuevo contacto"
        onClose={close}
        secondaryButtonAction={close}
        mainButtonAction={ saveContact }
        mainButtonText="Guardar"
        >
        <div className="ClientForm">
    
            <div className="fields-row">
                <WindInput 
                    className='fields-field'
                    placeholder={"Nombre del contacto"}
                    {...bindSimple('name')}
                    inputProps={{maxLength:100}}
                />
                <WindInput 
                    className='fields-field'
                    placeholder={"Apellido del contacto"}
                    {...bindSimple('lastName')}
                    inputProps={{maxLength:100}}
                />
                
                <WindInput 
                    className='fields-field'
                    placeholder={"Email de contato"}
                    {...bindSimple('email')}
                    inputProps={{maxLength:60}}
                />
            </div>

            <div className="fields-row">
                
                <WindInput 
                    className='fields-field'
                    placeholder={"Teléfono de contacto"}
                    {...bindSimple('phone')}
                    inputProps={{maxLength:20}}
                />
                <WindSwitch
                    className='fields-field'
                    placeholder={'Enviar credenciales'}
                    {...bindSimple('sendAccess')}
                />
                <div className='fields-field'>&nbsp;</div>
            </div> 
        </div>

        </Modal>

    )
}

export default ContactModal;