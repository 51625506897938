import React from "react";
import Button from "../Button/Button";

import "./CenteredButtons.scss"

const CenteredButtons = ({ buttons }) => {
    if ( !Array.isArray(buttons) ) return null;

    return(
        <div className="CenteredButtons">
            { buttons.map( (button) => 
                <span key={button.text}>
                    <Button onClick={button.callback} outline={button.outline? true: false} >{button.text}</Button>
                </span> 
            ) }
        </div>
    );

}

export default CenteredButtons;