import React, {useContext, useEffect} from 'react';
import {ApiContext} from "../../../services/api/api-config";
import { paths } from "../../../services/routes/notLoggedRoutes";
import LoginForm from '../../../components/LoginForm/LoginForm';

const Logout = () => {
    const api = useContext(ApiContext);
    useEffect(()=>{
        // goto login
        api.logout().then( ()=> window.location.href = paths.login );
    },[api]);

    return (
        <LoginForm />
    );
};

export default Logout;
