import { useState, useContext, useEffect } from "react"
import { ApiContext } from "../../../../../services/api/api-config";
import IconButton from "../../../../../components/utility/IconButton/IconButton";
import { faToggleOff, faToggleOn } from "@fortawesome/pro-light-svg-icons";
import { getNotifier } from "../../../../../services/notifier";

import "../../InvoicesList.scss"

const SwitchIgnore = ({ taxDocument }) => {
    const api = useContext(ApiContext);
    const [ ignored, setIgnored ] = useState( false )

    useEffect(
        () => setIgnored(taxDocument.isIgnored),
        [setIgnored, taxDocument]
    );

    const ignore = () => {
        const params = {
            id: taxDocument.id,
            params: {
                isIgnored: !ignored
            }
        };

        api.taxDocuments['update'](params).then(() => {
            getNotifier().success('Se ha guardado correctamente');
            setIgnored( !ignored );
        });

    }
    
    return(
        <IconButton 
            onClick={ignore}
            className={'ignore-button'}
            icon={ ignored? faToggleOn: faToggleOff}
            color={ ignored? 'success' :'danger' }
        />
    );
}

export default SwitchIgnore;