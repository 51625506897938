import { useCallback, useContext } from "react";
import Modal from "../../../../../components/utility/Modal/Modal"
import WindInput from '../../../../../components/formComponents/WindInput/WindInput';
import TideEntitySelect from "../../../../../components/utility/TideEntitySelect/TideEntitySelect";
import { satCatalogSGroups } from "../../../../../services/modelUtils/taxDocumentUtils";
import useFormState from "../../../../../hooks/useFormState";
import { ApiContext } from "../../../../../services/api/api-config";
import { getEmptyRfc, prepareRfcForServer } from "../../../../../services/modelUtils/tenantRfcUtils";
import { getNotifier } from "../../../../../services/notifier";
import './RegisterRfcModal.scss'

const RegisterRfcModal = ({onClose, onActionSuccess}) => {

    const api = useContext(ApiContext);
    const { form, setForm,bindSimple } = useFormState(getEmptyRfc());

    const close = useCallback(() => {
        if (onClose) onClose()
    }, [onClose]);

    const save = useCallback(() =>{
        try{
            const serverData = prepareRfcForServer(form)
            api.tenantRfcs.create({params: serverData.tenantRfc}).then(()=>{
                api.fiscalDatas.create({params: serverData.fiscalData}).then(()=>{
                    getNotifier().success("RFC agregado")
                    if (onActionSuccess) onActionSuccess(); 
                })
            })

            close()
        }catch(e){
            getNotifier().error("Error: ", e)
        }

    }, [form, api, close, onActionSuccess])

    return(
        <Modal title={"Nuevo RFC"}
            onClose={close}
            secondaryButtonAction={close}
            mainButtonAction={save}
            mainButtonText={"Guardar"}>

            <div className="input-row">

                <WindInput 
                    placeholder={"RFC*"}
                    value={form.rfc}
                    {...bindSimple('rfc')}
                />
                <WindInput 
                    placeholder={"Razón Social*"}
                    value={form.rfc}
                    {...bindSimple('businessName')}
                />

                <div>
                    <label>Régimen Fiscal</label>
                    <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Regimen Fiscal'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_RegimenFiscal"
                        }}
                        preload
                        filterBy={'description'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        value={JSON.parse(form.taxRegime)}
                        onChange={(value) => {setForm((prevForm)=>({...prevForm, taxRegime:JSON.stringify(value)}))}}
                    />
                </div>   

            </div>

            <div className="input-row">
                <WindInput 
                    placeholder={"Código Postal*"}
                    value={form.rfc}
                    {...bindSimple('zip')}
                />

                <WindInput 
                    placeholder={"Calle"}
                    value={form.rfc}
                    {...bindSimple('street')}
                />
                <WindInput 
                    placeholder={"Número exterior"}
                    value={form.rfc}
                    {...bindSimple('externalNumber')}
                />


            </div>

            <div className="input-row">
                <WindInput 
                    placeholder={"Número interior"}
                    value={form.rfc}
                    {...bindSimple('internalNumber')}
                />

                <WindInput 
                    placeholder={"Colonia"}
                    value={form.rfc}
                    {...bindSimple('neighborhood')}
                />

                <WindInput 
                    placeholder={"Municipio o Alcaldía"}
                    value={form.rfc}
                    {...bindSimple('delegation')}
                />


            </div>

            <div className="input-row">
                <WindInput 
                    placeholder={"Ciudad"}
                    value={form.rfc}
                    {...bindSimple('city')}
                />

                <WindInput 
                    placeholder={"Estado"}
                    value={form.rfc}
                    {...bindSimple('state')}
                />
                <WindInput 
                    placeholder={"Email*"}
                    value={form.rfc}
                    {...bindSimple('email')}
                />
            </div>


        </Modal>
    )
}

export default RegisterRfcModal