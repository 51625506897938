import {useEffect, useContext, useCallback } from "react";
import _ from "lodash";
import useFormState from "../../../hooks/useFormState";
import SimpleCard from "../../../components/layout/SimpleCard/SimpleCard"
import TopBar from "../../../components/utility/TopBar/TopBar";
import { ApiContext } from "../../../services/api/api-config";
import { getEmptyTaxDocument } from "../../../services/modelUtils/taxDocumentUtils";
import { convertTaxDocumentToForm } from "../../../services/modelUtils/taxDocumentUtils";
import { moneyFormatter } from "../../../services/currencyUtils";
import { taxDocumentDetailSGroups } from "../../../services/modelUtils/taxDocumentUtils";
import DragDropFile from "../../../components/formComponents/DragDropFile/DragDropFile";
import { getNotifier } from "../../../services/notifier";
import { useParams } from "react-router-dom";
import Button from "../../../components/utility/Button/Button";
import './NewInvoiceDetail.scss'

const NewInvoiceDetail = () =>{
    const api = useContext(ApiContext);
    const { form, setForm } = useFormState(() => getEmptyTaxDocument() );

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            api.taxDocuments
                .get({ id: id, params: { sGroups: taxDocumentDetailSGroups }, customProp: '_' })
                .then((taxDocument) => {
                    setForm(() => {
                        const updatedForm = convertTaxDocumentToForm(taxDocument);
                        return updatedForm;
                    });
                });
        }
    }, [id, api, setForm]);

    const handleFileChange = useCallback(async(type,file) => {

        try{
            let fileType = null
            type === "xml" ? fileType="xmlFile" : fileType="pdfFile"
    
            const result =  await api.taxDocuments.update({id: id, 
                params: {sGroups: taxDocumentDetailSGroups},
                files:{[fileType]:file}}
            )

            setForm(result)
            getNotifier().success("Archivos actualizados correctamente")
        }catch(e){
            getNotifier().error(e)
        }


    }, [setForm, api, id]);

    const handleFileRemove = useCallback(async(type) => {
        
        if(type==="pdf"){
            setForm(prevState => ({ ...prevState, pdfFile: null }));
            await api.taxDocuments.update({id:id, params: {
                pdfFile: null
            }})
        }else if(type==="xml"){
            setForm(prevState => ({ ...prevState, xmlFile: null }));
            await api.taxDocuments.update({id:id, params: {
                xmlFile: null
            }})
        }

    }, [setForm, api, id]);


    const downloadFile = (type) => {

        const url = type === "xml" ? api?.appFiles.getUrl(form.xmlFile) : api?.appFiles.getUrl(form.pdfFile);
        if (url) {
            window.open(url, "_blank", "noopener,noreferrer");
        }
    }
    return(
        <div className={"NewInvoiceDetail wind-scene"}>

            <TopBar title="Detalle de factura"/>
            
            <div className="display-flex">
                <SimpleCard title="Emisor">
                    <div className="grid-container bg-card">
                        <div>
                            <p className="font-bold">Razón Social</p>
                            <p>{form.issuerName}</p>
                        </div>

                        <div>
                            <p className="font-bold">RFC</p>
                            <p>{form.rfc}</p>
                        </div>

                        <div>
                            <p className="font-bold">Régimen fiscal</p>
                            <p>-</p>
                        </div>

                    </div>
                </SimpleCard>

                <SimpleCard title="Receptor">
                    <div className="grid-container bg-card">
                        <div>
                            <p className="font-bold">Razón Social</p>
                            <p>{form.receiverName}</p>
                        </div>

                        <div>
                            <p className="font-bold">RFC</p>
                            <p>{form.receiverRfc}</p>
                        </div>

                        <div>
                            <p className="font-bold">Régimen fiscal</p>
                            <p>-</p>
                        </div>

                    </div>
                </SimpleCard>
            </div>

            <SimpleCard title="Datos Generales">
                <div className="grid-container bg-card">
                    <div>
                        <p className="font-bold">Fecha de Emisión</p>
                        <p>{form.issuedAt}</p>
                    </div>

                    <div>
                        <p className="font-bold">Tipo de Documento</p>
                        <p>{form.documentType?.label || "-"}</p>
                    </div>

                    <div>
                        <p className="font-bold">Tipo de Pago</p>
                        <p>{form.paymentType?.label || "-"}</p>
                    </div>

                    <div>
                        <p className="font-bold">Forma de Pago</p>
                        <p>{"Pendiente"}</p>
                    </div>

                    <div>
                        <p className="font-bold">UUID</p>
                        <p>{form.uuidFolioFiscal}</p>
                    </div>

                    <div>
                        <p className="font-bold">Serie</p>
                        <p>{form.serie || "-"}</p>
                    </div>

                    <div>
                        <p className="font-bold">Folio</p>
                        <p>{form.folio || "-"}</p>
                    </div>

                    <div>
                        <p className="font-bold">Moneda</p>
                        <p>{form.currency?.label || "-"}</p>
                    </div>
                </div>
            </SimpleCard>


            <SimpleCard title="Totales">

                <table>
                    <tbody>
                        <tr>
                            <th>Subtotal</th>
                            <th>Impuesto</th>
                            <th>Total</th>
                        </tr>
                        <tr>
                            <td>${moneyFormatter(Number(form.subtotal))}</td>
                            <td>${moneyFormatter(Number(form.tax))}</td>
                            <td>${moneyFormatter(Number(form.total))}</td>
                        </tr>
                    </tbody>
                </table>


            </SimpleCard>


            {form.taxDocumentItems.length>0 && 
                <SimpleCard title="Conceptos">
                    {
                        _.map(form.taxDocumentItems, (item, index)=>(
                            <div className="bg-card" key={index}>
                                <p className="concept-title">{item.description}</p>
                                <div className="grid-container">

                                    <div>
                                        <p className="font-bold">Número de unidades</p>
                                        <p>{item.unitAmount}</p>
                                    </div>  

                                    <div>
                                        <p className="font-bold">Tipo de unidad</p>
                                        <p>{item.unitCode?.label || '-'}</p>
                                    </div>  

                                    <div>
                                        <p className="font-bold">Clave de producto</p>
                                        <p>{item.productIdentification}</p>
                                    </div>  
                                </div>

                                <p className="font-bold">Totales</p>
                                <table className="concept-table">
                                    <tbody>
                                        <tr>
                                            <th>Subtotal</th>
                                            <th>IVA</th>
                                            <th>IEPS</th>
                                            <th>ISR</th>
                                            <th>Impuesto</th>
                                            <th>Total</th>
                                        </tr>
                                        <tr>
                                            <td>${moneyFormatter(Number(item.price) - Number(item.taxAmount))}</td>
                                            <td>${moneyFormatter(Number(item.iva))}</td>
                                            <td>${moneyFormatter(Number(item.ieps))}</td>
                                            <td>${moneyFormatter(Number(item.isr))}</td>
                                            <td>${moneyFormatter(Number(item.taxAmount))}</td>
                                            <td>${moneyFormatter(Number(item.price))}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                    ))
                    }
                </SimpleCard>
            }


            <SimpleCard title="Archivos">

                <div className="display-flex">

                    <div className="drop-div">
                        {form.xmlFile && 
                            <Button onClick={()=>downloadFile('xml')}>Descargar XML</Button>
                        }

                        { !form.xmlFile && <p className="font-bold">Adjuntar XML</p>}
                        <DragDropFile
                        file={form.xmlFile}
                        onChange={(e)=>handleFileChange('xml', e)}
                        onRemove={()=>handleFileRemove('xml')}
                        />
                    </div>

                    <div className="drop-div">
                        {form.pdfFile && 
                            <Button onClick={()=>downloadFile('pdf')}>Descargar PDF</Button>
                        }

                        { !form.pdfFile && <p className="font-bold">Adjuntar PDF</p>}
                        <DragDropFile
                        file={form.pdfFile}
                        onChange={(e)=>handleFileChange('pdf',e)}
                        onRemove={()=>handleFileRemove('pdf')}
                        />
                    </div>

                </div>
            </SimpleCard>

        </div>
    )
}

export default NewInvoiceDetail