import React, { useCallback, useContext } from 'react';
import './TransactionFormModal.scss';
import _ from 'lodash';
import useCallbackCreator from 'use-callback-creator';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import {
  clientTransactionSGroups,
  getEmptyTransaction,
  prepareTransactionForServer,
  recalculateQuoteTransactionsAmountToPendingAmount,
  quoteTransactionHasBeenFetched,
  quoteTransactionIsOutOfAmountLimits,
} from '../../../services/modelUtils/transactionUtils';
import { getNotifier } from '../../../services/notifier';
import { unPaidPaymentQuoteStatus, unPaidQuoteStatus } from '../../../services/modelUtils/quoteUtils';
import Modal from '../../utility/Modal/Modal';
import WindInput from '../WindInput/WindInput';
import WindDatePicker from '../WindDatePicker/WindDatePicker';
import WindTextarea from '../WindTextarea/WindTextarea';
import TideEntitySelect from '../../utility/TideEntitySelect/TideEntitySelect';
import useFormState from '../../../hooks/useFormState';
import { ApiContext } from '../../../services/api/api-config';
import { moneyFormatter } from '../../../services/currencyUtils';
import IconButton from '../../utility/IconButton/IconButton';
import { useEffect } from 'react';

const TransactionFormModal = ({ onClose, client, quotes, project }) => {
  const { form, setForm, bindSimple } = useFormState(() => getEmptyTransaction());
  const api = useContext(ApiContext);
  const quoteTransactions = form.quoteTransactions;
  const additionalQuoteFilters = {
    sGroups: clientTransactionSGroups,
    "project.client": client.id,
    paymentStatus: unPaidPaymentQuoteStatus,
    status: unPaidQuoteStatus,
  };
  const total = _.sumBy( quoteTransactions, (quoteTransaction) => Number(quoteTransaction.amount) );

  useEffect(() => {
    if(!quotes) return;

  var quoteTransactions= [];
  if (project){
    quoteTransactions = _.map(quotes, (quote) => { return { amount: quote.pendingAmount, quote: {...quote, project: project}} });
  }else{
    quoteTransactions = _.map(quotes, (quote) => { return { amount: quote.pendingAmount, quote: quote} });
  }
    setForm((form) => ({...form, quoteTransactions: quoteTransactions}));
  }, [project, quotes, setForm]);

  // ----- Save client transaction
  const saveTransaction = useCallback(() => {
    let transaction;
    try {
      transaction = prepareTransactionForServer({
        ...form,
        transactionType: 'income',
        client,
        amount: total,
      });
    } catch (e) {
      return getNotifier().warning(e.message);
    }

    api.transactions.create({ params: transaction })
      .then(() => {
        getNotifier().success('El pago se ha guardado correctamente');
        onClose();
      });
  }, [form, api, onClose, total, client]);

  const addQuoteToTransaction = useCallback((quote) => {
    if(quoteTransactionHasBeenFetched(quote, quoteTransactions)) return;

    const quoteTransaction = { amount: quote.pendingAmount, quote: quote , project: quote.project};

    setForm({
      ...form,
      quoteTransactions: [ ...quoteTransactions, quoteTransaction ]
    });
  }, [form, setForm, quoteTransactions]);

  const onQuoteTransactionAmountChange = useCallbackCreator((index, value) => {
    const newQuoteTransactions = [ ...quoteTransactions ];
    newQuoteTransactions[index]={...newQuoteTransactions[index]};

    const [ amount, pendingAmount ] = [ value, newQuoteTransactions[index].quote.pendingAmount];
    if(quoteTransactionIsOutOfAmountLimits(amount, pendingAmount)) return;

    newQuoteTransactions[index].amount = value;
    setForm({
      ...form,
      quoteTransactions: newQuoteTransactions
    });
  }, [form, setForm]);

  const onQuoteTransactionRemove = useCallbackCreator((index) => {
    const newQuoteTransactions = [ ...quoteTransactions ];
    newQuoteTransactions.splice(index, 1);
    setForm({
      ...form,
      quoteTransactions: newQuoteTransactions
    });
  }, [form, setForm, quoteTransactions]);

  const onTransactionTotalChange = useCallback((transactionTotal) => {
    const newQuoteTransactions = [ ...quoteTransactions ];
    const index = newQuoteTransactions.length - 1;

    recalculateQuoteTransactionsAmountToPendingAmount(newQuoteTransactions);

    const total = _.sumBy( newQuoteTransactions, (quoteTransaction) => Number(quoteTransaction.amount) );
    const diff = total - transactionTotal;
    newQuoteTransactions[index].amount -= diff;

    const amount = newQuoteTransactions[index].amount;
    const pendingAmount = newQuoteTransactions[index].quote.pendingAmount;
    if(quoteTransactionIsOutOfAmountLimits(amount, pendingAmount)) return;

    setForm({
      ...form,
      amount: transactionTotal,
      quoteTransactions: newQuoteTransactions
    });
  }, [form, setForm, quoteTransactions]);

  return (
    <Modal
      title="Nuevo pago"
      className={"TransactionFormModal"}
      onClose={onClose}
      secondaryButtonAction={onClose}
      mainButtonAction={saveTransaction}
      mainButtonText="Guardar"
    >
      <div className='form-container'>
        
        <div className='form-row'>
          <TideEntitySelect
            entity='quotes'
            className='quotes-select'
            placeholder='Buscar cotizaciones ...'
            onChange={addQuoteToTransaction}
            filterBy={'folio'}
            label="Cotizaciones"
            preload
            additionalFilters={additionalQuoteFilters}
            labelCreator={(quote) => (
              <span data-tooltip={`${quote.title}`}>{quote.folio||'Sin folio'} - ${moneyFormatter(quote.total)}</span>
            )}
            value={null}
          />

          <WindDatePicker
            label='Fecha de pago'
            {...bindSimple('transactionDate')}
          />
        </div>

        <div className="quote-container">
          {_.map(quoteTransactions, (quoteTransaction, index) => (
            <div className="quote-single" key={quoteTransaction.quote.id||index}>
              <div className="quote-single-header">
                <div className="quote-single-title">
                  <div>
                  <IconButton
                    icon={faTrashAlt}
                    color='danger'
                    onClick={onQuoteTransactionRemove(index)}
                  />
                  </div><div>
                  Cotización</div>
                </div>
                <div>{quoteTransaction.quote.folio||'Sin folio'}</div>
              </div>

              <div className="quote-single-body">
                <div>
                  <div>Total</div>
                  <div className="single-title">${moneyFormatter(Number(quoteTransaction.quote.total))}</div>
                </div>
                <WindInput
                  value={quoteTransaction.amount}
                  onChange={onQuoteTransactionAmountChange(index)}
                  type='number'
                  placeholder='Monto'
                  label="Agregar monto"
                />
              </div>
              
              
              <div className="quote-single-footer-pending">
                Pendiente
                ${moneyFormatter(Number(quoteTransaction.quote.pendingAmount))}
              </div>
            <div>
                
              </div>
            </div>
          ))}
        </div>

        <br />
        <br />
        <div className="form-row mt-20">
          <TideEntitySelect
            entity='bankAccounts'
            label="Cuenta"
            {...bindSimple('bankAccount')}
            placeholder='Cuenta de pago ...'
            labelCreator={(bankAccount) => `${bankAccount.name} `}
            autoSelect
          />
          <WindInput
            value={total||undefined}
            onChange={onTransactionTotalChange}
            type='number'
            placeholder='Total'
          />
          
        </div>
        

        <div className="form-row mt-20">
          <WindTextarea
            {...bindSimple('comments')}
            placeholder='Comentarios'
            className='textArea'
            label="Comentarios"
          />
        </div>
      </div>
    </Modal>
  );
}

export default TransactionFormModal;
