import { useEffect } from "react";
import _ from 'lodash';
import { useHistory } from "react-router-dom";

export const paremetersPrefix = 'search_';

const useSearchHistory = (table) => {
    const history = useHistory();
    const page = table.tableProps.reactTable.state.pageIndex+1;
    const itemsPerPage = table.tableProps.reactTable.state.pageSize;
    const filters = table.filters;

    useEffect(() => {
        let fields = [];
        let data = '';
        
        _.forEach(filters, function(filterValues, key) {
            if(Array.isArray(filterValues)){
                _.forEach(filterValues, function(value) {
                    fields.push(`${paremetersPrefix}${key}=${value}`);
                });
            } else {
                fields.push(`${paremetersPrefix}${key}=${filterValues}`);
            }
        });

        if(fields.length)
            data = `&${fields.join('&')}`;

        history.replace({search: `?page=${page}&itemsPerPage=${itemsPerPage}${data}`});
    }, [history, filters, page, itemsPerPage]);
};

export default useSearchHistory;