import React from 'react';
import moment from 'moment';

const DynamicTable = ({ data }) => {
    if (!data || data.length === 0) {
        return "Sin resultados 😞";
    }

    // Get table headers dynamically from data
    const headers = Object.keys(data[0]);

    // Helper function to render cell content
    const renderCellContent =  (value, header) => {
        if (value === null) {
            return 'N/A';
        } else if (typeof value === 'object' && value.date) {
            // Format the date if the object contains a date string
            return moment(value.date).format('YYYY-MM-DD HH:mm:ss');
        }
        else if (header === 'uri' && typeof value === 'string') {
            // Extract the main path and ID for generic link creation
            const segments = value.split('/');
            const resource = segments[2]; // e.g., 'quotes', 'clientes', etc.
            const id = segments[3]; // The ID part of the URI
            const link = `/${resource}/${id}`;
            return <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>;
        }
        else if (typeof value === 'object') {
            return JSON.stringify(value);
        } else {
            return value;
        }
    };

    return (
        <div className="TideReactTable table-hoverable">
            <div className="table-container table-responsive">
                <table role="table" className="table">
                    <thead>
                    <tr role="row">
                        {headers.map((header, index) => (
                            <th key={index} role="columnheader" colSpan="1">
                                {header}
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {headers.map((header, colIndex) => (
                                <td key={colIndex}>
                                    {renderCellContent(row[header], header)}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DynamicTable;
