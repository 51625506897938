import React, {useMemo} from 'react';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import './ClientUsers.scss';
import TideReactTable from '../../../../../components/utility/TideReactTable/TideReactTable';
import useTideTable from '../../../../../components/utility/TideReactTable/useTideTable';
import { clientUsersTableColumns, clientUsersTableSGroups } from '../../../../../services/modelUtils/clientUtils';
import DetailHeader from '../../../../../components/utility/DetailHeader/DetailHeader';
import { useHistory } from 'react-router-dom';
import { paths } from '../../../../../services/routes/appRoutes';

const ClientUsers = ({ client }) => {
  const history = useHistory();
  const clientUsersRequestFilters = useMemo(() => ({ client: client.id, sGroups: clientUsersTableSGroups }), [client]);

  const table = useTideTable({
    entity: 'users',
    columns: clientUsersTableColumns,
    requestFilters: clientUsersRequestFilters,
  });

  const addUser = () => {
    history.push( paths.usersNew )
  }

  const tools = [
    { text: "Agregar usuario", callback: addUser, outline: true, icon: faPlus }
  ]

  return (
    <div className={"ClientUsers"}>
      <DetailHeader title="Usuarios" tools={tools} />

      <TideReactTable {...table.tableProps} className="table-hoverable" />
    </div>
  );
}

export default ClientUsers;
