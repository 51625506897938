import React, { useState } from 'react';
import MarkdownRenderer from 'react-markdown-renderer';
import Collapse from './Collapse';

const translateStepName = (stepName) => {
    switch (stepName) {
        case 'getTheUserObjective':
            return 'Entendiendo la pregunta...';
        case 'getTablesForQuery':
            return 'Obteniendo información necesaria para la consulta...';
        case 'getTableDefinitions':
            return 'Obteniendo resultados de la consulta...';
        case 'getSQLForUserRequest':
            return 'Obteniendo resultados de la consulta...';
        case 'convertSqlToDql':
            return 'Realizando interpretación de resultados...';
        case 'interpretSqlResults':
            return 'Realizando los últimos ajustes...';
        default:
            return `Procesado ${stepName || ''}...`;
    }
};

const ThoughtProcess = ({ thoughtProcess, autoOpen }) => {
    // State variable to track if steps are reversed
    const [reversed, setReversed] = useState(false);

    // Function to toggle the reversed state
    const toggleReversed = () => {
        setReversed(!reversed);
    };

    // Determine the order of steps based on reversed state
    const steps = reversed ? [...thoughtProcess.steps].reverse() : thoughtProcess.steps;

    return (
        <Collapse title="Ver Proceso de Pensamiento">
            <div className="thought-process">
                {/* Toggle Button */}
                <button onClick={toggleReversed}>
                    {reversed ? 'Orden Normal' : 'Invertir Pasos'}
                </button>

                {steps.map((step, stepIndex) => (
                    <div key={stepIndex} className="step">
                        <h5>Paso [{translateStepName(step.stepName)}]</h5>
                        <p>
                            <strong>Tiempo de Ejecución:</strong> {step.executionTime} segundos
                        </p>
                        <p>
                            <strong>Salida:</strong> {step.output}
                        </p>
                        {step.systemInput && (
                            <Collapse title="Ver Entrada del Sistema" autoOpen={autoOpen}>
                                {step.systemInput.map((input, index) => (
                                    <MarkdownRenderer key={index} markdown={input} />
                                ))}
                            </Collapse>
                        )}
                        <MarkdownRenderer markdown="---" />
                    </div>
                ))}
                {thoughtProcess.sqlResult && (
                    <>
                        <h4>Resultado SQL:</h4>
                        <pre>{JSON.stringify(thoughtProcess.sqlResult, null, 2)}</pre>
                    </>
                )}
                <p>
                    <strong>Inicio:</strong>{' '}
                    {new Date(thoughtProcess.startedAt * 1000).toLocaleString()}
                </p>
                {thoughtProcess.endedAt !== 0 && (
                    <p>
                        <strong>Finalización:</strong>{' '}
                        {new Date(thoughtProcess.endedAt * 1000).toLocaleString()}
                    </p>
                )}
            </div>
        </Collapse>
    );
};

export default ThoughtProcess;
