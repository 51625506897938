import React, { useCallback, useState, useEffect, useContext } from "react";
import Modal from "../../../../components/utility/Modal/Modal";
import WindSelect from "../../../../components/formComponents/WindSelect/WindSelect";
import { ApiContext } from "../../../../services/api/api-config";
import { taxDocumentMonths } from "../../../../services/modelUtils/taxDocumentUtils";
import "./ReportModal.scss"
const ReportModal = ({onClose}) =>{

  const [reportMonth, setReportMonth] = useState()
  const [reportYear, setReportYear] = useState()
  const [oldestYear, setOldestYear] = useState()
  const [filteredMonths, setFilteredMonths] = useState(taxDocumentMonths);

  const api = useContext(ApiContext);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; 

  const years = Array.from(
    { length: currentYear - oldestYear + 1 },
    (_, i) => oldestYear + i
  );

  const handleMonthChange = (e)=>{
    setReportMonth(e)
  }
  const handleYearChange = useCallback((e) => {
    setReportYear(e);

    if (e.value === currentYear) {
      const filtered = taxDocumentMonths.filter(
        (month) => parseInt(month.id) < currentMonth
      );
      setFilteredMonths(filtered);
    } else {
      setFilteredMonths(taxDocumentMonths);
    }
  }, [currentYear, currentMonth]);

  useEffect(() => {
    const defaultMonth = (taxDocumentMonths.filter(item => parseInt(item.id) === currentMonth-1)).pop();
    handleYearChange({label: currentYear.toString(), value: currentYear})
    setReportMonth({label: defaultMonth.month, value: defaultMonth.id})
    

    const fetchOldestYear = async() =>{
      const date = await api.taxDocuments.get({params:{
        sGroups: ['tax_document_read_issued_at'], 
        'itemsPerPage': 1,
        'order[issuedAt]': 'ASC'
      }})

      const year = (new Date(date[0]?.issuedAt)).getFullYear()
      setOldestYear(year)
    }

    fetchOldestYear()
  }, [api, currentYear, currentMonth, handleYearChange]);


  const close = useCallback(() => {
    if (onClose) onClose()
  }, [onClose]);

  const download = () => {
    window.location.href = api.taxDocuments.getCompleteReport(reportMonth.value, reportYear.value)
    close()
  }

    return(

      <Modal
        title='Generar reporte'
        onClose={close}
        secondaryButtonAction={close}
        mainButtonAction={download}
        mainButtonText="Descargar"
      >

        <p>Selecciona el año y mes del reporte de facturas a generar</p>

        <div className="report-selector">

          <WindSelect 
            className={"select"}
            label="Año" 
            name="year"
            options = {years.map(year => ({label: year.toString(), value: year }))} 
            onChange={handleYearChange}
            value={reportYear}
          />

          {reportYear &&
            <WindSelect 
            label="Mes" 
            name="month"
            options = {filteredMonths.map(month => ({label: month.month, value: month.id}) )} 
            onChange={handleMonthChange}
            value={reportMonth}
            />
          }

        </div>
      </Modal>
    )
}


export default ReportModal