import React, { useCallback, useContext, useEffect } from 'react'
import Modal from '../../../components/utility/Modal/Modal';
import useFormState from '../../../hooks/useFormState';
import WindInput from "../../../components/formComponents/WindInput/WindInput";
import WindSelect from '../../../components/formComponents/WindSelect/WindSelect';
import WindDatePicker from '../../../components/formComponents/WindDatePicker/WindDatePicker';
import './RecurringPaymentsFormModal.scss';
import { frequencyDescription, frequencyTypes, getDaysList, getFrequencyTypes, getMonthsList, prepareRecurringPaymentForServer } from '../../../services/modelUtils/recurringPaymentUtils';
import { getNotifier } from '../../../services/notifier';
import { ApiContext } from '../../../services/api/api-config';
import { moneyFormatter } from '../../../services/currencyUtils';

const initialFormState = {
    concept: '',
    startDate: '', 
    frequencyInterval: 1,
    onMonth: 1,
    onDay: 1,
};

const RecurringPaymentsFormModal = ({onClose, onRecurringPaymentSaved, editData}) => {
    const api = useContext(ApiContext);
    const {form, bindSimple, setForm} = useFormState(() => initialFormState);

    const title = editData ? 'Editar pago recurrente' : 'Agregar nuevo pago recurrente'; 
    
    useEffect(() => {
        if (editData) {
            const transformedData = {
                ...editData,
                amount: editData.amount ? moneyFormatter(editData.amount) : '',
            };

            setForm(transformedData);
        }
    }, [editData, setForm]);
    

    const bindAmount = (field)=>{
        return{
            value: form[field],

            onChange: (value) =>{
                setForm((prevForm) => ({
                    ...prevForm,
                    [field]:  value
                }));
            },
            onBlur: () => {
                const formattedValue = moneyFormatter(form[field]);
                setForm((prevForm) => ({
                    ...prevForm,
                    [field]: formattedValue 
                }));
            }
        }
    }



    const close = useCallback(() => {
        if (onClose)
            onClose()
    }, [onClose]);

    const saveRecurringPayment = useCallback(() => {
        let recurringPayment;
        try {
            recurringPayment = prepareRecurringPaymentForServer(form);
        } catch (e) {
            return getNotifier().warning(e.message);
        }
    
        const handleRecurringPaymentSuccess = () => {
            getNotifier().success(`El pago recurrente se ha ${editData ? 'modificado' : 'creado'} correctamente`);
            if (onRecurringPaymentSaved) {
                onRecurringPaymentSaved();
            }
            if (onClose) {
                onClose();
            }
        };
    
        const handleRecurringPaymentError = () => {
            getNotifier().error(`Ha ocurrido un error al ${editData ? 'modificar' : 'crear'} el pago recurrente`);
        };
    
        const paymentPromise = editData 
            ? api.recurringPayments.update({ id: editData.id, params: recurringPayment })
            : api.recurringPayments.create({ params: recurringPayment });
    
        paymentPromise.then(handleRecurringPaymentSuccess).catch(handleRecurringPaymentError);
    
    }, [form, api, editData,onClose,onRecurringPaymentSaved]);
    


    return (
        <Modal className={'RecurringPaymentsFormModal'}
               onClose={close}
               secondaryButtonAction={close}
               mainButtonAction={saveRecurringPayment}
               mainButtonText="Guardar"
        >
            <div className='RecurringPaymentsForm'>
                
                    <div className='form-container'>
                        <h2 className="SceneTitle modal-title secondary">
                            <span className="title-content">{title}</span>
                        </h2>

                        <div className='form-row mt-20'>
                            <WindInput
                                placeholder="Concepto"
                                {...bindSimple('concept')}
                            />
                            <WindInput
                                placeholder="Monto"
                                {...bindAmount('amount')}
                            />
                        </div>
                        <div className='form-row mt-20'>
                            <WindDatePicker
                                label="Fecha de primer pago"
                                {...bindSimple('firstPaymentDate')}
                            />
                            <WindDatePicker
                                label="Fecha de expiración"
                                {...bindSimple('expirationDate')}
                            />
                        </div>
                        <div className='form-row mt-20'>
                            <WindSelect
                                placeholder="Frecuencia"
                                {...bindSimple('frequencyType')}
                                options={getFrequencyTypes()}
                            />
                            <div className='fullcenter'>
                                <WindInput
                                    placeholder="Cada (intervalo)"
                                    {...bindSimple('frequencyInterval')}
                                />
                                { frequencyDescription[form?.frequencyType?.value] }
                            </div>
                        </div>
                        {form?.frequencyType?.value===frequencyTypes.yearly && 
                            <div className='form-row mt-20'>
                                <div className='fullcenter'>
                                    <div className='repeatIn'>
                                        El mes:
                                    </div>
                                    <WindSelect
                                        placeholder="Mes"
                                        {...bindSimple('onMonth')}
                                        options={getMonthsList()}
                                    />
                                </div>
                                <div className='fullcenter'>
                                    <div className='repeatIn'>
                                        El día:
                                    </div>
                                    <WindSelect
                                        placeholder="Día"
                                        {...bindSimple('onDay')}
                                        options={Array.from(Array(31).keys()).map((i) => ({value: i+1, label: i+1}))}
                                    />
                                </div>
                            </div>}

                        {form?.frequencyType?.value===frequencyTypes.monthly && 
                            <div className='form-row mt-20'>
                                <div className='fullcenter'>
                                    <div className='repeatIn'>
                                        El día:
                                    </div>
                                    <WindSelect
                                        placeholder="Día"
                                        {...bindSimple('onDay')}
                                        options={Array.from(Array(31).keys()).map((i) => ({value: i+1, label: i+1}))}
                                    />
                                </div>
                            </div>}
                            
                        {form?.frequencyType?.value===frequencyTypes.weekly && 
                            <div className='form-row mt-20'>
                                <div className='fullcenter'>
                                    <div className='repeatIn'>
                                        El día:
                                    </div>
                                    <WindSelect
                                        placeholder="Día"
                                        {...bindSimple('repeatIn')}
                                        options={getDaysList()}
                                    />
                                </div>
                            </div>}
                    </div>
                
            </div>  
        </Modal>
    );
}

export default RecurringPaymentsFormModal;