import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/pro-light-svg-icons";

const getQuoteStatusIcon = (showCheck) => {
    if(showCheck)
        return <FontAwesomeIcon icon={faCheck} className="icon icon--green" />;

    return <FontAwesomeIcon icon={faTimes} className="icon icon--red" />;
};

export const getStatusIcon = (showCheck) => {
    if(showCheck)
        return <FontAwesomeIcon icon={faCheck} className="icon icon--green" />;

    return <FontAwesomeIcon icon={faTimes} className="icon icon--red" />;
};

export default getQuoteStatusIcon;