
import React, {useCallback, useContext, useMemo, useState} from 'react';
import {recurringPaymentListSGroups, getRecurringPaymentColumns} from "../../../services/modelUtils/recurringPaymentUtils";
import {filterTypes} from "../../../services/searchUtils";
import {ApiContext} from "../../../services/api/api-config";
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import useSearchHistory from '../../../hooks/useSearchHistory';
import useUrlFilters from '../../../hooks/useUrlFilters';
import useBoolean from '../../../hooks/useBoolean';
import RecurringPaymentsFormModal from '../RecurringPaymentsFormModal/RecurringPaymentsFormModal';
import SearchBar from '../../../components/search/SearchBar/SearchBar';
import SectionHeader from '../../../components/utility/SectionHeader/SectionHeader';
import { getFinanceHeaderInfo } from '../../../constants/SectionHeaderInfo';
import { SecurityContext } from '../../../services/SecurityManager';

const requestFilters = {sGroups: recurringPaymentListSGroups};
const filtersConfig = {
    placeholder: 'Buscar una cuenta...', filters: [
        {main: true, field: 'concept'},
        {type: filterTypes.TEXT, field: 'concept', label: 'Buscar por concepto de pago'},
    ]
};

const RecurringPaymentList = () => {
    const urlFilters = useUrlFilters();
    
    const api = useContext(ApiContext);
    const [showingForm, showForm, hideForm] = useBoolean(false);
    const exportToExcel = useCallback(() => {window.location.href = api.recurringPayments.getExportExcelUrl({pagination: false, sGroups: recurringPaymentListSGroups})}, [api]);
    const security = useContext(SecurityContext);
    const headerInfo = getFinanceHeaderInfo(security)
    const sceneTools = useMemo( () => {
        let tools = [
            { text: "Exportar", callback: exportToExcel, outline: true },
            { text: "Agregar", callback: showForm },

        ];
        return tools;
    }, [exportToExcel, showForm] );

    const [dataToEdit, setDataToEdit] = useState()

    const showFormWithConcept = useCallback((rowData) => {      
        setDataToEdit(rowData)
        showForm();
    }, [showForm]);

    const recurringPaymentColumns = useMemo(() => getRecurringPaymentColumns(showFormWithConcept), [showFormWithConcept]);

    const clearDataState = () => {
        if(dataToEdit) setDataToEdit(undefined);
        hideForm()
    }

    //Table config
    const table = useTideTable({
        entity: 'recurringPayments',
        columns: recurringPaymentColumns,
        requestFilters,
        ...urlFilters
    });

    const { tableProps, filters, setFilters } = table;

    useSearchHistory(table);
    
    return (
        <div className={"RecurringPaymentList wind-scene"}>

            <SectionHeader pageInfo={headerInfo} tools={sceneTools}/>        
            
            <SearchBar 
            title="Mis pagos recurrentes" 
            filters={filters} 
            filtersConfig={filtersConfig} 
            onFiltersChange={setFilters}
            labels={false}
            />

            <div className='center-container'>
                <TideReactTable {...tableProps} className="table-hoverable" />
            </div>

            {showingForm &&
                <RecurringPaymentsFormModal
                    onClose={clearDataState}
                    onRecurringPaymentSaved={() => table.reload()}  
                    editData={dataToEdit}
                />
            }
        </div>
    );
}

export default RecurringPaymentList;