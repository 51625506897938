import React from 'react'
import './BrandingInfo.scss';
import WindInput from '../../../../../components/formComponents/WindInput/WindInput';
import WindInputFile from "../../../../../components/formComponents/WindInputFile/WindInputFile";
import {faImage} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const BrandingInfo = ({bindSimple, filePath, handleOnSelectFile}) => {
    return (
        <div className="ClientForm">
            <div className="fields-row">
                <WindInput
                    className='fields-field'
                    placeholder={"Seleccione color principal"}
                    {...bindSimple('brandingMainColor')}
                    type="color"
                />

                <WindInput
                    className='fields-field'
                    placeholder={"Seleccione color de acento"}
                    {...bindSimple('brandingAccentColor')}
                    type="color"
                />

                <WindInputFile
                    label="Seleccione un logo"
                    className='fields-field'
                    onChange={handleOnSelectFile}
                    inputProps={{accept: "image/*"}}
                />
            </div>
            <div className="fields-row thumbnail-container">
                {filePath != null &&
                    <a href={filePath} target="_blank" rel="noreferrer">
                        <img src={filePath} alt="logoClient" className="center"/>
                    </a>
                }
                {filePath == null &&
                    <div className="empty-image">
                        <FontAwesomeIcon icon={faImage} className="icon"/><br/>
                        Seleccione un logo
                    </div>
                }
            </div>
        </div>
    )
}

export default BrandingInfo;