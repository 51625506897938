import React from 'react';
import WindDatePicker from '../../../../../components/formComponents/WindDatePicker/WindDatePicker';
import WindSelect from '../../../../../components/formComponents/WindSelect/WindSelect';
import TideEntitySelect from '../../../../../components/utility/TideEntitySelect/TideEntitySelect';
import { bankStatementStatusOptions } from '../../../../../services/modelUtils/bankStatementUtils';

const BankStatementGeneralInfo = ({ bindSimple }) => {

  return (
    <>
      <div className='fields'>
        <TideEntitySelect
          className='bank-account-field'
          entity='bankAccounts'
          placeholder="Cuenta"
          {...bindSimple('bankAccount')}
          labelCreator={(bankAccount) => `${bankAccount.name} - ${bankAccount.number}`}
          preload
        />

        <WindDatePicker
          className='bank-account-field'
          placeholder={"Inicio periodo"}
          {...bindSimple('periodStartDate')}
          label={'Inicio periodo'}
        />

        <WindDatePicker
          className='bank-account-field'
          placeholder={"Fin periodo"}
          {...bindSimple('periodEndDate')}
          label={'Fin periodo'}
        />

        <WindSelect
          className='bank-account-field'
          placeholder={"Estado"}
          {...bindSimple('status')}
          options={bankStatementStatusOptions}
        />
      </div>
    </>
  );
}

export default BankStatementGeneralInfo;
