import React, {useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef} from 'react';
import './QuotationRequestForm.scss';
import { faPlusSquare, faSave } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import ToolBar from '../../../components/utility/ToolBar/ToolBar';
import TopBar from '../../../components/utility/TopBar/TopBar';
import useFormState from '../../../hooks/useFormState';
import { ApiContext } from '../../../services/api/api-config';
import { getEmptyQuotationRequest, prepareQuotationRequestForServer, quotationRequestStatusOptions } from '../../../services/modelUtils/quotationRequestUtils';
import { getNotifier } from '../../../services/notifier';
import SimpleCard from '../../../components/layout/SimpleCard/SimpleCard';
import { getEmptyQuotationRequestProduct } from '../../../services/modelUtils/quotationRequestProductsUtils';
import _ from 'lodash';
import useCallbackCreator from 'use-callback-creator';
import classNames from 'classnames';
import WindSelect from '../../../components/formComponents/WindSelect/WindSelect';
import TideEntitySelect from '../../../components/utility/TideEntitySelect/TideEntitySelect';
import ProductForm from './components/ProductForm/ProductForm';
import {useHistory, useLocation} from "react-router-dom";
import {paths} from "../../../services/routes/appRoutes";
import {projectSelectorSGroups} from "../../../services/modelUtils/projectUtils";
import { useParams } from 'react-router-dom';
import { quotationRequestSGroups, convertQuoteRequestToForm } from '../../../services/modelUtils/quotationRequestUtils';

const QuotationRequestForm = () => {
    const api = useContext(ApiContext);
    const history = useHistory();
    const {id} = useParams();

    // ----- Autofocus first input -----
    const titleInputRef = useRef();
    useLayoutEffect(()=>titleInputRef.current.focus() ,[]);

    // ----- Manging form state -----
    const { form, setForm, bindInput, bindSimple } = useFormState(() => getEmptyQuotationRequest());
    const addProduct = useCallback(()=>{
        setForm(form=>({
            ...form,
            products: [...form.products, getEmptyQuotationRequestProduct()]
        }));
    },[setForm]);

    // ----- load data if exists id -----
    useEffect(()=>{
        if(id){
            api.quotationRequests.get({ id, params: { sGroups: quotationRequestSGroups } })
                .then(quotationRequest => {
                    setForm(convertQuoteRequestToForm(quotationRequest));
                });
        }
    }, [id, api, setForm]);

    const removeQuotationRequestProduct = useCallbackCreator((index)=>{
        const newProducts = [...form.products];
        newProducts.splice(index, 1);

        setForm({ ...form, products: newProducts });
    },[setForm, form]);


    const handleQuotationRequestProductChange = useCallbackCreator((index, quoteProduct)=>{
        const newProducts = [...form.products];
        newProducts[index] = quoteProduct;
        setForm({ ...form, products: newProducts });
    }, [setForm, form]);
    
    // Make the project and the client match
    const {project, client} = form;
    useEffect(()=>{
        if( project?.client && client ){
            setForm(form=>({
                ...form,
                client: project.client
            }));
        }
    },[project, client, setForm]);

    // If the client change, delete the project if it's from another client
    const handleClientChange = useCallback((client)=>{
        setForm(form=>({
            ...form,
            client,
            project: form?.project?.client?.id === client.id? form.project : null
        }))
    },[setForm]);

    // If the project change, set the client
    const handleProjectChange = useCallback((project)=>{
        setForm(form=>({
            ...form,
            project,
            client: project?.client,
        }))
    },[setForm]);

    // ------ Preload project if needed ------
    const { search } = useLocation();
    useEffect(()=>{
        const preloadProjectId = (new URLSearchParams(search)).get('project');
        if( preloadProjectId ){
            api.projects.get( { id:preloadProjectId, params:{sGroups: projectSelectorSGroups}, customProp:'_' } ).then( project=>{
                if(project)
                    setForm( form=>({...form, project, client: project.client}));
            })
        }
    },[api, search, setForm]);

    // ----- Sending to server -----
    const loadingId = 'QuotationRequestForm.create';
    const loading = useSelector(s => !!s.loadingIds[loadingId]);

    const handleSave = useCallback(()=>{
        const {id} = form;
        const apiMethod = id ? 'update' : 'create';
        
        let quotationRequest;
        try{
            quotationRequest = prepareQuotationRequestForServer({...form, status: form.status?.value});
        }
        catch (e){
            return getNotifier().warning(e.message);
        }

        const params = {
            id,
            loadingId,
            params: quotationRequest,
        };

        api.quotationRequests[apiMethod](params)
            .then((quotationRequest)=>{
                getNotifier().success(`La solicitud se ha ${id?'editado':'creado'} correctamente`);
                history.replace( paths.quotationRequestDetail.replace(':id', quotationRequest.id) );
            })
            .catch((e)=>{ getNotifier().error(e.message) });
    },[form, api, history, loadingId]);

    // ----- Filter projects selector -----
    const projectFilters = useMemo(() =>({
            client: form.client?.id,
            'order[updatedDate]':'DESC',
            sGroups: projectSelectorSGroups
    }), [form.client]);

    // ----- Toolbar config -----
    const tools = useMemo(() => [
        { icon: faSave, callback: handleSave, text: "Guardar solicitud", disabled: loading },
        { icon: faPlusSquare, callback: addProduct, text: "Añadir producto", disabled: loading  },
    ], [loading, addProduct, handleSave]);

    return (
        <div className={"QuotationRequestForm wind-scene"}>

            <TopBar
                title={ id ? "Editar solicitud" : "Nueva solicitud" }
                titleLinkBack
            />

            <ToolBar tools={tools} />

            <div className='center-container'>

                <div className='quote-header' >
                    <input ref={titleInputRef} className='title-input' placeholder="Agrega un título a la solicitud" {...bindInput('title')} />
                </div>


                <SimpleCard className='general-info' title="Información general">
                    <div className='fields'>
                        <TideEntitySelect
                            className='project-field'
                            entity='clients'
                            placeholder={"Compañía"}
                            preload={true}
                            value={form.client}
                            onChange={handleClientChange}
                        />

                        <TideEntitySelect
                            className='project-field'
                            entity='projects'
                            placeholder={"Proyecto"}
                            value={form.project}
                            onChange={handleProjectChange}
                            additionalFilters={projectFilters}
                            preload={true}
                        />

                        <WindSelect
                            className={'project-field'}
                            options={quotationRequestStatusOptions}
                            placeholder={"Estado"}
                            {...bindSimple('status')}
                        />
                    </div>
                </SimpleCard>

                <SimpleCard className={classNames('products', form.products.length === 1 && 'single-product')} title="Productos">
                    {_.map(form.products, (quotationRequestProduct, index) => (
                        <ProductForm
                            key={quotationRequestProduct.id || index}
                            onRemove={removeQuotationRequestProduct(index)}
                            quotationRequestProduct={quotationRequestProduct}
                            onChange={handleQuotationRequestProductChange(index)}
                        />
                    ))}
                </SimpleCard>
            </div>
        </div>
    );
};

export default QuotationRequestForm;
