import React from 'react';
import './WindSelectStatus.scss';
import Select from 'react-select';
import { components } from 'react-select';
import classNames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCaretDown } from '@fortawesome/pro-light-svg-icons';
  
  const CustomSingleValue = props => {
    const colorStyle = {
      backgroundColor: props.data.bgColor, 
      borderRadius: '7px',
      padding: '2px 8px',
      color: props.data.textColor
    };
  
    return (
      <components.SingleValue {...props} >
        <span style={colorStyle}>
          {props.children}
        </span>
      </components.SingleValue>
    );
  };
  
  const DropdownIndicator = (props) =>
  <button className='DropdownIndicator'>
    <FontAwesomeIcon className={classNames('caret', props?.selectProps?.menuIsOpen && 'open')} icon={faCircleCaretDown}/>
  </button>


const WindSelectStatus = ({ value, onChange, options, placeholder, className, disabled, label, noLabel, inputProps={}, ...props }) => {
  
    return (
      <div className={classNames(className)}>
        { (!noLabel)?
          <div><label>{(label)?label:placeholder}</label></div>: null
        }
        
        <Select
        className={classNames('WindSelectStatus', 'tide-react-select')}
        classNamePrefix="tide-react-select"
        options={options}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        components={{DropdownIndicator, SingleValue: CustomSingleValue}}
        {...inputProps}
        {...props}
        />
      </div>
    );
  }

export default WindSelectStatus;




  