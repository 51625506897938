import React from 'react';
import './Button.scss';
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/pro-light-svg-icons";

const Button = ({ children, color, outline, onClick, disabled, loading, rightIcon, leftIcon, className, ...props }) => {

    return (
        <button className={
            classNames("Button", loading&&'loading', color, outline&&'outline', disabled&&'disabled', className)}
            onClick={onClick}
            disabled={disabled}
            {...props}
        >
            {
                leftIcon? <span><FontAwesomeIcon icon={leftIcon} />&nbsp;</span> : ''
            }
            <span className='content'>{children}</span>
            {!!loading &&
                /* -- SPINNER -- */
                <span className='spinner-outer'>
                    <span className='spinner-inner'>
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </span>
                </span>}
                
            {
                rightIcon? <span><FontAwesomeIcon icon={rightIcon} /></span> : ''
            }

        </button>
    );
};

export default Button;
