import {
    faFile,
    faFileCode, faFileCsv,
    faFileExcel,
    faFileImage, faFileLines,
    faFileMusic,
    faFilePdf, faFileVideo,
    faFileWord
} from "@fortawesome/pro-light-svg-icons";
import ImagePreview from "../components/crmComponents/FileContainer/components/FilePreview/components/ImagePreview";
import TextPreview from "../components/crmComponents/FileContainer/components/FilePreview/components/TextPreview";
import PdfPreview from "../components/crmComponents/FileContainer/components/FilePreview/components/PdfPreview";

export const getFileExtension = ( fileName )=>fileName.split('.').pop().toLowerCase();

export const previewComponents = [
    {ext:['jpg','jpeg','png','gif','webp'], component: ImagePreview},
    {ext: ['txt','md','csv','sh', 'json', 'c', 'cpp', 'sql', 'php', 'html', 'css', 'scss', 'sass', 'js', 'jsx', 'json', 'sh', 'env'],
        component: TextPreview},
    {ext:['pdf'], component: PdfPreview},
];

export const getFilePreviewComponent = (fileName)=>{
    const ext = getFileExtension(fileName);
    for( let i=0; i<previewComponents.length; i++ ){
        if( previewComponents[i].ext.indexOf(ext) !== -1 )
            return previewComponents[i].component;
    }
    return false;
}

export const isPreviewableExtensions = ( fileName='' )=>{
    return !!getFilePreviewComponent(fileName);
}

/**
 * Tells if a file can be previewed instantly or we should ask the user for a confirmation click to view it.
 * @param appFile
 * @returns {boolean}
 */
export const shouldShowInstantPreview = (appFile)=>{
    return  appFile && isPreviewableExtensions(appFile.originalName) && appFile.size <= INSTANT_PREVIEW_MAX_SIZE;
}
const INSTANT_PREVIEW_MAX_SIZE = 2 * 1024 * 1024;//2MB

const maxDecimals = (num, decimals=2)=>Math.round(num*10**decimals)/10**decimals;
export const bytesToString = ( bytes )=>{
    //We will be using the SI standard for units. More info in: https://wiki.ubuntu.com/UnitsPolicy
    if( bytes < 1000 )
        return `${bytes} B`;
    bytes = bytes / 1000;
    if( bytes < 1000 )
        return `${maxDecimals(bytes)} KB`;
    bytes = bytes / 1000;
    if( bytes < 1000 )
        return `${maxDecimals(bytes)} MB`;
    bytes = bytes / 1000;
    return `${maxDecimals(bytes)} GB`;
}

export const getIconForFile = ( fileName='' )=>{
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension){

        case 'txt':
        case 'md':
            return faFileLines;
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'webp':
        case 'psd':
            return faFileImage;
        case 'csv':
            return faFileCsv;
        case 'xls':
        case 'xlsx':
            return faFileExcel;
        case 'doc':
        case 'docx':
        case 'odt':
            return faFileWord;
        case 'pdf':
            return faFilePdf;
        case 'mp4':
        case 'webm':
            return faFileVideo;
        case 'mp3':
        case 'ogg':
        case 'wav':
            return faFileMusic;
        case 'c':
        case 'cpp':
        case 'sql':
        case 'php':
        case 'html':
        case 'css':
        case 'scss':
        case 'sass':
        case 'js':
        case 'jsx':
        case 'json':
        case 'sh':
            return faFileCode;

        default:
            return faFile;
    }
};

export const supportedImageExtensions = ['jpg', 'png', 'jpeg', 'gif'];

export const isImage = (fileName)=>{
    if(!fileName)
        return false;
    return supportedImageExtensions.indexOf( fileName.split('.').pop().toLowerCase() ) !== -1;
};