import InfoPair from "../../../../components/layout/InfoPair/InfoPair";
import RichTextEditor from '../../../../components/formComponents/RichTextEditor/RichTextEditor';
import './ProductDetail.scss';

export const ProductDetail = ({product, showLine=false}) => {
    return (<div className="ProductDetail">
        <div className={"info-block"}>
            <InfoPair value={product?.name} title={"Nombre del producto"}/>
            
            <div className="switch">
                <div className="content">
                    <span className='placeholder'>
                        Opcional
                    </span>
                    {product?.isOptional ? 'Sí' : 'no'}
                </div>
            </div>
        </div>
        <div className='description description--detail'>
            <RichTextEditor
                html={product?.description}
                readonly={true}
            />
        </div>

        { showLine && <hr className="rounded"></hr> }
    </div>);
};
