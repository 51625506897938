import React, { useState, useContext } from 'react';
import './ClientMainDetails.scss';
import DetailTabs from '../../../../../components/layout/DetailTabs/DetailTabs';
import { getClientTabs } from '../../../../../services/modelUtils/clientUtils';
import { SecurityContext } from '../../../../../services/SecurityManager';

const ClientMainDetails = ({ client, addTransaction }) => {
  const security = useContext(SecurityContext);
  const clientTabs = getClientTabs(security)
  const [ activeTab, setActiveTab ] = useState(() => clientTabs[0]);
  const { Component } = activeTab;

  return (
    <div className="ClientMainDetails">
      <DetailTabs
        tabs={clientTabs}
        onTabSelect={setActiveTab}
        activeTab={activeTab}
      />
      {Component && <Component client={client} addTransaction={addTransaction} />}
    </div>
  );
}
 
export default ClientMainDetails;