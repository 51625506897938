import moment from 'moment';
import React, { useMemo } from 'react';
import { projectTableSGroups } from '../../../../../services/modelUtils/projectUtils';
import TideReactTable from '../../../../../components/utility/TideReactTable/TideReactTable';
import useTideTable from '../../../../../components/utility/TideReactTable/useTideTable';
import { moneyFormatter } from '../../../../../services/currencyUtils';
import {Link} from "react-router-dom";

const ClientProjects = ({client}) => {
    const customProp = 'Client.Projects.get.'+client?.id;

    // ----- Loading project table
    const columns = useMemo(()=>[
        { Header: "Folio", accessor: (p) => (p.folio) },
      { Header: "Nombre", id: 'projectId', accessor: p => <Link to={`/projects/${p.id}`}>{p.name}</Link> },
      { Header: "Cliente", accessor: 'client.fullName' },
      { Header: "Cot. totales", accessor: 'projectStats.quotesCount' },
      { Header: "Cot. aprobadas", accessor: 'projectStats.approvedQuotesCount' },
      { Header: "Pendiente de pago", accessor: (p) => `$ ${moneyFormatter (Number(p.projectStats.allQuotesSum) - Number(p.projectStats.paymentsSum))}` },
      { Header: "Última actualización", accessor: (p) => (p.updatedDate? moment(p.updatedDate).format('DD/MM/YYYY'):null ) },
    ], []);

    const tableParameters = useMemo(() => {
        return {
            entity: 'projects',
            columns,
            requestFilters: {
                sGroups:projectTableSGroups, 
                'order[createdDate]':'DESC',
                client: client.id
            },
            requestOptions: {
                customProp
            }
        };
    } , [client, customProp, columns]);

    const table = useTideTable(tableParameters);
  
    return (
        <div className='info-container'>
            <div className="single-title">Proyectos</div>
            <TideReactTable {...table.tableProps} className="table-hoverable" />
        </div>
    );
}

export default ClientProjects;