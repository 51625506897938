import React, {  useMemo } from 'react';
import { faUser, faArrowRightFromBracket } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import { paths } from '../../../services/routes/appRoutes';
import DropdownSingleMenu from '../DropdownSingleMenu/DropdownSingleMenu';
import { useHistory } from 'react-router-dom';
import BreadcrumbNavigation from '../BreadcrumbNavigation/BreadcrumbNavigation';

import './TopBar.scss';


const TopBar = ({ breadcrumb }) => {
    
    const me = useSelector(({api})=>api.me);
    const history = useHistory();

    const viewProfile = () => {
        history.push( paths.usersDetail.replace(":id",me.id) );
    }

    const logout = () => {
        if(sessionStorage.getItem('impersonatedUser'))
            sessionStorage.removeItem('impersonatedUser');
        document.location.href = paths.logout;
    }

    const userOptions = [
        { text: "Ver perfil", callback: viewProfile, icon: faUser },
        { text: "Cerrar Sesión", callback: logout, icon: faArrowRightFromBracket }
    ];

    const userInfo = useMemo( () => {
        return me;
    }, [me] );

    return (
        <div className={"TopBar"}>
            <BreadcrumbNavigation/>
            <div className="breadcrumb">
                {
                    Array.isArray(breadcrumb) && 
                    breadcrumb.map(
                        (item) => 
                            <span className="breadcrumb-item" key={item}>
                                {item}
                                <span className="breadcrumb-indicator"> &gt; </span>
                            </span>
                    )
                }
            </div>
            <div className='buttons-container'>
                <DropdownSingleMenu 
                    text={"User"} 
                    options={userOptions} 
                    icon={faUser} 
                    profile={userInfo}
                    color={"black"} />
            </div>
        </div>
    );
};

export default TopBar;
