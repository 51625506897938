import React, {useContext, useEffect, useState} from 'react'
import './NavBar.scss';
import getAppRoutes from '../../../services/routes/appRoutes';
import {Link, useLocation} from "react-router-dom";
import _ from 'lodash';
import {SecurityContext} from '../../../services/SecurityManager';
import classNames from 'classnames';
import Button from '../Button/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleUp, faX} from '@fortawesome/pro-light-svg-icons';
import logo from "../../../assets/images/new_wind_logo.png";
//import logo from "../../../assets/images/wind-logo-hr.png";

const NavBar = () => {
    const security = useContext(SecurityContext);
    const location = useLocation();
    let routes = getAppRoutes(security);
    const [open, setOpen] = React.useState(false);
    const [active, setActive] = useState("");

    const openMenu = (name) => {
        setActive(name === active ? "" : name);
    }

    useEffect(() => {
        _.map(getAppRoutes(security), (route) => {
            if (route.hidden) return null;
            const {nestedRoutes} = route;
            _.map(nestedRoutes, (nestedRoute) => {
                if (nestedRoute.hidden) return null;
                if (location.pathname === nestedRoute.path) {
                    setActive(route.name);
                }
            });
        });
    }, [location, security]);

    const finishImpersonate = () => {
        sessionStorage.removeItem('impersonatedUser');
        window.location.reload();   
    }

    return (
        <nav className="NavBar">
            <div>
                <img src={logo} alt=""/>
            </div>
            <ul className={classNames('NavBar__list-items', open ? "NavBar__list-items--open" : "")}>
                {
                    _.map(routes, (route, idxMain) => {
                        if (route.hidden) {
                            return null;
                        }
                        const {nestedRoutes} = route;
                        const isParent = _.filter(nestedRoutes, {hidden: false}).length > 0;
                        const itemActiveClass = location.pathname === route.path && !isParent ? 'menu-active' : '';
                        return (
                            <li className="container-route" key={route.path || idxMain}>
                                <div className={classNames("primary", itemActiveClass)}>
                                    <Link className="main-primary" to={route.path}>
                                        <span className='main-item-icon'>
                                            {route.icon ? (
                                                route.icon.prefix && route.icon.iconName ? (
                                                    <FontAwesomeIcon icon={route.icon} size="lg" />
                                                ) : (
                                                    <img src={route.icon} alt="icon" />
                                                )
                                            ) : null}
                                        </span>
                                        <span className='main-item-text'>{route.name}</span>
                                    </Link>
                                    {
                                        (isParent) ?
                                            <Button
                                                leftIcon={route.name === active ? faAngleUp : faAngleDown}
                                                onClick={() => openMenu(route.name)}
                                                className="btn-styless">
                                            </Button> :
                                            null
                                    }
                                </div>
                                {
                                    <ul className={classNames('main-secondary', (active === route.name ? "vi" : "invi"))}>
                                        {_.map(nestedRoutes, (route, i) => {
                                            if (route.hidden) {
                                                return null;
                                            }
                                            const itemActiveClass = location.pathname === route.path ? 'menu-active' : '';
                                            return (
                                                <li key={route.path || i}
                                                    className={classNames("secondary", itemActiveClass)}>
                                                    <Link className="secondary-link" to={route.path}>
                                                        {route.name}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                }

                            </li>
                        );
                    })
                }

                {sessionStorage.getItem('impersonatedUser') && 
                    <div className='primary'>
                        <Link className="main-primary" to={"#"} onClick={finishImpersonate}>
                            <span className='main-item-icon'><FontAwesomeIcon icon={faX}/></span>
                            <span className='main-item-text'>Terminar sesión</span>
                        </Link>
                    </div>
                }
                
            </ul>

            <div className={classNames("NavBar__hambuger", open ? "open" : "")} onClick={() => setOpen(!open)}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </nav>
    )
}

export default NavBar;
