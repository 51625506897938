import React, { useState, useRef, useEffect } from 'react';
import IconButton from '../IconButton/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';

import windAvatarDefault from "../../../assets/images/wind-avatar-placeholder.png";
import "./DropdownSingleMenu.scss";

const DropdownSingleMenu = ({ text, options, icon, color, profile, outline }) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleOptionClick = (callback) => {
		setIsOpen(false); // Close the dropdown after selecting an option
		callback();
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className="dropdown-container" ref={dropdownRef}>
			{
				icon?
				<IconButton icon={icon} onClick={toggleDropdown} color={color} />
				:
				<button className={classNames("Button", ( outline? "more-outline": "" ) )} onClick={toggleDropdown}>
					{text} <FontAwesomeIcon icon={faArrowRight} />
				</button>
			}
			
			
			{isOpen && (
				<div className="dropdown-options">
					{
						profile && 
						<div className="dropdown-profile">
							<div className="dropdown-profile-avatar">
								<img src={windAvatarDefault} className="dropdown-avatar" alt="" />
							</div>
							<div className="dropdown-profile-data">
								<div className="dropdown-profile-name">{profile.fullName}</div>
								<div className="dropdown-profile-position">
									{profile.position? profile.position: profile.role?.title}
								</div>
							</div>
							
						</div>
					}
					
					{
						( options && typeof(options) === 'object' ) ?
						options.map(
							(option) => 
								<button className="dropdown-option" onClick={()=>handleOptionClick(option.callback)} key={option.text}>
									{
										option.icon &&
										<FontAwesomeIcon icon={option.icon} className="dropdown-option-icon" />
									}
									<div>{option.text}</div>
								</button>			
							
						)
						: null
					}
				</div>
			)}
		</div>
	);
};

export default DropdownSingleMenu;
