import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { paths } from "../../../services/routes/appRoutes";
import StatusIndicator from "../../utility/StatusIndicator/StatusIndicator";
import { ApiContext } from "../../../services/api/api-config";
import { transactionType, transactionTypeColor } from "../../../services/modelUtils/transactionUtils";

import "./AccountsOverview.scss"

const AccountsOverview = () => {

    const api = useContext(ApiContext);
    const [ types, setTypes ] = useState([]);
    const [ total, setTotal ] = useState("0");

    useEffect(
        () => {
            api.widgetsTransactions.get().then(
                (data) => {
                    setTypes(data.types);
                    setTotal(data.count);
                }
            )
            
        },[api,setTypes,setTotal]
    );

    return(
        <div className="AccountsOverview">
            <div className="accounts-overview-header">
                <span className="single-title">Cuentas</span>
                <Link to={paths.financeAccounts } className="link strong right">
                    Ver todo
                </Link>
            </div>
            <div className="accounts-overview-data">
                <StatusIndicator text="Total" number={total} layout="long" className="mb-15 accounts-overview-total" />
                {
                    types.map( (st) => {
                        return <div key={st.status}>
                            <StatusIndicator 
                                text={transactionType[st.transactionType]} 
                                number={st.qty} 
                                type={ transactionTypeColor[st.transactionType] || "success" } 
                                layout="long" 
                                className="mb-15" />
                        </div>
                    } )
                }
            </div>
        </div>
    );
    
}

export default AccountsOverview;