import React from 'react';
import './MainLoadingIndicator.scss';
import {useSelector} from "react-redux";;

const MainLoadingIndicator = () => {

    const isLoadingRequest = useSelector(({loading})=>!!loading);

    if(!isLoadingRequest)
        return null; 

    return (
        <div className={"MainLoadingIndicator"}>
            <span className="loader"></span>
            <span className="text-loader"></span>
            
        </div>
    );
};

export default MainLoadingIndicator;
