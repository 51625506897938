import React from "react";
import ReactEcharts from "echarts-for-react";

const LineChart = ({dataX=[], dataY=[]}) => {

    const options = {
        xAxis: {
            type: 'category',
            data: dataX,
            axisLabel:{
                fontSize: 21,
                color: function(value, index){
                    return '#D1D4D9';
                }
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: function (value) {
                    return   value+"K"
                },
                fontSize: 21,
                color: function(value, index){
                    return '#D1D4D9';
                }
            },
            splitLine:{
                show: true,
                lineStyle: {
                    type: [20,20],
                    dashOffset: 20
                }
            }
        },
        series: [
            {
                data: dataY,
                type: 'line',
                smooth: true,
                lineStyle: {
                    color: '#e77678',
                    width: 10,
                    shadowBlur: 70.5,
                    shadowOffsetY: 50
                },

            }
        ]
    };

    return (
        <ReactEcharts
            option={options}
            style={{height: "400px", width: "100%"}}
        />
    );
}
export default LineChart;
