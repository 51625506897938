import React from 'react';
import WindInput from "../../../../../../formComponents/WindInput/WindInput";

const SearchBarTextFilter = ({ value, onChange, config, withLabel=true }) => {
    return (
        <div className={"TextFilter SearchBarFilter"}>
            <WindInput placeholder={config?.label} value={value} onChange={onChange} withLabel={withLabel} />
        </div>
    );
};

export default SearchBarTextFilter;
