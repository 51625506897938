
import React, {useContext, useCallback, useState} from 'react'
import { faTrashAlt, faFileArchive } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiContext } from '../../../../../services/api/api-config';
import { useSelector } from 'react-redux';
import windAvatarPlaceholder from "../../../../../assets/images/wind-avatar-placeholder.png";

import './CommentDetail.scss';
import { getNotifier } from '../../../../../services/notifier';
import Modal from '../../../../../components/utility/Modal/Modal';

const CommentDetail = ({comment, reloadConversation}) => {
  const api = useContext(ApiContext);
  const avatarUrl = (comment.user?.avatar ? api?.appFiles.getUrl(comment.user?.avatar) : windAvatarPlaceholder);
  const me = useSelector(({api})=>api.me);

  const [confirmDelete, setConfirmDelete] = useState();

  const handleDelteComment = useCallback(() => {
    if(!comment.id) return;

    api.messages.delete({id: comment.id}).then(() => {
      getNotifier().success("Comentario eliminado");
      if(reloadConversation)
        setConfirmDelete(false);
        reloadConversation();
    });    
  }, [api, comment, reloadConversation]);
  
  return (
    <>
    <div className='CommentDetail'>
      <div className='Header'>
        <div className='UserDetails'>
          <img src={avatarUrl} alt={comment.user.fullName} />
          <span>{comment.user.fullName}</span>
        </div>
        
        <div className='Toolbar'>
          <div className='deleteIcon'>
            {comment.user.id===me.id && <FontAwesomeIcon icon={faTrashAlt} onClick={()=>setConfirmDelete(true)} data-test-id="delete-message-button" />}
          </div>
        </div>
      </div>
      <div className='CommentText'>
        { !comment.appFile ? comment.message ?? comment.message : <a href={api.appFiles.getUrl(comment.appFile)} target='_blank' rel="noopener noreferrer" download>Ver documento <FontAwesomeIcon icon={faFileArchive} /></a> }
      </div>
    </div>
    {confirmDelete &&
      <Modal
        onClose={()=>setConfirmDelete(false)}
        title={'Eliminar el comentario'}
        mainButtonText={'Eliminar'}
        mainButtonAction={handleDelteComment}
        mainButtonColor={'danger'}
        secondaryButtonAction={()=>setConfirmDelete(false)}
        
      >
        ¿ Estás seguro que deseas eliminar el comentario de manera permanente ?
      </Modal>}
    </>
  )
}

export default CommentDetail;