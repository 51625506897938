import React, {useMemo} from "react";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import { superAdminTableColumns, userTableSGroups } from "../../../services/modelUtils/superAdminUtils";
import TopBar from "../../../components/utility/TopBar/TopBar";
import SceneLinkMenu from "../../../components/utility/SceneLinkMenu/SceneLinkMenu";
import SceneTitle from "../../../components/layout/SceneTitle/SceneTitle";
import useBoolean from "../../../hooks/useBoolean";
import SuperAdminFormModal from "./components/SuperAdminFormModal";
import "./SuperAdminList.scss"

const requestFilters={"role.name":"SUPER_ADMIN", sGroups: userTableSGroups};
const SuperAdminList = () => {
    const [showingForm, showForm, hideForm] = useBoolean(false);

    const table = useTideTable({
        entity: 'users',
        columns: superAdminTableColumns,
        requestFilters,
    });

    const { tableProps } = table;

    const tools = useMemo(() => [
        { text: "Crear", callback: showForm, outline: false },
    ], [showForm]);


    return(
        <div className={"AdminList wind-scene"}>
            <TopBar />
            <SceneTitle description="Consulta la información de los super administradres">Super Administradores</SceneTitle>
            <SceneLinkMenu tools={tools}/>

            <TideReactTable {...tableProps}/>

            { showingForm &&
                <SuperAdminFormModal
                    onClose={hideForm}
                    onAdminSaved={() => table.reload()}
                />
            }
        </div>
    )
}

export default SuperAdminList;
