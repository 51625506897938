import React, { useContext } from 'react';
import WindInput from '../../../../../components/formComponents/WindInput/WindInput';
import WindSwitch from '../../../../../components/formComponents/WindSwitch/WindSwitch';
import TideEntitySelect from '../../../../../components/utility/TideEntitySelect/TideEntitySelect';
import { SecurityContext } from '../../../../../services/SecurityManager';

const UserInfo = ({ bindSimple, userId }) => {
  const security = useContext(SecurityContext);

  const userCannotEditCompany = !security.isTenantUser();
  
  return (
    <div className="userform-formfields"> 
      <div className="userform-field">
        <label>Nombre</label>
        <WindInput
          className='project-field width-50'
          placeholder={"Nombre"}
          withLabel={false}
          {...bindSimple('name')}
        />
      </div>
      
      <div className="userform-field">
        <label>Apellido</label>
        <WindInput
          className='project-field'
          placeholder={"Apellido"}
          withLabel={false}
          {...bindSimple('lastName')}
        />
      </div>

      <div className="userform-field">
        <label>Compañía</label>
        <TideEntitySelect
          className='project-field'
          entity='clients'
          placeholder={"Empresa"}
          {...bindSimple('client')}
          preload={true}
          disabled={userCannotEditCompany}
        />
      </div>

      <div className="userform-field">
        <label>Usuario</label>
        <WindInput
          className='project-field'
          placeholder={"Usuario"}
          withLabel={false}
          {...bindSimple('username')}
        />
      </div>

      <div className="userform-field">
        <label>Teléfono</label>
        <WindInput
          className='project-field'
          placeholder={"Teléfono"}
          withLabel={false}
          {...bindSimple('phone')}
        />
      </div>

      <div className="userform-field">
        <label>Puesto</label>
        <WindInput
          className='project-field'
          placeholder={"Puesto"}
          withLabel={false}
          {...bindSimple('position')}
        />
      </div>

      <div className="userform-field">
        <label>Rol</label>
        <TideEntitySelect
          className='project-field'
          entity='roles'
          placeholder={"Rol"}
          {...bindSimple('role')}
          additionalFilters={{'assignable': 1}}
          labelCreator={(role) => role.title}
          preload={true}
        />
      </div>

      <div className="userform-field">
        <label>Email</label>
        <WindInput
          className='project-field'
          placeholder={"Email"}
          withLabel={false}
          {...bindSimple('email')}
        />
      </div>
        

        <WindSwitch
          className='project-field'
          placeholder={'Activo'}
          {...bindSimple('isActive')}
        />
{!userId && 
          <WindSwitch
            className='project-field'
            placeholder={'Enviar accesos'}
            {...bindSimple('sendAccess')}  
          />}
        
      
        

        

        

        
      
    </div>
  );
}
 
export default UserInfo;
