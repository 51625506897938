import React, { useContext, useEffect, useCallback, useState } from 'react';
import useBoolean from '../../../../../hooks/useBoolean';
import {ApiContext} from "../../../../../services/api/api-config";
import SimpleCard from '../../../../../components/layout/SimpleCard/SimpleCard';
import {SecurityContext} from "../../../../../services/SecurityManager";
import Button from '../../../../../components/utility/Button/Button';
import RegisteredRfc from '../RegisteredRfc/RegisteredRfc';
import RegisterRfcModal from '../RegisterRfcModal/RegisterRfcModal'
import './TenantRfcForm.scss';

const TenantRfcForm = () => {

    const api = useContext(ApiContext);
    const context = useContext(SecurityContext);
    const [ rfcs, setRfcs ] = useState([]);
    const [showingRegisterRfcModal, showRegisterRfcModal, hideRegisterRfcModal] = useBoolean(false);

    const fetchFiscalData = useCallback(async() => {

        if (!context.isTenantUser()) return 
        
        const tenantRfcs = await api.tenantRfcs.get({params:{sGroups: ["tenant_rfc_read", "csd_read_id"]}})
        const rfcData = await Promise.all(
            tenantRfcs.map(async rfc => {
                const fiscalData = await api.fiscalDatas.get({ rfc: rfc.rfc });
                return { ...rfc, fiscalData: fiscalData.filter(item=> item.rfc=== rfc.rfc)[0]};
            })
        );

        setRfcs(rfcData)
    }, [api, context])

    // ------ Load data ------
    useEffect(() => {
        fetchFiscalData()
    }, [fetchFiscalData]);

    return (
        <>
        <SimpleCard className='TenantRfcForm general-info'>

            {rfcs.map(rfc => 
                <RegisteredRfc key={rfc.rfc} rfc={rfc} onActionSuccess={fetchFiscalData}/>
             )
            }

            <Button onClick={showRegisterRfcModal}>Agregar RFC</Button>
        </SimpleCard>

        {showingRegisterRfcModal &&
            <RegisterRfcModal onClose={hideRegisterRfcModal} onActionSuccess={fetchFiscalData}/>
        }
        </>
    )
}

export default TenantRfcForm;