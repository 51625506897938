import React, { useState } from 'react'
import TenantRfcForm from './components/tenantrfcs/TenantRfcForm';
import Customization from './components/Customization/Customization';
import InvoicePreferences from './components/InvoicePreferences/InvoicePreferences';
import DetailTabs from '../../../components/layout/DetailTabs/DetailTabs';
import TopBar from '../../../components/utility/TopBar/TopBar';
import SceneTitle from '../../../components/layout/SceneTitle/SceneTitle';
import './AdminPanel.scss';

const adminTabs = [
    {value: 'rfc', label: 'Información Fiscal', Component: TenantRfcForm},
    {value: 'invoicePreferences', label: 'Facturación', Component: InvoicePreferences},
    {value: 'customization', label: 'Personalización', Component: Customization}
]

const AdminPanel = () => {

    const [ activeTab, setActiveTab ] = useState(() => adminTabs[0]);
    const { Component } = activeTab;

    return (
        <div className={"AdminPanel wind-scene"}>
   
           <TopBar/>
           <SceneTitle className='title' description="Persoanliza el sistema">Configuración</SceneTitle>
            <DetailTabs
                    tabs={adminTabs}
                    onTabSelect={setActiveTab}
                    activeTab={activeTab}
            />
            <div className="admin-tabs">
                {Component && <Component className="component"/>}
            </div> 
            
        </div>
    )
}

export default AdminPanel;