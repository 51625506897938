import React, { useMemo,  useState} from 'react';
import useTideTable from '../../../components/utility/TideReactTable/useTideTable';
import TideReactTable from '../../../components/utility/TideReactTable/TideReactTable';
import {filterTypes} from '../../../services/searchUtils';
import {
    naviMessageTableColumns,
    naviMessageTableSGroups,
} from '../../../services/modelUtils/naviMessageUtils';
import useSearchHistory from '../../../hooks/useSearchHistory';
import useUrlFilters from '../../../hooks/useUrlFilters';
import SearchBar from '../../../components/search/SearchBar/SearchBar';
import './NaviMessageList.scss';
import ThoughtProcess from "../NaviAgent/components/ThoughtProcess";

const filtersConfig = {
    placeholder: 'Search Navi Messages...',
    filters: [
        {main: true, field: 'userInput', label: 'Search by User Input'},
        {type: filterTypes.TEXT, field: 'errorMessage', label: 'Search by Error Message'},
        {type: filterTypes.TEXT, field: 'naviOutput', label: 'Search by Navi Output'},
        {
            type: filterTypes.ORDER,
            field: 'order[executionMilis]',
            label: 'Order by Execution Time',
        },
    ],
};

const NaviMessageList = () => {

    const urlFilters = useUrlFilters();

    const requestFilters = useMemo(
        () => ({
            sGroups: naviMessageTableSGroups,
            'order[createdDate]': 'DESC',
        }),
        []
    );

    const table = useTideTable({
        entity: 'navi_messages',
        columns: naviMessageTableColumns,
        requestFilters,
        ...urlFilters,
    });

    const {tableProps, filters, setFilters} = table;

    useSearchHistory(table);

    const onCloseSearch = () => {
    };

    const [selectedMessage, setSelectedMessage] = useState(null);

    const handleRowClick = (message) => {
        setSelectedMessage(message);
    };

    const customGetRowProps = (row) => {
        const isSelected = row.original.id === selectedMessage?.id;
        const className = isSelected ? 'selected-row' : '';
        const rowProps = row.getRowProps();
        return {
            ...rowProps,
            className: `${rowProps.className || ''} ${className}`,
        };
    };

    return (
        <div className="NaviMessageList">
            <SearchBar
                title="Navi Messages"
                filters={filters}
                filtersConfig={filtersConfig}
                onFiltersChange={setFilters}
                onClose={onCloseSearch}
                labels={false}
            />

            {/* New Inner Container */}
            <div className="navi-message-content">
                <div className="message-container">
                    <div className="message-list">
                        <TideReactTable
                            {...tableProps}
                            className="table-hoverable"
                            customGetRowProps={customGetRowProps}
                            onRowClick={(message) => handleRowClick(message)}
                        />
                    </div>
                    <div className="message-details">
                        {selectedMessage ? (
                            <NaviMessageDetail message={selectedMessage}/>
                        ) : (
                            <div className="no-message-selected">
                                Please select a message to view details
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const NaviMessageDetail = ({message}) => {
    return (
        <div className="navi-message-detail">
            <p>
                <strong>ID:</strong> {message.id}
            </p>
            <p>
                <strong>Modelo:</strong> {message.model}
            </p>
            <p>
                <strong>Tiempo de ejecución (ms):</strong> {message.executionMilis}
            </p>
            <p>
                <strong>Solicitud de usuario:</strong> {message.userInput}
            </p>
            <p>
                <strong>Respuesta de Navi:</strong> {message.naviOutput}
            </p>
            {message.errorMessage &&
                <p>
                    <strong>Mensaje de error:</strong> {message.errorMessage}
                </p>
            }

            {message.thoughtProcess &&
                <ThoughtProcess thoughtProcess={message.thoughtProcess} autoOpen={true}/>
            }

        </div>
    );
};

export default NaviMessageList;
