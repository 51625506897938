import { faTools } from '@fortawesome/pro-light-svg-icons';
import React, { useState, useEffect, useContext, useRef } from 'react'
import { useMemo } from 'react';
import WindSelect from '../../formComponents/WindSelect/WindSelect';
import WindTextarea from '../../formComponents/WindTextarea/WindTextarea';
import InfoPair from '../../layout/InfoPair/InfoPair';
import Button from '../../utility/Button/Button';
import IconButton from '../../utility/IconButton/IconButton';
import Modal from '../../utility/Modal/Modal';
import CommentsList from './components/CommentsList/CommentsList';
import useFormState from '../../../hooks/useFormState';
import ChannelForm from './components/ChannelForm/ChannelForm';
import {ApiContext} from "../../../services/api/api-config";
import { conversationsSGroups } from '../../../services/modelUtils/conversationUtils';
import './Conversations.scss';
import { useSelector } from 'react-redux';
import { getNotifier } from '../../../services/notifier';
import _ from 'lodash';
import { useCallback } from 'react';

const Conversations = ({id, entityName="Proyecto", conversationTitle="", closeConversations, clientId}) => {
  const api = useContext(ApiContext);
  const { form, bindSimple } = useFormState();
  const [channel, setChannel] = useState();
  const [showChannelsForm, setShowChannelsForm] = useState();  
  const me = useSelector(({api})=>api.me);

  const inputFile = useRef();

  const setNewConversation = useCallback((conversation, channel) => {
    setConversation(conversation);
    
    if(channel)
      setChannel({label: channel.name, value: channel.id});
  }, []);
  
  // ----- Load conversation data
  const reloadConversation = useCallback((channel) => {
    api.conversations.get({id: `${id}`, params:{ sGroups: conversationsSGroups }}).then((conversation) => setNewConversation(conversation, channel));
  }, [api, id, setNewConversation]);

  const [conversation, setConversation] = useState();

  useEffect(()=>{reloadConversation()},[reloadConversation]);

  const comments = useMemo(() => {
    return (channel && conversation ? conversation.channels?.find(_channel => _channel.id===channel.value)?.messages : conversation?.messages||[]) || [];
  }, [conversation, channel]);

  const handleChangeChannel = (e) => { 
    setChannel(e);
  };

  useEffect(() => {
    if(conversation?.channels?.length && !channel) {
      setChannel({label: conversation.channels[0].name, value: conversation.channels[0].id});
    }
  }, [conversation, channel]);
  
  const sumComments = (total, channel) => {
    const totalComments = Number(channel.messages?.length) || 0;
    
    return total + totalComments;
  };

  const totalComments = useMemo(() => {
    return conversation?.channels?.reduce(sumComments, 0);
  }, [conversation]);

  const handleSaveComment = () => {
    if(!form.comment?.length)
      return getNotifier().warning("Debe esribir un comentario");
      
    if(!channel)
      return getNotifier().warning("Debe seleccionar un canal");

    const message = {
      channel: channel?.value,
      user: me.id,
      message: form.comment,
      client: clientId,
    };

    api.messages.create({ params:message })
      .then((message) => {
          form.comment = ''; 
          reloadConversation();
      });
  };
  
  const isLoading = useSelector(({loading})=>!!loading);

  const handleConfigClick = () => {
    setShowChannelsForm(true);
  };

  const handleFileUpload = event => {
    let file = event.target.files[0];
    if(!file)
      return getNotifier().warning("Debe seleccionar un archivo para enviar");
      
    if(!channel)
      return getNotifier().warning("Debe seleccionar un canal");

    const message = {
      channel: channel?.value,
      user: me.id,
      message: form.comment,
      client: clientId,
    };

    api.messages.create({ params:message, files: {appFile: file} })
      .then((message) => {
          form.comment = ''; 
          reloadConversation();
      });
  };

  return (
    <Modal
      title={`Comentarios - ${entityName}`}
      className={"Conversations"}
      onClose={closeConversations}
      mainButtonText="Guardar"
      handleTitleClick={() => setShowChannelsForm(false)}
      showBackArrow={showChannelsForm}
    >
      <div className='rowflex'>
        <InfoPair value={conversationTitle} title={"Proyecto"}/>
        <InfoPair value={totalComments} title={"Comentarios"}/>
        <InfoPair value={conversation?.channels?.length} title={"Canales"}/>
      </div>

      {showChannelsForm && 
        <ChannelForm 
          channels={conversation?.channels} 
          selectedChannel={channel}
          channelId={channel?.value}
          conversation={conversation}
          reloadConversation={reloadConversation}
          returnToConversation={() => setShowChannelsForm(false)}
        />}

      {!showChannelsForm && 
        <div>
          <div className='rowSimple'>
            <WindSelect 
              className={'selectChannels'}
              label="Canal" 
              name="canal" 
              options={conversation?.channels?.map(channel => ({label: channel.name, value: channel.id}))}
              onChange={handleChangeChannel}
              value={channel}
            />
            <IconButton 
              icon={faTools} 
              className={"TooltsButton"} 
              onClick={handleConfigClick} 
              data-test-id="channel-config-button" 
              data-tooltip={"Configuración de canales"}
              disabled={isLoading}
            />
          </div>

          <CommentsList comments={_.sortBy(comments, 'createdDate')} reloadConversation={reloadConversation} />
          
          <WindTextarea 
            placeholder={"Escribe un comentario"} 
            className="inputComment" 
            {...bindSimple('comment')}
          />

          <div className='rowflex mt-1'>
            <input type='file' 
              className='file-input' 
              onChange={handleFileUpload} 
              key={Math.random()}
              hidden
              ref={inputFile}
            />
            <Button 
              color={'blue'} 
              outline
              onClick={() => inputFile.current?.click()}
              data-test-id="send-file-button"
              disabled={!channel || isLoading}
            >
              Agregar Archivo              
            </Button>
            <Button 
              color={'blue'} 
              outline 
              onClick={handleSaveComment} 
              data-test-id="save-comment-button"
              disabled={!channel || isLoading}
            >
              Comentar
            </Button>
          </div>
        </div>}
    </Modal>
  )
}

export default Conversations;