import React, {useMemo, useCallback, useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {Link, useHistory} from 'react-router-dom';
import TideReactTable from '../../../components/utility/TideReactTable/TideReactTable';
import useTideTable from '../../../components/utility/TideReactTable/useTideTable';
import {moneyFormatter} from '../../../services/currencyUtils';
import {
    projectTableSGroups,
    projectStatusOptionsForSelect,
    projectStatusTrans,
    projectStatusTransColor
} from '../../../services/modelUtils/projectUtils';
import {paths} from '../../../services/routes/appRoutes';
import {ApiContext} from "../../../services/api/api-config";
import {filterTypes} from "../../../services/searchUtils";
import useSearchHistory from '../../../hooks/useSearchHistory';
import useUrlFilters from '../../../hooks/useUrlFilters';
/// import { SecurityContext } from '../../../services/SecurityManager';
import SearchBar from "../../../components/search/SearchBar/SearchBar";
import HorizontalStatsIndicator from '../../../components/utility/HorizontalStatsIndicator/HorizontalStatusIndicator';
import StatusIndicator from '../../../components/utility/StatusIndicator/StatusIndicator';
import ProjectNewForm from './components/ProjectNewForm/ProjectNewForm';
import useBoolean from '../../../hooks/useBoolean';
import SectionHeader from '../../../components/utility/SectionHeader/SectionHeader';
import  { getProjectHeaderInfo } from '../../../constants/SectionHeaderInfo';
import { SecurityContext } from '../../../services/SecurityManager';
import logo from "../../../assets/images/wind-logo-hr.png"
import "./ProjectTable.scss"

const customProp = 'projectTable';
const filtersConfig = {
    placeholder: 'Buscar un proyecto...', filters: [
        {main: true, field: 'search'},
        {type: filterTypes.SELECT, field: 'status', label:'Estatus', isMulti: true, options:projectStatusOptionsForSelect},
        {field: 'createdDate[after]', type: filterTypes.DATE, label: 'Fecha (desde)'},
        {field: 'createdDate[before]', type: filterTypes.DATE, label: 'Fecha (hasta)'},
        {field: 'name', label:'Nombre del proyecto'},
        {field: 'client.fullName', label:'Cliente'},
        {field: 'folio', label:'Folio del proyecto'},
        {type: filterTypes.ORDER, field: 'order[createdDate]', label:'Ordenar por creación'},
    ]
};

const ProjectTable = () => {
    const api = useContext(ApiContext);
    const history = useHistory();
    const urlFilters = useUrlFilters();

    const security = useContext(SecurityContext);
    const headerInfo = getProjectHeaderInfo(security)

    const [ addProject, addingProject, cancelAddingProject ] = useBoolean(false);
    const [ stats, setStats ] = useState([]);

    // ----- Loading project table
    const columns = useMemo(() => [
        {Header: "Folio", accessor: (p) => p.folio},
        {
            Header: "Nombre", id: 'name',
            accessor: (project)=>
            <Link to={paths.projectDetail.replace(':id', project.id)} data-tooltip={"Ir a proyectos"}>
                {project.name}
            </Link>
        },
        {Header: "Cliente", accessor: 'client.fullName'},
        {Header: 
            "Estado", 
            id: 'status', 
            accessor: (p) => { 
                return <StatusIndicator text={projectStatusTrans[p.status]||p.status} type={projectStatusTransColor[p.status]||"info"} layout="inner" />
            } 
        },
        {
            Header: "Cot. totales", 
            accessor: (p) => `${p.projectStats?.quotesCount||0}`, 
            Cell: ({ value }) => <div className="text-center">{value}</div>
        },
        {
            Header: "Cot. aprobadas", 
            accessor: (p) => `${p.projectStats?.approvedQuotesCount||0}`,
            Cell: ({ value }) => <div className="text-center">{value}</div>

        },
        {
            Header: "Pendiente de pago",
            accessor: (p) => `$ ${moneyFormatter(Number(p.projectStats?.approvedQuotesSum) - Number(p.projectStats?.paymentsSum))}`,
            Cell: ({ value }) => <div className="text-right">{value}</div>

        },
        {
            Header: "Última actualización",
            accessor: (p) => (p.updatedDate ? moment(p.updatedDate).format('DD/MM/YYYY') : null)
        },
    ], []);

    const projectFilters = useMemo(() => ({sGroups: projectTableSGroups}), []);
    const projectOptions = useMemo(() => ({ customProp }), []);

    useEffect(
        () => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            if ( params.creation && params.creation === "a" ) addingProject();

            api.widgetsProjects.get().then(
                (data) => {
                    let statsP = data.status.map(
                        (st) => {
                            return {
                                text: projectStatusTrans[st.status],
                                number: st.qty,
                                type: projectStatusTransColor[st.status]
                            }
                        }
                    )
                    setStats(statsP);
                }
            )
            
        },[api,setStats,addingProject]
    );

    const table = useTideTable({
        entity: 'projects',
        columns,
        requestOptions: projectOptions,
        requestFilters: projectFilters,
        ...urlFilters
    });

    const { tableProps, filters, setFilters } = table;
    useSearchHistory(table);

    // ----- Link to add projects
    const handleAddProject = useCallback(() => history.replace(paths.projectNew), [history]);
    // const redirectToProjectsList = useCallback(() => history.replace(paths.projects), [history]);
    const exportToExcel = useCallback(() => {
        window.location.href = api.projects.getExportExcelUrl({pagination: false})
    }, [api]);

    const sceneTools = useMemo(() =>  
        security.canAdminProjects() ?[
            { text: "Exportar", callback: exportToExcel, outline: true },
            { text: "Crear", callback: handleAddProject, outline: false },
    ] : []
    , [exportToExcel, handleAddProject, security]);
    
    const onProjectAdded = () => {
        cancelAddingProject();
        table.reload();
    }

    return (
        <div className={"ProjectTable wind-scene"}>

            <SectionHeader pageInfo={headerInfo} tools={sceneTools}/>
            {security.canAdminProjects() && 
            <>
                <HorizontalStatsIndicator stats={stats} />
                
                <SearchBar title="Todos los proyectos" filters={filters} filtersConfig={filtersConfig} onFiltersChange={setFilters} labels={false}/>
                
                <div className='center-container'>
                    <TideReactTable {...tableProps} className="table-hoverable"/>
                </div>

                { addProject && <ProjectNewForm onProjectSaved={onProjectAdded} onClose={cancelAddingProject} /> }
            </>
            }
            {!security.canAdminProjects() &&
                
                <div className="logo-content">
                    <img src={logo} alt="Wind" className='wind-logo'/>
                </div>
                
            }
        </div>
    );
}

export default ProjectTable;
