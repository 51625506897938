import { copyPropertyOrNull, copyPropertyOrThrow, copyPropertyIdOrNull } from "../formUtils";
import windAvatarPlaceholder from "../../assets/images/wind-avatar-placeholder.png";
import { paths } from "../../services/routes/appRoutes";
import {Link} from "react-router-dom";

export const getEmptyUser = () => {
    return {
        id: null,
        name: null,
        lastName: null,
        client: null,
        isActive: false,
        username: null,
        email: null,
        phone: null,
        role: null,
        sendAccess: false,
    };
};

export const prepareUserForServer = ( form )=>{
    let user = {}

    copyPropertyOrThrow( form, user, "name", "Por favor llena el campo de nombre" );
    copyPropertyOrThrow( form, user, "lastName", "Por favor llena el campo de apellido" );

    copyPropertyIdOrNull( form, user, "client" );
    copyPropertyIdOrNull( form, user, "role" );

    copyPropertyOrThrow( form, user, "isActive", "Por favor selecciona si el usuario es activo" );
    copyPropertyOrThrow( form, user, "email", "Por favor llena el campo de email de usuario" );

    copyPropertyOrNull( form, user, "username" );
    copyPropertyOrNull( form, user, "phone" );
    copyPropertyOrNull( form, user, "sendAccess" );
    copyPropertyOrNull( form, user, "position" );

    user.username = user.username||user.email;
    
    return user;
}

export const getUserAvatarUrl = ( user, api )=>{
    return user?.avatar ?
        api?.appFiles.getUrl(user.avatar):
        windAvatarPlaceholder;
};

export const userTableColumns = [
    {
        Header: 'Nombre',
        accessor: (user) => <Link to={paths.usersDetail.replace(":id", user.id)}>{user.name}</Link>,
        id: 'userName',
    },
    {
        Header: 'Empresa',
        accessor: (user) => <Link to={paths.clientDetail.replace(":id", user.client?.id)}>{user.client?.fullName}</Link>,
        id: 'clientName',
    },
    {
        Header: 'Email',
        accessor: 'email'
    },
    {
        Header: 'Teléfono',
        accessor: 'phone'
    },
    {
        Header: 'Puesto',
        accessor: 'position'
    },
    {
        Header: 'Principal',
        accessor: (u) => (u.isCompanyOwner? 'Si' : 'No'),
        id: 'isCompanyOwner',
    }
]

export const userTableSGroups = [
    'user_read',
    'user_read_client',
        'client_read_name',
        'client_read_full_name',
        'client_read_id',
    'user_read_position'
];

export const usersDetailSGroups = [
    'user_read',
    'user_read_client',
        'client_read_name',
        'client_read_full_name',
    'user_read_avatar',
    'user_read_role',
        'role_read',
    'user_read_permission_groups',
    'permission_group_read',
    'app_file_read',
]
