import { useContext, useEffect, useState } from "react"
import WindInput from "../../../../../components/formComponents/WindInput/WindInput"
import TideEntitySelect from "../../../../../components/utility/TideEntitySelect/TideEntitySelect"
import { satCatalogSGroups } from "../../../../../services/modelUtils/taxDocumentUtils"
import { ApiContext } from "../../../../../services/api/api-config"
import { getNotifier } from "../../../../../services/notifier"
import useBoolean from "../../../../../hooks/useBoolean"
import RegisterCsdModal from "../../../AdminPanel/components/RegisterCsdModal/RegisterCsdModal"
const IssuerData = ({bindSimple, form, setForm}) => {

    const api = useContext(ApiContext)

    const [selectedIssuer, setSelectedIssuer] = useState(null)
    const [showingRequiredCsd, showRequiredCsd, hideRequiredCsd] = useBoolean()
    const [fiscalData, setFiscalData] = useState([])

    useEffect(()=>{
        if(form.issuerCompanyName || form.issuerZipCode || form.issuerRfc || form.issuerTaxRegime){
            setSelectedIssuer(true)
        }else{
            const fetchFiscalData = async() => {
                const fetchedData = await api.fiscalDatas.get()
                setFiscalData(fetchedData)
            }   

            fetchFiscalData()
        }
    }, [api, form])
    
    const handleRequiredCsdClose = () => {
        setSelectedIssuer(null)
        setForm({
            ...form,
            'tenantRfcId': null,
            'issuerRfc': null,
            'issuerCompanyName': null,
            'issuerZipCode': null  
        })
        hideRequiredCsd()
    }

    const handleIssuerChange = async (issuer) => {
        setSelectedIssuer(issuer)
        const selectedFiscalData = fiscalData.find(data => data.rfc === issuer.rfc)

        if(issuer.csd === null){
            showRequiredCsd()
        }
        if (selectedFiscalData) {
            setForm({
                ...form,
                'tenantRfcId': issuer.id,
                'issuerRfc': selectedFiscalData.rfc,
                'issuerCompanyName': selectedFiscalData.businessName,
                'issuerZipCode': selectedFiscalData.zip  
            })
        } else {
            getNotifier().error("No hay datos fiscales registrados para este RFC")
        }
    }
    
    return(
        <>
            {!selectedIssuer && 
                <div className="issuer-rfc">
                    <label>{"RFC"}</label>
                    <TideEntitySelect
                        entity='tenantRfcs'
                        placeholder='RFC'
                        additionalFilters={{
                            sGroups: ['tenant_rfc_read_rfc', 'tenant_rfc_read_id', 'tenant_rfc_read_csd'],
                        }}
                        preload
                        filterBy={'rfc'}
                        labelCreator={(rfc) => rfc.rfc}
                        value={selectedIssuer}
                        onChange={handleIssuerChange}
                    />
                </div>
            }

            { selectedIssuer && 
                <>
                    <div className="input-row">

                        <WindInput
                            placeholder={"RFC"}
                            type={'text'}
                            value={form.issuerRfc}
                            inputProps={{readOnly:true}}
                        />

                        <WindInput
                            placeholder={"Razón Social"}
                            type={'text'}
                            value={form.issuerCompanyName}
                            inputProps={{readOnly:true}}
                        />

                        <WindInput
                            placeholder={"Codigo Postal"}
                            type={'number'}
                            {...bindSimple('issuerZipCode')}
                            inputProps={{readOnly:true}}
                        />
                        
                    </div> 
                


                    <div>
                    <label>{"Regimen Fiscal del Emisor"}</label>
                    </div>

                    <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Regimen Fiscal'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_RegimenFiscal"
                        }}
                        preload
                        filterBy={'description'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        {...bindSimple('issuerTaxRegime')}
                    />
                </>
            }


            {showingRequiredCsd &&
                <RegisterCsdModal onClose={handleRequiredCsdClose} rfc={selectedIssuer}/>
            }

        </>
    )
}
export default IssuerData
