import React, {useCallback, useEffect, useMemo, useState, useContext} from 'react';
import WindDatePicker from '../../../../../components/formComponents/WindDatePicker/WindDatePicker';
import WindSelect from '../../../../../components/formComponents/WindSelect/WindSelect';
import WindSwitch from '../../../../../components/formComponents/WindSwitch/WindSwitch';
import WindInput from '../../../../../components/formComponents/WindInput/WindInput';
import TideEntitySelect from '../../../../../components/utility/TideEntitySelect/TideEntitySelect';
import { quoteStatusOptions } from '../../../../../services/modelUtils/quoteUtils';
import {projectSelectorSGroups} from "../../../../../services/modelUtils/projectUtils";
import { moneyFormatter } from '../../../../../services/currencyUtils';
import { useHistory } from 'react-router-dom';
import { paths } from '../../../../../services/routes/appRoutes';
import { SecurityContext } from '../../../../../services/SecurityManager';

import './GeneralInfo.scss';

const GeneralInfo = ({ bindSimple ,total, subtotal, form, setForm }) => {
  const history = useHistory();
  const clientID = form.client?.id;

  const [ivaState, setIvaState] = useState(true); 
  const security = useContext(SecurityContext);
  const canCreateProject = security.canAdminProjects()
  const canCreateClient = security.canAdminClients()


  const handleIvaChange =useCallback( (newIvaValue) => {
    setIvaState(newIvaValue);
    setForm(form =>({
      ...form,
      iva: !ivaState
    }))
  },[setForm, ivaState]);

  // ----- Filter projects selector -----
  const projectFilters = useMemo(() =>({
    client: form.client?.id,
    'order[updatedDate]':'DESC',
    sGroups: projectSelectorSGroups
  }), [form.client]);

  // Make the project and the client match
  const {project, client} = form;
  useEffect(()=>{
    if( project?.client && client ){
      setForm(form=>({
        ...form,
        client: project.client
      }));
    }
  },[project, client, setForm]);
  // If the client change, delete the project if it's from another client
  const handleClientChange = useCallback((client)=>{
    setForm(form=>({
      ...form,
      client,
      project: form?.project?.client?.id === client.id? form.project : null
    }))
  },[setForm]);
  // If the project change, set the client
  const handleProjectChange = useCallback((project)=>{
    setForm(form=>({
      ...form,
      project,
      client: project?.client,
    }))
  },[setForm]);

  const newProjectParams = useMemo(()=>({client: clientID, sGroups: projectFilters.sGroups}), [clientID, projectFilters]);
  const newClientParams = useMemo(()=>({sendAccess: false}), []);

  const addProject = () => {
    history.push( paths.projects + "?creation=a" )
  }

  const addClient = () => {
    history.push(paths.clientNew)
  }

  const linkAddProject = canCreateProject ? { text: "+ Nuevo", callback: addProject  } : null;
  const linkAddClient = canCreateClient ? { text: "+ Nuevo", callback: addClient } : null;

  return (
    <>
      <div className='fields'>

        <TideEntitySelect
          className='project-field width-25'
          entity='clients'
          label="Compañía"
          placeholder={"Compañía"}
          value={form.client}
          onChange={handleClientChange}
          preload
          creatable = {canCreateClient}
          createExtraParams={newClientParams}
          action={linkAddClient}
        />

        <TideEntitySelect
          className='project-field width-25'
          entity='projects'
          label="Proyecto"
          placeholder={"Proyecto"}
          additionalFilters={projectFilters}
          value={form.project}
          onChange={handleProjectChange}
          preload
          creatable={canCreateProject}
          createExtraParams={newProjectParams}
          action={linkAddProject}
        />

      </div>

      <div className='fields'>
        <WindDatePicker
          className='project-field width-25'
          placeholder={"Expiración"}
          {...bindSimple('expirationDate')}
          label={'Expiración'}
        />
        <WindSelect
          className={'project-field width-25'}
          options={quoteStatusOptions}
          label="Estado"
          placeholder={form?.status.label}
          {...bindSimple('status')}
          
        />

        <WindSwitch
          className='project-field'
          placeholder={'IVA'}      
          {...bindSimple('iva')}
          onChange={handleIvaChange}
          value={ivaState}
        />

      </div>

      <div className='fields'>
        <WindInput 
          className="project-field width-25" 
          label="Subtotal"
          value={"$ " + moneyFormatter(subtotal)} 
          disabled={true} />
        
        <WindInput 
          className="project-field width-25" 
          label="Total"
          value={"$ " + moneyFormatter(total)} 
          disabled={true} />

      </div>
    </>
  );
}

export default GeneralInfo;
