import {useLayoutEffect} from "react";

export default function useDragNDropEvents({containerRef, dragHintRef, dragEnterCallback, dragLeaveCallback, dropCallback}){
    useLayoutEffect(()=>{
        const preventDefault = event=>{
            event.preventDefault();
            event.stopPropagation();
        }

        const handleDrop = ( event )=>{
            preventDefault(event);
            dragLeaveCallback();
            dropCallback(event);
        }

        const containerElement = containerRef.current;
        const hintElement = dragHintRef.current;

        containerElement.addEventListener('dragover', preventDefault);
        containerElement.addEventListener('dragenter', dragEnterCallback);
        containerElement.addEventListener('drop', handleDrop);

        hintElement.addEventListener('dragover', preventDefault);
        hintElement.addEventListener('drop', handleDrop);
        hintElement.addEventListener('dragenter', dragEnterCallback);
        hintElement.addEventListener('dragleave', dragLeaveCallback);

        return ()=>{
            containerElement.removeEventListener('dragover', preventDefault);
            containerElement.removeEventListener('dragenter', dragEnterCallback);
            containerElement.removeEventListener('drop', handleDrop);

            hintElement.removeEventListener('dragover', preventDefault);
            hintElement.removeEventListener('drop', handleDrop);
            hintElement.removeEventListener('dragenter', dragEnterCallback);
            hintElement.removeEventListener('dragleave', dragLeaveCallback);
        }
    },[containerRef, dragHintRef, dragEnterCallback, dragLeaveCallback, dropCallback]);
}
