import { faCaretCircleDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useBoolean from '../../../hooks/useBoolean';
import { ApiContext } from '../../../services/api/api-config';
import { permissionMaskIsActive } from '../../../services/modelUtils/tideAclUtils';
import { getUserAvatarUrl } from '../../../services/modelUtils/userUtils';
import { paths } from '../../../services/routes/appRoutes';
import './UserPermissionsBar.scss';

const UserPermissionsBar = ({ user, acl, aclPermissions, domainPermissions, onChange }) => {
  const api = useContext(ApiContext);
  const history = useHistory();

  const [ controllerIsOpen,,,toggleController ] = useBoolean(false);

  const style = { backgroundImage: `url(${getUserAvatarUrl(user, api)})` };

  const isUserAbleTo = useCallback((permission) => {
    if (!user)
      return false;

    if (!acl) return user.permissionsArray.includes(permission);

    return permissionMaskIsActive(acl.permissionMask, permission);
  }, [user, acl]);

  const redirectToUserProfile = useCallback(()=>history.push(paths.usersDetail.replace(':id', user?.id)), [history, user]);

  return (
    <div className={"UserPermissionsBar"}>
      <div className="header-container">
        <div
          className="user-info hover-pointer"
          data-test-id="user-avatar"
          onClick={redirectToUserProfile}
          data-tooltip="Ver perfil de usuario"
        >
          <div className={"profile-avatar"} style={style} />
          <div className={"profile-name"}>{user?.fullName||'Nombre del usuario'}</div>
        </div>

        <div className="permissions-actions">
            {_.map(domainPermissions, (domainPermission, idx) => {
              if (!isUserAbleTo(domainPermission.name)) return;

              return (
                <div key={idx} className="permissions-action">
                  <FontAwesomeIcon icon={domainPermission.icon} />
                </div>
              );
            })}

            {_.map(aclPermissions, (aclPermission, idx) => {
              if (!isUserAbleTo(aclPermission.mask)) return;

              return (
                <div
                  key={idx}
                  className="permissions-action hover-pointer"
                  data-tooltip="Desactivar permiso"
                  data-test-id="acl-action"
                >
                  <FontAwesomeIcon icon={aclPermission.icon} />
                </div>
              );
            })}

          
          {acl && (
            <div className="permissions-toggler">
              <FontAwesomeIcon
                icon={faCaretCircleDown}
                onClick={toggleController}
                data-test-id="acl-toggler"
                className={classNames('caret', controllerIsOpen && 'open')}
              />
            </div>
          )}
        </div>

      </div>

      {acl && controllerIsOpen && (
        <div className="tools-container">
          {_.map(aclPermissions, (aclPermission, idx) => {
            const hasPermission = isUserAbleTo(aclPermission.mask);

            return (
            <div
              key={idx}
              className="acl-action"
              data-tooltip={`${hasPermission?'Desactivar':'Activar'} permiso`}
              onClick={() => onChange(user, acl, aclPermission.mask)}
            >
              <FontAwesomeIcon
                icon={aclPermission.icon}
                className={classNames('acl-action-icon', hasPermission && 'enable')}
              />
              <div className="acl-action-label">{aclPermission.label}</div>
            </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
 
export default UserPermissionsBar;
