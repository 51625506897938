import Login from "../../scenes/public/Login/Login";
import ResetPassword from "../../scenes/public/ResetPassword/ResetPassword";
import ForgotPassword from "../../scenes/public/ForgotPassword/ForgotPassword";

export const paths={
    login:'/login',
    register:'/register',
    resetPassword:'/reset_password',
    forgotPassword:'/forgot_password',
};

const notLoggedRoutes=[
    {path:paths.login, component: Login},
    {path:paths.resetPassword, component: ResetPassword},
    {path:paths.forgotPassword, component: ForgotPassword},
];

export default notLoggedRoutes;
