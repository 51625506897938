import {  useMemo } from "react";
import { useParams } from 'react-router-dom';
import useTideTable from "../../../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../../../components/utility/TideReactTable/TideReactTable";
import {  taxDocumentTableListSGroups } from "../../../../../services/modelUtils/taxDocumentUtils";
import { moneyFormatter } from "../../../../../services/currencyUtils";


const requestFilters={sGroups: taxDocumentTableListSGroups};
const requestOptions = { customProp:'InvoicesRelated' };

const InvoicesRelated = ({ changesMade }) => {
    
    const { id } = useParams();
    const relfilters = { initialFilters: { bankTransactions: id } };
    
    const invoicesColumns = useMemo( () => [
        {
            Header:"Cliente",
            id: 'folio',
            accessor: txd => <span>{ txd.client?.fullName || '. . .' }</span>
        },
        {
            Header: "Concepto",
            id: 'concepto',
            accessor: txd => {
                let concepto = txd.taxDocumentItems?.map( item => item.description ).join(" | ")||"- - -";
                return ( <span>{concepto}</span> );
            } 
        },
        
        {
            Header: "Monto",
            id: 'Monto',
            accessor: td => {
                return ( <span>{`$${moneyFormatter(td.total)}`}</span> );
            } 
        }
    ], [ ] );

    //Table config
    const tableRel = useTideTable( {
        entity: 'taxDocuments',
        columns: invoicesColumns,
        requestOptions,
        requestFilters,
        ...relfilters,
    } );

    
    const { tableProps } = tableRel;

    return (
        <div>
            { tableProps ? <TideReactTable {...tableProps} className="table-hoverable" />: <span></span> }
        </div>
    );

}

export default InvoicesRelated;