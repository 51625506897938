
import React, { useCallback, useMemo, useContext} from 'react'
import { paths } from "../../../services/routes/appRoutes";
import { useHistory, useLocation } from "react-router-dom";
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import { filterTypes } from "../../../services/searchUtils";
import {
    paymentQuoteStatus, paymentQuoteStatusTrans,
    quoteListSGroups,
    quoteListTableColumns,
    quoteStatus,
    quoteStatusTrans
} from "../../../services/modelUtils/quoteUtils";
import { ApiContext } from "../../../services/api/api-config";
import useSearchHistory from '../../../hooks/useSearchHistory';
import useUrlFilters from '../../../hooks/useUrlFilters';
import _ from 'lodash';
import SearchBar from '../../../components/search/SearchBar/SearchBar';
import SectionHeader from '../../../components/utility/SectionHeader/SectionHeader';
import  { getProjectHeaderInfo } from '../../../constants/SectionHeaderInfo';
import { SecurityContext } from '../../../services/SecurityManager';

const filtersConfig = {
    placeholder: 'Buscar una cotización...', filters: [
        { main: true, field: 'search' },
        { type: filterTypes.TEXT, field: 'title', label: 'Buscar por título' },
        { type: filterTypes.TEXT, field: 'folio', label: 'Buscar por folio' },
        { type: filterTypes.TEXT, field: 'client.fullName', label: 'Buscar por empresa' },
        { type: filterTypes.TEXT, field: 'project.name', label: 'Buscar por proyecto' },
        { type: filterTypes.SELECT, field: 'status', label: 'Buscar por estado', isMulti: true, options:[
            ..._.map(quoteStatus, (status)=>({value: status, label: quoteStatusTrans[status]}))
        ] },
        {type: filterTypes.ORDER, field: 'order[createdDate]', label:'Ordenar por creación'},
        {
            type: filterTypes.SELECT,
            field: 'paymentStatus',
            label: 'Buscar por estado de pago',
            isMulti: true,
            options: [
                ..._.map(paymentQuoteStatus, (status)=>({value: status, label: paymentQuoteStatusTrans[status]}))
            ]
        },
    ]
};

const QuoteList = () => {
    const api = useContext(ApiContext);

    const { search } = useLocation();
    const history = useHistory();

    const urlFilters = useUrlFilters();

    const projectId = (new URLSearchParams(search)).get('project') || '';
    const requestFilters = useMemo(() => ({sGroups: quoteListSGroups, project: projectId, 'order[createdDate]':"DESC" }), [projectId]);

    const security = useContext(SecurityContext);
    const headerInfo = getProjectHeaderInfo(security)

    //Table config
    const table = useTideTable({
        entity: 'quotes',
        columns: quoteListTableColumns,
        requestFilters,
        ...urlFilters
    });

    const { tableProps, filters, setFilters } = table;
    
    useSearchHistory(table);
    const onCloseP = () => {}
    const exportToExcel = useCallback(() => {
        window.location.href = api.quotes.getExportExcelUrl({ pagination: false, ...requestFilters, ...filters })
    }, [api, requestFilters, filters]);

    // ----- Link to add projects
    const handleAddQuote = useCallback(() => history.replace(paths.quoteForm), [history]);
    
    const sceneTools = useMemo(() =>  
        security.canAdminQuotes ?[
            { text: "Exportar", callback: exportToExcel, outline: true },
            { text: "Crear", callback: handleAddQuote, outline: false },
    ] : []
    , [exportToExcel, handleAddQuote, security]);

    return (
        <div className={"QuoteList wind-scene"}>

            <SectionHeader pageInfo={headerInfo} tools={sceneTools}/>

            <SearchBar title="Todas las cotizaciones" filters={filters} filtersConfig={filtersConfig} onFiltersChange={setFilters} onClose={onCloseP} labels={false}/>
            <div className='center-container'>
                <TideReactTable {...tableProps} className="table-hoverable" />
            </div>

        </div>
    )
}

export default QuoteList;
