import React, { useRef, useCallback } from 'react';
import './ImageMenuItem.scss';
import { faImages } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ImageMenuItem = ({ icon = faImages, action }) => {
  const handleIconClick = useCallback(() => { hiddenInput.current.click() }, []);

  const hiddenInput = useRef();

  return (
    <div className={"ImageMenuItem"}>
      <button
        className={"ImageMenuItem__button"}
        onClick={handleIconClick}
      >
        <FontAwesomeIcon icon={icon} />
      </button>
      <input hidden type="file" accept="image/*" onChange={action} ref={hiddenInput} />
    </div>
  );
}
 
export default ImageMenuItem;