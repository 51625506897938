import React, {useCallback, useState, useContext} from 'react';
import './ResetPassword.scss';
import useFormState from "../../../hooks/useFormState";
import logo from '../../../assets/images/new_wind_logo.png';
//import logo from '../../../assets/images/wind-logo-hr.png';
import WindInput from "../../../components/formComponents/WindInput/WindInput";
import Button from "../../../components/utility/Button/Button";
import useBodyClass from "../../../hooks/useBodyClass";
import {Link, useLocation} from "react-router-dom";
import { paths } from "../../../services/routes/notLoggedRoutes";
import useBoolean from '../../../hooks/useBoolean';
import {ApiContext} from '../../../services/api/api-config';
import { getNotifier } from '../../../services/notifier';
import {useSelector} from 'react-redux';

const ResetPassword = () => {
    useBodyClass('clear-background');
    
    const {form, handleSimpleChange, setForm} = useFormState({});
    const api = useContext(ApiContext);
    const [showMessage, setShowMessage] = useState();
    const queryString = useLocation().search;
    const [changedPassword, setChangedPassword] = useBoolean();
    const loadingId = 'ResetPassword.updatePassword';

    const updatePassword = useCallback((e) => {
        e.preventDefault();
        const token = new URLSearchParams(queryString).get("_token");

        if(!token)
            return getNotifier().error("Debes acceder desde el enlace en el correo para cambiar tu contraseña.");
        
        if(!form.pass || !form.pass_confirmation)
            return getNotifier().error("Debes ingresar ambas contraseñas.");
        
        if(form.pass !== form.pass_confirmation)
            return getNotifier().error("Las contraseñas no coinciden.");

        api.users.resetPassword({params: {token: token, password: form.pass}, loadingId})
            .then((response) => {
                setChangedPassword();
                setForm({});
            }).catch((e) => {
                setShowMessage("No pudimos actualizar tu contraseña, inténtalo nuevamente más tarde.");
            });
    }, [api, form, queryString, setChangedPassword, setForm]);

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);
    
    return (
        <div className='ResetPassword'>
            <div className='form-block'>
                <img src={logo} alt={"Wind"} className='logo'/>

                {!changedPassword &&
                    <form onSubmit={updatePassword}>
                        <p className='reset-title'>Ingresa una nueva contraseña</p>

                        <WindInput placeholder="Escribe tu contraseña" type='password' onChange={handleSimpleChange('pass')} value={form.pass || ''} /><br/>
                        <WindInput placeholder="Confirma tu contraseña" type='password' onChange={handleSimpleChange('pass_confirmation')} value={form.pass_confirmation || ''} /><br/>
                        <Button type='submit' disabled={loading}>Cambiar contraseña</Button>
                        
                        {showMessage && <p className='error'>{showMessage}</p>}

                        <p className='reset-password'>
                            <Link to={paths.login}>¿Recordaste tu contraseña?</Link>
                        </p>
                    </form>}

                {changedPassword &&
                    <div className="reset-title mb-4">
                        <p>
                            Tu contraseña se ha cambiado correctamente, 
                            da click en el siguiente enlace para iniciar
                            sesión
                        </p>
                        
                        <p className='reset-password'>
                            <Link to={paths.login}><Button>Iniciar Sesión</Button></Link>
                        </p>
                    </div>}
            </div>

        </div>
    );
};

export default ResetPassword;
