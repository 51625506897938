import React from "react";
import classNames from "classnames";

import "./StatusIndicator.scss";

const StatusIndicator = ({ text, type="", layout="short", number, className="" }) => {
    
    const InlineShort = () => 
            <div className="StatusIndicator-inline-short">
                {
                    type && 
                    <div className={classNames("StatusIndicator-indicator","StatusIndicator-"+type)}></div>
                }
                
                <div className="StatusIndicator-inline-short-text">
                    {text}
                </div>
                {
                    number?
                    <div className="StatusIndicator-inline-short-number">
                        {number}
                    </div>
                    : null
                }
            </div>
        


    const InlineLong = () => 
            <div className="StatusIndicator-inline-long">
                {
                    type && 
                    <div className={classNames("StatusIndicator-indicator","StatusIndicator-"+type)}></div>
                }
                
                <div className="StatusIndicator-inline-long-text">
                    {text}
                </div>
                {
                    number?
                    <div className="StatusIndicator-inline-long-number">
                        {number}
                    </div>
                    : null
                }
            </div>

    const Inner = () => 
            <div className={classNames("StatusIndicator-inline-inner","StatusIndicator-inline-inner-"+type)}>
                {text}
            </div>
        

    let indicator = <InlineShort />;
    switch ( layout ) {
        case 'short': indicator = <InlineShort />; break;
        case 'long': indicator = <InlineLong />;  break;
        case 'inner': indicator = <Inner />; break;
        default: break;
    }

    return (
        <div className={classNames("StatusIndicator", className)}>
            {indicator}
        </div>
    );
}

export default StatusIndicator;