import React, { useCallback, Fragment } from 'react';
import DropDownMenuItem from '../../MenuItems/DropdownMenuItem/DropDownMenuItem';
import { fontFamilyOptions, fontSizeOptions } from '../../../../../../services/richTextEditorUtils';
import _ from 'lodash';

const FontFormatGroup = ({ editor }) => {
  const defaultFontFamily = _.head(fontFamilyOptions);
  const defaultFontSize = _.last(fontSizeOptions);

  const fontFamily = editor.getAttributes('textStyle').fontFamily;
  const fontSize = editor.getAttributes('heading').level;

  const currentFontFamily = _.find(fontFamilyOptions, [ 'value', fontFamily ]);
  const currentFontSize = _.find(fontSizeOptions, [ 'value', fontSize ]);


  const handleHeadingChange = useCallback((fontSize) => {
    const level = fontSize.value;

    if (level === 4) {
      return editor.chain().focus().setParagraph().run();
    }

    editor.chain().focus().setHeading({ level: level }).run();
  }, [editor]);
    

  const handleFontFamilyChange = useCallback((fontFamily) => {
    const fontValue = fontFamily.value;

    if (_.isEmpty(fontValue)) {
      return editor.chain().focus().unsetFontFamily().run();
    }

    editor.chain().focus().setFontFamily(fontValue).run();
  }, [editor]);

  const items = [
    {
      title: 'heading',
      value: currentFontSize||defaultFontSize,
      onChange: handleHeadingChange,
      options: fontSizeOptions,
    },
    {
      title: 'font_family',
      value:  currentFontFamily||defaultFontFamily,
      onChange: handleFontFamilyChange,
      options: fontFamilyOptions,
    }
  ];

  return (
    <>
      {_.map(items, (item) => (
        <Fragment key={item.title}>
          <DropDownMenuItem {...item} />
        </Fragment>
      ))}
    </>
  );
}
 
export default FontFormatGroup;