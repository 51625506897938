import React, { useContext, useState, useEffect } from 'react'
import moment from "moment";
import { useParams } from 'react-router-dom';
import { ApiContext } from '../../../services/api/api-config';
import TopBar from '../../../components/utility/TopBar/TopBar';
import SimpleCard from '../../../components/layout/SimpleCard/SimpleCard';
import InvoiceSelector from './components/InvoiceSelector/InvoiceSelector';
import InvoicesRelated from './components/InvoicesRelated/InvoicesRelated';
import { bankTransactionSGroups } from '../../../services/modelUtils/bankTransactionUtils';
import { moneyFormatter } from "../../../services/currencyUtils";

import './TransactionInvoice.scss';

const TransactionInvoice = () => {
    const api = useContext(ApiContext);
    const { id } = useParams();
    const [ transaction, setTransaction ] = useState();
    const [ changes, setChanges ] = useState(false);

    useEffect(() => {
        if (id)
            api.bankTransactions.get({ id, params:{sGroups: bankTransactionSGroups}, customProp:'_' })
                .then( bankTransaction => setTransaction( bankTransaction ) );
    }, [id, api, setTransaction]);

    const reloadRelated = () => {
        setChanges( !changes );
    }

    return (
        <div className={"TransactionInvoice wind-scene"}>
            <TopBar
                title={ "Transacción" }
                titleLinkBack
            />

            <div className="two-cols">
                <SimpleCard title="Datos de la transacción">
                    <div className={'TideReactTable'}>
                        <div className={'table-container table-responsive'}>
                            <table className={'table'}>
                                <thead>
                                    <tr>
                                        <th>Banco</th>
                                        <th>{ transaction?.bankAccount?.bank?.name }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Transacción</td>
                                        <td>{transaction?.reference}</td>
                                    </tr>
                                    <tr>
                                        <td>Monto</td>
                                        <td>{ (transaction?.amount)? `$ ${moneyFormatter(transaction.amount)}`: ' $ 0.00' }</td>
                                    </tr>
                                    <tr>
                                        <td>Fecha</td>
                                        <td>{ (transaction?.date)? moment(transaction.date).format('DD/MM/YYYY'): '' }</td>
                                    </tr>
                                    <tr>
                                        <td>Tipo</td>
                                        <td>{transaction?.type}</td>
                                    </tr>
                                    <tr>
                                        <td>Concepto</td>
                                        <td>{transaction?.concept}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </SimpleCard>
                <SimpleCard title="Facturas relacionadas">
                    <InvoicesRelated changesMade={changes} />
                </SimpleCard>
            </div>

            <SimpleCard title="Facturas sugeridas">
                
            </SimpleCard>

            <SimpleCard title="Facturas disponibles">
                <InvoiceSelector bankTransaction={transaction} reloadRelated={reloadRelated} />
            </SimpleCard>
            
        </div>
    )
}

export default TransactionInvoice;