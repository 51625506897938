import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import  animationData  from '../../assets/animations/loader.json';
import './Splash.scss'

const Splash=()=>{
    return (
        <div className='SplashWrapper'>
            <div className='Splash'>
                <Player
                  className='splash-logo'
                  autoplay
                  loop
                  src={animationData}
                />
            </div>
        </div>
        );
    };

export default Splash;
