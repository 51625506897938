import React from 'react';
import './FilePreview.scss';
import {getFilePreviewComponent} from "../../../../../services/fileUtils";

const FilePreview = ({ appFile }) => {

    const Component = getFilePreviewComponent( appFile.originalName );
    if(!Component)
        return null;

    return (
        <div className={"FilePreview"}>
            <Component appFile={appFile} />
        </div>
    );
};

export default FilePreview;
