
import React from 'react'
import './UserButton.scss';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserButton = ({user, onRemove}) => {
  return (
    <div className='UserButton'>
      <div className='details'>
        <span>{user.fullName}</span>
        
        <span 
          onClick={() => onRemove(user)}
          className={'deleteButton'}
          data-test-id="remove-user-button"
        >
          <FontAwesomeIcon icon={faTimes} color='red' />
        </span>
      </div>
    </div>
  )
} 

export default UserButton;