import React, { useEffect, useRef, useCallback } from 'react';
import './ProductForm.scss';
import WindInput from '../../../../../components/formComponents/WindInput/WindInput';
import WindSwitch from '../../../../../components/formComponents/WindSwitch/WindSwitch';
import IconButton from '../../../../../components/utility/IconButton/IconButton';
import RichTextEditor from '../../../../../components/formComponents/RichTextEditor/RichTextEditor';
import { faMinusSquare } from '@fortawesome/pro-light-svg-icons';
import useCallbackCreator from 'use-callback-creator';

const ProductForm = ({ quotationRequestProduct, onChange, onRemove }) => {
    const richTextEditor = useRef();

    const handleChange = useCallbackCreator((name, value) => {
        onChange({ ...quotationRequestProduct, [name]: value });
    }, [onChange, quotationRequestProduct]);

    const setTextEditorReference = useCallback((newTextEditor) => {
        richTextEditor.current = newTextEditor;
        quotationRequestProduct.getDescription = newTextEditor?.getContentHTML;
    }, [quotationRequestProduct]);

    useEffect(() => {
        quotationRequestProduct.getDescription = richTextEditor.current.getContentHTML;
    }, [quotationRequestProduct]);

    const handleProductRemove = useCallback(() => {
        onRemove();
    }, [onRemove]);

    return (
        <div className={'ProductForm'}>
            <div className='fields'>
                <WindInput
                    className='project-field project-field--big'
                    placeholder={"Nombre del producto"}
                    value={quotationRequestProduct?.name}
                    onChange={handleChange('name')}
                />
                <WindInput
                    className='project-field'
                    placeholder={"Cantidad"}
                    value={quotationRequestProduct?.quantity}
                    onChange={handleChange('quantity')}
                    type={'number'}
                />
                <WindSwitch
                    className='project-field'
                    placeholder={'Opcional'}
                    value={quotationRequestProduct?.isOptional}
                    onChange={handleChange('isOptional')}
                />
                <IconButton
                    className={'remove-button'}
                    onClick={handleProductRemove}
                    data-tooltip={'Remover producto'}
                    icon={faMinusSquare}
                    color='danger'
                />
            </div>
            <div className='description'>
                <RichTextEditor
                    ref={setTextEditorReference}
                    html={quotationRequestProduct?.description}
                    placeholder={'Agrega una descripción ...'}
                />
            </div>
            <hr className="rounded" />
        </div>
    );
}

export default ProductForm;