import React, { useCallback, useContext } from 'react';
import './TransactionFormM.scss';
import {
  prepareTransactionForUpdate,
} from "../../../../../../../services/modelUtils/transactionUtils";
import { getNotifier } from "../../../../../../../services/notifier";
import Modal from "../../../../../../../components/utility/Modal/Modal";
import WindInput from "../../../../../../../components/formComponents/WindInput/WindInput";
import WindDatePicker from "../../../../../../../components/formComponents/WindDatePicker/WindDatePicker";
import WindTextarea from "../../../../../../../components/formComponents/WindTextarea/WindTextarea";
import TideEntitySelect from "../../../../../../../components/utility/TideEntitySelect/TideEntitySelect";
import { ApiContext } from "../../../../../../../services/api/api-config";
import { useEffect } from 'react';
import useFormState from "../../../../../../../hooks/useFormState"

const TransactionForm = ({ onClose, data }) => {
  const { form, setForm, bindSimple } = useFormState(() => data);
  const api = useContext(ApiContext);

  useEffect(() => {
    setForm((form) => ({...form}));
  }, [ setForm]);

  // ----- Save client transaction
  const saveTransaction = useCallback(() => {
    let transaction;
    try {
      transaction = prepareTransactionForUpdate({
        ...form,
        transactionType: 'income',
      });
    } catch (e) {
      return getNotifier().warning(e.message);
    }

    api.transactions.update({ id: data.id, params: transaction })
      .then(() => {
        getNotifier().success('El pago se ha guardado correctamente');
        onClose();
      });
  }, [form, api, onClose,  data]);

  return (
    <Modal
      title={ data.id? "Actualizar pago": "Agregar nuevo pago"}
      className={"TransactionFormM"}
      onClose={onClose}
      secondaryButtonAction={onClose}
      mainButtonAction={saveTransaction}
      mainButtonText="Guardar"
    >
      <div className='form-container'>
        
        <br />
        <div className="form-row mt-20">
          <TideEntitySelect
            label="Cuenta"
            entity='bankAccounts'
            {...bindSimple('bankAccount')}
            placeholder='Cuenta de pago ...'
            labelCreator={(bankAccount) => `${bankAccount.name} - ${bankAccount.number}`}
            autoSelect
          />

          <WindDatePicker
            label='Fecha de pago'
            {...bindSimple('transactionDate')}
          />
        </div>
        <div className="form-row mt-20">
          <WindInput
            value={form.amount||undefined}
            {...bindSimple('amount')}
            type='number'
            placeholder='Total'
          />
        </div>

        <div className="form-row mt-20">
          <WindTextarea
            label="Comentarios"
            {...bindSimple('comments')}
            placeholder='Comentarios'
            className='textArea'
          />
        </div>
      </div>
    </Modal>
  );
}

export default TransactionForm;
