import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import './ProjectDetails.scss';
import TopBar from "../../../components/utility/TopBar/TopBar";
import QuoteTable from "../../../components/crmComponents/QuoteTable/QuoteTable";
import {ApiContext} from "../../../services/api/api-config";
import { useParams } from "react-router-dom";
import {projectDetailSGroups} from "../../../services/modelUtils/projectUtils";
import { getNotifier } from '../../../services/notifier';

// -- 
import ProjectHeader from './components/ProjectHeader/ProjectHeader';
import DetailTabs from '../../../components/layout/DetailTabs/DetailTabs';
import ProjectInfo from './components/ProjectInfo/ProjectInfo';
import ProjectPermission from "./components/ProjectPermission/ProjectPermission";
import ProjectFiles from './components/ProjectFiles/ProjectFiles';
import { SecurityContext } from '../../../services/SecurityManager';


const ProjectDetails = () => {
    const projectTabs = [
        { value: 'information', label: 'Información', Component: ProjectInfo },
        { value: 'permission', label: 'Permisos', Component: ProjectPermission},
        { value: 'files', label: 'Archivos', Component: ProjectFiles },
    ];

    const api = useContext(ApiContext);
    const { id } = useParams();

    const [ activeTab, setActiveTab ] = useState(() => projectTabs[0]);
    const { Component } = activeTab;

    const security = useContext(SecurityContext);

    if(security.canAdminQuotes()){
        projectTabs.push({ value: 'quotes', label: 'Cotizaciones', Component: QuoteTable })
    }

    // ----- Load project data
    const [project, setProject] = useState();
    const apiConfig = useMemo(()=>({ id, params:{ sGroups: projectDetailSGroups } }),
        [id]);
    useEffect(()=>{
        api.projects.get(apiConfig).then(setProject);
    },[api, apiConfig]);

    // ----- Change project status -----
    const handleChangeStatus = useCallback((statusOption) => {
        const status = statusOption.value;

        api.projects.update({
            id,
            params: { status, sGroups: projectDetailSGroups },
        }).then((project) => {
            setProject(project);
            getNotifier().success('El estado del proyecto se actualizó correctamente 🎉');
        })
        .catch(() => { getNotifier().error('Hubo un error al actualizar el estado del proyecto'); }
        );
    }, [api, id]);

    const handleUpdate = () => {
        api.projects.get(apiConfig).then(setProject);
    }

    return (
        <div className={"ProjectDetails wind-scene"}>

            <TopBar/>
            <ProjectHeader project={project} onChangeStatus={handleChangeStatus} />
            {
                project?
                <>
                    <DetailTabs
                        tabs={projectTabs}
                        onTabSelect={setActiveTab}
                        activeTab={activeTab}
                    />
                <div className="project-tabs">                    
                    {Component && <Component project={project} handleUpdate={handleUpdate} />}
                </div></> : null
                
            }
            
            

        </div>
    );
};

export default ProjectDetails;
