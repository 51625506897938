import React from "react";
import InfoPairBlock from "../../../../../components/layout/InfoPairBlock/InfoPairBlock";
import { moneyFormatter } from "../../../../../services/currencyUtils";
import Button from "../../../../../components/utility/Button/Button";
import DropdownSingleMenu from "../../../../../components/utility/DropdownSingleMenu/DropdownSingleMenu";

import "./QuoteTools.scss";

const QuoteTools = ({ quote, tools }) => {

    return (
        <div className="QuoteTools">
            <div className="quote-tools-info">
                <InfoPairBlock title="Productos" value={quote?.quoteProducts?.length || 0} />
                <InfoPairBlock title="Subtotal" value={ "$ " + moneyFormatter( quote?.subtotal || 0 )} />
                <InfoPairBlock title="Total" value={ "$ " + moneyFormatter( quote?.total || 0 )} />
            </div>

            <div className="quote-tools-tools">
                {
                    tools.map( (tool) => {
                        if ( tool.options ) {
                            return(
                                <DropdownSingleMenu text="Más acciones" options={tool.options} outline={true} />
                            );
                        }
                        else {
                            return(
                                <Button 
                                    onClick={tool.callback} 
                                    outline={true} 
                                    disabled={tool.disabled}
                                    color={ tool.color? tool.color: "info" }
                                    leftIcon={tool.icon} >
                                    {tool.text}
                                </Button>
                            );
                        }
                    } )
                }
            </div>
        </div>
    );

}

export default QuoteTools;