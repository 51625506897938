import React, {useCallback} from 'react';
import './SearchBarOrderFilter.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDownBigSmall, faArrowDownSmallBig, faFilterSlash} from "@fortawesome/pro-light-svg-icons";

const SearchBarOrderFilter = ({value, onChange, config}) => {

    const handleClick = useCallback(()=>{
        if(!value)
            return onChange('ASC');
        if(value==='ASC')
            return onChange('DESC');
        return onChange();
    },[value, onChange]);

    let icon=faFilterSlash;
    let tooltip='Ordenar ascendentemente';
    if(value ==='ASC') {
        icon = faArrowDownSmallBig
        tooltip = 'Ordenar descendentemente';
    }
    if(value === 'DESC') {
        icon = faArrowDownBigSmall
        tooltip = 'Eliminar ordenamiento';
    }

    return (
        <div className={"SearchBarOrderFilter"} onClick={handleClick} data-tooltip={tooltip}>
            <span><FontAwesomeIcon icon={icon}/></span>
            <span>{config.label}</span>
        </div>
    );
};

export default SearchBarOrderFilter;
