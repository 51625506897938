import { useEffect, useState } from "react"
import WindInput from "../../../../../components/formComponents/WindInput/WindInput"
import TideEntitySelect from "../../../../../components/utility/TideEntitySelect/TideEntitySelect"
import { satCatalogSGroups } from "../../../../../services/modelUtils/taxDocumentUtils"
const ReceiverData = ({bindSimple, form, setForm}) => {

    const [selectedClient, setSelectedClient] = useState(null)

    useEffect(()=>{
        if(form.receiverCompanyName || form.receiverRfc || form.receiverZipCode || form.receiverCompanyName){
            setSelectedClient(true)
        }
    },[form])

    const addClient = () => {
        setSelectedClient(true)
    }     
    const handleClientChange = (client) =>{
        setSelectedClient(client)
        setForm({
            ...form,
            'receiverRfc': client?.fiscalData?.rfc || '',
            'receiverCompanyName': client?.fiscalData?.businessName || '',
            'receiverZipCode': client?.fiscalData?.zip || ''
        })
    }

    const linkAddClient =  {text: "+ Ingresar manualmente", callback: addClient}

    return(
        <>

            {!selectedClient && 
                <>
                    <div className="issuer-rfc"> 
                        <label>{"Cliente"}</label>
                        <TideEntitySelect
                            entity='clients'
                            placeholder='Cliente'
                            additionalFilters={{
                                sGroups: ['client_read', 'fiscal_data_read_zip', 'fiscal_data_read_business_name', 'fiscal_data_read_rfc'],
                            }}
                            preload
                            value={selectedClient}
                            onChange={handleClientChange}
                            action={linkAddClient}
                        />
                    </div>
                </>
            }

            {selectedClient && 
                <>
                    <div className="input-row">
                        <WindInput
                            placeholder={"RFC"}
                            type={'text'}
                            value={form.receiverRfc}
                            {...bindSimple('receiverRfc')}

                        />

                        <WindInput
                            placeholder={"Razón Social"}
                            type={'text'}
                            value={form.receiverCompanyName}
                            {...bindSimple('receiverCompanyName')}
                        />

                        <WindInput
                            placeholder={"Codigo Postal"}
                            type={'number'}
                            value={form.receiverZipCode}
                            {...bindSimple('receiverZipCode')}
                        />
                    </div>

                    <div><label>{"Regimen Fiscal"}</label></div>
                    <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Regimen Fiscal'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_RegimenFiscal"
                        }}
                        preload
                        filterBy={'description'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        {...bindSimple('receiverTaxRegime')}
                    />
                </>
            }
        </>        
    )

}

export default ReceiverData