import { faCheck, faEye, faMinusSquare } from "@fortawesome/pro-light-svg-icons";
import _ from "lodash";

export const bankTransactionStatusTrans = {
  PENDING: "Pendiente",
  CONFIRMED: "Confirmado",
  DENIED: "Denegado",
};

export const bankTransactionStatusIcons = {
  PENDING: {
    icon: faEye,
    color: "info",
  },
  CONFIRMED: {
    icon: faCheck,
    color: "success",
  },
  DENIED: {
    icon: faMinusSquare,
    color: "danger",
  },
};

export const bankTransactionStatus = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  DENIED: 'DENIED',
};

export const bankTransactionStatusOptions = _.map(bankTransactionStatus, (value) => {
  return { value: value, label: bankTransactionStatusTrans[value] };
});

export const bankLinkStatus = {
  VALID: "valid",
  INVALID: "invalid",
  UNCONFIRMED: "unconfirmed",
  TOKEN_REQUIRED: "token_required",
  NOT_FOUND: "not_found",
};

export const transactionDirection = {
  INFLOW: "Ingreso",
  OUTFLOW: "Gasto",
};

export const transactionStatus = {
  PENDING: "Pendiente",
  PROCESSED: "Procesada",
  UNCATEGORIZED: "Sin categorizar"
};

export const bankTransactionSGroups = [
  'bank_transaction_read',
  'bank_transaction_read_bank_account',
  'bank_account_read_bank',
  'bank_account_read',
  'bank_read'
];
