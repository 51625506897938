import React, { useContext, useMemo, useEffect } from 'react';
import './QuotationRequestDetail.scss';
import { faTrashCan, faPen } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import ToolBar from '../../../components/utility/ToolBar/ToolBar';
import TopBar from '../../../components/utility/TopBar/TopBar';
import { ApiContext } from '../../../services/api/api-config';
import { getNotifier } from '../../../services/notifier';
import SimpleCard from '../../../components/layout/SimpleCard/SimpleCard';
import _ from 'lodash';
import InfoPair from "../../../components/layout/InfoPair/InfoPair";
import DetailHeader from '../../../components/utility/DetailHeader/DetailHeader';
import { ProductDetail } from './ProductDetail/ProductDetail';
import { archiveStatus, quotationRequestSGroups } from '../../../services/modelUtils/quotationRequestUtils';
import { useCallback } from 'react';
import {Link, useParams} from "react-router-dom";
import { paths } from '../../../services/routes/appRoutes';
import { STATE_ACTIONS } from "tide-api";
import {useHistory} from "react-router-dom";
import useBoolean from '../../../hooks/useBoolean';
import RemoveQuotationRequestModal from './components/RemoveQuotationRequestModal/RemoveQuotationRequestModal';

const QuotationRequestDetail = () => {
    const api = useContext(ApiContext);
    const { id } = useParams();
    const history = useHistory();
    const customProp = 'QuotationRequest.Detail.get.'+id;

    const [ deleteQuotationRequestModal, deleteQuotationRequestOn , deleteQuotationRequestOff, ] = useBoolean(false);

    useEffect(()=>{
        api.quotationRequests.get({ id, customProp, params: { sGroups: quotationRequestSGroups }, stateAction: STATE_ACTIONS.STATE_ACTION_SET })
          .catch(() => getNotifier().error('No se encontró la solicitud de cotización.'));
    },[api, id, customProp]);

    const quotationRequest = useSelector(s=>s.api[customProp]);
    const loadingId = 'QuotationRequestDetail.get';
    const loading = useSelector(s => !!s.loadingIds[loadingId]);

    const handleEdit = useCallback(() => {
        history.push( paths.quotationRequestEdit.replace(':id', id) );
    }, [history, id]);

    // ----- Toggle quotation request is archive -----
    const toggleQuotationRequestIsArchive = useCallback(() => {
        const { id, isArchived } = quotationRequest;

        const params = {
            id,
            loadingId,
            customProp,
            params: {
                sGroups: quotationRequestSGroups,
                isArchived: !isArchived
            },
            stateAction: STATE_ACTIONS.STATE_ACTION_SET
        };

        api.quotationRequests.update(params)
            .then(() => getNotifier().success(`Solicitud de cotización ${isArchived?'desarchivada':'archivada'}.`))
            .catch(() => getNotifier().error(`No se pudo ${isArchived?'desarchivar':'archivar'} la solicitud de cotización.`));
    }, [api, quotationRequest, customProp]);
    
    // ----- Delete quotation request -----
    const deleteQuotationRequest = useCallback(() => {
        api.quotationRequests.delete({ id, customProp })
          .then(() => {
            getNotifier().success('Solicitud de cotización eliminada.');
            history.replace(paths.quotationRequestNew);
          })
          .catch(() => getNotifier().error('No se pudo eliminar la solicitud de cotización.'));
    }, [api, id, history, customProp]);

    const tools = useMemo(() => [
        { icon: faPen, callback: handleEdit, text: "Editar solicitud", disabled: loading },
        {
            icon: archiveStatus(quotationRequest).icon,
            callback: toggleQuotationRequestIsArchive,
            text: archiveStatus(quotationRequest).tooltip,
            disabled: loading
        },
        { icon: faTrashCan, callback: () => deleteQuotationRequestOn(), text: "Eliminar solicitud", disabled: loading },
    ], [loading, deleteQuotationRequestOn, handleEdit, toggleQuotationRequestIsArchive, quotationRequest]);

    return (
        <div className={"QuotationRequestDetail wind-scene"}>

            { deleteQuotationRequestModal && <RemoveQuotationRequestModal onRemove={deleteQuotationRequest} onClose={deleteQuotationRequestOff} />}

            <TopBar
                title="Solicitud"
                titleLinkBack
            />

            <ToolBar tools={tools} />

            {quotationRequest &&
                <div className='center-container'>
                    <DetailHeader title={quotationRequest?.title && `${quotationRequest?.title} - ${quotationRequest?.id}`} />

                    <SimpleCard className='general-info' title="Información general">
                        <div className={"info-block"}>
                            <Link to={paths.clientDetail.replace(":id", quotationRequest?.project?.client?.id)} data-tooltip={"Ver perfil del cliente"}>
                                <InfoPair value={quotationRequest?.project?.client?.fullName} title={"Cliente"}/>
                            </Link>

                            <Link to={paths.projectDetail.replace(":id", quotationRequest?.project?.id)} data-tooltip={"Ver proyecto"}>
                                <InfoPair value={quotationRequest?.project?.name} title={"Proyecto"}/>
                            </Link>
                            
                            <InfoPair value={quotationRequest?.folio} title={"Folio"}/>

                            <Link to={paths.quoteDetail.replace(":id", quotationRequest?.quote?.id)} data-tooltip={"Ver cotización"}>
                                <InfoPair value={quotationRequest?.quote?.folio || '-'} title={"Cotización"}/>
                            </Link>
                        </div>
                    </SimpleCard>

                    <SimpleCard className='general-info' title="Productos">
                        {_.map(quotationRequest.quotationRequestProducts, (quotationRequestProduct, index) => (
                            <ProductDetail
                                product={quotationRequestProduct}
                                key={index}
                                showLine={index<quotationRequest.quotationRequestProducts?.length-1}
                            />
                        ))}
                    </SimpleCard>
                </div>}
            </div>
    );
};

export default QuotationRequestDetail;
