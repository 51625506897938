import React from 'react';
import './InfoPair.scss';
import classNames from 'classnames';

const InfoPair = ({value, title, right=false, isHtml=false, className=""}) => {

    return (
        <div className={classNames("InfoPair", className)}>
            <div className={classNames("info-title", (right? "info-title-right": "") )}>{title}</div>
            <div className={classNames("info-value", (right? "info-value-right": "") )}>
                { isHtml? <span dangerouslySetInnerHTML={{ __html: value }} /> : value}
            </div>
        </div>
    );
};

export default InfoPair;
