import React from 'react';
import semver from 'semver-lite';
import packageInfo from '../../pckg';
import {getNotifier} from "../notifier";
import qs from 'qs';
import moment from 'moment';

function errorHandler(e, callParams) {
    //If the user's session ended, do not show the error notifier
    if(e?.status === 401 || e?.code === 401)
        return;
    if (!callParams?.config?.preventNotifier)
        getNotifier().error((e?.detail) || 'Parece que hubo un error 😿');
}
export const querySerialize = (obj) => qs.stringify(obj, { arrayFormat: 'brackets' });

const config ={
    host:'http://localhost:8000',
    commonPath:'api',
    appendHeaders: (headers) => {
        const impersonatedUser = sessionStorage.getItem('impersonatedUser');
        if (impersonatedUser) {
            headers['X-Switch-User'] = impersonatedUser;
        }
        
    },
    credentials:'include',
    saveTokenToLocalStorage: true,
    getDataFromResponse: handleResponse,
    getMetaDataFromResponse:(r)=>r.meta,
    strictMode: false,
    onError: errorHandler,
    handleUnknownMethods: true,
    forceCustomProp: false,
    endpoints:[
        'me',
        'clients',
        'refreshToken',
        'quoteProducts',
        'conversations',
        'messages',
        'bankAccounts',
        'quoteTransactions',
        {
            name:'transactionCategories',
            customMethods:{
                expensesByCategories: function({params={}, ...config}={}){
                    return this.apiCall('transaction_categories/expenses_by_category', '_', params||{}, config);
                },
                incomesByCategories: function({params={}, ...config}={}){
                    return this.apiCall('transaction_categories/incomes_by_category', '_', params||{}, config);
                },
            }
        },
        {
            name:'appFiles',
            customMethods:{
                getUrl: function({id}){ return `${this.host}/api/file/${id}?token=${this.token}`},
                getDownloadUrl: function({id}){ return `${this.host}/api/file/download/${id}?token=${this.token}`},
            }
        },
        {
            name:'projects',
            customMethods: {
                getExportExcelUrl: function( filters )
                {                    
                    const reportConfig = {
                        name: `Proyectos ${moment().format("YYYY-MM-DD HH:mm")}.xlsx`,
                        headers: {
                            'folio': 'Folio',
                            'name': 'Nombre',
                            'description': 'Descripción',
                            'status': 'Estado',
                            'startDate': 'Fecha de inicio',
                            'endDate': 'Fecha de fin',
                            'client.fullName': 'Nombre de cliente'
                        }
                    };

                    const query = querySerialize({...{...filters, sGroups: ["project_read", "project_read_client","client_read"]}, token: this.token , reportConfig: JSON.stringify(reportConfig)});
                    return `${this.host}/api/projects.xlsx?${query}`;
                }
            }
        },
        {
            name: 'quotes',
            customMethods: {
                getDownloadPdfUrl:function({id}){ return `${this.host}/api/quotes/pdf/${id}?token=${this.token}`},
                getExportExcelUrl: function( filters )
                {
                    const reportConfig = {
                        name: `Cotizaciones ${moment().format("YYYY-MM-DD HH:mm")}.xlsx`,
                        headers: {
                            'title': 'Título',
                            'folio':'Folio',
                            'client.fullName':'Empresa',
                            'project.name':'Proyecto',
                            'status': 'Estado',
                            'paidAmount': 'Pagado',
                            'total': 'Precio',
                            'createdDate': 'Creación'
                        }
                    };

                    const query = querySerialize({...filters, token: this.token , reportConfig: JSON.stringify(reportConfig)});
                    return `${this.host}/api/quotes.xlsx?${query}`;
                }
            }
        },
        {
            name: 'error',
            preventDefaultMethods: true,
            customMethods: {
                send: function( error, stack, user, additionalInfo ){
                    let params = { stack,
                        error: error && error.message,
                        user: user && {id: user.id, username: user.username},
                        ...additionalInfo };
                    return this.apiCall( '/frontend_error', "error_sent", params, { method: "POST", queueable: true, useCommonPath: false }  )
                }
            }
        },
        {
            name: 'users',
            customMethods: {
                passwordRecovery: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('/recover_password_request', customProp, params, {
                        method: "POST",
                        useCommonPath: false, ...config
                    })
                },
                resetPassword: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('/reset_password', customProp, params, {
                        method: "POST",
                        useCommonPath: false, ...config
                    })
                },
            }
        },
        {
            name:'transactions',
            customMethods: {
                getExportExcelUrl: function( filters )
                {
                    const reportConfig = {
                        name: `Transacciones ${moment().format("YYYY-MM-DD HH:mm")}.xlsx`,
                        headers: {
                            'id': 'Folio',
                            'concept': 'Concepto',
                            'invoice.folio': 'Factura',
                            'bankAccount.name': 'Cuenta',
                            'transactionDate': 'Fecha de inicio',
                            'transactionType': 'Tipo de transacción',
                            'amount': 'Cantidad',
                            'status': 'Estado'
                        }
                    };

                    const query = querySerialize({...filters, token: this.token , reportConfig: JSON.stringify(reportConfig)});
                    return `${this.host}/api/transactions.xlsx?${query}`;
                }
            }
        },
        {
            name:'memoranda',
            customMethods: {
                getDownloadPdfUrl:function({id}){ return `${this.host}/api/memoranda/pdf/${id}?token=${this.token}`},
                getExportExcelUrl: function( filters )
                {
                    const reportConfig = {
                        name: `Minutas ${moment().format("YYYY-MM-DD HH:mm")}.xlsx`,
                        headers: {
                            'title': 'Título',
                            'createdBy.fullName': 'Creador',
                            'project.name': 'Proyecto',
                            'createdDate': 'Creación',
                        }
                    };

                    const query = querySerialize({...filters, token: this.token , reportConfig: JSON.stringify(reportConfig)});
                    return `${this.host}/api/memoranda.xlsx?${query}`;
                }
            }
        },
        {
            name: 'quotationRequests',
            customMethods: {
                getExportExcelUrl: function( filters )
                {
                    const reportConfig = {
                        name: `Solicitudes  ${moment().format("YYYY-MM-DD HH:mm")}.xlsx`,
                        headers: {
                            'title': 'Título',
                            'createdBy.fullName': 'Creador',
                            'project.name': 'Proyecto',
                            'createdDate': 'Creación'
                        }
                    };

                    const query = querySerialize({...filters, token: this.token , reportConfig: JSON.stringify(reportConfig)});
                    return `${this.host}/api/quotation_requests.xlsx?${query}`;
                }
            }
        },
        {
            name:'recurringPayments',
            customMethods: {
                getExportExcelUrl: function( filters )
                {
                    const reportConfig = {
                        name: `Pagos recurrentes ${moment().format("YYYY-MM-DD HH:mm")}.xlsx`,
                        headers: {
                            'concept': 'Concepto',
                            'amount': 'Monto',
                            'requiredPayment': 'Pago requerido',
                            'lastPayment.transactionDate': 'Pago realizado',
                            'recurrence': 'Periodicidad',
                            'expirationDate': 'Expiration',
                            'lastPayment.status': 'Pagado'
                        }
                    };

                    const query = querySerialize({...filters, token: this.token , reportConfig: JSON.stringify(reportConfig)});
                    return `${this.host}/api/recurring_payments.xlsx?${query}`;
                }
            }
        },
        {
            name: 'belvo',
            customMethods: {
                generateToken: function({params={}, ...config}={}){
                    return this.apiCall('belvo/generate_token', '_', params||{}, config);
                },
                getAccounts: function({linkId='', params={}, ...config}={}){
                    return this.apiCall('belvo/get_accounts?link_id='+linkId, '_', params||{}, config);
                },
                getTransactions: function({linkId='', params={}, customProp='_', ...config}={}){
                    return this.apiCall('belvo/get_transactions?link_id='+config.filters.linkId, customProp, params||{}, config);
                },
                syncTransactions: function({linkId='', params={}, customProp='_', ...config}={}){
                    return this.apiCall('belvo/sync_transactions?link_id='+config.filters.linkId, customProp, params||{}, config);
                },
                retrieveTransactions: function({linkId='', params={}, customProp='_', ...config}={}){
                    return this.apiCall('belvo/retrieve_transactions?link_id='+config.filters.linkId, customProp, params||{}, config);
                }
            }
        },
        {
            name:"csd",
            customMethods:{
                storeCsd: function(files, params={}){
                    return this.apiCall('csd/store','_',params,{method:"POST"}, files)
                }                    
            }
        },
        {
            name:'taxDocuments',
            customMethods: {
                downloadCfdi: function(params){
                    const query = querySerialize({cfdi: JSON.stringify(params)});
                    return `${this.host}/api/taxDocuments/cfdi-download?${query}`
                },
                previewCfdi: function(params){
                    const query = querySerialize({cfdi: JSON.stringify(params)});
                    return `${this.host}/api/taxDocuments/cfdi-preview?${query}`
                },
                issueCfdi: function(params){
                    return this.apiCall('taxDocuments/cfdi', '_',params, {method: "POST"})
                },
                getCompleteReport: function(month, year){
                    const query= `month=${month}&year=${year}`
                    return `${this.host}/api/taxDocuments/report?${query}`;
                },
                getExportExcelUrl: function( filters )
                {
                    const reportConfig = {
                        name: `Documentos fiscales ${moment().format("YYYY-MM-DD HH:mm")}.xlsx`,
                        headers: {
                            'id': 'ID',
                            'folio': 'Folio',
                            'client.fullName': 'Cliente',
                            'quotesList': 'Cotizaciones',
                            'total': 'Total',
                            'issuedAt': 'Fecha de emisión',
                            'status': 'Estatus'
                        }
                    };

                    const query = querySerialize({...filters, token: this.token , reportConfig: JSON.stringify(reportConfig)});
                    return `${this.host}/api/tax_documents.xlsx?${query}`;
                }
            }
        },
        {
            name:'windAgent',
            customMethods:{
                ask: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('wind_agent/ask', '_',params, {method: "POST"})
                }
            }
        },
    ],
    login:{
        createBody: (username, password, otp) => {
            return JSON.stringify({"username": username, "password": password, "client_data": window?.navigator?.userAgent||'no-data'});
        },
        getHeaders: () => {
            return {
                'Content-Type': 'application/json'
            }
        }
    },
};


function handleResponse(response, headers){

    try {
        let server = headers.get("X-App-Version");

        this.appVersion = server;

        let force = !!server && server[server.length - 1] === 'f';
        if (force) server = server.slice(0, server.length - 1);

        if (semver.validate(packageInfo.version) && semver.validate(packageInfo.version) && semver.gt(server, packageInfo.version)) {
            if(!this.newerVersion)
                console.log("Newer version detected " + server);
            if (!this.newerVersion && window.swRegistration) {
                window.swRegistration.update()
                    .then(() => {
                        //This will broadcast the refresh message to all active tabs of the app through the sw
                        if (force)
                            window.navigator.serviceWorker.controller.postMessage('force_refresh');
                    });
            }
            this.newerVersion = packageInfo.version;
            if (force && !window.swRegistration)
                window.location.reload();
        }
    }
    catch(e){
        console.error('Error reading versions: '+e);
    }

    return response.data;
}

if(process.env.REACT_APP_BUILD === 'dev')
    config.host=localStorage.host||'https://wind-dev-back.tide.company';
if(process.env.REACT_APP_BUILD === 'prod')
    config.host=localStorage.host||'https://back.windapp.mx';

export default config;

export const ApiContext = React.createContext(null);
