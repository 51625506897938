import { copyPropertyOrThrow } from "../formUtils";

export const prepareQuoteTransactionForServer = ( form, clientId ) => {
  let quoteTransaction = {};

  quoteTransaction.client = clientId;

  copyPropertyOrThrow( form, quoteTransaction, "amount", "Por favor ingresa el monto" );

  quoteTransaction.quote = form.quote.id;
  quoteTransaction.amount += '';

  return quoteTransaction;
}
