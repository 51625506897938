

export const channelSGroups = [
    'conversation_read',
    'conversation_read_client',
    'channel_read_id',
    'channel_read_name',
    'channel_read_messages',
    'channel_read_enable_external_users',
    'channel_read_enable_internal_users',
    'channel_read_channel_users',
        'channel_user_read_id',
        'channel_user_read_user',
    'client_read',
    'message_read_id',
    'message_read_message',
    'user_read'
]

export const convertChannelToForm = (channel) => {
    return {
        label: channel?.name, 
        value: channel?.id, 
        enable_external_users: channel?.enableExternalUsers, 
        enable_internal_users: channel?.enableInternalUsers,
        name: channel?.name, 
    }
}