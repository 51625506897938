import _ from 'lodash';
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { ApiContext } from '../../../services/api/api-config';
import { aclDefinitions, ACL_PERMISSION_SHOW, getActivePermissionsMasks, permissionMaskIsActive } from '../../../services/modelUtils/tideAclUtils';
import { usersDetailSGroups } from '../../../services/modelUtils/userUtils';
import { getNotifier } from '../../../services/notifier';
import InfoPair from '../../layout/InfoPair/InfoPair';
import SceneTitle from '../../layout/SceneTitle/SceneTitle';
import TideEntitySelect from '../../utility/TideEntitySelect/TideEntitySelect';
import UserPermissionsBar from '../../utility/UserPermissionsBar/UserPermissionsBar';

import './AclPermissionsForm.scss';

const AclPermissionsForm = ({  entity, entityObject, hideGeneral=false }) => {
  const api = useContext(ApiContext);
  const aclDefinition = aclDefinitions[entity];
  const clientId = entityObject.client.id;

  const { domain, name, aclPermissions, domainPermissions } = aclDefinition;

  const [usersWithDomainPermission, setUsersWithDomainPermission] = useState([]);
  const [usersAcls, setUsersAcls] = useState([]);

  const additionalUserFilters = { sGroups: usersDetailSGroups, client: clientId };

  // Load users with admin permissions and acl permissions
  useEffect(() => {
    api.users.domainPermissions({ params: { entityName: entity, clientId: clientId } }).then(setUsersWithDomainPermission);

    api.tideAcls.users({ params: { domain, objectId: entityObject.id } }).then(setUsersAcls);
  }, [api, domain, entityObject.id, entity, clientId]);

  // Replace usersAcls state with new acl by user
  const updateUserAcl = useCallback((user, acl) => {
    if (acl === null) {
      setUsersAcls(_.filter(usersAcls, (userAcl) => userAcl.user.id !== user.id));
    }

    // if acl is new, add it
    if (!_.find(usersAcls, (userAcl) => userAcl.user.id === user.id)) {
      return setUsersAcls([...usersAcls, { user: user, acl }]);
    }

    setUsersAcls((usersAcls) => {
      const newUsersAcls = _.map(usersAcls, (userAcl) => {
        if (userAcl.user.id === user.id) userAcl.acl = acl;

        return userAcl;
      });

      return newUsersAcls;
    });
  }, [usersAcls]);

  // Handle acl permission toggle active status
  const onUserAclChange = useCallback((user, acl, permissionMask) => {
    const activePermissionMasks = getActivePermissionsMasks(acl, aclPermissions);

    if( permissionMaskIsActive(acl.permissionMask, permissionMask )) {
      activePermissionMasks.splice(activePermissionMasks.indexOf(permissionMask), 1);
    } else {
      activePermissionMasks.push(permissionMask);
    }

    const newPermissionMask = _.reduce(activePermissionMasks, (acc, permissionMask) => { return acc | permissionMask }, 0);
    const params = { permissionMask: newPermissionMask, domain, user: user.id, objectId: entityObject.id};

    api.tideAcls.create({ params }).then((acl) => updateUserAcl(user, acl));
  }, [api, domain, entityObject.id, aclPermissions, updateUserAcl]);

  // Get total length of users with any kind of permissions
  const getUsersLength = useCallback(() => {
    const usersWithDomainPermissionIds = usersWithDomainPermission.map(user => user.id);
    const usersAclsIds = usersAcls.map(usersAcl => usersAcl.user.id);

    return _.uniq(usersWithDomainPermissionIds.concat(usersAclsIds)).length;
  }, [usersWithDomainPermission, usersAcls]);

  // Create new user acl
  const createNewAcl = useCallback((user) => {
    if (_.find(usersAcls, (userAcl) => userAcl.user.id === user.id)) {
      return getNotifier().warning('El usuario ya tiene permisos asignados');
    }

    const params = { permissionMask: ACL_PERMISSION_SHOW, domain, user: user.id, objectId: entityObject.id};

    api.tideAcls.create({ params }).then((acl) => { updateUserAcl(user, acl) });
  }, [api, domain, entityObject.id, updateUserAcl, usersAcls]);

  return (
    <div className="acl-permissions-modal__content">

        <div className="acl-permissions-modal__content__header">
          <div className="info-container">
            <InfoPair value={entityObject.name||'No se encontró nombre'} title={name} />
          </div>
          <div className="info-container">
            <InfoPair value={getUsersLength()} title={"Usuarios con acceso"} right={true} />
          </div>
        </div>

        <div className="acl-permissions-modal__content__body">

          <SceneTitle secondary>Usuarios</SceneTitle>
          <div className="entity-users-container">
            {_.map(usersAcls, (userAcl, idx) => (
              <UserPermissionsBar
                key={userAcl.user.id||idx}
                user={userAcl.user}
                acl={userAcl.acl}
                aclPermissions={aclPermissions}
                onChange={onUserAclChange}
              />
            ))}
          </div>

          <div className="acl-permissions-modal__content__footer">
            <TideEntitySelect
              entity='users'
              className='users-select'
              placeholder='Seleccionar'
              label="Agregar usuario"
              onChange={createNewAcl}
              additionalFilters={additionalUserFilters}
              labelCreator={(user) => `${user.fullName}`}
              value={null}
            />
          </div>

          {
            !hideGeneral?
              <div>
              <SceneTitle secondary>Usuarios con permiso general</SceneTitle>
              <p>Para permitir o restringir acceso a todos los proyectos debemos modificar los permisos en el perfil del usuario.</p>
              <div className="general-users-container">
                {_.map(usersWithDomainPermission, (user, idx) => (
                  <UserPermissionsBar
                    key={user.id||idx}
                    user={user}
                    domainPermissions={domainPermissions}
                  />
                ))}
              </div></div> : null
          }
          

        </div>

        
    </div>
  );
}
 
export default AclPermissionsForm;
