import React, { useState, useCallback, Fragment } from 'react';
import useBoolean from '../../../../../../hooks/useBoolean';
import { faLink } from '@fortawesome/pro-duotone-svg-icons';
import _ from 'lodash';
import LinkMenuItem from '../../MenuItems/LinkMenuItem/LinkMenuItem';
import ImageMenuItem from '../../MenuItems/ImageMenuItem/ImageMenuItem';

const MediaFormatGroup = ({ editor }) => {
  const [ linkItemShow,,, toggleLinkItem ] = useBoolean();
  const [ link, setLink ] = useState();

  editor.on('transaction', ({ editor }) => {
    const currentLink = editor.getAttributes('link').href;

    setLink(currentLink);
  });

  const isLinkMarkActive = useCallback(() => { return editor.isActive('link') }, [editor]);

  const saveUrl = (url) => {
    if (_.isEmpty(url)) {
      return editor.chain().focus().extendMarkRange('link').unsetLink().run();
    }

		editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    toggleLinkItem();
  };

  const handleImageChange = useCallback((event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    if (!file) { return; }

    reader.readAsDataURL(file);

    reader.onload = (e) => {
      const base64Image = e.target.result;
      editor.chain().focus().setImage({ src: base64Image }).run();
    };
  }, [editor]);

  const items = [
    {
      icon: faLink,
      title: 'link',
      type: 'link',
      isOpen: linkItemShow,
      action: () => toggleLinkItem(),
      value: link,
      onInputChange: setLink,
      onSave: saveUrl,
      isActive: isLinkMarkActive,
    },
    {
      title: 'image',
      type: 'image',
      action: handleImageChange,
    }
  ];

  return (
    <>
    { _.map(items, (item) => (
      <Fragment key={item.title}>
        { item.type === 'link' && <LinkMenuItem {...item} /> }
        { item.type === 'image' && <ImageMenuItem {...item} /> }
      </Fragment>
    ))}
    </>
  );
}
 
export default MediaFormatGroup;