import React from 'react';
import './SimpleCard.scss';
import SceneTitle from "../SceneTitle/SceneTitle";
import classNames from "classnames";

const SimpleCard = ({title, className, children, ...props}) => {

    return (
        <div className={classNames("SimpleCard", className)} {...props} >
            {!!title && <SceneTitle className='card-title' secondary>{title}</SceneTitle>}
            <div className='card-content'>
                {children}
            </div>
        </div>
    );
};

export default SimpleCard;
