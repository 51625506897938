import { useCallback, useContext, useState } from "react";
import Modal from "../../../../../components/utility/Modal/Modal"
import { ApiContext } from "../../../../../services/api/api-config";
import { getNotifier } from "../../../../../services/notifier";
import WindInput from "../../../../../components/formComponents/WindInput/WindInput";
import DragAndDrop from "../../../../../components/utility/DragAndDrop/DragAndDrop";
import './RegisterCsdModal.scss'

const RegisterCsdModal = ({onClose, rfc, onSaveSuccess}) => {

    const api = useContext(ApiContext);

    const [csdKey, setCsdKey] = useState(null)
    const [csdCer, setCsdCer] = useState(null)
    const [csdPassword, setCsdPassword] = useState(null)

    const close = useCallback(() => {
        if (onClose) onClose()
    }, [onClose]);

    const save = useCallback(() =>{
        if(!csdCer){ 
            getNotifier().error("Por favor carga tu archivo .cer")
            return
        }
        if(!csdKey){
            getNotifier().error("Por favor carga tu archivo .key")
            return
        }
        if(!csdPassword){
            getNotifier().error("Por favor ingresa tu contraseña")
            return
        }
        if(!csdKey.name.includes(".key")){
            getNotifier().error("La extensión del archivo .key no es válida")
            return
        }
        if(!csdCer.name.includes(".cer")){
            getNotifier().error("La extensión del archivo .cer no es válida")
            return
        }

        api.csd.storeCsd([csdKey,csdCer], {tenant_rfc_id: rfc.id, csd_password: csdPassword}).then(()=>{
            getNotifier().success("Archivos guardados correctamente")
            onClose()
        })

    }, [api, csdKey, csdCer, csdPassword, onClose, rfc])

    return(
        <Modal title={"Certificado de Sello Digital"}
            onClose={close}
            secondaryButtonAction={close}
            mainButtonAction={save}
            mainButtonText={"Guardar"}>

            <p>Para poder emitir facturas es necesario que registres tus archivos .cer y .key de tu CSD</p>
          
            <div className="rfc-files">
                <DragAndDrop setOuterFile={setCsdCer} allowedFilesLabel=".CER"/>
                <DragAndDrop setOuterFile={setCsdKey} allowedFilesLabel=".KEY"/>
            </div>

            <div className="csd-password">
                <WindInput
                    placeholder={"Contraseña"}
                    type={'password'}
                    value={csdPassword}
                    onChange={(data)=>{setCsdPassword(data)}}
                />
            </div>

        </Modal>
    )
}

export default RegisterCsdModal