import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { paths } from "../../../services/routes/appRoutes";
import StatusIndicator from "../../utility/StatusIndicator/StatusIndicator";
import { ApiContext } from "../../../services/api/api-config";
import { transTaxDocumentTypes, transTaxDocumentTypesColor } from "../../../services/modelUtils/taxDocumentUtils";

import "./InvoicesOverview.scss"

const InvoicesOverview = () => {

    const api = useContext(ApiContext);
    const [ types, setTypes ] = useState([]);
    const [ total, setTotal ] = useState("0");

    useEffect(
        () => {
            api.widgetsInvoices.get().then(
                (data) => {
                    setTypes(data.types);
                    setTotal(data.count);
                }
            )
            
        },[api,setTypes,setTotal]
    );

    return(
        <div className="InvoicesOverview">
            <div className="invoices-overview-header">
                <span className="single-title">Facturas</span>
                <Link to={paths.invoices} className="link strong right">
                    Ver todo
                </Link>
            </div>
            <div className="invoices-overview-data">
                <StatusIndicator text="Total" number={total} layout="long" className="mb-15 invoices-overview-total" />
                {
                    types.map( (st) => {
                        return <div key={st.status}>
                            <StatusIndicator 
                                text={transTaxDocumentTypes[st.documentType]} 
                                number={st.qty} 
                                type={ transTaxDocumentTypesColor[st.documentType] || "success" } 
                                layout="long" 
                                className="mb-15" />
                        </div>
                    } )
                }
                
            </div>
        </div>
    );
    
}

export default InvoicesOverview;