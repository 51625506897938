import React, {useCallback, useState, useContext} from 'react';
import './ForgotPassword.scss';
import {ApiContext} from "../../../services/api/api-config";
import useFormState from "../../../hooks/useFormState";
import logo from '../../../assets/images/new_wind_logo.png';
//import logo from '../../../assets/images/wind-logo-hr.png';
import WindInput from "../../../components/formComponents/WindInput/WindInput";
import Button from "../../../components/utility/Button/Button";
import useBodyClass from "../../../hooks/useBodyClass";
import {Link} from 'react-router-dom';
import {paths} from "../../../services/routes/notLoggedRoutes";

const ForgotPassword = () => {
    useBodyClass('clear-background');

    const api = useContext(ApiContext);

    const {form, handleSimpleChange} = useFormState({});
    const [mailSent, setMailSent] = useState();
    const [showMessage, setShowMessage] = useState();

    const resetPassword = useCallback((e) => {
        e.preventDefault();
        if (!form.email)
            return;

        api.users.passwordRecovery({params: {email: form.email}}).then(() => {
            setMailSent(true);
            setShowMessage(null);
        }).catch(() => {
            setShowMessage('No pudimos recuperar tu contraseña, inténtalo nuevamente más tarde.');
        });
    }, [form, api]);

    /**
     * Valid wrote email and return if is valid
     * @param email
     * @returns {boolean}
     */
    let handleValidEmail = (email) => {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email)
    }

    return (
        <div className='ForgotPassword'>
            <div className='form-block'>
                <img src={logo} alt={"Wind"} className='logo'/>

                <p className="forgot-title">
                    {!mailSent ? 'Ingresa tu email para reiniciar tu contraseña' : '¡Listo! Revista tu bandeja de entrada'}
                </p>

                <form onSubmit={resetPassword}>
                    <WindInput placeholder="Email"
                        value={form.email}
                        onChange={handleSimpleChange('email')}
                        inputProps={{disabled:mailSent}}
                    />
                    <br/>
                    <Button type='submit' disabled={mailSent || !handleValidEmail(form.email)}>
                        {mailSent ? 'Enviado' : 'Enviar'}
                    </Button>

                    {showMessage && <p className='error'>{showMessage}</p>}

                    <p className='forgot-password'>
                        <Link to={paths.login} data-tooltip='Regresar al login'>¿Recordaste tu contraseña?</Link>
                    </p>
                </form>
            </div>
        </div>
    )
}

export default ForgotPassword;
