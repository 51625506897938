import _ from "lodash";
import moment from "moment";
import { moneyFormatter } from "../currencyUtils";
import { copyPropertyIdOrNull, copyPropertyOrNull, copyPropertyOrThrow, copyPropertyValueOrThrow } from "../formUtils";
import { prepareQuoteTransactionForServer } from "./quoteTransactionUtils";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const TRANSACTION_TYPE = {
  INCOME: 'income',
  EXPENSE: 'expense',
};

export const getEmptyTransaction = ()=> {
  return {
    id: Math.random(),
    transactionDate: new Date(),
    type: 'income',
    bankAccount: null,
    total: null,
    comments: undefined,
    quoteTransactions: [],
  };
};

export const prepareTransactionForServer = ( form ) => {
  let transaction = {};
  transaction.client = form?.client?.id;

  copyPropertyOrThrow( form, transaction, "transactionDate", "Por favor selecciona una fecha de pago" );
  copyPropertyOrThrow( form, transaction, "transactionType", "Por favor selecciona un tipo de pago" );
  copyPropertyOrThrow( form, transaction, "amount", "Por favor ingresa el total" );

  copyPropertyIdOrNull( form, transaction, "bankAccount");
  copyPropertyOrNull( form, transaction, "comments" );

  const quoteTransactions = _.map( form.quoteTransactions, (quoteTransaction)=>prepareQuoteTransactionForServer(quoteTransaction,transaction.client) );
  transaction.quoteTransactions = quoteTransactions;
  transaction.transactionDate = moment(form.transactionDate).format( "YYYY-MM-DD HH:mm" );
  transaction.amount += '';

  const quotesFolios = _.map( form.quoteTransactions, (quoteTransaction)=>quoteTransaction.quote?.folio ).join(", ");
  const projectNames = _.map( form.quoteTransactions, (quoteTransaction)=>quoteTransaction.quote?.project?.name);  
  transaction.concept = `${_.uniq(projectNames)} - Pago de cotizaciones ${quotesFolios} `;
  transaction.status = transactionStatus.APPROVED;

  return transaction;
};

export const quoteTransactionHasBeenFetched = (quote, quoteTransactions) => {
  return quoteTransactions.findIndex(quoteTransaction => quoteTransaction.quote.id === quote.id) !== -1;
};

export const recalculateQuoteTransactionsAmountToPendingAmount = (quoteTransactions) => {
  _.each(quoteTransactions, (quoteTransaction, index) => {
    if(index !== quoteTransactions.length - 1) {
      quoteTransaction.amount = quoteTransaction.quote.pendingAmount;
    }
  });
};

export const quoteTransactionIsOutOfAmountLimits = (amount, pendingAmount) => {
  if(Number(amount) < 0) return true;

  if(Number(pendingAmount) < Number(amount)) return true;

  return false;
};

export const clientTransactionSGroups = [
  "quote_read",
  "quote_read_pending_amount",
  "project_read_name",
  "quote_read_project",
];

export const transactionsTableSGroups = [
  "transaction_read",
  "transaction_read_quotes_folios",
  "bank_account_read_name",
  "transaction_read_invoice",
  "invoice_read_folio",
  "transaction_read_quote_transactions",
  /* "quote_transaction_read_id", */
  "quote_transaction_read_quote",
  "quote_read"
];

export const getTransactionsTableColumns = (showModal) => [
  {
    Header: 'Folio',
    accessor: 'id'
  },
  {
    Header: 'Concepto',
    accessor: (trx) =>{
      return(
        <Link to="#" onClick={()=>showModal(trx)} data-tooltip={'Editar transacción'}> {trx.concept} </Link>
        )

    },
  },
  {
    Header: 'Factura',
    accessor: 'invoice.folio',
  },
  {
    Header: 'Cuenta',
    accessor: 'bankAccount.name'
  },
  {
    Header: 'Fecha',
    id: 'date',
    accessor: (trx) => (trx.transactionDate? moment(trx.transactionDate).format('DD/MM/YYYY'):null)
  },
  {
    Header: 'Cantidad',
    id: 'amount',
    accessor: (trx) => `$ ${moneyFormatter(trx.amount||0)}`,
    Cell: ({ value }) => <div className="text-right">{value}</div>
  },
  {
    Header: 'Estado',
    id: 'status',
    accessor: (trx) => getTransactionStatusTrans(trx.status)
  },
  {
    Header: 'Tipo',
    accessor: (trx) => { return trx.transactionType === 'income' ? 'INGRESO' : 'EGRESO' } 
  },
];

export const transactionStatusTrans = {
  PENDING: 'Pendiente',
  APPROVED: 'Aprobada',
  CANCELLED: 'Cancelada',
};
export const getTransactionStatusTrans = (status)=>transactionStatusTrans[status]||status;

export const transactionStatus = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  CANCELLED: 'CANCELLED',
};

export const transactionStatusList = _.map(transactionStatus, (value, key) => ({value: key, label: getTransactionStatusTrans(value)}) );

export const transactionGraphSGroups = [
  "transaction_read",
  "transaction_read_quotes_folios",
  "bank_account_read_name",
  "transaction_read_invoice",
  "transaction_read_folio",
  "transaction_read_concept",
  "invoice_read_folio",
  "invoice_read_balance_original",
];

export const bankTransactionSGroups = [
  "bank_transaction_read",
  "account_statement_read",
  "bank_account_read"
];

export const prepareCompleteTransactionForServer = ( form ) => {
  let transaction = {};
  transaction.client = form?.client?.id;

  copyPropertyOrThrow( form, transaction, "concept", "Por favor ingresa el concepto" );
  copyPropertyOrThrow( form, transaction, "transactionDate", "Por favor selecciona la fecha de la transacción" );
  copyPropertyValueOrThrow( form, transaction, "transactionType", "Por favor selecciona el tipo de la transacción" );
  copyPropertyOrThrow( form, transaction, "amount", "Por favor ingresa cantidad" );
  copyPropertyIdOrNull( form, transaction, "bankAccount");
  copyPropertyOrNull( form, transaction, "comments" );
  copyPropertyValueOrThrow( form, transaction, "status", "Por favor selecciona el estatus de la transacción" );
  copyPropertyIdOrNull( form, transaction, "transactionCategory");

  transaction.amount += '';

  return transaction;
};

export const prepareTransactionForUpdate = ( form ) => {
  let transaction = {};
  transaction.client = form?.client?.id;

  copyPropertyOrThrow( form, transaction, "transactionDate", "Por favor selecciona una fecha de pago" );
  copyPropertyOrThrow( form, transaction, "transactionType", "Por favor selecciona un tipo de pago" );
  copyPropertyOrThrow( form, transaction, "amount", "Por favor ingresa el total" );

  copyPropertyIdOrNull( form, transaction, "bankAccount");
  copyPropertyOrNull( form, transaction, "comments" );

  return transaction;
};

export const transactionTypeSelect = [
  {value:'income', label:'Ingreso'},
  {value:'expense', label:'Gasto'}
  ];

export const transactionType = {
  expense: 'Gastos',
  income: 'Ingresos',
};

export const transactionTypeColor = {
  expense: 'danger',
  income: 'success',
  approved: 'warning',
};

