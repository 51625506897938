
import React, {useContext} from 'react';
import './Dashboard.scss';
import TopBar from "../../../components/utility/TopBar/TopBar";
import SceneTitle from '../../../components/layout/SceneTitle/SceneTitle';
import MoneyFlow from '../../../components/widgets/MoneyFlow/MoneyFlow';
import InvoicesOverview from '../../../components/widgets/InvoicesOverview/InvoicesOverview';
import ProjectsOverview from '../../../components/widgets/ProjectsOverview/ProjectsOverview';
import AccountsOverview from '../../../components/widgets/AccountsOverview/AccountsOverview';
import { SecurityContext } from '../../../services/SecurityManager';
import logo from "../../../assets/images/wind-logo-hr.png"

const Dashboard = () => {

    const security = useContext(SecurityContext);
    const canAdminTaxDocuments = security?.canAdminTaxDocuments()    

    return (
        <div className={"Dashboard wind-scene"}>

            <TopBar />
            {canAdminTaxDocuments &&
                <>
                    <SceneTitle description="Resumen de actividades">Hola</SceneTitle>

                    <div className="dashboard-content">

                        <div className="dashboard-content-widgets">
                            <MoneyFlow />
                        </div>

                        <div className="dashboard-content-summary">
                            <SceneTitle tag="h3">Overview</SceneTitle>
                            <InvoicesOverview />
                            <ProjectsOverview />
                            <AccountsOverview />
                        </div> 

                    </div>
                </>
            }

            {!canAdminTaxDocuments &&
                <>
                    <SceneTitle description="Bienvenido a Wind">Hola</SceneTitle>
                    <div className="logo-content">
                        <img src={logo} alt="Wind" className='wind-logo'/>
                    </div>
                </>
            }
            
        </div>
    );
}

export default Dashboard;