import React from 'react'
import WindInput from '../../../../../components/formComponents/WindInput/WindInput';
import WindSwitch from '../../../../../components/formComponents/WindSwitch/WindSwitch';
import './ContactInfo.scss';

const ContactInfo = ({ bindSimple, form, className }) => {
    return (
        <div className="ClientForm">
            <div className="fields-row">
                <WindSwitch
                    className='fields-field'
                    placeholder={'Agregar información de contacto'}
                    {...bindSimple('addContactInfo')}
                />
            </div> 
            {form.addContactInfo &&
            <div className="fields-row">
                <WindInput 
                    className='fields-field'
                    placeholder={"Nombre del contacto"}
                    {...bindSimple('name')}
                    inputProps={{maxLength:100}}
                />
                <WindInput 
                    className='fields-field'
                    placeholder={"Apellido del contacto"}
                    {...bindSimple('lastname')}
                    inputProps={{maxLength:100}}
                />
                
                <WindInput 
                    className='fields-field'
                    placeholder={"Email de contato"}
                    {...bindSimple('email')}
                    inputProps={{maxLength:60}}
                />
            </div>}
            
            {form.addContactInfo && 
            <div className="fields-row">
                
                <WindInput 
                    className='fields-field'
                    placeholder={"Teléfono de contacto"}
                    {...bindSimple('phone')}
                    inputProps={{maxLength:20}}
                />
                <WindSwitch
                    className='fields-field'
                    placeholder={'Enviar credenciales'}
                    {...bindSimple('sendAccess')}
                />
                <div className='fields-field'>&nbsp;</div>
            </div>}

                
        </div>
    )
}

export default ContactInfo;