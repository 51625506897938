import { faEye } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import FileDetailsModal from '../../../../../components/crmComponents/FileContainer/components/FileDetailsModal/FileDetailsModal';
import InfoPair from '../../../../../components/layout/InfoPair/InfoPair';
import SimpleCard from '../../../../../components/layout/SimpleCard/SimpleCard';
import Button from '../../../../../components/utility/Button/Button';
import useBoolean from '../../../../../hooks/useBoolean';
import { moneyFormatter } from '../../../../../services/currencyUtils';
import { bankStatementStatusIcons, bankStatementStatusTrans } from '../../../../../services/modelUtils/bankStatementUtils';
import './BankStatementMainDetails.scss';

const BankStatementMainDetails = ({ bankStatement }) => {
  const [ appFileModal,,,toggleAppFileModal] = useBoolean(false);


  return (
    <SimpleCard
      title="Información general"
      className={"BankStatementMainDetails"}
    >
      <div className="info-blocks">
        <InfoPair
          value={bankStatement?.bankAccount ? bankStatement.bankAccount?.name : 'Sin cuenta asociada'}
          title="Cuenta"
        />
        <InfoPair
          value={moment(bankStatement?.periodStartDate).format('DD/MM/YYYY')}
          title="Inicio periodo"
        />
        <InfoPair
          value={moment(bankStatement?.periodEndDate).format('DD/MM/YYYY')}
          title="Fin periodo"
        />
        <InfoPair
          value={bankStatement?.totalTransactions}
          title="Transacciones"
        />
        <InfoPair
          value={`$${moneyFormatter(bankStatement?.totalIncome)}`}
          title="Ingresos"
        />
        <InfoPair
          value={`$${moneyFormatter(bankStatement?.totalExpense)}`}
          title="Egresos"
        />
        <InfoPair
          value={
            <div className={`badge badge-${bankStatementStatusIcons[bankStatement?.status] ? bankStatementStatusIcons[bankStatement?.status].color : 'info'}`}>
              <FontAwesomeIcon
                icon={bankStatementStatusIcons[bankStatement?.status] ? bankStatementStatusIcons[bankStatement?.status].icon : faEye}
              />
              <span className='badge-text'>{bankStatementStatusTrans[bankStatement?.status]||'Pendiente'}</span>
            </div>
          }
          title="Estado"
        />
      </div>


      <div className="app_file_button">
          <Button
            outline
            color="primary"
            onClick={toggleAppFileModal}
          >
            Ver archivo
          </Button>
      </div>

    {appFileModal && (
      <FileDetailsModal
        appFile={bankStatement?.document}
        onClose={() => toggleAppFileModal()}
      />
    )}
    </SimpleCard>
  );
}
 
export default BankStatementMainDetails;
