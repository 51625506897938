/**
 * Each object contains:
 * - title (string): The title of the section.
 * - description (string): A brief description of the section's purpose or contents.
 * - links (array of objects): An array of link objects. Each link object includes:
 *   - text (string): The text to be displayed for the link.
 *   - to (string): A string identifier used to determine the route for the link. 
 *   - apiCall (optional string): An optional field specifying the endpoit to fetch the count of registers for each section
 * 
 * Pass this objects as props for the SectionHeader component
 */

export const FinanceHeaderInfo = {
    title: "Finanzas",
    description: "Registra y gestiona las facturas y cuentas bancarias",
    links: [
        { text: "Dashboard", to: "finance"},
        { apiCall: "widgetsInvoices", text: "Facturas", to: "invoices"},
        { apiCall: "widgetsTransactions", text: "Transacciones", to: "financeTransactions"},
        { apiCall: "widgetsBankAccounts", text: "Cuentas", to: "financeAccounts" },
        { apiCall: "widgetsRecurringPayments", text: "Pagos Recurrentes", to: "financeRecurringTransactions"},
    
    ]
}

export const ProjectHeaderInfo = {
    title: "Mis proyectos",
    description: "Revisa el estado de tus proyectos, cotizaciones y solicitudes",
    links: [
        { apiCall: "widgetsProjects", text: "Proyectos", to: "projects"},
        { apiCall: "widgetsQuotes", text: "Cotizaciones", to: "quoteList"}
    ]
}


export const getProjectHeaderInfo = (security) =>{
    const canAdminProjects = security.canAdminProjects()
    const canAdminQuotes = security.canAdminQuotes()

    const permissions = {
        "Proyectos": canAdminProjects,
        "Cotizaciones": canAdminQuotes,
    };

    const filteredLinks = ProjectHeaderInfo.links.filter(link => permissions[link.text]);
    ProjectHeaderInfo.links = filteredLinks
    return ProjectHeaderInfo
}

export const getFinanceHeaderInfo = (security) =>{
    const canAdminTaxDocuments = security.canAdminTaxDocuments();
    const canAdminTransactions = security.canAdminTransactions();
    const canAdminBankAccounts = security.canAdminBankAccounts() || security.canListBankAccounts();
    const canAdminRecurringPayments = security.canAdminRecurringPayments();

    const permissions = {
        "Dashboard": canAdminTaxDocuments,
        "Facturas": canAdminTaxDocuments,
        "Transacciones": canAdminTransactions,
        "Cuentas": canAdminBankAccounts,
        "Pagos Recurrentes": canAdminRecurringPayments,
    };

    const filteredLinks = FinanceHeaderInfo.links.filter(link => permissions[link.text]);
    FinanceHeaderInfo.links = filteredLinks
    return FinanceHeaderInfo
}
