import React, { Fragment } from 'react';
import MenuItem from '../../MenuItems/MenuItem/MenuItem';
import { faItalic, faUnderline } from '@fortawesome/pro-light-svg-icons';
import { faBold, faCode } from '@fortawesome/pro-duotone-svg-icons';
import _ from 'lodash';

const TextFromatGroup = ({ editor }) => {
  const items = [
    {
      icon: faBold,
      title: 'bold',
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive('bold'),
    },
    {
      icon: faItalic,
      title: 'italic',
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive('italic'),
    },
    {
      icon: faUnderline,
      title: 'underline',
      action: () => editor.chain().focus().toggleUnderline().run(),
      isActive: () => editor.isActive('underline'),
    },
    {
      icon: faCode,
      title: 'code',
      action: () => editor.chain().focus().toggleCodeBlock().run(),
      isActive: () => editor.isActive('codeBlock'),
    },
  ];

  return (
    <>
      {_.map(items, (item) => (
        <Fragment key={item.title}>
          <MenuItem key={item.title} {...item} />
        </Fragment>
      ))}
    </>
  );
}
 
export default TextFromatGroup;