import {copyPropertyIdOrThrow, copyPropertyOrThrow} from "../formUtils";


export const bankAccountListSGroups = [
    'bank_account_read',
    'bank_account_read_bank',
        'bank_read'
];


export const bankAccountDetailSGroups = [
    'bank_account_read',
    'bank_account_read_bank',
       'bank_read'
];

export const prepareBankAccountForServer = ( form ) => {
    let bankAccount = {};
    bankAccount.client = form?.client?.id;

    copyPropertyOrThrow( form, bankAccount, "name", "Por favor introduce el nombre de la cuenta" );
    copyPropertyOrThrow( form, bankAccount, "number", "Por favor introduce el número de cuenta de la cuenta" );
    copyPropertyOrThrow( form, bankAccount, "clabe", "Por favor introduce la cuenta clabe de la cuenta" );
    copyPropertyIdOrThrow( form, bankAccount, "bank", "Por favor selecciona el banco de la cuenta");

    return bankAccount;
};

export const getEmptyBankAccount = () => {
    return {
        id: null,
        name: null,
        number: null,
        clabe: null,
        bank: null,
        belvoToken: null,
    };
}

export const convertBankAccountToForm = (bankAccount) => {
    return {
        ...bankAccount,
    }
}

export const bankAccountSGroups = [
    'bank_account_read'
];
