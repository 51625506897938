import React, {useContext} from 'react';
import './BankAccountCard.scss';
import {moneyFormatter} from "../../../../../services/currencyUtils";
import {paths} from "../../../../../services/routes/appRoutes";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faPlug } from "@fortawesome/pro-light-svg-icons";
import IconButton from '../../../../../components/utility/IconButton/IconButton';
import { SecurityContext } from '../../../../../services/SecurityManager';

const BankAccountCard = ({bankAccount, showEdit}) => {

    const security = useContext(SecurityContext);
    const canAdminBankAccounts = security?.canAdminBankAccounts()
    const accountDetailPath = canAdminBankAccounts ? paths.bankAccountDetail.replace(":id", bankAccount.id) : "#" 

    return (
        <div className='BankAccountCard'>
            <div className='card-title'>
                <Link to={accountDetailPath} data-tooltip={"Ir a detalles de cuenta"} className={"account-link"}>
                    <h2>{bankAccount?.name}</h2>
                    <h4>{bankAccount?.bank?.name}</h4>
                </Link>

                <div className='card-title-icons'>
                    {canAdminBankAccounts &&
                        <IconButton
                        icon={faGear}
                        color={ 'white' }
                        onClick={ () => { showEdit(bankAccount.id) } }
                        />
                    }

                    {
                        ( bankAccount.belvoLink || bankAccount.syncfyCredential)? 
                        <Link className='IconButton' data-tooltip={"Cuenta conectada al banco"} to={"#"}><FontAwesomeIcon icon={faPlug}/></Link>
                        : ''
                    }
                </div>

            </div>

            <div className='card-info'>
                <div className='card-info-field'><strong className='bold-field'>No. cuenta</strong> {bankAccount?.number}</div>
                <div className='card-info-field'><strong className='bold-field'>Clabe</strong> {bankAccount?.clabe}</div>
                <div className='card-balance-field'><strong className='bold-field'>Saldo disponible</strong> <span className='balance'>${moneyFormatter(bankAccount?.balance)} MXN</span></div>
            </div>
        </div>
    );
};

export default BankAccountCard;
