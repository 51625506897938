import React, { useContext, useEffect, useState } from "react";
import useFormState from "../../../../../hooks/useFormState";
import './Customization.scss';
import DragAndDrop from "../../../../../components/utility/DragAndDrop/DragAndDrop";
import { ApiContext } from "../../../../../services/api/api-config";
import { getNotifier } from "../../../../../services/notifier";

import WindInput from "../../../../../components/formComponents/WindInput/WindInput";
import Button from "../../../../../components/utility/Button/Button";

const tenantSGroups = [
    'tenant_read',
    'tenant_read_logo'
]

const Customization = () => {
    const api = useContext(ApiContext);
    const [tenant, setTenant] = useState();
    const [tenantLogo, setTenantLogo] = useState();
    const [saveBtn, setSaveBtn] = useState(false);

    const { form, bindSimple, setForm } = useFormState(() => ({}));

    useEffect(() => {
        let isMounted = true;

        const fetchTenant = async () => {
            try {
                const tenantRfcs = await api.tenantRfcs.get();

                if (tenantRfcs.length > 0) {
                    const tenantId = tenantRfcs[0].tenant.split('/').pop();
                    const tenant = await api.tenants.get({ id: tenantId, params: { sGroups: tenantSGroups } });
                    const logoId = tenant?.companyLogo?.split('/').pop();
                    const tenantLogo = await api.appFiles.getUrl({ id: logoId });

                    if (isMounted) {
                        setTenant(tenant);
                        setTenantLogo(tenantLogo);
                        setForm({
                            primaryColor: tenant?.primaryColor || '#000000',
                            secondaryColor: tenant?.secondaryColor || '#000000'
                        });
                        setSaveBtn(false);
                    }
                }
            } catch (error) {
                if (isMounted) {
                    console.error("Error fetching tenant data:", error);
                }
            }
        };

        fetchTenant();

        return () => {
            isMounted = false;
        };
    }, [api, setForm]);

    const saveLogo = async (file, setDroppedFiles) => {
        try {
            const updatedTenant = await api.tenants.update({ id: tenant.id, params: { sGroups: tenantSGroups }, files: file });

            const logoId = updatedTenant?.companyLogo?.split('/').pop();
            const updatedTenantLogo = await api.appFiles.getUrl({ id: logoId });

            setTenantLogo(updatedTenantLogo);
            setDroppedFiles(null);

            getNotifier().success("Logotipo guardado correctamente");
        } catch (e) {
            getNotifier().error("Error al guardar logotipo");
        }
    };

    const saveColors = async () => {
        try {
            await api.tenants.update({ id: tenant.id, params: { ...form } });
            setSaveBtn(false);
            getNotifier().success("Colores guardados correctamente");
        } catch (e) {
            getNotifier().error("Error al guardar colores");
        }
    };

    return (
        <div className="customization-container">
            <h2>Logotipo</h2>
            <div className="inner-container">
                <div className="logo-block">
                    <p>Carga tu logo</p>
                    <DragAndDrop onSave={saveLogo} />
                </div>
                {tenant?.companyLogo &&
                    <div className="logo-block">
                        <p>Logo actual</p>
                        <img src={`${tenantLogo}`} alt="" className="logo-img" />
                    </div>
                }
            </div>

            <div className="outer-container">
                <h2>Colores</h2>
                <div className="inner-container">
                    <WindInput
                        className="color-input"
                        placeholder={"Seleccione color principal"}
                        type="color"
                        value={form.primaryColor}
                        onChange={(e) => {
                            const { onChange } = bindSimple('primaryColor');
                            if (onChange) onChange(e);
                            setSaveBtn(true);
                        }}
                    />

                    <WindInput
                        className="color-input"
                        placeholder={"Seleccione color secundario"}
                        type="color"
                        value={form.secondaryColor}
                        onChange={(e) => {
                            const { onChange } = bindSimple('secondaryColor');
                            if (onChange) onChange(e);
                            setSaveBtn(true);
                        }}
                    />
                </div>

                {saveBtn &&
                    <div className="btnContainer">
                        <Button onClick={saveColors}>Guardar</Button>
                    </div>
                }
            </div>
        </div>
    );
};

export default Customization;
