import React, {
  useContext,
  useEffect,
} from 'react';
import './ClientDetail.scss';
import TopBar from '../../../components/utility/TopBar/TopBar';
import DetailHeader from '../../../components/utility/DetailHeader/DetailHeader';
import { clientDetailSGroups } from '../../../services/modelUtils/clientUtils';
import { ApiContext } from '../../../services/api/api-config';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getNotifier } from '../../../services/notifier';
import ClientMainDetails from './components/ClientMainDetails/ClientMainDetails';
import useBoolean from '../../../hooks/useBoolean';
import TransactionFormModal from '../../../components/formComponents/TransactionFormModal/TransactionFormModal';
import { moneyFormatter } from '../../../services/currencyUtils';

const ClientDetail = () => {
  const api = useContext(ApiContext);
  const [ showTransactionForm, transactionFormOn, transactionFormOff ] = useBoolean(false);
  // const history = useHistory();

  const { id } = useParams();

  // const handleAddUser = useCallback(() => history.push(`${paths.usersNew}?client=${id}`), [history, id]);

  // ----- Load client data
  const customProp = 'ClientDetail.get.'+id;

  useEffect(()=>{
    api.clients.get({ id, customProp, params: { sGroups: clientDetailSGroups } })
      .catch(() => getNotifier().error('No se encontró el cliente solicitado'));
    if ( id === 989898 ) transactionFormOn();
  },[api, id, customProp, transactionFormOn]);

  const client = useSelector(s=>s.api[customProp])||[];
  /*
  const tools = useMemo(()=>[
    { icon: faUserPlus, callback: handleAddUser, text: 'Agregar usuario', testId: 'addUser' },
    { icon: faHandHoldingUsd, callback: () => transactionFormOn(), text: "Agregar nuevo pago", testId: "client-detail-add-transaction-button" },
    { icon: faPencil, callback: () => history.push(paths.clientEdit.replace(':id', id)), text: 'Editar cliente' },
  ],[ transactionFormOn, handleAddUser, history, id ]);
*/

  const clientData = [
    { title: "Total aprobado", value: "$ "+moneyFormatter(client?.clientStats?.approvedQuotesSum || 0) },
    { title: "Total cotizado", value: "$ "+moneyFormatter(client?.clientStats?.allQuotesSum || 0) }
  ]

  
  return (
    <div className={"ClientDetail wind-scene"}>

      <TopBar />
      
      <div className='center-container'>
        <DetailHeader 
          title={"Cliente " + (client?.id ? client.id : "-")} 
          subtitle={client?.companyName || client?.fullName} 
          data={clientData} 
          circle={true} />

        <div className="general-content">
          <ClientMainDetails client={client} addTransaction={transactionFormOn} />
        </div>
      </div>

      { showTransactionForm &&
        <TransactionFormModal
          onClose={transactionFormOff}
          client={client}
        />
      }
    </div>
  );
}

export default ClientDetail;
