import React, {useCallback, useState} from 'react';
import './WindInputPassword.scss';
import classNames from "classnames";
import _ from 'lodash';
import {faEye, faEyeSlash} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const WindInputPassword = ({ value, onChange, placeholder, type, className, disabled, inputProps={}, label, withLabel=true, ...props }) => {
    const [visible, setVisible] = useState(false);
    const handleChange = useCallback((e)=>{
        onChange?.(e.target.value);
    },[onChange]);

    return (
        <div className={classNames( "WindInput WindInputPassword", value&&"with-content", className)} {...props}>
            {withLabel? <div><label>{label? label: placeholder}</label></div>: null}
            <input
                className={classNames('input-el', inputProps.className)}
                value={typeof value === 'undefined' || _.isNull(value) ? '':value}
                onChange={handleChange}
                type={visible ? 'text' : 'password'}
                disabled={disabled}
                {...inputProps}
                placeholder={placeholder}
            />
            <div className="icon" onClick={() => setVisible(!visible)}>
                <FontAwesomeIcon icon={visible ? faEyeSlash : faEye}/>
            </div>
        </div>
    );
};

export default WindInputPassword;