import React, {  useMemo, useContext, useState, useEffect, useCallback } from 'react'
import TideReactTable from '../../../../../components/utility/TideReactTable/TideReactTable';
import useTideTable from '../../../../../components/utility/TideReactTable/useTideTable';
import { moneyFormatter } from '../../../../../services/currencyUtils';
import Button from "../../../../../components/utility/Button/Button";
import { ApiContext } from '../../../../../services/api/api-config';
import useScript from '../../../../../hooks/useScript';
import { getNotifier } from '../../../../../services/notifier.js';
import moment from 'moment';
import { paths } from "../../../../../services/routes/appRoutes";
import { Link } from "react-router-dom";
import { bankLinkStatus, transactionDirection, transactionStatus } from '../../../../../services/modelUtils/bankTransactionUtils';

export const TransactionsList = ({bankAccountId}) => {
    useScript('https://cdn.belvo.io/belvo-widget-1-stable.js');

    const api = useContext(ApiContext);
    const [ bankAccount, setBankAccount ] = useState(null);
    const [ message, setMessage ] = useState('');

    const columns = useMemo(() => [
        {
            Header: 'Concepto',
            accessor: (trx) => {
                return(<Link to={paths.transactionInvoices.replace(':id', trx.id)} data-tooltip={'Ver transacción'} >
                        { trx.concept }
                    </Link>)
            },
        },
        {
            Header: 'Referencia',
            accessor: 'reference',
            sortable: false,
        },
        {
            Header: 'Monto',
            id: 'amount',
            accessor: (trx) => `$ ${moneyFormatter(trx.amount)} ${trx.currency}`,
            sortable: false,
        },
        {
            Header: 'Fecha',
            id: 'created_at',
            accessor: (trx) => (trx.date? moment(trx.date).format('DD/MM/YYYY'):null),
            sortable: false,
        },
        {
            Header: 'Tipo',
            accessor: (trx) =>  transactionDirection[ trx.type ],
            sortable: false,
        },
        {
            Header: 'Estatus',
            accessor: (trx) => transactionStatus[ trx.status ],
            sortable: false,
        }
    ], []);

    useEffect( () => {
        api.bankAccounts.get( { id: bankAccountId } ).then( (data) => { setBankAccount(data); } );
    }, [api, bankAccountId, setBankAccount ]);

    const requestFilters = useMemo(() => ({
        bankAccount: bankAccountId
    }) , [bankAccountId]);
    
    const table = useTideTable({
        entity: 'bankTransactions',
        requestFilters: requestFilters,
        requestOptions: requestFilters,
        columns,
    });

    

    const onExit = useCallback((data) => {
        setMessage("Saliste de la aplicación. Para ver tus transacciones, sincroniza nuevamente con Belvo.");
    }, []);

    const onEvent = useCallback((event) => {
        
    }, []);

    const updatedLinkBelvo = useCallback((link, institution) => {
        if( link === bankAccount.belvoLink ) {
            // syncTransactions();
        }
    }, [bankAccount]);

    const showConnect = useCallback((token) => {
        // config options: 
        // https://developers.belvo.com/docs/widget-startup-configuration
        // for default generated links are recurring
        const config = {
            callback: (link, institution) => updatedLinkBelvo(link, institution),
            onExit,
            onEvent,
            resources: ["ACCOUNTS", "OWNERS", "TRANSACTIONS"],
            access_mode: "recurrent",
            show_abandon_survey: false,
            external_id: `WIND-${bankAccount.id}`  // local user id -> 
        }
        window.belvoSDK.createWidget(token.access, config).build();
    }, [updatedLinkBelvo, onEvent, onExit, bankAccount]);

    const renewToken = useCallback ( () => {
        api.belvo.generateToken({ params:{ link_id:bankAccount.belvoLink  } }).then(showConnect);
    }, [api, showConnect, bankAccount]  );

    const syncTransactions =  useCallback ( () => {
        if ( bankAccount !== null ) {
            getNotifier().info( "Se están sincronizando las transacciones." );
            api.belvo.syncTransactions({filters: {linkId: bankAccount.belvoLink }})
            .then( (data) => { 
                getNotifier().info( data );
                table.reload();
            } )
            .catch( error => {
                if( error.status === 428 ) {
                    renewToken();
                }
                else {
                    getNotifier().error( "Se ha detectado un error con su cuenta; por favor vaya a la sección de administración de cuentas para volver a conectarla." );
                }
            } );
        }
    }, [api, renewToken, table, bankAccount] );

    const retrieveTransactions =  useCallback ( () => {
        if ( bankAccount !== null ) {
            getNotifier().info( "Se están solicitando las transacciones." );
            api.belvo.retrieveTransactions( {filters: {linkId: bankAccount.belvoLink }} )
            .then( (data) => { 
                getNotifier().info( data );
            } )
            .catch( error => {
                if( error.status === 428 ) {
                    renewToken();
                }
                else {
                    getNotifier().error( "Se ha detectado un error con su cuenta; por favor vaya a la sección de administración de cuentas para volver a conectarla." );
                }
            } );
        }
    }, [ api, renewToken, bankAccount ] );

    const syncTransactionsSyncfy =  useCallback ( () => {
        if ( bankAccount !== null ) {
            getNotifier().info( "Se están sincronizando las transacciones." );
            api.syncfy.syncTransactions({params: {bank_account: bankAccount.id }})
            .then( (data) => { 
                getNotifier().info( "Se han sincronizado " + data.saved + " transacciones" );
                table.reload();
            } )
            .catch( error => {
                getNotifier().error( "Se ha detectado un error con su cuenta; por favor vaya a la sección de administración de cuentas para volver a conectarla." );
            } );
        }
    }, [api, table, bankAccount] );

    return (
        <div>
            <h2>
             {bankAccount?.name}
            </h2>

            <p>
                {
                    bankAccount?.belvoLink?
                    <span>
                        <Button onClick={syncTransactions}>
                        Sincronizar transacciones
                        </Button>
                        {
                            bankAccount?.belvoLinkStatus === bankLinkStatus.VALID ?
                            '':
                            <Button onClick={retrieveTransactions}>
                                Solicitar transacciones al banco
                            </Button>
                        }
                    </span>
                    : ''
                }
                
                {
                    bankAccount?.syncfyCredential?
                    <Button  onClick={syncTransactionsSyncfy}>
                        Sincronizar transacciones
                    </Button>
                    : ''
                }
                
            </p>
            <p>
                {message}
            </p>

            <div>
                {bankAccountId && <TideReactTable key={bankAccountId} 
                    {...table.tableProps} className="table-hoverable"
                />}
            </div>
            <div id="belvo" />
        </div>
    );
}
