import React, { Fragment, useCallback, useState } from 'react';
import './MenuBar.scss';
import { faRemoveFormat } from '@fortawesome/pro-duotone-svg-icons';
import TextFromatGroup from '../MenuGroups/TextFormatGroup/TextFormatGroup';
import ParagraphFormatGroup from '../MenuGroups/ParagraphFormatGroup/ParagraphFormatGroup';
import MediaFormatGroup from '../MenuGroups/MediaFormatGroup/MediaFormatGroup';
import MenuItem from '../MenuItems/MenuItem/MenuItem';
import FontFormatGroup from '../MenuGroups/FontFormatGroup/FontFormatGroup';
import { useEffect } from 'react';
import TablesMenuGroup from '../MenuGroups/TablesMenuGroup/TablesMenuGroup';

const MenuBar = ({ editor }) => {
  const [, setTransactionCount ] = useState(0);

  useEffect(() => {
    const addOneToState = () => { setTransactionCount((count) => count + 1) };

    editor.on('transaction', addOneToState);
    return () => editor.off('transaction', addOneToState);
  }, [editor]);

  const removeTextFormat = useCallback(() => {
    editor.chain().focus().clearNodes().unsetAllMarks().run()
  }, [editor]);

  return (
    <div className={"MenuBar"}>
      <FontFormatGroup editor={editor} />
      <div className="divider" />
      <TextFromatGroup editor={editor} />
      <div className="divider" />
      <ParagraphFormatGroup editor={editor} />
      <div className="divider" />
      <MediaFormatGroup editor={editor} />
      <div className="divider" />
      <Fragment>
        <MenuItem icon={faRemoveFormat} action={removeTextFormat} />
      </Fragment>
      <div className="divider" />
      <TablesMenuGroup editor={editor} />
    </div>
  );
}
 
export default MenuBar;