import React, { useState } from 'react';
import './ToolBar.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTools, faClose } from '@fortawesome/pro-light-svg-icons';
import classNames from "classnames";

const ToolBar = ({ tools }) => {
    const [showTexts, setShowTexts] = useState(false);
    const [showTools, setShowTools] = useState(false);
    
    const toggleTools = () => {
        setShowTools(!showTools);
    };
    
    return (
        <div className={classNames("ToolBar", showTools ? "ToolBar--show" : "")}
            onMouseEnter={() => {
                setShowTexts(true);
            }}
            onMouseLeave={() => {
                setShowTexts(false);
            }}
        >
            
            <button className='tool-btn tools-mobile' onClick={toggleTools}>
                {!showTools ? <FontAwesomeIcon icon={faTools}/> : <FontAwesomeIcon icon={faClose}/>}
            </button>

            {tools?.map( (tool, i)=>
                <button
                    data-test-id={tool.testId}
                    key={i} 
                    className={ classNames('tool-btn', (showTexts ? 'tool-btn--padding' : '') ) }
                    onClick={tool.callback}
                    data-tooltip={(tool.disabled||!tool.tooltip)?undefined:tool.tooltip}
                    disabled={tool.disabled===true}                    
                >
                    {tool.text &&
                        <div className='ToolBar__text'>
                            {tool.text}
                        </div>}
                    <FontAwesomeIcon icon={tool.icon}/>
                </button>
            )}
        </div>
    );
};

export default ToolBar;
