import React from 'react';
import './TideReactTable.scss';
import Pagination from "./components/Pagination/Pagination";
import classNames from 'classnames';

const TideReactTable = ({className, reactTable, onRowClick, loading, totalItems, pageSizeOptions, displayPagination=true, customGetRowProps=null}) => {

    return (
        <div className={classNames("TideReactTable", className)}>
            <div className='table-container table-responsive'>
            <table {...reactTable.getTableProps()} className='table'>
                <thead>
                    {reactTable.headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                        </tr>
                    ))}
                </thead>

                <tbody {...reactTable.getTableBodyProps()}>
                    {reactTable.page.map(row => {
                        reactTable.prepareRow(row);
                            const rowProps = (customGetRowProps)? customGetRowProps(row): row.getRowProps();
                            return (
                                <tr {...rowProps} onClick={ onRowClick && ((e)=>onRowClick(row.original, e)) }>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                </tbody>
            </table>
            </div>

            {!reactTable.rows?.length &&
            <div className='spacer' />}

            {/* {loading &&  <div className={'over-message'}><LoadingIcon/></div>} */}

            {(!reactTable.rows?.length && !loading)&&
                <p className={'over-message'}>
                    <span>No se encontró ningún resultado</span>
                </p>}
            {displayPagination &&
            <div className={'table-footer'}>
                <Pagination reactTable={reactTable} totalItems={totalItems} pageSizeOptions={pageSizeOptions}/>
            </div>
            }
        </div>
    );
};

export default TideReactTable;
