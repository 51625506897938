import React from "react";
import WindSelect from "../../../../../components/formComponents/WindSelect/WindSelect";
import { projectStatusTrans, projectStatusOptions } from "../../../../../services/modelUtils/projectUtils";

import "./ProjectHeader.scss"

const ProjectHeader = ({ project, onChangeStatus }) => {
    if ( !project ) return null;
    return(
        <div className="ProjectHeader">
            <div className="project-name">{project.name}</div>
            <div className="project-info">
                {project.client?.fullName}
            </div>
            <div className="project-info">{project.folio}</div>
            <div className="project-status">
                <WindSelect
                    label="Estado"
                    value={{ value: project?.status||'', label: projectStatusTrans[project?.status] }}
                    options={projectStatusOptions}
                    onChange={onChangeStatus}
                    placeholder={projectStatusTrans[project?.status]}
                />
            </div>
        </div>
    );

}

export default ProjectHeader;