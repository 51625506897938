import React, {useCallback, useContext} from "react"
import Modal from "../../../../components/utility/Modal/Modal";
import WindInput from "../../../../components/formComponents/WindInput/WindInput";
import useFormState from "../../../../hooks/useFormState";
import { getEmptyAdmin, prepareAdminForServer } from "../../../../services/modelUtils/superAdminUtils";
import { ApiContext } from "../../../../services/api/api-config";
import { getNotifier } from "../../../../services/notifier";
import "./SuperAdminFormModal.scss"

const SuperAdminFormModal = ({onClose, onAdminSaved}) => {

    const api = useContext(ApiContext);
    const { form,  bindSimple } = useFormState(() =>getEmptyAdmin());

    const close = useCallback(() => {
        if (onClose)
            onClose()
    }, [onClose]);

    const saveAdmin = useCallback(async () => {
        try {

          const role = await api.roles.get({params:{name:"SUPER_ADMIN"}});
          form.role = role[0].id;

          const user = prepareAdminForServer({ ...form });
          const createdUser = await api.users.create({ params: user });
      
            if (createdUser) {
              getNotifier().success("Super administrador creado exitosamente");
              if(onAdminSaved) onAdminSaved()
              close()
            } else {
              throw new Error("Error al crear usuario");
            }
           
        } catch (error) {
          getNotifier().error(error.message);
        }
      }, [form, api, onAdminSaved, close]);

    return(
        <Modal
        title="Nuevo super administrador"
        onClose={close}
        secondaryButtonAction={close}
        mainButtonText="Guardar"
        mainButtonAction={ saveAdmin }
        >
            <div className='TenantForm'>

                <div className="input-row">
                    <WindInput
                        className={"inputs"}
                        placeholder="Nombre*"
                        {...bindSimple('name')}
                    />
                    <WindInput
                        className={"inputs"}
                        placeholder="Apellido*"
                        {...bindSimple('lastName')}
                    />
                </div>

                <div className="input-row">
                    <WindInput
                        className={"inputs"}
                        placeholder="Email*"
                        {...bindSimple('email')}
                    />
                    <WindInput
                        className={"inputs"}
                        placeholder="Teléfono"
                        {...bindSimple('phone')}
                    />
                </div>
            </div>

        </Modal>
    )
}

export default SuperAdminFormModal;