import React, { useCallback } from 'react';
import './WindInput.scss';
import classNames from 'classnames';
import _ from 'lodash';

const WindInput = ({ value, onChange, placeholder, type, className, disabled, inputProps = {}, label, withLabel = true, showTooltip = false, ...props }) => {

  const handleChange = useCallback((e) => {
    onChange?.(e.target.value);
  }, [onChange]);

  return (
    <div className={classNames("WindInput", value && "with-content", className)} {...props}>
      {withLabel ? <div><label>{label ? label : placeholder}</label></div> : null}
      <div className={classNames("tooltip", { "show-tooltip": showTooltip })}>
        <input
          className={classNames('input-el', inputProps.className)}
          value={typeof value === 'undefined' || _.isNull(value) ? '' : value}
          onChange={handleChange}
          type={type}
          disabled={disabled}
          {...inputProps}
          placeholder={placeholder}
        />
        {showTooltip && <span className="tooltiptext">{value}</span>}
      </div>
    </div>
  );
};

export default WindInput;
