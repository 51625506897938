import { faCalendarLines, faEye, faFileInvoiceDollar, faMessageQuestion, faPencilAlt, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import _ from "lodash";

// Permission constants
export const ACL_PERMISSION_SHOW        = 1;
export const ACL_PERMISSION_UPDATE      = (1<<2) | ACL_PERMISSION_SHOW;
export const ACL_PERMISSION_DELETE      = (1<<3) | ACL_PERMISSION_SHOW;
export const ACL_PERMISSION_UPDATE_ACLS = (1<<4) | ACL_PERMISSION_SHOW;

export const aclDefinitions = {
  project: {
    name: "Proyecto",
    // This permissions will be compared with user acl (TideAcl) to determine if the user can manage a single entity (Project)
    aclPermissions: [
      { icon: faEye, label: "Ver", mask: ACL_PERMISSION_SHOW, name: "ACL_PERMISSION_SHOW" },
      { icon: faPencilAlt, label: "Editar", mask: ACL_PERMISSION_UPDATE, name: "ACL_PERMISSION_UPDATE" },
      { icon: faTrashAlt, label: "Eliminar", mask: ACL_PERMISSION_DELETE, name: "ACL_PERMISSION_DELETE" },
      { icon: faFileInvoiceDollar, label: "Ver todas las cotizaciones", mask: (1<<9) | ACL_PERMISSION_SHOW, name: "ACL_PERMISSION_SHOW_ALL_QUOTES" },
      { icon: faMessageQuestion, label: "Ver todas las solicitudes", mask: (1<<10) | ACL_PERMISSION_SHOW, name: "ACL_PERMISSION_SHOW_ALL_QUOTATION_REQUESTS" },
      { icon: faCalendarLines, label: "Ver todas las minutas", mask: (1<<11) | ACL_PERMISSION_SHOW, name: "ACL_PERMISSION_SHOW_ALL_MEMORANDUM" },
    ],
    // This permissions will be compared with the user permissions to determine if the user can manage the entity (Project)
    domainPermissions: [
      { icon: faEye, label: "Ver", name: "PROJECT_SHOW" },
      { icon: faPencilAlt, label: "Editar", name: "PROJECT_UPDATE" },
      { icon: faTrashAlt, label: "Eliminar", name: "PROJECT_DELETE" },
    ],
    domain: "App\\Entity\\Project",
  },
  quote: {
    name: "Cotización",
    // This permissions will be compared with user acl (TideAcl) to determine if the user can manage a single entity (Project)
    aclPermissions: [
      { icon: faEye, label: "Ver", mask: ACL_PERMISSION_SHOW, name: "ACL_PERMISSION_SHOW" },
      { icon: faPencilAlt, label: "Editar", mask: ACL_PERMISSION_UPDATE, name: "ACL_PERMISSION_UPDATE" },
      { icon: faTrashAlt, label: "Eliminar", mask: ACL_PERMISSION_DELETE, name: "ACL_PERMISSION_DELETE" },
    ],
    // This permissions will be compared with the user permissions to determine if the user can manage the entity (Project)
    domainPermissions: [
      { icon: faEye, label: "Ver", name: "QUOTE_SHOW" },
      { icon: faPencilAlt, label: "Editar", name: "QUOTE_UPDATE" },
      { icon: faTrashAlt, label: "Eliminar", name: "QUOTE_DELETE" },
    ],
    domain: "App\\Entity\\Quote",
  }
};

export const permissionMaskIsActive = (aclPermissionMask, permissionMask) => {
  return (Number(aclPermissionMask) & permissionMask) === permissionMask;
}

export const getActivePermissionsMasks = (acl, aclPermissions) => {
  return _.map(aclPermissions, (aclPermission) => {
    if (permissionMaskIsActive(acl.permissionMask, aclPermission.mask)) return aclPermission.mask;
  }).filter(Boolean);
}
