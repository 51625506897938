import React, {useContext} from 'react';
import UserAvatar from '../UserAvatar/UserAvatar';
import InfoPair from '../../../../../components/layout/InfoPair/InfoPair';
import Button from '../../../../../components/utility/Button/Button';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import { SecurityContext } from '../../../../../services/SecurityManager';
import './UserInfo.scss';

const UserInfo = ({ user, handleEdit }) => {

  const Avatar = <UserAvatar user={user} className="avatar" />;

  const security = useContext(SecurityContext);
  const canAdminClients = security?.canAdminUsers()
  
  return (
    <div className="UserInfo">
      <div className="userinfo-header">
        <div className="userinfo-header-title">
          <div className="single-title">Información general</div>
        </div>
        {canAdminClients && 
          <div className="userinfo-header-actions">
            <Button onClick={handleEdit} leftIcon={faPencil} outline>Editar</Button>
          </div>
        }
      </div>

      <div className="userinfo-info">
        <div className="userinfo-avatar">
          {Avatar}
        </div>
        <div className="userinfo-data">
          <InfoPair title="Nombre completo" value={user?.fullName||"-"} className="space" />
          <InfoPair title="Usuario" value={user?.username||"-"} className="space" />
          <InfoPair title="Empresa" value={user?.client?.fullName||"-"} className="space" />
          <InfoPair title="Correo" value={user?.email||"-"} className="space" />
          <InfoPair title="Telefono" value={user?.phone||"-"} className="space" />
        </div>
      </div>
      
      
    </div>
  );
}
 
export default UserInfo;