
import React, {useCallback, useMemo, useContext} from 'react';
import TopBar from "../../../components/utility/TopBar/TopBar";
import {paths} from "../../../services/routes/appRoutes";
import {useHistory} from "react-router-dom";
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import {userTableColumns, userTableSGroups} from "../../../services/modelUtils/userUtils";
import useSearchHistory from '../../../hooks/useSearchHistory';
import useUrlFilters from '../../../hooks/useUrlFilters';
import SearchBar from '../../../components/search/SearchBar/SearchBar';
import SceneLinkMenu from '../../../components/utility/SceneLinkMenu/SceneLinkMenu';
import { SecurityContext } from '../../../services/SecurityManager';

const requestFilters={sGroups: userTableSGroups};
const filtersConfig = { 
    placeholder: 'Buscar un usuario...', 
    filters: [ 
        { main: true, field:'name', label:"Nombre de usuario" },
        {field: 'name', label:'Nombre de usuario'},
    ]};

const UserList = () => {
    const urlFilters = useUrlFilters();
    
    const history = useHistory();

    const security = useContext(SecurityContext);
    const canAdminUsers = security?.canAdminUsers()

    //Table config
    const table = useTideTable({
        entity: 'users',
        columns: userTableColumns,
        requestFilters,
        ...urlFilters
    });

    const { tableProps, filters, setFilters } = table;

    useSearchHistory(table);

    // ----- Link to add projects
    const handleAdd = useCallback(()=>history.push(paths.usersNew), [history]);
    const redirectToUserProfile = useCallback((user)=>history.push(paths.usersDetail.replace(':id', user?.id)), [history]);
    
    const tools = useMemo(() => 
        canAdminUsers ? [
            { text: "Crear", callback: handleAdd, outline: false },
        ] : [], 
    [canAdminUsers, handleAdd]);
    

    return (
        <div className={"UserList wind-scene"}>

            <TopBar />
            
            
            
            
            <SceneLinkMenu tools={tools}>
                <SearchBar title="Todos los usuarios" 
                    filters={filters} 
                    filtersConfig={filtersConfig} 
                    onFiltersChange={setFilters}
                    labels={false}
                    more={false}
                    longFields={true}  />
            </SceneLinkMenu>
            
            <div className='center-container'>
                <TideReactTable {...tableProps} onRowClick={redirectToUserProfile} className="table-hoverable" />
            </div>

        </div>
    );
};

export default UserList;
