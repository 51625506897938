import React, {useCallback, useContext, useEffect, useState} from 'react'
import _ from 'lodash';
import useCallbackCreator from 'use-callback-creator';
import useFormState from '../../../hooks/useFormState'
import SimpleCard from '../../../components/layout/SimpleCard/SimpleCard'
import { getEmptyInvoice } from '../../../services/modelUtils/taxDocumentUtils'
import IssuerData from './components/IssuerData/IssuerData'
import ReceiverData from './components/ReceiverData/ReceiverData'
import InoviceGeneralData from './components/InvoiceGeneralData/InvoiceGeneralData'
import ProductsData from './components/ProductsData/ProductsData'
import { getEmptyInvoiceProduct, updateInvoiceWithQuote } from '../../../services/modelUtils/taxDocumentUtils';
import { ApiContext } from '../../../services/api/api-config';
import TotalData from './components/TotalData/TotalData';
import SceneTitle from '../../../components/layout/SceneTitle/SceneTitle';
import TopBar from '../../../components/utility/TopBar/TopBar';
import Button from '../../../components/utility/Button/Button';
import useBoolean from '../../../hooks/useBoolean';
import Modal from '../../../components/utility/Modal/Modal';
import iconSuccess from "../../../assets/images/icon-success.png";
import { paths } from '../../../services/routes/appRoutes';
import { useHistory, useLocation } from 'react-router-dom';
import './InvoiceForm.scss'

const InvoiceForm = () => {

    const location = useLocation();
    const quote = location.state?.quote || null;
    const history = useHistory();
    const api = useContext(ApiContext);
    const {form, setForm, bindSimple} = useFormState(()=> getEmptyInvoice())
    const [showingCfdiSuccess, showCfdiSuccess, hideCfdiSuccess ] = useBoolean(false)
    const [disableIssueBtn, setDisableIssueBtn] = useState(false)
    const [issuedCfdi, setIssuedCfdi] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            const tenant = (await api.tenants.get({ params: { sGroups: ['tenant_read_id', 'tenant_read_invoice_preferences'] } }))[0];
            const preferences = tenant?.invoicePreferences ? JSON.parse(tenant.invoicePreferences) : {};
            const quoteData = quote ? updateInvoiceWithQuote(quote) : {};

            setForm((prevForm) => ({
                ...prevForm,
                ...preferences,
                ...quoteData,
            }));
        };

        fetchData();
    }, [api, quote, setForm]);

    const handleInvoiceProductChange = useCallbackCreator((index, invoice)=>{
        const newInvoiceProducts = [...form.invoiceProducts];
        newInvoiceProducts[index] = invoice;
        setForm({ ...form, invoiceProducts: newInvoiceProducts });
    }, [setForm, form]);

    const handleRemoveInvoiceProduct = useCallbackCreator((index)=>{
        const newInvoiceProducts = [...form.invoiceProducts];
        newInvoiceProducts.splice(index, 1);
        setForm({ ...form, invoiceProducts: newInvoiceProducts });
    },[setForm, form]);

    const handleAddInvoiceProduct = useCallback(()=>{
        setForm({
          ...form,
          invoiceProducts: [...form.invoiceProducts, getEmptyInvoiceProduct()]
        });
      },[setForm, form]);

      const emitCfdi = async() => {
        setDisableIssueBtn(true)
        try{
            const cfdiResponse = await api.taxDocuments.issueCfdi(form)
            if(cfdiResponse?.message === "OK"){
                setIssuedCfdi(cfdiResponse.cfdi)
                showCfdiSuccess()
                setDisableIssueBtn(false)
            }
        }catch(e){
            setDisableIssueBtn(false)
        }
      }

      const previewCfdi = async () => {
        const res = await api.taxDocuments.previewCfdi(form)
        window.open(res, '_blank');
      }

      const downloadFiles = async() => {
        const res = await api.taxDocuments.downloadCfdi(issuedCfdi)
        window.open(res, '_blank');
        hideCfdiSuccess()
        history.replace(paths.invoices);
      }

      const close = () => {
        history.replace(paths.invoices)
      }

    return(
        <div className={'InvoiceForm wind-scene'}>

            <TopBar title="Proyectos"/>

            <SceneTitle className='title'>Facturación</SceneTitle>

            <SimpleCard title="Datos del Emisor">
                <IssuerData bindSimple={bindSimple} form={form} setForm={setForm}/>
            </SimpleCard>

            <SimpleCard title="Datos del Receptor">
                <ReceiverData bindSimple={bindSimple} form={form} setForm={setForm}/>
            </SimpleCard>

            <SimpleCard title="Datos generales">
            <InoviceGeneralData bindSimple={bindSimple} form={form} setForm={setForm}/>
            </SimpleCard>

            <SimpleCard title="Conceptos">
                {
                    _.map(form.invoiceProducts, (invoiceProduct, index) => (
                        <ProductsData
                            key={invoiceProduct.id || index}
                            invoiceProduct = {invoiceProduct}
                            onChange={handleInvoiceProductChange(index)}
                            onRemove={handleRemoveInvoiceProduct(index)}
                            onAdd={handleAddInvoiceProduct}
                            last={form.invoiceProducts.length === index+1}
                            unique={form.invoiceProducts.length === 1}
                        />

                    ))

                }
            </SimpleCard>

            <SimpleCard title="Totales">
                <TotalData form={form} setForm={setForm}/>
            </SimpleCard>

            <Button outline={true} onClick={previewCfdi}>Vista Previa</Button>
            <Button onClick={emitCfdi} disabled={disableIssueBtn}>Timbrar</Button>

            {showingCfdiSuccess &&
                <Modal title={"CFDI emitido correctamente"}
                onClose={close}
                secondaryButtonAction={close}
                mainButtonAction={downloadFiles}
                mainButtonText={"Descargar archivos"}
                secondaryButtonText={"Salir"}>

                    <div className="center"><img src={iconSuccess} alt="Success"/></div>

                </Modal>
            }


        </div>
    )
}

export default InvoiceForm