
import React, { useContext, useEffect, useState, useCallback } from "react";
import _ from "lodash";
import classNames from "classnames";
import ReactEcharts from "echarts-for-react";
//import { transactionGraphSGroups } from "../../../services/modelUtils/transactionUtils"
import { ApiContext } from "../../../services/api/api-config";
import WindDatePicker from "../../formComponents/WindDatePicker/WindDatePicker";
import WindSelect from "../../formComponents/WindSelect/WindSelect";
import moment from "moment";
import useFormState from "../../../hooks/useFormState";
import { moneyFormatter } from "../../../services/currencyUtils";

import logoEarnings from "../../../assets/images/icon-earnings.png";
import logoExpenses from "../../../assets/images/icon-expenses.png";
import "./MoneyFlow.scss"

const taxDocumentsSGroups = [
    'tax_document_read_total',
    'tax_document_read_is_ignored',
    'tax_document_read_issued_at',
    'tax_document_read_is_issuer',
    'tax_document_read_status',
    'tax_document_read_document_type'
]

const initialData = {
    incomes: [],
    expenses: [],
    balances: [],
    dates: []
}

const temporalities =[ 
    {label: "Meses", id: "months"},
    {label: "Años", id:"years"}
]

const MoneyFlow = () => {
    const fromDate = moment().subtract(12,'month').toDate();
    const [ to, setTo ] = useState( new Date() );
    const [ from, setFrom ] = useState( fromDate );
    const [visualization, setVisualization] = useState(temporalities[0])

    const [monthlyData, setMonthlyData] = useState(initialData);
    const [yearlyData, setYearlyData] = useState(initialData);

    const { form, setForm, bindSimple } = useFormState( { from, to } );

    const api = useContext(ApiContext);

    const [data, setData] = useState(initialData);

    const isIncome = (invoice) => invoice.documentType === 'I' && invoice.isIssuer === true && invoice.status==="ACTIVE"
    const isOutcome = (invoice) => ((invoice.documentType === 'I' && invoice.isIssuer === false) 
        || (invoice.documentType === 'E' || invoice.documentType === 'N'))
        && invoice.status==="ACTIVE"


        const createData = useCallback((taxDocuments) => {
            let monthlyIncomes = [];
            let monthlyExpenses = [];
            let monthlyBalances = [];
            let monthlyFormatedDates = [];
        
            let yearlyIncomes = [];
            let yearlyExpenses = [];
            let yearlyBalances = [];
            let yearlyFormatedDates = [];
        
            const monthlyAggregatedData = {};
            const yearlyAggregatedData = {};
        
            // Get current date
            const currentDate = moment();
            const currentMonth = currentDate.format("YYYY-MM");
            const currentYear = currentDate.format("YYYY");
            const firstMonth = moment.min(taxDocuments.map(invoice => moment(invoice.issuedAt)));
            
            //Init data in zeros            
            for (let m = moment(firstMonth); m.isSameOrBefore(currentMonth, 'month'); m.add(1, 'months')) {
                const monthPeriod = m.format("YYYY-MM");                
                monthlyAggregatedData[monthPeriod] = { incomes: 0, expenses: 0 };
            }

            for (let y = firstMonth.year(); y <= currentYear; y++) {
                const yearPeriod = `${y}`;
                yearlyAggregatedData[yearPeriod] = { incomes: 0, expenses: 0 };
            }

            // Aggregate data based on issuedAt date in taxDocuments
            taxDocuments.forEach(invoice => {
                const monthPeriod = moment(invoice.issuedAt).format("YYYY-MM");
                const yearPeriod = moment(invoice.issuedAt).format("YYYY");
        
                if (isIncome(invoice)) {
                    monthlyAggregatedData[monthPeriod].incomes += parseFloat(invoice.total || 0);
                    yearlyAggregatedData[yearPeriod].incomes += parseFloat(invoice.total || 0);
                }
        
                if (isOutcome(invoice)) {
                    monthlyAggregatedData[monthPeriod].expenses += parseFloat(invoice.total || 0);
                    yearlyAggregatedData[yearPeriod].expenses += parseFloat(invoice.total || 0);
                }
            });
        
            // Sort the keys of monthlyAggregatedData and yearlyAggregatedData
            const sortedMonthlyKeys = Object.keys(monthlyAggregatedData).sort();
            const sortedYearlyKeys = Object.keys(yearlyAggregatedData).sort();
        
            // Populate the arrays with monthly aggregated data
            sortedMonthlyKeys.forEach(period => {
                monthlyFormatedDates.push(moment(period, "YYYY-MM").format("MMM YYYY"));
                monthlyIncomes.push(monthlyAggregatedData[period].incomes);
                monthlyExpenses.push(monthlyAggregatedData[period].expenses);
                monthlyBalances.push(monthlyAggregatedData[period].incomes - monthlyAggregatedData[period].expenses);
            });
        
            // Populate the arrays with yearly aggregated data
            sortedYearlyKeys.forEach(period => {
                yearlyFormatedDates.push(moment(period, "YYYY").format("YYYY"));
                yearlyIncomes.push(yearlyAggregatedData[period].incomes);
                yearlyExpenses.push(yearlyAggregatedData[period].expenses);
                yearlyBalances.push(yearlyAggregatedData[period].incomes - yearlyAggregatedData[period].expenses);
            });
        
            const sumMonthlyEarnings = _.sum(monthlyIncomes);
            const sumMonthlyExpenses = _.sum(monthlyExpenses);
        
            const sumYearlyEarnings = _.sum(yearlyIncomes);
            const sumYearlyExpenses = _.sum(yearlyExpenses);
        
            let monthlyDiff = sumMonthlyEarnings - sumMonthlyExpenses;
            let monthlyDiffPer = Math.round(sumMonthlyExpenses * 100 / (sumMonthlyEarnings === 0 ? sumMonthlyExpenses : sumMonthlyEarnings)) - 100;
            if (monthlyDiffPer < 0) monthlyDiffPer = monthlyDiffPer * -1;
        
            let yearlyDiff = sumYearlyEarnings - sumYearlyExpenses;
            let yearlyDiffPer = Math.round(sumYearlyExpenses * 100 / (sumYearlyEarnings === 0 ? sumYearlyExpenses : sumYearlyEarnings)) - 100;
            if (yearlyDiffPer < 0) yearlyDiffPer = yearlyDiffPer * -1;
        
            return {
                monthly: {
                    dates: monthlyFormatedDates,
                    incomes: monthlyIncomes,
                    expenses: monthlyExpenses,
                    balances: monthlyBalances,
                    sumExpenses: sumMonthlyExpenses,
                    sumEarnings: sumMonthlyEarnings,
                    diff: monthlyDiff,
                    diffPer: monthlyDiffPer
                },
                yearly: {
                    dates: yearlyFormatedDates,
                    incomes: yearlyIncomes,
                    expenses: yearlyExpenses,
                    balances: yearlyBalances,
                    sumExpenses: sumYearlyExpenses,
                    sumEarnings: sumYearlyEarnings,
                    diff: yearlyDiff,
                    diffPer: yearlyDiffPer
                }
            };
        }, []);
        
        
    

    // load transactions data
    useEffect(() => {
        async function fetchTaxDocuments(){
            const taxDocuments = await api.taxDocuments.get({params: {
                sGroups: taxDocumentsSGroups,
                'issuedAt[after]': from,
                'issuedAt[before]': to,
                'status': 'ACTIVE',
                'isIgnored': false,
                'pagination': false
            }})
            const dataset = createData(taxDocuments ?? [])    
            setMonthlyData(dataset.monthly)
            setYearlyData(dataset.yearly)

            visualization.value === "years" ? setData(dataset.yearly) : setData(dataset.monthly)
        } 

        fetchTaxDocuments()
    }, [api, createData, from, to, visualization]);

    const { incomes, expenses, balances, dates, sumExpenses, sumEarnings, diff, diffPer } = data;

    const options = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        xAxis: [
          {
            type: 'category',
            data: dates,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
                formatter: function (value) {
                    return   moneyFormatter(value).replace(".00","") + " MXN"
                }
            }
          }
        ],
        series: [
          {
            name: 'Ingresos',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return moneyFormatter(value) + ' MXN';
              }
            },
            itemStyle: {
                color: '#A1DD70' 
            },
            data: incomes
          },
          {
            name: 'Egresos',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return moneyFormatter(value) + ' MXN';
              }
            },
            itemStyle: {
                color: '#EE4E4E'  
            },
            data: expenses
          },
          {
            name: 'Balance',
            type: 'line',
            tooltip: {
              valueFormatter: function (value) {
                return moneyFormatter(value) + ' MXN';
              }
            },
            itemStyle: {
                color: '#4D96FF'  
            },
            data: balances
          }
        ]
      };


    const handleTo = ( param ) => {
        setTo( param );
        setForm({ to: param, from });
        form.submit = null;
    }

    const handleFrom = ( param ) => {
        setFrom( param );
        setForm({ from: param, to });
    }

    const handleVisualizationChange = (param) =>{
        setVisualization(param)
        param.value === "years" ? setData(yearlyData) : setData(monthlyData)
    }


    return (
        <div className="MoneyFlow">
            <div className="money-flow-header">
                <div className="single-title">Earnings</div>
                <div className="money-flow-filters">
                    <WindSelect 
                        label={"Temporalidad"}
                        name="period" 
                        options={temporalities.map(item => ({label: item.label, value: item.id}))}
                        onChange={handleVisualizationChange}
                        value={visualization}
                    />
                    <WindDatePicker label="Desde" {...bindSimple('from')} onChange={handleFrom} />
                    <WindDatePicker label="Hasta" {...bindSimple('to')} onChange={handleTo} />
                </div>
            </div>
            
            <div className="money-flow-header">
                <div className="money-flow-summary">
                    <div>
                        <div><img src={logoEarnings} alt="" /></div>
                        <div className="money-flow-summary-data">
                            <div className="summary-title">Earnings</div>
                            <div className="summary-value">$ {moneyFormatter(sumEarnings)}</div>
                        </div>
                    </div>
                    <div>
                        <div><img src={logoExpenses} alt="" /></div>
                        <div className="money-flow-summary-data">
                            <div className="summary-title">Expenses</div>
                            <div className="summary-value">$ {moneyFormatter(sumExpenses)}</div>
                        </div>
                    </div>
                </div>
                <div className="money-flow-diff">
                    <div className="diff-dates">
                        {from.toLocaleDateString()} - {to.toLocaleDateString()}
                    </div>
                    <div className="diff-diff">
                        <div className="diff-money">
                            $ {moneyFormatter(diff)}
                        </div>
                        <div className={classNames("diff-percentage", ( diff<0? "danger": "success" ) )}>
                            {diff<0?"-":"+"}{diffPer}%
                        </div>
                    </div>
                </div>
            </div>

            <ReactEcharts
                option={options}
                style={{ height: "400px", width: "100%"}}
            />
        </div>
        
    );
}
export default MoneyFlow;
