import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import './QuoteDetail.scss';
import TopBar from "../../../components/utility/TopBar/TopBar";
import SceneTitle from "../../../components/layout/SceneTitle/SceneTitle";
import {getIvaAmount, getQuoteSubtotal, quoteDetailSGroups, quoteReviewDetails, quoteStatus, quoteStatusOptions, quoteStatusTrans} from "../../../services/modelUtils/quoteUtils";
import {ApiContext} from "../../../services/api/api-config";
import {Link, useHistory, useParams} from "react-router-dom";
import InfoPair from "../../../components/layout/InfoPair/InfoPair";
import moment from "moment";
import Switch from "../../../components/utility/Switch/Switch";
import QuoteProduct from "./components/QuoteProduct/QuoteProduct";
import useBoolean from "../../../hooks/useBoolean";
import {
    faTrashCan,
    faPen,
    faFilePdf,
    faFileCertificate,
    faCheck,
    faHandHoldingUsd,
    faShieldKeyhole,
    faTimes, faClone
} from "@fortawesome/pro-light-svg-icons";
import Modal from "../../../components/utility/Modal/Modal";
import {paths} from "../../../services/routes/appRoutes";
import {getNotifier} from "../../../services/notifier";
import TransactionFormModal from '../../../components/formComponents/TransactionFormModal/TransactionFormModal';
import AclPermissionsModal from "../../../components/crmComponents/AclPermissionsModal/AclPermissionsModal";
import {displayDate} from "../../../services/jsUtils";
import WindSelect from '../../../components/formComponents/WindSelect/WindSelect';
import QuoteTools from './components/QuoteTools/QuoteTools';
import Button from '../../../components/utility/Button/Button';
import { SecurityContext } from '../../../services/SecurityManager';

const QuoteDetail = (factory, deps) => {

    const api = useContext(ApiContext);
    const history = useHistory();
    const { id } = useParams();

    const security = useContext(SecurityContext);

    const [ showTransactionForm, transactionFormOn, transactionFormOff ] = useBoolean(false);
    const [ showConfirmationIvaModal, confirmationIvaModalOn, confirmationIvaModalOff ] = useBoolean(false);

    // ----- Load quote from server -----
    const [quote, setQuote] = useState();
    useEffect(()=>{
        api.quotes.get({ id, params:{sGroups: quoteDetailSGroups}, customProp:'_' }).then(setQuote);
    },[api, id]);

     // ----- Change quote status -----
    const handleChangeStatus = useCallback((statusOption) => {
        const status = statusOption.value;

        api.quotes.update({
            id,
            params: { status, sGroups: quoteDetailSGroups },
        }).then((quote) => {
            setQuote(quote);
            getNotifier().success('El estado de la cotización se actualizó correctamente 🎉');
        })
        .catch(() => { getNotifier().error('Hubo un error al actualizar el estado de la cotización'); }
        );
    }, [api, id]);
    
    // ---- Delete quote -----
    const [isDeleting, startDeleting, stopDeleting] = useBoolean();
    const handleDelete = useCallback(()=>{
        api.quotes.delete({ id:quote.id })
            .then(()=>{
                stopDeleting();
                history.replace(paths.projectDetail.replace(':id', quote.project.id));
                getNotifier().success('La cotización ha sido eliminada 🗑');
            });
    },[api, history, quote, stopDeleting]);

    const handleEdit = useCallback(() => {
        history.push( paths.quoteEdit.replace(':id', id) );
    }, [history, id]);
    const handleClone = useCallback(() => {
        history.push( paths.quoteClone.replace(':id', id) );
    }, [history, id]);

    const downloadPdf = useCallback(()=>{
        if(quote)
            window.location.href = api.quotes.getDownloadPdfUrl(quote);
    }, [quote, api]);

    // ---- Approve quote -----
    const [isApproving, startApproving, stopApproving] = useBoolean();
    const approveQuote = useCallback(()=>{
        if (quote) {
            if(quote.status === quoteStatus.APPROVED) return getNotifier().warning('La cotización ya ha sido aprobada');

            api.quotes.update({ id:quote.id, params:{ status: quoteStatus.APPROVED, sGroups: quoteDetailSGroups } })
                .then((newQuote)=>{
                    getNotifier().success('La cotización ha sido aprobada 🎉');
                    setQuote(newQuote);
                    stopApproving();
                });
        }
    }, [api, quote, setQuote, stopApproving]);

    // ---- Reject quote -----
    const [isRejecting, startRejecting, stopRejecting] = useBoolean();
    const rejectQuote = useCallback(()=>{
        if (quote) {
            if(quote.status === quoteStatus.REJECTED) return getNotifier().warning('La cotización ya ha sido rechazada');

            api.quotes.update({ id:quote.id, params:{ status: quoteStatus.REJECTED, sGroups: quoteDetailSGroups } })
                .then((newQuote)=>{
                    getNotifier().success('La cotización ha sido rechazada 🎉');
                    setQuote(newQuote);
                    stopRejecting();
                });
        }
    }, [api, quote, setQuote, stopRejecting]);

    const createInvoice = useCallback(() => {
        history.push({
            pathname: paths.invoicesForm,
            state: { quote },
        });
    }, [history, quote])

    // Acl Permissions Modal Config
    const [ aclProjectPermissionsModal, aclProjectPermissionsModalOn, aclProjectPermissionsModalOff ] = useBoolean(false);

    // -- Toolbar --
    const tools = useMemo(() => {
        const baseTools = [
            { icon: faCheck, callback: startApproving, text: 'Aprobar', disabled: quote && quote.status === quoteStatus.APPROVED, color: "success" },
            { icon: faTimes, callback: startRejecting, text: 'Rechazar', disabled: quote && quote.status === quoteStatus.REJECTED, color: "danger" },
            { icon: faTrashCan, callback: startDeleting, text: "" },
            {
                options: [
                    { icon: faClone, callback: handleClone, text: "Clonar cotización" },
                    { icon: faShieldKeyhole, callback: aclProjectPermissionsModalOn, text: "Permisos de acceso" },
                    security.canAdminQuoteTransactions() ? { icon: faHandHoldingUsd, callback: () => transactionFormOn(), text: "Agregar nuevo pago" } : null,
                    quote?.status === quoteStatus.APPROVED ? { icon: faFileCertificate, callback: createInvoice, text: "Facturar" }: null,
                    { icon: faFilePdf, callback: downloadPdf, text: "Descargar PDF" },
                ].filter(option => option !== null)
            }
        ];
    
        return baseTools;
    }, [startDeleting, handleClone, downloadPdf, quote, startApproving, transactionFormOn, aclProjectPermissionsModalOn, startRejecting, security, createInvoice]);

    // -- toggle IVA
    const toggleIva = useCallback(() => {
        if (quote) {
            quote.iva = !quote.iva;
            
            const iva = quote.iva;
            const subtotal = getQuoteSubtotal(quote);
            const total = subtotal + getIvaAmount(quote, subtotal);

            api.quotes.update({ id:quote.id, params:{ iva, subtotal: subtotal.toString(), total: total.toString(), sGroups: quoteDetailSGroups } })
                .then((newQuote)=>{
                    getNotifier().success('El IVA se actualizó correctamente 🎉');
                    setQuote(newQuote);
                    confirmationIvaModalOff();
                }
            );
        }
    }, [api, quote, setQuote, confirmationIvaModalOff]);
    return (
        <div className={"QuoteDetail wind-scene"}>

            <TopBar title="Cotización" titleLinkBack/>

            <div className='quote-header' >
                <SceneTitle className="quote-title" secondary>{quote?.title}</SceneTitle>
                <div className="quote-status-select">
                    <WindSelect
                        value={{value: quote?.status||'', label: quoteStatusTrans[quote?.status]}}
                        options={quoteStatusOptions}
                        onChange={handleChangeStatus}
                        placeholder= {quoteStatusTrans[quote?.status]}
                        noLabel={true}
                    />
                </div>
            </div>
            
            <QuoteTools quote={quote} tools={tools} />
            
            <div className="info-header">
                <div className="single-title">Información general</div>
                <div>
                    <Button onClick={handleEdit} outline leftIcon={faPen}>Editar</Button>
                </div>
            </div>
            
            <Link to={paths.clientDetail.replace(":id", quote?.project?.client?.id)} data-tooltip={"Ver perfil del cliente"}>
                <InfoPair value={quote?.project?.client?.fullName} title='Cliente' />
            </Link>
            <Link to={paths.projectDetail.replace(":id", quote?.project?.id)} data-tooltip={"Ver detalle del proyecto"}>
                <InfoPair value={quote?.project?.name} title='Proyecto' />
            </Link>
            <InfoPair value={quote?.folio} title='Cotización' />
            <InfoPair value={quote?.quotationRequest?.folio||'NA'} title='Solicitud' />
            <InfoPair value={quote?.expirationDate?displayDate(quote?.expirationDate):'NA'} title='Expiración' />
            <InfoPair value={quote?.createdBy?.fullName||'Sin información'} title='Creado por' />
            <InfoPair value={<Switch value={quote?.iva} onChange={confirmationIvaModalOn} />} title='IVA' />
            
            
            {quoteReviewDetails[quote?.status] &&
                <div className='info-blocks'>
                    <InfoPair value={quote?.reviewedBy?.fullName} title={quoteReviewDetails[quote?.status].title} />
                    <InfoPair value={quote?.reviewedDate && moment(quote.reviewedDate).format('DD/MM/YYYY')} title={quoteReviewDetails[quote?.status].dateTitle} />
                    
                    <div />
                    <div />
                </div>
            }

            <div className="single-title product-divider">Productos</div>
            {quote?.quoteProducts?.map( product=> <QuoteProduct key={product.id} quoteProduct={product}/> )}
            

            {/* ----- Deleting Modal ----- */}
            {isDeleting && <Modal
                title='Eliminar cotización'
                mainButtonAction={handleDelete}
                mainButtonColor={'danger'}
                mainButtonText={'Eliminar'}
                secondaryButtonAction={stopDeleting}
                onClose={stopDeleting}
            >
                <p>¿ Estás seguro que quieres eliminar la cotización <strong>{quote.title} - {quote.folio}</strong> ?</p>
            </Modal>}

            {/* ----- Approving Modal ----- */}
            {isApproving && <Modal
                title='Aprobar cotización'
                mainButtonAction={approveQuote}
                mainButtonColor={'success'}
                mainButtonText={'Aprobar'}
                secondaryButtonAction={stopApproving}
                onClose={stopApproving}
            >
                <p>¿ Estás seguro que quieres aprobar la cotización <strong>{quote.title} - {quote.folio}</strong> ?</p>
            </Modal>}

            {/* ----- Rejecting Modal ----- */}
            {isRejecting && <Modal
                title='Rechazar cotización'
                mainButtonAction={rejectQuote}
                mainButtonColor={'success'}
                mainButtonText={'Rechazar'}
                secondaryButtonAction={stopRejecting}
                onClose={stopRejecting}
            >
                <p>¿ Estás seguro que quieres rechazar la cotización <strong>{quote.title} - {quote.folio}</strong> ?</p>
            </Modal>}

            {/* ----- Transaction Form ----- */}
            {showTransactionForm && (
                <TransactionFormModal
                    onClose={transactionFormOff}
                    quotes={[quote]}
                    client={quote?.project?.client}
                />
            )}


            { aclProjectPermissionsModal && quote && (
                <AclPermissionsModal
                    entity='quote'
                    entityObject={quote}
                    onClose={aclProjectPermissionsModalOff}
                />
            )}

            {/* ----- Show IVA Modal ----- */}
            { showConfirmationIvaModal && (
                <Modal
                    title='IVA'
                    mainButtonAction={toggleIva}
                    mainButtonColor={'success'}
                    mainButtonText={'Guardar'}
                    secondaryButtonAction={confirmationIvaModalOff}
                    onClose={confirmationIvaModalOff}
                >
                    <p>¿ Estás seguro que quieres <strong>{ quote.iva ? 'quitar' : 'agregar' }</strong> el IVA de la cotización <strong>{quote.title} - {quote.folio}</strong> ?</p>
                </Modal>
            )}
        </div>
    );
};

export default QuoteDetail;
