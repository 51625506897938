import React from 'react';
import './DropDownMenuItem.scss';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { faSort } from '@fortawesome/pro-duotone-svg-icons';

const DropDownMenuItem = ({ options, value, onChange }) => {
  return (
      <Select
        className={classNames('DropDownMenuItem__select', 'tide-react-select')}
        classNamePrefix='tide-react-select'
        isSearchable={false}
        value={value}
        onChange={onChange}
        options={options}
        placeholder={false}
        components={components}
      />
  );
}
 
export default DropDownMenuItem;

const components = {
  DropdownIndicator: (props) =>
      <button className='DropdownIndicator'>
        <FontAwesomeIcon className={classNames('caret', props?.selectProps?.menuIsOpen && 'open')} icon={faSort} />
      </button>
}