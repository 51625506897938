import React from 'react';
import './TideToaster.scss';
import _ from 'lodash';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faTriangleExclamation, faCircleXmark, faCircleInfo } from '@fortawesome/pro-light-svg-icons';

class TideToaster extends React.Component {

    constructor(props) {
        super(props);
        this.state = { messages: [] };
    }

    clearMessage = (id) => {
        const index = _.findIndex(this.state.messages, (msg) => msg.id === id);
        if (index === -1)
            return;
        const messages = [...this.state.messages];
        messages.splice(index, 1);
        this.setState({ messages });
    };

    showMessage = (content, config) => {

        const message = { content, ...config, id: Math.random() };
        this.setState({
            messages: [...this.state.messages, message]
        });
        setTimeout(() => {
            this.clearMessage(message.id);
        }, Number(config.duration) || 6000);

        return message;
    };

    error = (content, config = {}) => {
        this.showMessage(content, { type: 'error', ...config });
    };
    warning = (content, config = {}) => {
        this.showMessage(content, { type: 'warning', ...config });
    };

    success = (content, config = {}) => {
        this.showMessage(content, { type: 'success', ...config });
    };

    info = (content, config = {}) => {
        this.showMessage(content, { type: 'info', ...config });
    };

    getIconForType = (type)=>{
        if(type==='success'){
            return <div className='icon'><FontAwesomeIcon icon={faCircleCheck} /></div>
        }
        if(type==='warning'){
            return <div className='icon'><FontAwesomeIcon icon={faTriangleExclamation} /></div>
        }
        if(type==='error'){
            return <div className='icon'><FontAwesomeIcon icon={faCircleXmark} /></div>
        }
        if(type==='info'){
            return <div className='icon'><FontAwesomeIcon icon={faCircleInfo} /></div>
        }
        return null;
    }

    render() {

        const { messages } = this.state;

        return (
            <div className={"TideToaster"}>
                {messages.map( message=> {
                    const Icon = this.getIconForType(message.type);
                    return (
                        <div key={message.id}
                            className={classNames('message-bar', message.className, message.type )} >
                            {Icon}
                            <div className='content'>
                                {message.content}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default TideToaster;
