import React, { useEffect, useRef, useCallback } from 'react';
import './QuoteProductForm.scss';
import WindInput from '../../../../../components/formComponents/WindInput/WindInput';
import WindSwitch from '../../../../../components/formComponents/WindSwitch/WindSwitch';
import RichTextEditor from '../../../../../components/formComponents/RichTextEditor/RichTextEditor';
import { faXmarkCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import Modal from '../../../../../components/utility/Modal/Modal';
import useBoolean from '../../../../../hooks/useBoolean';
import Button from '../../../../../components/utility/Button/Button';
import useCallbackCreator from 'use-callback-creator';

const QuoteProductForm = ({ quoteProduct, onChange, onRemove, handleAddProduct, unique, last }) => {
  const richTextEditor = useRef();
  const [ modalDelete, showModalDelete, hideModalDelete ] = useBoolean(false);

  const handleChange = useCallbackCreator((name, value) => {
    onChange({ ...quoteProduct, [name]: value });
  }, [onChange, quoteProduct]);

  const setTextEditorReference = useCallback((newTextEditor) => {
    richTextEditor.current = newTextEditor;
    quoteProduct.getDescription = newTextEditor?.getContentHTML;
  },[quoteProduct]);

  useEffect(() => {
    quoteProduct.getDescription = richTextEditor.current.getContentHTML;
  }, [quoteProduct]);

  const handleProductRemove = useCallback(() => {
    onRemove();
  }, [onRemove]);
  
  return (
    <div className={'QuoteProductForm'}>
      <div className="product-form-header">
        <div className="single-title">Productos </div>
        <div className="product-buttons-header">
            {
              !unique && <Button onClick={showModalDelete} color="danger" outline={true} leftIcon={faXmarkCircle}>Eliminar producto</Button>
            }

            {
              last && <Button onClick={handleAddProduct} color="info" outline={true} leftIcon={faPlusCircle}>Agregar producto</Button>
            }
        </div> 
        
      </div>
      
      <div className='fields'>
        <WindInput
          className='project-field width-50'
          placeholder={"Nombre del producto"}
          onChange={handleChange('name')}
          value={quoteProduct?.name}
        />
      </div>
      <div className="fields" >
        <WindInput
          className='project-field width-25'
          placeholder={"Cantidad"}
          value={quoteProduct?.quantity}
          onChange={handleChange('quantity')}
          type={'number'}
        />
        <WindInput
          className='project-field width-25'
          placeholder={"Precio"}
          type={'number'}
          value={quoteProduct?.price}
          onChange={handleChange('price')}
        />
        <WindSwitch
          className='project-field'
          placeholder={'Opcional'}
          value={quoteProduct?.isOptional}
          onChange={handleChange('isOptional')}
        />
        
      </div>
      <div className='description width-50'>
        <RichTextEditor
          label="Descripción"
          ref={setTextEditorReference}
          html={quoteProduct?.description}
          placeholder={'Agrega una descripción ...'}
        />
      </div>
      

      { modalDelete && 
        <Modal
            mainButtonAction={handleProductRemove}
            mainButtonText="Eliminar producto"
            secondaryButtonAction={hideModalDelete}
            secondaryButtonText="Cancelar"
          >
          <div className="modal-delete">
            <div className="single-title">&iquest;Seguro que desea eliminar el producto?</div>
            <div>Los cambios que se han hecho no serán guardados</div>
          </div>
        </Modal> 
      }

    </div>
  );
}
 
export default QuoteProductForm;
