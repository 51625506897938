import { faCheck, faEye, faMinusSquare } from "@fortawesome/pro-light-svg-icons";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { moneyFormatter } from "../currencyUtils";
import { copyPropertyIdOrNull, copyPropertyOrThrow } from "../formUtils";

export const bankStatementStatusTrans = {
  PENDING: "Pendiente",
  CONFIRMED: "Confirmado",
  DENIED: "Denegado",
};

export const bankStatementStatusIcons = {
  PENDING: {
    icon: faEye,
    color: "info",
  },
  CONFIRMED: {
    icon: faCheck,
    color: "success",
  },
  DENIED: {
    icon: faMinusSquare,
    color: "danger",
  },
};

export const bankStatementStatus = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  DENIED: 'DENIED',
};

export const bankStatementStatusOptions = _.map(bankStatementStatus, (value) => {
  return { value: value, label: bankStatementStatusTrans[value] };
});

export const prepareAccountStatementForServer = ( form ) => {
  let accountStatement = {};

  copyPropertyOrThrow( form, accountStatement, "periodStartDate", "Por favor selecciona el inicio del periodo" );
  copyPropertyOrThrow( form, accountStatement, "periodEndDate", "Por favor selecciona el fin del periodo" );
  copyPropertyIdOrNull( form, accountStatement, "bankAccount", "Por favor selecciona una cuenta de banco" );

  accountStatement.status = form.status.value||null;

  return accountStatement;
};

export const accountStatementsListSGroups = [
  "account_statement_read",
  "account_statement_read_total_transactions",
];

export const accountStatementDetailSGroups = [
  "account_statement_read",
  "account_statement_read_total_transactions",
  "account_statement_read_bank_transactions",
    "bank_transaction_read",
    "bank_transaction_read_transaction",
    "transaction_read",
  "account_statement_read_app_file",
    "app_file_read",
];

export const accountStatementsTableColumns = [
  { Header:"Cuenta", accessor: as => `${as.bankAccount ? as.bankAccount.name : 'Sin cuenta asociada'}` },
  { Header:"Periodo", accessor: as => `${moment(as.periodStartDate).format('DD/MM/YYYY')} - ${moment(as.periodEndDate).format('DD/MM/YYYY')}` },
  { Header: "Transacciones", accessor: 'totalTransactions' },
  { Header: "Ingresos", accessor: as => <span>${moneyFormatter(as.totalIncome)}</span> },
  { Header: "Egresos", accessor: as => <span>${moneyFormatter(as.totalExpense)}</span> },
  { Header: "Estado", accessor: as =>
    <span
      className={`badge badge-${bankStatementStatusIcons[as.status] ? bankStatementStatusIcons[as.status].color : 'info'}`}
    >
      <FontAwesomeIcon icon={bankStatementStatusIcons[as.status] ? bankStatementStatusIcons[as.status].icon : faEye} />
    </span>
  },
]; 
