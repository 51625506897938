import React, { useLayoutEffect, useRef} from 'react';
import './SearchBar.scss';
import _ from 'lodash';
import AdvancedLocalFilters from "./components/AdvancedLocalFilters/AdvancedLocalFilters";

/**
 * The search bar has two modes.
 * Local filter mode. Used by the parent by the control props, requires de filtersConfig prop
 * Global filter mode. It searches throughout all the system, it requires no props since the results will link to the corresponding page
 *
 * @param onClose Callback to close the search bar
 * @param filtersConfig { placeholder: string, filters: [ { field: string, main: bool, type: string, label: string } ] }
 * @param filters { field: any } The local filters value to control the component
 * @param onFiltersChange Filter change callback to control the component
 * @returns function Component
 * @constructor
 */
const SearchBar = ({
                       
                       //For local filtering
                       filtersConfig={},
                       filters={},
                       onFiltersChange, title,
                       labels=true,
                       more=true,
                       longFields=false
                   }) => {

    //Focus on open
    const mainInputRef = useRef();
    useLayoutEffect(()=>mainInputRef.current?.focus(), []);

    //Is any filter active
    // const isActive = !!_.find(filters);

    const cleanFilters = (filters)=>{
        let newFilters = {};

        _.forEach(filters, (value, key)=>{
            if(value === '' || typeof value === 'undefined' || value === null || value === 'null')
                return;
            newFilters[key] = value;
        });

        onFiltersChange(newFilters);
    }

    return (
        <div className={"SearchBar"}>
            <AdvancedLocalFilters 
                filters={filters} 
                onFiltersChange={cleanFilters} 
                filtersConfig={filtersConfig} 
                labels={labels}
                more={more}
                title={title}
                longfield={longFields} />
            { /*
            <button className='clear-button' data-tooltip={isActive?"Limpiar filtros":undefined} onClick={()=>onFiltersChange({})}>
                <FontAwesomeIcon icon={isActive? faTimes : faMagnifyingGlass} className='clear-icon' />
            </button>
            */ }
        </div>);
};

export default SearchBar;

