import React from 'react';
import { Editor } from '@tiptap/core'
import './RichTextEditor.scss';
import { EditorContent } from '@tiptap/react';
import MenuBar from './components/MenuBar/MenuBar';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import Image from '@tiptap/extension-image';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import TextStyle from '@tiptap/extension-text-style';
import FontFamily from '@tiptap/extension-font-family';
import Placeholder from '@tiptap/extension-placeholder';

import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'

// load all highlight.js languages
import lowlight from 'lowlight';

class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.editor = this.initializeEditor();
    this.editorCanvas = React.createRef();
    this.getContentHTML = this.getContentHTML.bind(this);
  }

  getContentHTML() {
    return this.editor.getHTML();
  }

  initializeEditor() {
    return new Editor({
      editable: !this.props.readonly,
      extensions: [
        StarterKit.configure({
          codeBlock: false,
          heading: {
            levels: [1, 2, 3],
          },
        }),
        Underline,
        Image,
        TextStyle,
        FontFamily,
        Link.configure({
          openOnClick: false,
        }),
        TextAlign.configure({
          types: ["heading", "paragraph"]
        }),
        CodeBlockLowlight.configure({
          lowlight,
        }),
        Placeholder.configure({
          placeholder: this.props.placeholder,
        }),
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
      ],
      content: this.props.html,
    });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.html !== prevProps.html) {
      this.editor.commands.setContent(this.props.html);
    }
  }

  render() {
    const editor = this.editor;

    if (!editor) return null;
    editor.element = this.editorCanvas.current;

    return (
      <div className={"RichTextEditor"}>
        { this.props.label? <div>{this.props.label}</div>: null }
        {!this.props.readonly && 
          <MenuBar editor={editor} />}
        <EditorContent
          ref={this.editorCanvas}
          className="editor-content"
          data-test-id="rich-text-editor"
          editor={editor}
        />
      </div>
    );
  };
}
 
export default RichTextEditor;
