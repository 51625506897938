
import React from 'react'
import TopBar from '../../../components/utility/TopBar/TopBar';
import './BelvoConnect.scss';
import { TransactionsList } from './components/TransactionsList/TransactionsList';
import { useParams } from 'react-router-dom';

const BelvoConnect = () => {
    const params = useParams();
    
    return (
        <div className={"BelvoConnect wind-scene"}>

            <TopBar title="Transacciones" titleLinkBack />

            <div className='center-container'>
                {params && <TransactionsList bankAccountId={params.bankAccount} />}
            </div>
        </div>
    );
}

export default BelvoConnect;