import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import './FileContainer.scss';
import FileIcon from "./components/FileIcon/FileIcon";
import useBoolean from "../../../hooks/useBoolean";
import classNames from "classnames";
import {ApiContext} from "../../../services/api/api-config";
import {fileContainerViewerSGroups} from "../../../services/modelUtils/fileContainerUtils";
import useDragNDropEvents from "./useDragNDropEvents";
import FileDetailsModal from "./components/FileDetailsModal/FileDetailsModal";
import {getNotifier} from "../../../services/notifier";
import _ from 'lodash';

/**
 *
 * @param fileContainer A FileContainer entity
 * @returns {JSX.Element}
 * @constructor
 */
const FileContainer = ({ fileContainer } ) => {

    // ----- Load file container info -----
    const api = useContext(ApiContext);
    const [filesData, setFilesData ] = useState();
    useEffect(()=>{
        if(!fileContainer) return;
        api.fileContainers.get({ id:fileContainer.id+'', params:{sGroups: fileContainerViewerSGroups } })
            .then(setFilesData);
    },[api, fileContainer]);

    // ----- Handle file uploading -----
    const handleNewFile = useCallback(( fileList=[] )=>{
        if(!fileContainer) return;
        api.fileContainers.update({id: fileContainer.id, params:{sGroups: fileContainerViewerSGroups}, files: [...fileList]})
            .then(setFilesData);
    },[api, fileContainer]);

    // ----- Handle file dragging -----
    const [isFileOver, setFileOver, cancelFileOver] = useBoolean();
    const containerRef = useRef();
    const dragHintRef = useRef();

    const handleDrop = useCallback((e)=>{
        const fileList = e.dataTransfer?.files;
        if( fileList?.length )
            handleNewFile(e.dataTransfer.files);
    },[handleNewFile]);

    // Drag n drop events
    useDragNDropEvents({
        containerRef,
        dragHintRef,
        dragEnterCallback: setFileOver,
        dragLeaveCallback: cancelFileOver,
        dropCallback: handleDrop
    });

    // ----- Handle file details -----
    const [fileDetails, setFileDetails] = useState();
    const closeDetails = useCallback(()=>setFileDetails(null), []);

    // ----- Handle file deletion -----
    const handleDelete = useCallback((appFile)=>{
        closeDetails();
        const appFiles = _.filter(filesData?.appFiles?.map(file=>file.id), id=>id !== appFile.id );
        if(!appFiles)
            return;

        api.fileContainers.update({id: filesData.id, params:{ appFiles, sGroups: fileContainerViewerSGroups} })
            .then( ( newData )=>{
                setFilesData(newData);
                getNotifier().success('El archivo fue eliminado')
            });
    },[api, closeDetails, filesData]);

    return (
        <div className={"FileContainer"} ref={containerRef}>

            {filesData?.appFiles?.map( appFile=>
                <FileIcon key={appFile.id} appFile={appFile} onClick={setFileDetails} />
            )}

            <FileIcon addFileIcon onAddFile={handleNewFile}/>

            <div className={classNames('drag-hint', isFileOver&&'active')} ref={dragHintRef}>
                <p className='description'>Subir archivo</p>
            </div>

            {fileDetails && <FileDetailsModal appFile={fileDetails} onClose={closeDetails} onDelete={handleDelete} /> }

        </div>
    );
};

export default FileContainer;
