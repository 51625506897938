import React, { useCallback, useState } from 'react';
import { useMemo } from 'react';
import TideReactTable from '../../../../../components/utility/TideReactTable/TideReactTable';
import useTideTable from '../../../../../components/utility/TideReactTable/useTideTable';
import { transactionsTableSGroups } from '../../../../../services/modelUtils/transactionUtils';
import './ClientTransactions.scss';
import moment from 'moment';
import { moneyFormatter } from '../../../../../services/currencyUtils';
import Button from '../../../../../components/utility/Button/Button';
import useBoolean from '../../../../../hooks/useBoolean';
import TransactionForm from './components/TransactionForm/TransactionForm';
import { faPlus, faPencil } from '@fortawesome/pro-light-svg-icons';
import { Link } from 'react-router-dom';
import { paths } from '../../../../../services/routes/appRoutes';

const ClientTransactions = ({ client, addTransaction }) => {
  const [ showTransactionForm, transactionFormOn, transactionFormOff ] = useBoolean(false);
  const [ transaction, setTransaction ] = useState({});

  const transactionsFilters = useMemo(() => { 
    let filters = { sGroups: transactionsTableSGroups };

    if (client?.id) {
      filters['quoteTransactions.client.id'] = client.id;
    }
    
    return filters;
  }, [client]);
  let table;

  const editTransaction = useCallback( ( transaction ) => {
    setTransaction( transaction );
    transactionFormOn();
  }, [ transactionFormOn, setTransaction] );

  const clientTransactionsTableColumnsTab = useMemo( () => { return [
    { Header: 'Fecha', accessor: (transaction) => moment(transaction.transactionDate).format('DD/MM/YYYY') },
    { Header: 'Cantidad', accessor: (transaction) => `$${moneyFormatter(Number(transaction.amount))}` },
    { Header: 'Cotizaciones', accessor: (transaction) => {
      return(
        <div>
          {
            Array.isArray(transaction.quoteTransactions) ?
            transaction.quoteTransactions.map( (trans, index) => 
              <span key={trans.quote.folio + "-" + index}>
                <Link to={paths.quoteDetail.replace(":id",trans.quote.id)}>{trans.quote.folio}</Link>
              </span> )
            :
            "--"
          }
        </div>
      );
    } 
    },
    { Header: 'Cuenta de pago', accessor: (transaction) => transaction.bankAccount?.name ? transaction.bankAccount?.name : 'Efectivo' },
    { Header: 'Editar', accessor: (transaction) => {
        return <Button onClick={ () => editTransaction(transaction)} outline leftIcon={faPencil} className="no-border">
          Ver / Editar
        </Button> 
      } },
]; }, [editTransaction] );

  table = useTideTable({
      entity: 'transactions',
      columns: clientTransactionsTableColumnsTab,
      requestFilters: transactionsFilters, 
  });

  return (
    <div className="info-container">
      <div className="client-tab-header">
          <div className="single-title">Pagos</div>
          <div><Button onClick={addTransaction} outline leftIcon={faPlus}>Agregar pago</Button></div>
      </div>

      <TideReactTable
          className="table-hoverable"
          {...table.tableProps} />
      
      { showTransactionForm &&
        <TransactionForm
          onClose={transactionFormOff}
          data={transaction}
          
        />
      }

    </div>
  );
}
 
export default ClientTransactions;
