import React, {useState, useCallback, useContext, useEffect, useMemo} from 'react'
import SimpleCard from '../../../../layout/SimpleCard/SimpleCard';
import WindSelect from '../../../../formComponents/WindSelect/WindSelect';
import WindInput from '../../../../formComponents/WindInput/WindInput';
import InfoPair from '../../../../layout/InfoPair/InfoPair';
import WindSwitch from '../../../../formComponents/WindSwitch/WindSwitch';
import Button from '../../../../utility/Button/Button';
import {ApiContext} from "../../../../../services/api/api-config";
import useFormState from "../../../../../hooks/useFormState";
import TideEntitySelect from '../../../../utility/TideEntitySelect/TideEntitySelect';
import _ from 'lodash';
import UserButton from '../UserButton/UserButton';
import { getNotifier } from '../../../../../services/notifier';
import {channelSGroups, convertChannelToForm} from '../../../../../services/modelUtils/channelUtils';
import './ChannelForm.scss';
import Modal from '../../../../utility/Modal/Modal';
import { useSelector } from 'react-redux';

const emptyChannel = {label: 'Crear nuevo canal', value: ''};

const ChannelForm = ({conversation, selectedChannel, reloadConversation, channels, returnToConversation}) => {
  const api = useContext(ApiContext);
  const {form, handleSimpleChange, setForm } = useFormState({channel: selectedChannel});
  const [users, setUsers] = useState([]);
  const [channel, setChannel] = useState();  
  const [confirmDelete, setConfirmDelete] = useState();

  // delete channel
  const handleDeleteChannel = useCallback(() => {
    if(!channel?.value) return;

    api.channels.delete({id: channel?.value}).then(() => {
      getNotifier().success("Canal eliminado");
      reloadConversation();
      returnToConversation();
    });
  }, [api, channel, reloadConversation, returnToConversation]);

  const setSelectedChannel = useCallback((channel) => {
    if(!channel)
      setChannel(emptyChannel);
    else 
      setChannel({label: channel.name, value: channel.id});

    setUsers(channel.channelUsers?.map(channelUser => channelUser.user) || []);
    setForm(form => ({...form, ...convertChannelToForm(channel)}));
  }, [setForm, setChannel, setUsers]);

  useEffect(() => {
    if (selectedChannel?.value) {
      api.channels.get({id: `${selectedChannel.value}`, params:{ sGroups: channelSGroups }}).then(setSelectedChannel);
    }
  }, [selectedChannel, api, setSelectedChannel]);

  const isLoading = useSelector(({loading})=>!!loading);
  
  const handleSaveChannel = useCallback(() => {
    const apiMethod = channel?.value!=='' ? 'update' : 'create';
    const id = channel?.value;

    if (!form.name)
      return;
      
    const newChannel = {
      conversation: conversation.id,
      name: form.name,
      client: conversation.client.id,
      enableExternalUsers: form?.enable_external_users, 
      enableInternalUsers: form?.enable_internal_users,
      channelUsers: users?.map(u => ({user: u.id, client: conversation.client.id}))
    };

    api.channels[apiMethod]({ params:newChannel, id })
      .then((channel) => {
          form.name = ''; 
          reloadConversation(channel);
          getNotifier().success((!id ? "El canal se ha creado correctamente" : "El canal se ha actualizado correctamente"));
      });

  }, [api, form, conversation, reloadConversation, channel, users]);
  
  const channelComments = channels?.find(_channel => _channel.id===channel?.value)?.messages || [];
  const channelParticipants = [...new Set(channels?.find(_channel => _channel.id===channel?.value)?.messages.map(m=>m.user.id) || [])];
  
  // ----- Remove user from list
  const handleRemoveUser = useCallback((user) => {
    const usersList = _.difference(users, [user]);
    setUsers([...usersList]);
  }, [users]);

  // ----- Add user to list
  const handleAddUser = useCallback((user) => {
    const usersList = _.union(users, [user]);
    setUsers([...usersList]);
  }, [users]);

  // ----- Options for channels select
  const channelOptions = useMemo(() => {
    return [
      emptyChannel, 
      ...channels?.map(channel => ({label: channel.name, value: channel.id}))
    ];
  }, [channels]);
  

  const handleChangeChannel = useCallback((channel) => {
    const channelId = channel.value;
    const currentChannel = channels.find(_channel => _channel.id===channelId);
    setUsers([]);
    setChannel(channelOptions.find(_channel => _channel.value===channelId));
    setForm(form => ({...form, ...convertChannelToForm(currentChannel)}));

    if(channelId)
      api.channels.get({id: `${channelId}`, params:{ sGroups: channelSGroups }}).then(setSelectedChannel);
  }, [channels, channelOptions, setForm, api, setSelectedChannel]);

  return (
      <>
        <SimpleCard className={"ChannelForm"}>
          <div className='rowflex'>
            <div className='FormTitle'>Configuración del canal</div>
            <WindSelect 
              className={''}
              label="Canal" 
              name="canal" 
              options={channelOptions}
              onChange={handleChangeChannel}
              value={channel}
            />
          </div>
          <div className='rowflex mt-1'>
            <WindInput 
              placeholder="Nombre"
              onChange={handleSimpleChange('name')}
              value={form.name}
            />
            <InfoPair 
              value={channelComments.length} 
              title={"Comentarios"}            
            />
            <InfoPair 
              value={channelParticipants.length} 
              title={"Participantes"}
            />
          </div>
          <div className='rowflex mt-1'>
            <h3>Permisos</h3>
          </div>
          <div className='rowflex mt-1'>
            <WindSwitch 
              placeholder="Usuarios internos" 
              value={form.enable_internal_users} 
              onChange={handleSimpleChange('enable_internal_users')}
            />
            <WindSwitch 
              placeholder="Usuarios externos" 
              value={form.enable_external_users} 
              onChange={handleSimpleChange('enable_external_users')}
            />
          </div>

          <TideEntitySelect
            className='project-field mt-1'
            entity='users'
            placeholder={"Agregar usuario específico"}
            onChange={handleAddUser}
          />

          <div className='UsersList'>
            {users?.map(user => 
              <UserButton key={user.id} onRemove={handleRemoveUser} user={user}>
                {user.fullName}
              </UserButton>)}
          </div>

          <div className='rowflex mt-1'>
            {form.channel?.value!=='' && 
              <>
                <Button 
                  color={'danger'} 
                  outline 
                  onClick={()=>setConfirmDelete(true)} 
                  data-test-id="delete-channel-button"
                  disabled={isLoading}
                >
                  Eliminar canal
                </Button>
              </>}

            {form.channel?.value!=='' && 
              <Button 
                color={'blue'} 
                outline 
                onClick={handleSaveChannel} 
                data-test-id="save-channel-button"
                disabled={isLoading}
              >
                Guardar
              </Button>}
            
            {form.channel?.value===''&& 
              <Button color={'blue'} outline onClick={handleSaveChannel} data-test-id="create-channel-button">Crear</Button>}
          </div>
        </SimpleCard>
        {confirmDelete &&
          <Modal
            onClose={()=>setConfirmDelete(false)}
            title={'Eliminar el canal'}
            mainButtonText={'Eliminar'}
            mainButtonAction={handleDeleteChannel}
            mainButtonColor={'danger'}
            secondaryButtonAction={()=>setConfirmDelete(false)}
            
          >
            ¿ Estás seguro que deseas eliminar el canal de manera permanente ?
          </Modal>}
      </>
  );
}

export default ChannelForm;