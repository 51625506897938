import Logout from "../../scenes/public/Login/Logout";
import ProjectDetails from "../../scenes/crm/ProjectDetails/ProjectDetails";
import ProjectForm from "../../scenes/crm/ProjectForm/ProjectForm";
import QuoteForm from "../../scenes/crm/QuoteForm/QuoteForm";
import ClientList from "../../scenes/crm/ClientList/ClientList";
import ClientDetail from "../../scenes/crm/ClientDetail/ClientDetail";
import UserForm from "../../scenes/crm/UserForm/UserForm";
import UserList from "../../scenes/crm/UserList/UserList";
import QuotationRequestForm from "../../scenes/crm/QuotationRequestForm/QuotationRequestForm";
import UserDetail from "../../scenes/crm/UserDetail/UserDetail";
import QuotationRequestDetail from "../../scenes/crm/QuotationRequestDetail/QuotationRequestDetail";
import ProjectTable from "../../scenes/crm/ProjectTable/ProjectTable";
import QuoteDetail from "../../scenes/crm/QuoteDetail/QuoteDetail";
import MemorandumForm from "../../scenes/crm/MemorandumForm/MemorandumForm";
import MemorandumDetail from "../../scenes/crm/MemorandumDetail/MemorandumDetail";
import TransactionList from "../../scenes/crm/TransactionList/TransactionList";
import BankAccountList from "../../scenes/crm/BankAccountList/BankAccountList";
import BankAccountDetail from "../../scenes/crm/BankAccountDetail/BankAccountDetail";
import BankStatementForm from "../../scenes/crm/BankStatementForm/ BankStatementForm";
import QuoteList from "../../scenes/crm/QuoteList/QuoteList";
import MemorandumList from "../../scenes/crm/MemorandumList/MemorandumList";
import QuotationRequestList from "../../scenes/crm/QuotationRequestList/QuotationRequestList";
import ClientForm from "../../scenes/crm/ClientForm/ClientForm";
import RecurringPaymentList from "../../scenes/crm/RecurringPaymentList/RecurringPaymentList";
import BankStatementList from "../../scenes/crm/BankStatementList/BankStatementList";
import BankStatementDetail from "../../scenes/crm/BankStatementDetail/BankStatementDetail";
import Dashboard from "../../scenes/crm/Dashboard/Dashboard";
import BelvoConnect from "../../scenes/crm/BelvoConnect/BelvoConnect";
import InvoicesList from "../../scenes/crm/InvoicesList/InvoicesList";
import InvoiceForm from "../../scenes/crm/InvoiceForm/InvoiceForm";
import AdminPanel from "../../scenes/crm/AdminPanel/AdminPanel";
//import { faHouse, faClipboard, faUser, faCoins, faFileLines, faLayerGroup, faBuilding, faUserCrown, faMessageBot } from "@fortawesome/pro-light-svg-icons";
import { faFileLines, faBuilding, faUserCrown } from "@fortawesome/pro-light-svg-icons";
import TransactionInvoice from "../../scenes/crm/TransactionInvoice/TransactionInvoice";
import FinanceHome from "../../scenes/crm/FinanceHome/FinanceHome";
import TenantList from "../../scenes/crm/TenantList/TenantList";
import SuperAdminList from "../../scenes/crm/SuperAdminList/SuperAdminList";
import NewInvoiceDetail from "../../scenes/crm/NewInvoiceDetail/NewInvoiceDetail";
import NaviAgent from "../../scenes/crm/NaviAgent/NaviAgent";
import NaviMessageList from "../../scenes/crm/NaviMessageList/NaviMessageList";
import homeIcon from "../../assets/images/home-icon.svg"
import naviIcon from "../../assets/images/navi-icon.svg"
import financeIcon from "../../assets/images/finance-icon.svg"
import userIcon from "../../assets/images/user-icon.svg"
import projectsIcon from "../../assets/images/projects-icon.svg"
import clientIcon from "../../assets/images/client-icon.svg"

export const paths={

    // Index
    dashboard: '/dashboard',

    // Accounts
    bankAccounts: '/bank-accounts',
    bankAccountDetail: '/bank-accounts/:id',

    // Bank Statements
    bankStatements: '/bank-statements',
    bankStatementDetail: '/bank-statements/:id',
    bankStatementsForm: '/bank-statements/new',

    // Projects
    projects: '/projects',
    projectNew: '/projects/new',
    projectDetail: '/projects/:id',
    projectEdit: '/projects/:id/edit',
    projectsTable: '/projects/table',

    // Quotes
    quoteForm: '/quotes/new',
    quoteDetail: '/quotes/:id',
    quoteEdit: '/quotes/:id/edit',
    quoteClone: '/quotes/:id/clone',
    quoteList: '/quotes',

    // Quote Requests
    quotationRequestNew: '/quotation_requests/new',
    quotationRequestDetail: '/quotation_requests/:id',
    quotationRequestEdit: '/quotation_requests/:id/edit',
    quotationRequestList: '/quotation_requests',

    // Clients
    clients: '/clients',
    clientNew: '/clients/new',
    clientDetail: '/clients/:id',
    clientEdit: '/clients/:id/edit',

    // Memorandums
    memorandumNew: '/memorandums/new',
    memorandumDetail: '/memorandums/:id',
    memorandumEdit: '/memorandums/:id/edit',
    memorandumList: '/memorandums',

    // Finance
    finance: '/finance',
    financeDashboard: '/finance/dashboard',
    financeIncomes: '/finance/incomes',
    financeExpenses: '/finance/expenses',
    financeAccounts: '/finance/accounts',
    financeTaxes: '/finance/taxes',
    financeTransactions: '/finance/transactions',
    financeRecurringTransactions: '/finance/recurringTransactions',
    financeBelvoConnect: '/finance/belvoConnect',
    financeAccountTransactions: '/finance/accountTransactions/:bankAccount',
    transactionInvoices: '/transaction-invoices/:id',
    
    // invoices
    invoices: '/invoices',
    invoicesNew: '/invoices/new',
    //invoicesDetail: '/invoices/:id',
    invoicesEdit: '/invoices/:id/edit',
    invoicesForm: '/invoices/form',

    invoicesDetail: '/invoices/:id',
    // Fiscal data
    fiscales: '/fiscalDatas',
    fiscalesSat: '/fiscalDatas/Sat',
    fiscalesNew: '/fiscalDatas/new',
    fiscalesEdit: '/fiscalDatas/:id/edit',

    // Users
    users: '/users',
    usersNew: '/users/new',
    usersDetail: '/users/:id',
    usersEdit: '/users/:id/edit',
    logout: '/logout',

    // Admin
    admin: '/admin',

    tenants: '/tenants',
    superAdmins: '/superadmins',

    navi: '/navi',
    naviMessageList: '/navi-messages',
};

export const translatedPaths = {
        // Index
        '/dashboard': 'Inicio',

        // Accounts
       '/bank-accounts/:id': 'Cuenta',
    
        // Bank Statements
        '/bank-statements' : 'Estados bancarios',
        '/bank-statements/:id': 'Detalles del estado bancario',
        '/bank-statements/new': 'Nuevo estado bancario', 
    
        // Projects
        '/projects': 'Proyectos',
        '/projects/new': 'Proyectos/Nuevo Proyecto',
        '/projects/:id': 'Proyectos/Detalles del Proyecto',
        '/projects/:id/edit' : 'Proyectos/Editar Proyecto',
    
        // Quotes
        '/quotes/new': 'Cotizaciones/Nueva Cotización',
        '/quotes/:id': 'Cotizaciones/Nueva Cotización',
        '/quotes/:id/edit': 'Cotizaciones/Editar Cotización',
        '/quotes/:id/clone': 'Cotizaciones/Clonar Cotización',
        '/quotes': 'Cotizaciones',
    
        // Clients
        '/clients': 'Clientes',
        '/clients/new': 'Clientes/Nuevo Cliente',
        '/clients/:id': 'Clientes/Detalles de Cliente',
        '/clients/:id/edit':'Clientes/Editar Cliente ',

        // Finance
        '/finance':'Finanzas',
        '/finance/accounts': 'Finanzas/Cuentas',
        '/finance/taxes': 'Finanzas/Impuestos',
        '/finance/transactions': 'Finanzas/Transacciones',
        '/finance/recurringTransactions': 'Finanzas/Pagos Recurrentes',
        '/finance/accountTransactions/:bankAccount':'Finanzas/Transacciones de cuentas',
        '/transaction-invoices/:id':'Transacción',

        // invoices
        '/invoices':'Facturas',
        '/invoices/new':'Facturas/Nueva Factura',
        '/invoices/:id':'Facturas/Detalle de Factura',
        '/invoices/:id/edit':'Facturas/Editar Factura',

        // Users
        '/users':'Usuarios',
        '/users/new':'Usuarios/Nuevo Usuario',
        '/users/:id': 'Usuarios/Detalles de Usuario',
        '/users/:id/edit':'Usuarios/Editar Usuario',

        // Memorandums
        '/memorandums':'Minutas',
        '/memorandums/new': 'Minutas/Nueva Minuta',
        '/memorandums/:id/edit': 'Minutas/Editar Minuta',
        '/memorandums/:id': 'Detalles de Minuta',

        // Admin
        '/admin':'Configuración',

        '/tenants':'Tenants',
        '/superadmins':'Super Administradores',

        '/logout': 'Cerrar sesión',

        '/navi': 'Navi',
}

const getAppRoutes = (securityManager)=>{
    let routes = [];

    //Super admin block
    if(securityManager?.isSuperAdmin()){
        routes.push({path:paths.tenants, component: TenantList, name: 'Tenants', icon: faBuilding});
        routes.push({path:paths.superAdmins, component: SuperAdminList, name: 'Administradores', icon: faUserCrown});
        routes.push({path:paths.logout, component: Logout, name: 'Cerrar sesión', hidden: true});
        

        return routes
    }

    // index
    const index = {path:paths.dashboard, component: Dashboard, name: 'Inicio', icon: homeIcon};

    routes.push( index );
    routes.push({path:paths.navi, component: NaviAgent, name: 'Navi', hidden: false, icon: naviIcon});
    routes.push({path:paths.naviMessageList, component: NaviMessageList, name: 'Navi Messages', hidden: true, icon: naviIcon});
    // Projects
    const projectRoutes = {path:paths.projects, component: ProjectTable, name: 'Proyectos', nestedRoutes: [], icon: projectsIcon};

    if(securityManager?.canAdminProjects()){
        projectRoutes.nestedRoutes.push({path:paths.projects, component: ProjectTable, name: 'Tabla de proyectos', hidden: false});    
        projectRoutes.nestedRoutes.push({path:paths.projectNew, component: ProjectForm, name: 'Nuevo Proyecto', hidden: true});
        projectRoutes.nestedRoutes.push({path:paths.projectDetail, component: ProjectDetails, name: 'Detalles del proyecto', hidden: true});
        projectRoutes.nestedRoutes.push({path:paths.projectEdit, component: ProjectForm, name: 'Editar proyecto', hidden: true});
    }

    // Memorandums
    if(securityManager?.canAdminMemorandums()){
        projectRoutes.nestedRoutes.push({path:paths.memorandumList, component: MemorandumList, name: 'Minutas', hidden: true});

        projectRoutes.nestedRoutes.push({path:paths.memorandumNew, component: MemorandumForm, name: 'Nueva minuta', hidden:true});
        projectRoutes.nestedRoutes.push({path:paths.memorandumDetail, component: MemorandumDetail, name: 'Detalles de minuta', hidden: true});
        projectRoutes.nestedRoutes.push({path:paths.memorandumEdit, component: MemorandumForm, name: 'Editar minuta', hidden:true});
    }

    // Quotes
    if(securityManager?.canAdminQuotes()){
        projectRoutes.nestedRoutes.push({path:paths.quoteList, component: QuoteList, name: 'Cotizaciones', hidden: false});        
        projectRoutes.nestedRoutes.push({path:paths.quoteForm, component: QuoteForm, name: 'Nueva cotización', hidden:true});
        projectRoutes.nestedRoutes.push({path:paths.quoteDetail, component: QuoteDetail, name: 'Nueva cotización', hidden:true});
        projectRoutes.nestedRoutes.push({path:paths.quoteEdit, component: QuoteForm, name: 'Editar cotización', hidden:true});
        projectRoutes.nestedRoutes.push({path:paths.quoteClone, component: QuoteForm, name: 'Clonar cotización', hidden:true});
        projectRoutes.nestedRoutes.push({path:paths.quotationRequestList, component: QuotationRequestList, name: 'Solicitudes', hidden: true});
    }


    if(projectRoutes.nestedRoutes.length > 0){
        routes.push(projectRoutes);
    }

    // Quote Requests
    //if(securityManager.canAdminQuoteRequests()){
        
        routes.push({path:paths.quotationRequestNew, component: QuotationRequestForm, name: 'Nueva solicitud de cotización', hidden:true});
        routes.push({path:paths.quotationRequestDetail, component: QuotationRequestDetail, name: 'Detalle de solicitud de cotización', hidden:true});
        routes.push({path:paths.quotationRequestEdit, component: QuotationRequestForm, name: 'Editar solicitud de cotización', hidden:true});
    //}
    
    // Clients
    if(securityManager?.canAdminClients()){
        routes.push({path:paths.clients, component: ClientList, name: 'Clientes', icon: clientIcon, nestedRoutes: [
                {path:paths.clientNew, component: ClientForm, name: 'Nuevo Cliente', hidden: true},
        ]});
        routes.push({path:paths.clientDetail, component: ClientDetail, name: 'Detalles del cliente', hidden: true});
        routes.push({path:paths.clientEdit, component: ClientForm, name: 'Editar cliente', hidden: true});
    }

    // Finance    
    const financeRoutes = {path:paths.finance, component: FinanceHome, name: 'Finanzas', nestedRoutes:[], icon: financeIcon};

    if(securityManager?.canAdminTransactions()){
        financeRoutes.nestedRoutes.push({path:paths.financeTransactions, component: TransactionList, name: 'Transacciones', hidden: false});
    }
    
    if(securityManager?.canAdminBankAccounts() || securityManager?.canListBankAccounts()){
        financeRoutes.nestedRoutes.push({path:paths.financeAccounts, component: BankAccountList, name: 'Cuentas', hidden: false});
    }

    if(securityManager?.canAdminBankAccounts()){
        financeRoutes.nestedRoutes.push({path:paths.financeTaxes, component: ProjectForm, name: 'Impuestos', hidden: true});
        financeRoutes.nestedRoutes.push( {path:paths.transactionInvoices, component: TransactionInvoice, name: 'Transaccion', hidden: true} );
    }

    if(securityManager?.canAdminRecurringPayments()){
        financeRoutes.nestedRoutes.push({path:paths.financeRecurringTransactions, component: RecurringPaymentList, name: 'Pagos recurrentes', hidden: false});
    }

    // -- Invoices under Finance
    if(securityManager?.canAdminTaxDocuments()){
        financeRoutes.nestedRoutes.push( {path:paths.invoicesForm, component: InvoiceForm, name: 'Generar Factura', hidden: true} );
        financeRoutes.nestedRoutes.push( {path:paths.invoices, component: InvoicesList, name: 'Facturas', hidden: false} );
        financeRoutes.nestedRoutes.push( {path:paths.invoicesDetail, component: NewInvoiceDetail, name: 'Detalle de factura', hidden: true} );
    }

    
    if (financeRoutes.nestedRoutes.length > 0) {
        routes.push(financeRoutes);
    }
    
    // // Accounts
    routes.push({path:paths.bankAccountDetail, component: BankAccountDetail, name: 'Cuenta', hidden: true});
    routes.push({path:paths.financeAccountTransactions, component: BelvoConnect, name: 'Transacciones de cuenta', hidden: true});

    // Bank Statements
    routes.push({path:paths.bankStatementsForm, component: BankStatementForm, name: 'Nuevo estado bancario', hidden: true});
    routes.push({path:paths.bankStatements, component: BankStatementList, name: 'Estados bancarios', hidden: true});
    routes.push({path:paths.bankStatementDetail, component: BankStatementDetail, name: 'Detalles del estado bancario', hidden: true});

    const userRoutes = {path:paths.users, component: UserList, name: 'Usuarios', nestedRoutes:[], icon: userIcon};

    if(securityManager?.canAdminUsers() || securityManager?.canListUsers()){
       userRoutes.nestedRoutes.push({path:paths.usersNew, component: UserForm, name: 'Nuevo Usuario', hidden: true});
       userRoutes.nestedRoutes.push({path:paths.usersDetail, component: UserDetail, name: 'Detalles del usuario', hidden: true});
       userRoutes.nestedRoutes.push({path:paths.usersEdit, component: UserForm, name: 'Editar usuario', hidden: true});
       routes.push(userRoutes);
    }

    if(securityManager?.isTenantUser() || securityManager?.canAdminFiscalData()){
        // Admin
        const adminRoutes = {path:paths.admin, component: AdminPanel, name: 'Configuración', nestedRoutes:[], icon: faFileLines};
        routes.push( adminRoutes );
    }
    
    routes.push({path:paths.logout, component: Logout, name: 'Cerrar sesión', hidden: true});

    return routes;
};


export default getAppRoutes;
