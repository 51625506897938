import React, {useCallback} from 'react';
import './FileIcon.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getIconForFile} from "../../../../../services/fileUtils";
import classNames from "classnames";
import {faUp} from "@fortawesome/pro-light-svg-icons";

const FileIcon = ({ appFile, addFileIcon, onAddFile, onClick }) => {

    const name = addFileIcon? 'Subir': (appFile?.originalName||'Unknown');
    const icon = addFileIcon? faUp   : getIconForFile(name);

    const handleFileSelect = (e)=>{
        onAddFile(e.target.files);
    };

    const handleClick = useCallback(()=>{
        if(addFileIcon) return;
        onClick(appFile);
    },[addFileIcon, onClick, appFile]);

    return (
        <div
            className={classNames("FileIcon", addFileIcon&&'add-file')}
            data-tooltip={ addFileIcon?'':'Ver detalles del archivo' }
            onClick={ handleClick }
        >
            <div className='thumb-container'>
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className='name-container'>
                {name}
            </div>
            {addFileIcon && <input type='file' multiple className='file-input' onChange={handleFileSelect} key={Math.random()} />}
        </div>
    );
};

export default FileIcon;
