import React, { useMemo } from "react";
import TideReactTable from "../../../../../components/utility/TideReactTable/TideReactTable";
import useTideTable from "../../../../../components/utility/TideReactTable/useTideTable";
import { quoteListSGroups, quoteListTableColumns } from "../../../../../services/modelUtils/quoteUtils";

const ClientQuote = ({ client }) => {
    
    const customProp = useMemo(() => 'Client.Quotes.get.' + client?.id, [client?.id]);

    const tableParams = useMemo(()=>{
        return {
            entity: 'quotes',
            columns: quoteListTableColumns,
            requestFilters: {
                sGroups: quoteListSGroups,
                'order[createdDate]': 'DESC',
                'client.id': client.id
            },
            requestOptions: {
                customProp
            }
        }
    },[client, customProp])

    const table = useTideTable(tableParams);

    return (
        <div className="info-container">
            <div className="single-title">Cotizaciones</div>
            <TideReactTable {...table.tableProps} />
        </div>
    );
}

export default ClientQuote;