import React from "react";
import IconButton from "../../../../../components/utility/IconButton/IconButton";
import useBoolean from "../../../../../hooks/useBoolean";
import { faHouse, faPlug, faFileLock } from '@fortawesome/pro-light-svg-icons';
import { satWsStatus } from "../../../../../services/modelUtils/tenantRfcUtils";
import Button from "../../../../../components/utility/Button/Button";
import RegisterCsdModal from "../RegisterCsdModal/RegisterCsdModal";
import MainRfcModal from "../MainRfcModal/MainRfcModal";
import DeleteRfcModal from "../DeleteRfcModal/DeleteRfcModal";
import SatConnectionModal from "../SatConnectionModal/SatConnectionModal";
import './RegisteredRfc.scss';

const RegisteredRfc = ({rfc, onActionSuccess}) => {
    
    const [showingRegisterCsdModal, showRegisterCsdModal, hideRegisterCsdModal] = useBoolean(false);
    const [showingMainRfcModal, showMainRfcModal, hideMainRfcModal] = useBoolean(false);
    const [showingDeleteRfcModal, showDeleteRfcModal, hideDeleteRfcModal] = useBoolean(false);
    const [showingSatConnectionModal, showSatConnectionModal, hideSatConnectionModal] = useBoolean(false);

    return(
        <div className="rfc-container">

            <div className="row-data">
                <div className="column">
                    <p className="rfc-label">{rfc?.fiscalData?.businessName ?? "N/A"}</p>
                    <p className="rfc-sublabel">{rfc?.rfc ?? "N/A"}</p>
                </div>

                <div className="icon-row"> 
                    {rfc.isMainRfc &&
                        <IconButton
                        icon={faHouse}
                        onClick={showMainRfcModal}
                        title=  "RFC Principal"
                        /> 
                    } 

                    {rfc.csd && <IconButton
                        icon={faFileLock}
                        onClick={showRegisterCsdModal}
                        title="CSD disponible"
                    />}           

                    {(rfc.satWsId !== null && rfc?.satWsStatus === satWsStatus.VALID) &&                    
                        <IconButton
                            icon={faPlug}
                            
                            title="Conexión al SAT"
                        />                               
                    }

                </div>
            </div>


            <div className="row-data">
                <div className="column">
                    <label>Calle</label>
                    <p>{rfc?.fiscalData?.street ?? "N/A"}</p>
                </div>

                <div className="column">
                    <label>Número Exterior</label>
                    <p>{rfc?.fiscalData?.external_number?? "N/A"}</p>
                </div>

                <div className="column">
                    <label>Número Interior</label>
                    <p>{rfc?.fiscalData?.internal_number?? "N/A"}</p>
                </div>
            </div>


            <div className="row-data">
                <div className="column">
                    <label>Estado</label>
                    <p>{rfc?.fiscalData?.state?? "N/A"}</p>
                </div>
                
                <div className="column">    
                    <label>País</label>
                    <p>{rfc?.fiscalData?.country?? "N/A"}</p>
                </div>

                <div className="column">    
                    <label>Código Postal</label>
                    <p>{rfc?.fiscalData?.zip?? "N/A"}</p>
                </div>
            </div>


            <div className="action-buttons">
                <Button color='danger' outline={true} onClick={showDeleteRfcModal}>Eliminar RFC</Button>
                {!rfc?.satWsId && <Button outline={true} onClick={showSatConnectionModal}>Conectar al SAT</Button>}
                {!rfc?.csd && <Button outline={true} onClick={showRegisterCsdModal}>Agregar CSD</Button>}
            </div>
                        
            {showingRegisterCsdModal &&
                <RegisterCsdModal onClose={hideRegisterCsdModal} rfc={rfc}/>
            }

            {showingMainRfcModal && 
                <MainRfcModal onClose={hideMainRfcModal} onSaveSuccess={onActionSuccess}/>
            }

            {showingDeleteRfcModal && 
                <DeleteRfcModal onClose={hideDeleteRfcModal} onSaveSuccess={onActionSuccess} rfc={rfc}/>
            }

            {showingSatConnectionModal && 
                <SatConnectionModal onClose={hideSatConnectionModal} onSaveSuccess={onActionSuccess} rfc={rfc}/>
            }

        </div>
    )
}

export default RegisteredRfc