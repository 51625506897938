import React from 'react';
import './SelectorMenuItem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import classNames from 'classnames';

const SelectorMenuItem = ({ defaultIcon, icon, action, isOpen, options, isActive }) => {
  return (
    <div className={classNames('SelectorMenuItem')}>
      <button
        className={classNames(
          "SelectorMenuItem__button",
          isOpen && 'is-open',
          isActive && 'is-active'
        )}
        onClick={action}
      >
        <FontAwesomeIcon icon={isOpen?defaultIcon:icon} />
      </button>
      <div className={classNames("options", isOpen && 'is-open')}>
        {isOpen && _.map(options, (option) => (
          <button
            key={option.title}
            className={classNames("SelectorMenuItem__option")}
            onClick={option.action}
            title={option.title}
          >
            <FontAwesomeIcon icon={option.icon} />
          </button>
        ))}
      </div>
    </div>
  );
}
 
export default SelectorMenuItem;