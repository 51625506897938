import { faXmarkCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import TideEntitySelect from "../../../../../components/utility/TideEntitySelect/TideEntitySelect"
import { satCatalogSGroups } from "../../../../../services/modelUtils/taxDocumentUtils"
import useCallbackCreator from "use-callback-creator";
import WindInput from "../../../../../components/formComponents/WindInput/WindInput";
import WindSelect from "../../../../../components/formComponents/WindSelect/WindSelect";
import Button from "../../../../../components/utility/Button/Button";
import { useEffect, useState } from 'react';
const TaxData = ({ invoiceProduct, invoiceTax, onChange, onAdd, onRemove, last, unique}) => {
    
    const taxType = [{label: "Trasladado",value: "Trasladado"}, 
        {label: "Retenido",value: "Retenido"}] 

    const [showTaxAmount, setShowTaxAmount] = useState(false) 
    
    useEffect(()=>{
        if(invoiceTax.taxType){
            setShowTaxAmount(true)
        }
    },[setShowTaxAmount, invoiceTax])

    const handleChange = useCallbackCreator((name, value) => {
        let updatedTax = { ...invoiceTax, [name]: value };
        
        if(name === 'rate'){
            //CFDI must have 6 decimal palces on tax rate
            const parsedRate = parseFloat(value).toFixed(6) 
            const amount = ((parsedRate)*invoiceProduct.total).toFixed(2)
            updatedTax = { ...updatedTax, amount: amount, rate: value};
        }else if(name==='tax' && value.description === 'IVA'){
            const amount = ((0.160000)*invoiceProduct.total).toFixed(2)
            updatedTax = { ...updatedTax, rate: 0.160000.toFixed(6), amount: amount };
                
        }else if(name==="factor"){
            if(value.catalogKey==="Exento"){
                updatedTax = { ...updatedTax, factor: value, amount: 0, taxType: {label: "Trasladado",value: "Trasladado"} };
                setShowTaxAmount(false)
            }else{
                setShowTaxAmount(true)
            }
        }
        onChange(updatedTax);

    }, [invoiceTax, onChange, invoiceProduct.total]);

    return(
        <>
        <div className="input-row">

            <div className="entity-select-label">
                <label>{"Impuesto"}</label>
                <TideEntitySelect
                    entity='satCatalogs'
                    placeholder='Impuesto'
                    additionalFilters={{
                        sGroups: satCatalogSGroups,
                        "catalog": "c_Impuesto"
                    }}
                    filterBy={'description'}
                    labelCreator = {(satCatalogs) => 
                        `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                    }
                    preload
                    value={invoiceTax.tax}
                    onChange={handleChange('tax')}
                />
            </div>

            <div className="entity-select-label">
                
                <label>{"Tipo de impuesto"}</label>
                <WindSelect
                    name="taxType"
                    options={taxType}
                    onChange={handleChange('taxType')}
                    value={invoiceTax.taxType}
                />
            </div>

            <div className="entity-select-label">
                <label>{"Factor"}</label>
                <TideEntitySelect
                    entity='satCatalogs'
                    placeholder='Factor'
                    additionalFilters={{
                        sGroups: satCatalogSGroups,
                        "catalog": "c_TipoFactor"
                    }}
                    filterBy={'c_TipoFactor'}
                    labelCreator = {(satCatalogs) => 
                        `${satCatalogs.catalogKey}`
                    }
                    preload
                    value={invoiceTax.factor}
                    onChange={handleChange('factor')}
                />
            </div>
        </div>

        { showTaxAmount && 
            <div className="input-row">

                <WindInput
                    label={"Tasa en decimal"}
                    placeholder={"0.000000"}
                    value={invoiceTax.rate}
                    onChange={handleChange('rate')}
                />

                <WindInput
                    placeholder={"Base"}
                    type={'number'}
                    value={invoiceProduct.total}
                    inputProps={{ readOnly: true }}
                />

                <WindInput
                    placeholder={"Importe"}
                    type={'number'}
                    value={invoiceTax.amount}
                    inputProps={{ readOnly: true }}
                />

            </div>
        }


        {last && <Button onClick={onAdd} color="info" outline={true} leftIcon={faPlusCircle}>Agregar impuesto</Button>}
        {!unique && <Button onClick={onRemove} color="danger" outline={true} leftIcon={faXmarkCircle}>Eliminar impuesto</Button>}

        </>
    )
}

export default TaxData