import { faFilePdf, faFileSpreadsheet, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useLayoutEffect } from 'react';
import { useCallback } from 'react';
import { useRef } from 'react';
import './DragDropFile.scss';

const DragDropFile = ({ file, onChange, onRemove, disabled }) => {
  const handleFileClick = useCallback(() => { hiddenInput.current.click() }, []);

  const hiddenInput = useRef();
  const inputFile = useRef();

  const handleFileInputChange = useCallback((event) => {
    const file = event.target.files[0];
    if (!file) { return; }

    onChange(file);
  },[onChange]);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    onChange(e.dataTransfer.files[0]);
  }, [onChange]);

  useLayoutEffect(() => {
    const inputContainer = inputFile.current;

    inputContainer.addEventListener('dragover', handleDragOver);
    inputContainer.addEventListener('drop', handleDrop);
  }, [handleDragOver, handleDrop]);

  return (
    <div className="DragDropFile" ref={inputFile} onClick={handleFileClick}>
      <div className="file-container">
        {!file && (
          <div className="file-container__placeholder">
            <div className="file-container__placeholder__icon">
              <FontAwesomeIcon icon={faFilePdf} className="icon" />
              <FontAwesomeIcon icon={faFileSpreadsheet} className="icon" />
            </div>
            <div className="file-container__placeholder__text">
              <span>Da click o arrastra un archivo</span>
            </div>
          </div>
        )}
        {file && (
          <div className="file-container__file">
            <div className="file-container__file__icon">
              <FontAwesomeIcon icon={faFilePdf} className="icon" />
              <FontAwesomeIcon icon={faTimesCircle} className="close-icon" onClick={onRemove} />
            </div>
            <div className="file-container__file__text">
              <span>{file.name}</span>
            </div>
          </div>
        )}
      </div>
      <input hidden type="file" accept=".pdf, .xml, .csv" onChange={handleFileInputChange} ref={hiddenInput} disabled={disabled} />
    </div>
  );
}
 
export default DragDropFile;
