import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { translatedPaths } from '../../../services/routes/appRoutes';
import './BreadcrumbNavigation.scss';

const BreadcrumbNavigation = () => {
  const location = useLocation();

  if (location.pathname === '/dashboard') {
    return (
      <div className="breadcrumb">
        <Link to="/">Inicio</Link>
      </div>
    );
  }

  let pathname = location.pathname.replace(/\d+/, ':id');

  const translatedPath = translatedPaths[pathname] || '';
  const pathnames = translatedPath.split('/').filter(x => x);

  let pathSegments = location.pathname.split('/').filter(x => x);

  return (
    <div className="breadcrumb">
      <Link to="/">Inicio</Link>
      {pathnames.map((name, index) => {
        const routeSegments = pathSegments.slice(0, index + 1);
        const routeTo = `/${routeSegments.join('/')}`;
        const isLast = index === pathnames.length - 1;

        return isLast ? (
          <span className="breadcrumb-item" key={name}> &gt; {name}</span>
        ) : (
          <span className="breadcrumb-indicator" key={name}> &gt; <Link to={routeTo}>{name}</Link></span>
        );
      })}
    </div>
  );
};

export default BreadcrumbNavigation;
