import { useCallback, useContext } from "react";
import Modal from "../../../../../components/utility/Modal/Modal"
import { ApiContext } from "../../../../../services/api/api-config";
import { getNotifier } from "../../../../../services/notifier";

const DeleteRfcModal = ({onClose, onSaveSuccess, rfc}) => {
    const api = useContext(ApiContext);

    const close = useCallback(() => {
        if (onClose) onClose()
    }, [onClose]);

    const deleteRfc = async() => {
        try{
            if(rfc.csd) await api.csds.delete({ id: rfc.csd.id })
            rfc.satWsId ? await api.satwsdelete.delete({ id: rfc.id }) : await api.tenantRfcs.delete({id: rfc.id})
            await api.fiscalDatas.delete({id: rfc.fiscalData.id})
            close()
            onSaveSuccess()
        }catch(e){
            getNotifier().error(e)            
        }

    }

    return(
        <Modal title={"Eliminar RFC"}
        onClose={close}
        mainButtonAction={deleteRfc}
        mainButtonText="Confirmar"
        secondaryButtonAction={close}
        secondaryButtonText="Cancelar"
        >
          <p>¿ Estás seguro que quieres eliminar el RFC ? </p>

        </Modal>
    )
}

export default DeleteRfcModal