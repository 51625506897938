import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {bankAccountListSGroups} from "../../../services/modelUtils/bankAccountUtils";
import BankAccountCard from "./components/BankAccountCard/BankAccountCard";
import './BankAccountList.scss'
import {filterTypes} from "../../../services/searchUtils";
import SearchBar from '../../../components/search/SearchBar/SearchBar';
import {ApiContext} from "../../../services/api/api-config";
import _ from "lodash"
import BankAccountFormModal from "../BankAccountFormModal/BankAccountFormModal";
import useBoolean from "../../../hooks/useBoolean";
import SectionHeader from '../../../components/utility/SectionHeader/SectionHeader';
import { getFinanceHeaderInfo } from '../../../constants/SectionHeaderInfo';
import { SecurityContext } from '../../../services/SecurityManager';

const requestFilters = {sGroups: bankAccountListSGroups};
const filtersConfig = {
    placeholder: 'Buscar una cuenta...', filters: [
        {main: true, field: 'name'},
        {type: filterTypes.TEXT, field: 'name', label: 'Buscar por nombre'},
        {type: filterTypes.TEXT, field: 'number', label: 'Buscar por número de cuenta'}
    ]
};

const BankAccountList = () => {

    const api = useContext(ApiContext);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [filters, setFilters] = useState([])
    const [showingForm, showForm, hideForm] = useBoolean(false);
    const [ accountEdit, setAccountEdit ] = useState(0);

    const security = useContext(SecurityContext);
    const canAdminBankAccounts = security?.canAdminBankAccounts()
    const headerInfo = getFinanceHeaderInfo(security)

    const loadBankAccounts = useMemo(() => _.debounce((filters) => {
        api.bankAccounts.get({params: {...requestFilters, ...filters}, pagination: false}).then((data) => {
            setBankAccounts(data);
        })
    }, 650), [api]);

    const exportBankAccount = useCallback(() => {
        alert("todo: Export bank account");
    }, []);

    useEffect(() => {
        loadBankAccounts(filters)
    }, [filters, loadBankAccounts]);

    const editAccount = (idAccount) => {
        setAccountEdit( idAccount );
    }

    const closeModalAccount = () => {
        hideForm();
        setAccountEdit( 0 );
    }

    const sceneTools = useMemo(() => 
        canAdminBankAccounts ? [
            { text: "Exportar", callback: exportBankAccount, outline: true },
            { text: "Agregar", callback: showForm }
        ] : [], 
    [canAdminBankAccounts, exportBankAccount, showForm]);


    return (
        <div className={"BankAccountList wind-scene"}>

            <SectionHeader pageInfo={headerInfo} tools={sceneTools}/>    

            <SearchBar 
            title="Todas las cuentas bancarias" 
            filters={filters} 
            filtersConfig={filtersConfig} 
            onFiltersChange={setFilters}
            labels={false}
            />

            <div className='center-container'>
                <div className='bank-accounts-grid'>
                    {bankAccounts.map(bankAccount =>
                        <BankAccountCard 
                            key={bankAccount.id} 
                            bankAccount={bankAccount}
                            showEdit={editAccount}
                            />
                    )}
                </div>
            </div>

            { (showingForm || !!accountEdit) &&
                <BankAccountFormModal
                    onClose={closeModalAccount}
                    onBankAccountSaved={loadBankAccounts}
                    accountId={accountEdit}
                />
            }
        </div>
    );
};

export default BankAccountList;
