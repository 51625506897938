import React, {useCallback, useContext, useMemo, useEffect} from 'react';
import './ProjectForm.scss';
import TopBar from "../../../components/utility/TopBar/TopBar";
import {paths} from "../../../services/routes/appRoutes";
import ToolBar from "../../../components/utility/ToolBar/ToolBar";
import {faSave} from "@fortawesome/pro-light-svg-icons";
import useFormState from "../../../hooks/useFormState";
import SimpleCard from "../../../components/layout/SimpleCard/SimpleCard";
import TideEntitySelect from "../../../components/utility/TideEntitySelect/TideEntitySelect";
import WindTextarea from "../../../components/formComponents/WindTextarea/WindTextarea";
import {getNotifier} from "../../../services/notifier";
import {convertProjectToForm, prepareProjectForServer, projectDetailSGroups, projectStatusOptions} from "../../../services/modelUtils/projectUtils";
import {ApiContext} from "../../../services/api/api-config";
import {useSelector} from "react-redux";
import WindDatePicker from "../../../components/formComponents/WindDatePicker/WindDatePicker";
import {useHistory} from "react-router-dom";
import {useParams} from 'react-router-dom';
import WindSelect from '../../../components/formComponents/WindSelect/WindSelect';

const ProjectForm = () => {

    const api = useContext(ApiContext);
    const history = useHistory();
    const { form, setForm, bindInput, bindSimple } = useFormState(()=>({ startDate: new Date() }));
    const { id } = useParams();

    const loadingId = 'ProjectForm.create';
    const handleSave = useCallback(()=>{
        const { id } = form;
        const apiMethod = id ? 'update' : 'create';

        let project;
        try{
            project = prepareProjectForServer(form);
        }
        catch (e){
            return getNotifier().warning(e.message);
        }
        const params = { id, params:project, loadingId };
        api.projects[apiMethod](params)
            .then((project)=>{
                getNotifier().success(`El proyecto se ha ${id?'editado':'creado'} correctamente`);
                history.push( paths.projectDetail.replace(':id', project.id) );
            })
            .catch((e)=>{ getNotifier().error(e.message) })
    }, [form, api, history]);
    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    // ----- Load Project data
    useEffect(()=>{
        if(id) {
            api.projects.get({ id, loadingId, params: { sGroups: projectDetailSGroups } })
                .then(project => {
                    setForm(convertProjectToForm(project));
                })
                .catch(() => getNotifier().error('No se encontró el proyecto solicitado'));
        }
    },[api, id, loadingId, setForm]);

    const tools = useMemo(()=>[
        { icon: faSave, callback: handleSave, text: "Guardar proyecto", disabled: loading  },
    ],[handleSave, loading]);

    return (
        <div className={"ProjectForm wind-scene"}>

            <TopBar
                title="Nuevo proyecto"
                titleLinkBack
            />

            <ToolBar tools={tools} />

            <div className='center-container'>

                <div className='project-header' >
                    <input className='title-input' placeholder="Agrega un nombre al proyecto" {...bindInput('name')} />
                    <span className='project-status' data-tooltip={"Doble click para editar"}>{form.id?'en edición':'en creación'}</span>
                </div>

                <SimpleCard className='general-info' title="Información general">

                    <div className='fields'>
                        <TideEntitySelect
                            className='project-field'
                            entity='clients'
                            placeholder={"Compañía"}
                            label="Cliente"
                            {...bindSimple('client')}
                        />
                        <WindSelect
                            className='project-field'
                            placeholder={"Estado"}
                            {...bindSimple('status')}
                            options={projectStatusOptions}
                        />
                        <WindDatePicker
                            className='project-field'
                            label={'Fecha de inicio'}
                            {...bindSimple('startDate')}
                        />
                        <WindDatePicker
                            className='project-field'
                            label={'Fecha de fin'}
                            {...bindSimple('endDate')}
                        />
                    </div>

                    <WindTextarea className={" description-input"} placeholder='Agrega la descripción del proyecto' {...bindSimple('description')}/>

                </SimpleCard>

            </div>
        </div>
    );
};

export default ProjectForm;
