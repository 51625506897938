import React from 'react'
import WindInput from '../../../../../components/formComponents/WindInput/WindInput';
import WindSwitch from '../../../../../components/formComponents/WindSwitch/WindSwitch';
import classNames from "classnames";

const GeneralInfo = ({ bindSimple, form, className }) => {
    return (
        <div className={classNames("ClientForm")}>
            <div className="fields-row">
                <WindSwitch
                    className='project-field fields-field'
                    placeholder={'Persona moral'}
                    {...bindSimple('isLegalPerson')}
                />
            </div>
            <div className="fields-row">

                {form.isLegalPerson && 
                    <WindInput 
                        className='WindInput project-field fields-field'
                        placeholder={"Razón Social"}
                        {...bindSimple('companyName')}
                        inputProps={{maxLength:255}}
                />}
                
                {!form.isLegalPerson && 
                    <WindInput 
                        className='WindInput project-field fields-field'
                        placeholder={"Nombre(s)"}
                        {...bindSimple('clientName')}
                        inputProps={{maxLength:255}}
                />}

                {!form.isLegalPerson && 
                    <WindInput
                        className='WindInput project-field fields-field'
                        placeholder={"Apellido Paterno"}
                        {...bindSimple('lastName')}
                        inputProps={{maxLength:255}}
                    />}
            
                {!form.isLegalPerson && 
                    <WindInput
                        className='WindInput project-field fields-field'
                        placeholder={"Apellido Materno"}
                        {...bindSimple('secondLastName')}
                        inputProps={{maxLength:255}}
                    />}
            </div>
            
            <div className="fields-row">
                <WindInput 
                    className='project-field fields-field'
                    placeholder={"Iniciales"}
                    {...bindSimple('initials')}
                    inputProps={{maxLength:8}}
                />
                
                <WindInput
                    className='project-field fields-field'
                    placeholder={"Teléfono"}
                    {...bindSimple('phone')}
                    inputProps={{maxLength:20}}
                />

                <div className="fields-field"></div>
            </div>
            
        </div>
    )
}

export default GeneralInfo;