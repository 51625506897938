import React, {useEffect, useMemo, useContext} from 'react';
import TideReactTable from "../../utility/TideReactTable/TideReactTable";
import useTideTable from "../../utility/TideReactTable/useTideTable";
import { getQuoteStatusTrans, quoteListSGroups, getQuoteStatusColor } from "../../../services/modelUtils/quoteUtils";
import moment from 'moment';
import { moneyFormatter } from '../../../services/currencyUtils';
import {Link} from "react-router-dom";
import {paths} from "../../../services/routes/appRoutes";
import StatusIndicator from '../../utility/StatusIndicator/StatusIndicator';
import Button from '../../utility/Button/Button';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router-dom';
import {ApiContext} from "../../../services/api/api-config";

import "./QuoteTable.scss"

const customProp = 'project-quotes'
const requestOptions = {customProp}

const QuoteTable = ({project}) => {
    const history = useHistory();
    const api = useContext(ApiContext);

    const columns = useMemo(()=>[
        {
            Header: 'Folio',
            accessor: (quote) =>
                <Link to={paths.quoteDetail.replace(':id', quote.id)} data-tooltip={'Ver cotización'} >
                    {quote.folio}
                </Link>,
            id:'folio'
        },
        {
            Header:"Título",
            accessor: (quote)=><Link to={paths.quoteDetail.replace(':id', quote.id)} data-tooltip={'Ver cotización'} >
                {quote.title}
            </Link>,
            id:'title'
        },
        {Header:"Estado", id:'status', accessor: (q) => <StatusIndicator text={getQuoteStatusTrans(q.status)} type={getQuoteStatusColor(q.status)} layout="inner" /> },
        {Header:"Pagado", id:'payed',  accessor: (q) => `$ ${moneyFormatter(Number(q.total) - Number(q.pendingAmount))}`},
        {Header:"Precio", id:'price', accessor: (q) => `$ ${moneyFormatter(Number(q.total))}` },
        {Header:"Creación", accessor: (q) => (q.createdDate? moment(q.createdDate).format('DD/MM/YYYY'):null)},
    ], []);

    const quoteFilters = useMemo(() => ({
        sGroups:quoteListSGroups, 'order[createdDate]':'DESC',
        project: project?.id}), [project]);

    useEffect(()=>{
        api.clearProperty(customProp)   
    },[api])
    
    const table = useTideTable({
        entity: 'quotes',
        columns,
        requestFilters: quoteFilters,
        requestOptions
    });

    const addQuote = () => {
        history.push(paths.quoteForm);
    }

    return (
        <div className={"QuoteTable ProjectInfo"}>
            <div className="quote-table-header">
                <div className="single-title">Cotizaciones</div>
                <div>
                    <Button
                        onClick={addQuote}
                        leftIcon={faPlusCircle}
                        outline
                        >
                        Nueva cotización
                    </Button>
                </div>
            </div>
            
            <TideReactTable
                className="table-hoverable"
                {...table.tableProps}
            />

        </div>
    );
};

export default QuoteTable;
