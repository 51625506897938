import { useLocation } from "react-router-dom";
import {paremetersPrefix} from './useSearchHistory';
import queryToStr from 'query-string';
import _ from 'lodash';
import moment from "moment";

const useUrlFilters = () => {
    const { search } = useLocation();
    const urlParams = queryToStr.parse(search);
    let filters = {};
    const currentItemsPerPage = ((new URLSearchParams(search)).get('itemsPerPage'));

    _.forEach(urlParams, function(value, key) {
        if(key!=='page' && key!=='itemsPerPage' && key && value){
            const dateFilter = moment(value);

            if(dateFilter.isValid())
                filters[key] = dateFilter.toDate();
            else
                filters[key.replace(paremetersPrefix, "")] = value;
        }
    });

    return {
        initialFilters: filters,
        initialPage: ((new URLSearchParams(search)).get('page')) || 1,
        initialItemsPerPage: Number(currentItemsPerPage)
    };
}

export default useUrlFilters;