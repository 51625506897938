import { useState, useContext, useEffect } from "react"
import { ApiContext } from "../../../../../services/api/api-config";
import IconButton from "../../../../../components/utility/IconButton/IconButton";
import { faToggleOff, faToggleOn } from "@fortawesome/pro-light-svg-icons";
import { getNotifier } from "../../../../../services/notifier";

const InvoicesSwitch = ({ taxDocument, handleUpdate, bankTransaction }) => {
    const api = useContext(ApiContext);
    const [ related, setRelated ] = useState( false )

    useEffect(() => { 
        if ( taxDocument?.bankTransactions?.length === 0 ) {
            setRelated(false);
        }
        else {
            if ( taxDocument && bankTransaction && taxDocument?.bankTransactions ) {
                for( let i=0; i<taxDocument.bankTransactions.length; i++ ) {
                    let transactionUrl = taxDocument.bankTransactions[i];
                    let transaction = parseInt( transactionUrl.substring( transactionUrl.lastIndexOf("/")+1 ) );
                    if( transaction===bankTransaction.id  ) setRelated(true);
                }
            }
        }
    }, [setRelated, taxDocument, bankTransaction]);

    const attach = () => {
        const params = {
            taxDocumentId: taxDocument.id,
            bankTransactionId: bankTransaction.id,
        };
        api.matchBankInvoices['create']({params}).catch( err => { console.log("ERROr REPORTED: ",err) }).then( () => {
            getNotifier().success('Se ha relacionado correctamente');
            setRelated( true );
            handleUpdate();
        } );

        
    }
    
    return(
        <IconButton 
            key={taxDocument?.id+'bt'}
            className={'switch-ignore-invoice'}
            onClick={attach}
            icon={ related? faToggleOn: faToggleOff}
            color={ related? 'success' :'danger' }
        />
    );
}

export default InvoicesSwitch;