import React from 'react';
import './IconButton.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/pro-light-svg-icons';

const IconButton = ({className, onClick, icon = faPlusSquare, color = "default", ...props}) => {
    return (
        <button className={classNames("IconButton", className, color)} onClick={onClick} {...props}>
            <FontAwesomeIcon icon={icon}/>
        </button>
    );
}

export default IconButton;
