import React, { useContext, useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import SceneTitle from "../../layout/SceneTitle/SceneTitle";
import SceneLinkMenu from "../SceneLinkMenu/SceneLinkMenu";
import TopBar from "../TopBar/TopBar";
import { ApiContext } from "../../../services/api/api-config";
import { paths } from "../../../services/routes/appRoutes";

const SectionHeader = ({ pageInfo, tools }) => {
    const api = useContext(ApiContext);
    const location = useLocation();
    const [updatedLinks, setUpdatedLinks] = useState([]);
   
    useEffect(() => {
        const fetchLinkData = async () => {
            const linksWithAdditionalInfo = await Promise.all(pageInfo.links.map(async (item) => {
                let newItem = { ...item };

                if (item.apiCall) {
                    const data = await api[item.apiCall].get();               
                    newItem['number'] = data.count;
                }

                newItem['to'] = paths[item.to];
                newItem['active'] = location.pathname === paths[item.to];
                return newItem;
            }));
            setUpdatedLinks(linksWithAdditionalInfo);
        };

        fetchLinkData();
    },[api, location.pathname, pageInfo.links]);

    return (
        <div>
            <TopBar />
            <SceneTitle description={pageInfo.description}>{pageInfo.title}</SceneTitle>
            <SceneLinkMenu links={updatedLinks} tools={tools} />
        </div>
    );
}

export default SectionHeader;
