import { useContext, useEffect, useState } from "react"
import TideEntitySelect from "../../../../../components/utility/TideEntitySelect/TideEntitySelect"
import useFormState from "../../../../../hooks/useFormState"
import Button from "../../../../../components/utility/Button/Button"
import { satCatalogSGroups } from "../../../../../services/modelUtils/taxDocumentUtils"
import { prepareInvoicePreferencesForServer } from "../../../../../services/modelUtils/taxDocumentUtils"
import { getNotifier } from "../../../../../services/notifier"
import { ApiContext } from "../../../../../services/api/api-config"
import "./InvoicePreferences.scss"

const InvoicePreferences = () => {
    const api = useContext(ApiContext)
    const {form, setForm, bindSimple} = useFormState()
    const [tenant, setTenant] = useState()

    useEffect(()=>{
        const fetchTenant = async() => {
            const tenant = (await api.tenants.get({params:{sGroups: ['tenant_read_id', 'tenant_read_invoice_preferences']}}))[0]
            setTenant(tenant)

            if(tenant.invoicePreferences){
                const parsedPreferences = JSON.parse(tenant.invoicePreferences)
                setForm({...parsedPreferences})
            }
        }

        fetchTenant()
    }, [api, setTenant, setForm])


    const savePreferences = async() => {
        try{
            const preferencesForm = prepareInvoicePreferencesForServer(form)
            api.tenants.update({id: tenant.id, params: {invoicePreferences: JSON.stringify(preferencesForm)}})
            .then(()=>{
                getNotifier().success("Preferencias actualizadas correctamente");
            })

        }catch(e){
            getNotifier().error(e.message)
        }
    }

    return(
        <div className="main-div">
            <p>Para una facturación más rápida ingresa tus preferencias, podrás modificarlas al momento de facturar si es necesario</p>

            <div className="input-row">
            
                <div className="entity-select">
                    <label>{"Uso de CFDI"}</label>
                    <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Uso de CFDI'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_UsoCFDI"
                        }}
                        preload
                        filterBy={'description'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        {...bindSimple('cfdiUsage')}
                    />
                </div>

                <div className="entity-select">
                    <label>{"Tipo de Comprobante"}</label>
                    <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Tipo de comprobante'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_TipoDeComprobante",
                            "description": "Ingreso"
                        }}
                        preload
                        filterBy={'catalogKey'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        {...bindSimple('receiptType')}
                    />
                </div>
            </div>

            <div className="input-row">

                <div className="entity-select">
                    <label>{"Forma de Pago"}</label>
                    <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Forma de Pago'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_FormaPago",
                        }}
                        preload
                        filterBy={'description'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        {...bindSimple('paymentType')}
                    />
                </div>


                <div className="entity-select">
                    <label>{"Método de Pago"}</label>
                    <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Método de Pago'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_MetodoPago"
                        }}
                        preload
                        filterBy={'description'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        {...bindSimple('paymentMethod')}
                    />
                </div>

             
            </div>

            <div className="input-row">
                <div className="entity-select">
                    <label>{"Exportación"}</label>
                    <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Exportación'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_Exportacion"
                        }}
                        preload
                        filterBy={'description'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        {...bindSimple('export')}
                    />  
                </div>
            </div>


            <Button onClick={savePreferences}>Guardar</Button>
        </div>


    )
}

export default InvoicePreferences