import React, {useCallback, useEffect, useState} from 'react'
import useCallbackCreator from 'use-callback-creator';
import _ from 'lodash';
import { faXmarkCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import useBoolean from '../../../../../hooks/useBoolean';
import WindInput from '../../../../../components/formComponents/WindInput/WindInput'
import Modal from '../../../../../components/utility/Modal/Modal';
import TideEntitySelect from '../../../../../components/utility/TideEntitySelect/TideEntitySelect';
import Button from '../../../../../components/utility/Button/Button';
import { satCatalogSGroups } from '../../../../../services/modelUtils/taxDocumentUtils';
import TaxData from '../TaxData/TaxData';
import { getEmptyTaxes } from '../../../../../services/modelUtils/taxDocumentUtils';
const ProductsData = ({invoiceProduct, onChange, onAdd, onRemove, last, unique}) => {
    
    const [ modalDelete, showModalDelete, hideModalDelete ] = useBoolean(false);
    const [ showingTaxes, showTaxes, hideTaxes ] = useBoolean(false);
    const [taxCount, setTaxCount] = useState(0);

    useEffect(()=>{
        if(invoiceProduct.taxes && invoiceProduct.applyTaxes && invoiceProduct.applyTaxes.catalogKey!=='01'){
            showTaxes()
        }
    },[invoiceProduct, showTaxes])

    const handleChange = useCallbackCreator((name, value) => {
        let updatedProduct = { ...invoiceProduct, [name]: value };
        
        if (name === 'quantity' || name === 'unitValue') {
            const newQuantity = name === 'quantity' ? value : updatedProduct.quantity;
            const newUnitValue = name === 'unitValue' ? value : updatedProduct.unitValue;
            const newTotal = newQuantity * newUnitValue;

            updatedProduct = { ...updatedProduct, total: newTotal.toFixed(2) };
        }else if(name==='applyTaxes'){
            if(value.catalogKey === "02"){
                showTaxes();
                setTaxCount(1);
            }else{
                hideTaxes();
            } 
        }

        onChange(updatedProduct);
    }, [onChange, invoiceProduct]);

    const handleProductRemove = useCallback(() => {
        hideModalDelete();
        onRemove();
    }, [onRemove, hideModalDelete]);


    const handleInvoiceProductTaxChange =  useCallbackCreator((index, invoiceProductTax)=>{
        const newInvoiceProductTax = [...invoiceProduct.taxes]
        newInvoiceProductTax[index] = invoiceProductTax
        const updatedProduct = { ...invoiceProduct, "taxes": newInvoiceProductTax };
        onChange(updatedProduct)
    }, [onChange, invoiceProduct]);

    const handleRemoveInvoiceTaxProduct = useCallbackCreator((index)=>{
        const taxes = [...invoiceProduct.taxes]
        taxes.splice(index, 1)
        setTaxCount(taxes.length)
        const updatedProduct = { ...invoiceProduct, "taxes": taxes };
        onChange(updatedProduct)
    },[onChange, invoiceProduct]);

    const handleAddInvoiceProduct = ()=>{
        const newTaxes = [...invoiceProduct.taxes, getEmptyTaxes()]
        const updatedProduct = { ...invoiceProduct, "taxes": newTaxes };
        onChange(updatedProduct)
    }

    return(
        <>
            <div className='invoice-product'>
                <div className="input-row">

                    <div className="entity-select-label">
                        <label>{"Clave del Producto"}</label>
                        
                        <TideEntitySelect
                            entity='satCatalogs'
                            placeholder='Clave del Producto'
                            additionalFilters={{
                                sGroups: satCatalogSGroups,
                                "catalog": "c_ClaveProdServ"
                            }}
                            filterBy={'description'}
                            labelCreator = {(satCatalogs) => 
                                `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                            }
                            value={invoiceProduct.productKey}
                            onChange={handleChange('productKey')}
                        />
                    </div>

                    <div className="entity-select-label">
                        <label>{"Unidad"}</label>
                        <TideEntitySelect
                            entity='satCatalogs'
                            placeholder='Clave de Unidad'
                            additionalFilters={{
                                sGroups: satCatalogSGroups,
                                "catalog": "c_ClaveUnidad"
                            }}
                            filterBy={'description'}
                            labelCreator = {(satCatalogs) => 
                                `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                            }
                            value={invoiceProduct.unitKey}
                            onChange={handleChange('unitKey')}
                        />
                    </div>

                    <div className="entity-select-label">
                        <label>{"Objeto de Impuesto"}</label>
                        <TideEntitySelect
                            entity='satCatalogs'
                            placeholder='Objeto de Impuesto'
                            additionalFilters={{
                                sGroups: satCatalogSGroups,
                                "catalog": "c_ObjetoImp"
                            }}
                            filterBy={'description'}
                            labelCreator = {(satCatalogs) => 
                                `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                            }
                            preload
                            value={invoiceProduct.applyTaxes}
                            onChange={handleChange('applyTaxes')}
                        />
                    </div>

                </div>


                <div className="input-row">
                    <WindInput
                        placeholder={"Cantidad"}
                        type={'number'}
                        value={invoiceProduct.quantity}
                        onChange={handleChange('quantity')}  
                        
                    />
                
                    <WindInput
                        placeholder={"Valor Unitario"}
                        type={'number'}
                        value={invoiceProduct.unitValue}
                        onChange={handleChange('unitValue')}
                    />

                    <WindInput
                        placeholder={"Importe"}
                        type={'number'}
                        value={invoiceProduct.total}
                        inputProps={{readOnly:true}}
                    />
                </div>

                <div className="input-row">
                    <WindInput
                        placeholder={"Descripción"}
                        type={'text'}
                        value={invoiceProduct.description}
                        onChange={handleChange('description')}  
                    />
                </div>

                {showingTaxes &&
                <>
                    {(taxCount>0) && <div className='taxes-tag'>IMPUESTOS</div>}

                    {
                        _.map(invoiceProduct.taxes, (invoiceTax, index) => (
                            
                            <TaxData
                                key={index}
                                invoiceTax = {invoiceTax}
                                invoiceProduct={invoiceProduct}
                                onChange={handleInvoiceProductTaxChange(index)}
                                onRemove={handleRemoveInvoiceTaxProduct(index)}
                                onAdd={handleAddInvoiceProduct}
                                last={invoiceProduct.taxes.length === index+1}
                                unique={invoiceProduct.taxes.length === 1}

                            />

                        ))

                    }


                </>
                }
            </div>

            {(last || !unique) && 
                <div className='main-buttons'>
                    {last && <Button onClick={onAdd} color="info" outline={true} leftIcon={faPlusCircle}>Agregar producto</Button>}
                    {!unique && <Button onClick={showModalDelete} color="danger" outline={true} leftIcon={faXmarkCircle}>Eliminar producto</Button>}
                </div>
            }
            
            { modalDelete && 
                <Modal
                    mainButtonAction={handleProductRemove}
                    mainButtonText="Eliminar producto"
                    secondaryButtonAction={hideModalDelete}
                    secondaryButtonText="Cancelar"
                >
                    <div className="modal-delete">
                        <div className="single-title">&iquest;Seguro que desea eliminar el producto?</div>
                        <div>Los cambios que se han hecho no serán guardados</div>
                    </div>
                </Modal> 
            }
        </>
        
    )
}

export default ProductsData