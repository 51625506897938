import {createContext} from 'react';
import _ from 'lodash';

const permissionsToProjects = ["PROJECT_LIST", "PROJECT_CREATE", "PROJECT_UPDATE", "PROJECT_DELETE", "PROJECT_SHOW"];
const permissionsToQuotes = ["QUOTE_LIST", "QUOTE_CREATE", "QUOTE_UPDATE", "QUOTE_DELETE", "QUOTE_SHOW"];
const permissionsToClients = ["CLIENT_LIST", "CLIENT_CREATE", "CLIENT_UPDATE", "CLIENT_DELETE", "CLIENT_SHOW"];
const permissionsToUsers = ["USER_LIST", "USER_CREATE", "USER_UPDATE", "USER_DELETE", "USER_SHOW"];
const permissionsToTransactions = ["TRANSACTION_LIST", "TRANSACTION_CREATE", "TRANSACTION_UPDATE", "TRANSACTION_DELETE", "TRANSACTION_SHOW"];
const permissionsToRecurringPayments = ["RECURRING_PAYMENT_LIST", "RECURRING_PAYMENT_CREATE", "RECURRING_PAYMENT_UPDATE", "RECURRING_PAYMENT_DELETE", "RECURRING_PAYMENT_SHOW"];
const permissionsToBankAccounts = ["BANK_ACCOUNT_LIST", "BANK_ACCOUNT_CREATE", "BANK_ACCOUNT_UPDATE", "BANK_ACCOUNT_DELETE", "BANK_ACCOUNT_SHOW"];
const permissionsToMemorandums = ["MEMORANDUM_LIST", "MEMORANDUM_CREATE", "MEMORANDUM_UPDATE", "MEMORANDUM_DELETE", "MEMORANDUM_SHOW"];
//const permissionsToQuotationRequests = ["QUOTATION_REQUEST_LIST", "QUOTATION_REQUEST_CREATE", "QUOTATION_REQUEST_UPDATE", "QUOTATION_REQUEST_DELETE", "QUOTATION_REQUEST_SHOW"];
const permissionsToListBankAccounts = ["BANK_ACCOUNT_LIST", "BANK_ACCOUNT_SHOW"];
const permissionsToListUsers = ["USER_LIST", "USER_SHOW"];
const permissionsToFiscalData = ['FISCAL_DATA_LIST','FISCAL_DATA_CREATE','FISCAL_DATA_UPDATE','FISCAL_DATA_DELETE','FISCAL_DATA_SHOW'];
const permissionsToTaxDocuments = ['TAX_DOCUMENT_LIST','TAX_DOCUMENT_CREATE','TAX_DOCUMENT_UPDATE','TAX_DOCUMENT_DELETE','TAX_DOCUMENT_SHOW'];
const permissionsToBankTransactions = ['BANK_TRANSACTION_LIST','BANK_TRANSACTION_CREATE','BANK_TRANSACTION_UPDATE','BANK_TRANSACTION_DELETE','BANK_TRANSACTION_SHOW']
const permissionsToQuoteTransactions = ['QUOTE_TRANSACTION_LIST','QUOTE_TRANSACTION_CREATE','QUOTE_TRANSACTION_UPDATE','QUOTE_TRANSACTION_DELETE','QUOTE_TRANSACTION_SHOW']
export default class SecurityManager{
    constructor(me){
        if( !me )
            throw new Error("The Security object requires an user to be initialized");

        this.me = me;
        this.role = (me.role && me.role.name) ? me.role.name:'';
        this.permissions = me.permissionsArray||[];
    }

    havePermission($permission){
        if(this.role==='SUPER_ADMIN'){
            return true;
        }
        return _.includes(this.permissions, $permission);
    }

    haveAllPermissions= (permissions=[])=>
        this.role==='SUPER_ADMIN' || permissions.reduce((haveAll, permission)=>haveAll&&this.havePermission(permission), true);

    haveAtLeastOnePermission= (permissions)=>
        this.role==='SUPER_ADMIN' || !!_.intersection(this.permissions, permissions).length>0;

    isActualUser= (user)=> !!(user && user.id===this.me.id );

    canSeeEntity= ()=>true;

    isSuperAdmin = () => this.me.role.name === 'SUPER_ADMIN';
    
    // Users
    isTenantUser = () => this.me.role.name === 'GENERAL_ADMIN';

    // Update
    canUpdateUserPermissionGroups = () => this.havePermission('USER_PERMISSION_GROUP_UPDATE');

    ///////////////////////////
    // System permissions
    canAdminProjects = () => this.haveAllPermissions(permissionsToProjects);
    canAdminQuotes = () => this.haveAllPermissions(permissionsToQuotes);
    canAdminClients = () => this.haveAllPermissions(permissionsToClients);
    canAdminUsers = () => this.haveAllPermissions(permissionsToUsers);
    canAdminTransactions = () => this.haveAllPermissions(permissionsToTransactions);
    canAdminRecurringPayments = () => this.haveAllPermissions(permissionsToRecurringPayments);
    canAdminBankAccounts = () => this.haveAllPermissions(permissionsToBankAccounts);
    canAdminMemorandums = () => this.haveAllPermissions(permissionsToMemorandums);
    canAdminFiscalData = () => this.haveAllPermissions(permissionsToFiscalData);
    canAdminTaxDocuments = () => this.haveAllPermissions(permissionsToTaxDocuments);
    canAdminQuotationRequests = () => true; //this.haveAllPermissions(permissionsToQuotationRequests); // TODO: Add permission in front
    canAdminBankTransactions = () => this.haveAllPermissions(permissionsToBankTransactions);
    canAdminQuoteTransactions = () => this.haveAllPermissions(permissionsToQuoteTransactions);
    canListUsers = () => this.haveAllPermissions(permissionsToListUsers);
    canListBankAccounts = () => this.haveAllPermissions(permissionsToListBankAccounts);
}

export const SecurityContext = createContext(null);
