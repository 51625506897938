import moment from "moment";

export const naviMessageTableColumns = [
    {
        Header: 'Mensaje',
        accessor: 'userInput',
        Cell: ({ row }) => {
            const hasError = !!row.original.errorMessage;
            const className = hasError ? 'user-input error' : 'user-input no-error';

            return (
                <div className="message-cell">
                    <div className={className}>
                        {row.original.userInput}
                    </div>
                    <div className="message-meta">
                        {moment(row.original.createdDate).format('DD/MM/YYYY HH:mm')} • {row.original.executionMilis} ms
                    </div>
                </div>
            );
        },
    },
    // Removed the 'Error' column
    // Add more columns if necessary
];

export const naviMessageTableSGroups = [
    'navi_message_read',
    'navi_message_read_id',
    'navi_message_read_user_input',
    'navi_message_read_error_message', // Ensure this is included
    'navi_message_read_execution_milis',
    'navi_message_read_thought_process',
    'read_created_date',
];
