import React, {useContext, useState} from 'react';
import {ApiContext} from '../../../services/api/api-config';
import './NaviAgent.scss';
import WindSelect from '../../../components/formComponents/WindSelect/WindSelect';
import MarkdownRenderer from 'react-markdown-renderer';
import {faMessageBot, faUser} from "@fortawesome/pro-light-svg-icons";
import naviPng from '../../../assets/images/navi.png';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DynamicTable from "./components/DynamicTable";
import ThoughtProcess from "./components/ThoughtProcess";


const agentName = 'Navi';
const modelOptions = [
    {value: 'gpt-4o-mini', label: 'GPT-4o Mini'},
    {value: 'qwen2.5-coder:latest', label: 'Qwen 2.5 Coder (7B)'},
    {value: 'gpt-4-turbo', label: 'GPT-4 Turbo'},
    {value: 'qwen2.5:7b', label: 'Qwen 2.5 (7B)'},
    {value: 'qwen2.5:14b', label: 'Qwen 2.5 (14B)'},
    {value: 'gemma2:9b', label: 'Gema2 (9B)'},
    {value: 'gpt-4o', label: 'GPT-4o'},
    {value: 'mistral-nemo', label: 'Mistral Nemo'},
    {value: 'llama3.1:8b', label: 'Llama 3.1 (8B)'},
    {value: 'llama3.2:3b', label: 'Llama 3.2 (3B)'},
    {value: 'granite3-dense:8b', label: 'Granite 3 Dense (8B)'},
    {value: 'mannix/defog-llama3-sqlcoder-8b:latest', label: 'SQL coder Llama3 (8B)'},
    {value: 'sqlcoder:latest', label: 'SQL coder (8B)'},

];


const translateStepName = (stepName) => {
    switch (stepName) {
        case 'getTheUserObjective':
            return 'Entendiendo la pregunta...';
        case 'getTablesForQuery':
            return 'Obteniendo información necesaria para la consulta...';
        case 'getTableDefinitions':
            return 'Obteniendo resultados de la consulta...';
        case 'getSQLForUserRequest':
            return 'Obteniendo resultados de la consulta...';
        case 'convertSqlToDql':
            return 'Realizando interpretación de resultados...';
        case 'interpretSqlResults':
            return 'Realizando los últimos ajustes...';
        default:
            return `Procesado ${stepName || ''}...`;


    }
}

;
const NaviAgent = () => {
    const api = useContext(ApiContext);
    const [model, setModel] = useState(modelOptions[0].value);
    const [input, setInput] = useState('');
    const [conversation, setConversation] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [questionId, setQuestionId] = useState(0);


    const sendMessage = async () => {
        if (!input.trim()) return;

        const qId = questionId + 1;
        setQuestionId(qId);

        const userMessage = {sender: 'User', model, text: input, icon: faUser};
        setConversation((prev) => [...prev, userMessage]);

        const url = `${api.host}/api/wind_agent/ask`;
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${api.token}`},
            body: JSON.stringify({model, message: input}),
        };

        setIsLoading(true); // Start loading state

        let initialAgentMessage = {
            sender: agentName,
            icon: faMessageBot,
            image: naviPng,
            model,
            questionId: qId,
            thoughtProcess: null,
            text: 'Cargando respuesta...',
        };

        setConversation((prev) => [...prev, initialAgentMessage]);

        try {
            const response = await fetch(url, requestOptions);
            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            let buffer = ''; // Buffer to accumulate partial JSON data

            while (true) {
                const {done, value} = await reader.read();
                if (done) break;

                // Decode the chunk and accumulate in buffer
                buffer += decoder.decode(value, {stream: true});

                // Process each complete JSON message (each chunk should end with '\n')
                let boundary = buffer.indexOf('\n');
                while (boundary !== -1) {
                    // Extract the complete JSON message
                    const chunk = buffer.slice(0, boundary).trim();
                    buffer = buffer.slice(boundary + 1); // Remove processed chunk from buffer

                    try {
                        const data = JSON.parse(chunk);
                        console.log(data);


                        if (data.thoughtProcess && data.thoughtProcess.steps && data.thoughtProcess.steps.length > 0) {
                            const latestStep = data.thoughtProcess.steps[data.thoughtProcess.steps.length - 1].stepName;

                            // Create a new message object with the latest step
                            const updatedAgentMessage = {
                                ...initialAgentMessage,
                                actualStep: latestStep,
                                questionId: qId,
                                thoughtProcess: data.thoughtProcess,
                            };

                            setConversation((prev) => {
                                const newConversation = [...prev];
                                newConversation[newConversation.length - 1] = updatedAgentMessage;
                                return newConversation;
                            });
                        }

                        // If we have the final response, set it and stop updating
                        if (data.response) {
                            console.log("llegaa");

                            const finalAgentMessage = {
                                ...initialAgentMessage,
                                text: data.response,
                                questionId: qId,
                                thoughtProcess: data.thoughtProcess,
                            };

                            setConversation((prev) => {
                                const newConversation = [...prev];
                                newConversation[newConversation.length - 1] = finalAgentMessage;
                                return newConversation;
                            });
                            setIsLoading(false); // End loading state
                            break; // Exit the loop as the response is complete
                        }
                    } catch (err) {
                        console.error('Failed to parse chunk:', chunk, err);
                    }

                    boundary = buffer.indexOf('\n'); // Look for the next complete chunk
                }
            }
        } catch (error) {
            console.error('Error fetching agent response:', error);
            setConversation((prev) => [
                ...prev,
                {
                    sender: agentName,
                    icon: faMessageBot,
                    image: naviPng,
                    text: 'Ocurrió un error al obtener la respuesta.',
                    model,
                    questionId: qId,
                    thoughtProcess: null,
                },
            ]);
        } finally {
            setIsLoading(false); // Ensure loading state ends in case of errors
        }

        setInput('');
    };


    const handleModelChange = (selectedOption) => {
        setModel(selectedOption.value);
    };

    return (
        <div className="WindAgent">
            <div className="header">
                <h2> Pregunta a N<strong>a</strong>v<strong>i</strong></h2>
            </div>
            <div className="conversation">
                {conversation.map((msg, index) => (
                    <div key={index} className={`message ${msg.sender.toLowerCase()}`}>
                        <div className="message-sender">
                            <div className="icon">
                                {msg.image ? (
                                    <img src={msg.image} alt={msg.sender} className="sender-image"/>
                                ) : (
                                    <FontAwesomeIcon icon={msg.icon}/>
                                )}
                            </div>
                            <span className="sender-name">{msg.sender}</span>
                        </div>
                        <br/>
                        {isLoading && msg.sender === agentName && questionId === msg.questionId &&
                            <div className="loading">
                                <div className="loader"></div>
                                <p className='fade-in'>{translateStepName(msg.actualStep)}</p>
                            </div>
                        }
                        {msg.text && <div className='fade-in'><MarkdownRenderer markdown={msg.text}/></div>}
                        {msg.thoughtProcess?.resultData?.dqlResult && <div className='fade-in'><DynamicTable data={msg.thoughtProcess?.resultData?.dqlResult}/></div>}
                        {msg.sender === agentName && msg.thoughtProcess && (
                            <ThoughtProcess thoughtProcess={msg.thoughtProcess} />
                        )}

                        {msg.sender === agentName && msg.thoughtProcess === null && (
                            <p><em>No hay proceso de pensamiento disponible.</em></p>
                        )}
                        {msg.sender === agentName && <small>{msg.model}</small>}
                    </div>
                ))}
            </div>
            <div className="input-container">
                <WindSelect
                    label=""
                    value={modelOptions.find((option) => option.value === model)}
                    options={modelOptions}
                    onChange={handleModelChange}
                />
                <input
                    type="text"
                    disabled={isLoading}
                    placeholder="Escribe alguna pregunta..."
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
                />
                <button disabled={isLoading} onClick={sendMessage}>Enviar</button>
            </div>
        </div>
    );
};

export default NaviAgent;
