import React, { useMemo, useCallback } from 'react';
import './BankStatementList.scss';
import { faFileExcel, faPlus } from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router-dom';
import ToolBar from '../../../components/utility/ToolBar/ToolBar';
import TopBar from '../../../components/utility/TopBar/TopBar';
import { paths } from '../../../services/routes/appRoutes';
import TideReactTable from '../../../components/utility/TideReactTable/TideReactTable';
import useTideTable from '../../../components/utility/TideReactTable/useTideTable';
import { accountStatementsListSGroups, accountStatementsTableColumns } from '../../../services/modelUtils/bankStatementUtils';

const requestFilters={sGroups: accountStatementsListSGroups};
const BankStatementList = () => {
  const history = useHistory();

  //Table config
  const { tableProps } = useTideTable({
      entity: 'accountStatements',
      columns: accountStatementsTableColumns,
      requestFilters
  });

  // ------ Toolbar definition ------
  const exportToExcel = useCallback(() => {}, []);
  const handleAdd = useCallback(() => history.push(paths.bankStatementsForm), [history]);
  const tools = useMemo(() => [
    {icon: faPlus, callback: handleAdd, text: "Crear nuevo estado bancario", testId: "add-bank-statement-button"},
    {icon: faFileExcel, callback: exportToExcel, text: "Descargar excel", testId: 'export-excel-button'},
  ], [handleAdd, exportToExcel]);

  const onRowClick = useCallback((row) => {
    history.push(paths.bankStatementDetail.replace(':id', row.id) )
}, [history]);

  return (
    <div className={"BankStatementList wind-scene"}>

      <TopBar
        title="Estados bancarios"
        titleLinkBack
      />

      <ToolBar tools={tools} />

      <div className='center-container'>
        <TideReactTable {...tableProps} className="table-hoverable" onRowClick={onRowClick} />
      </div>
    </div>
  );
}
 
export default BankStatementList;
