
/**
 * Icons from
 * https://icons8.com/icons/set/delete-row
 */

export const addRowAboveIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAACMklEQVRoge2ZS07DMBCGP/oQC+iSCq5AOQYgViCk3oBtVV53KHu2sKN74AwVQoKy4dWqB0BiC2xaQWHhiRLSJHVKG7sov2Q58djJ/B7PeJyAPorAHdCU66nEAnAPfEtpAUtGNRoBfhJTSaYIPKAUd+pvX5v1y8xriRawiEvEL7PaMrcMzrpDBH5b6yZx7WLgmsEI5SWCyJrSd6rgJ2INMqYVGBdSIrYhJWIb/j2RKwZzK2/YDZIlVRpBCs+EELFyr/BgQO9c3AGGETrB/95Hpg4pEduQErENKRHbkBKxDSkRQzgGXuMMsPZrCZANarTNInmgDNRRXzA/gHe5rossls4mLLIFdBh+sOpIXy0kSSQDHHne+QjsASVgTkoJ2AeepE8fqKFhnSSJOCS6QIUQHxBkgSrQkzG1YQ+f9Ll7R96zhZrdLrAaoMclcBHQviZk+sBmFJHGBEm8ALMox3Z8ohKiR9TK2MX1mXwUmUmjjOsTYcspikgOeBZ52WT43Zb6BPgaYfwncCrX2lFsEmijZnM5os+woLMi8vYY9YqNN1GiIPeX6PuZEwAKcv9m084eJ9xblT45S6sU0Ud7aZm0SFPq9T88Y0Nqoz9jnfD7xOjhtyXy8ti1iwHvhlgN6RNFZB9LNkRwU5QeKu3w4wI4D2hfRzNFSRI11Mz2UGlH1B+CHMoS2kljksigFOrj+swBKiLNS1kBDnFTEu003gQ20T9YWbOcwuA/6r5LeQbORBbo2D8yBQ/IbCwEXAAAAABJRU5ErkJggg==";
export const deleteRowIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAA+0lEQVRIie3VPU7DQBCG4Qd6oCG5BRdAFLShROQONHAHfm4E1wihT0c4QGKoEwp7I2sFlh2vXSB/0ld4d0fv7GhmzaBBg/6LrrDEtmMvMSmD+4AGf5TBYbFr7TiHPcB+1QDuWrs+6ht8iUWcSYquHuMZc3wXfsNTsQcnqcFTZP6e3ww3cVBb8BSbCmjwJoa3AY9V3zT2GqPQXJ9RAnV8W8Tc46hBose4Cx8T+RvaBHxWxL43jNvKG661ymU+rzh3UTqXpZ7jg5p7SX5Ic3uUOsWNX/eIeUnAbTxOK5ymAJM/CnUfkOtU0DJ8XQFddQENGuERM3wVnuFBVN4fKHLJKGO5kSUAAAAASUVORK5CYII=";
export const addColumnBeforeIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAB90lEQVRoge2YuUpDQRSGv4idSTqDtpJaY62NBktbG9/E5SW0ERstXV5B3IK4oI0igloJgpEIQtRCwVjcc5mrZLnLSRzC/HC461k+ZubcBZycnJxa6AioKVtJs8AccAEctLhPG8I3NYgrCXgaEkRLavH6gUsJdgMMdCqxZryoEGqJNePFgVBJrB3vhOgLUn1xJo3XI9YV+ju1BkP6WTUivuLAWAkC0WGsBYHfD8SzTiZuQzxywDlw3OHE6iD/lThx++0KORDb5EBskwOxTQ7ENjkQ2+RAbJONIClgClgH7oEP4A3vY28VKGonbNdrfCmw38j2gLx2Yu14NeAJmANGgD6xAjAv12rACzCumVhD+UC8DSDd5N4MsImBSTwymiCHGIhUiPtTwJb47CZNrgUyiZlO9UaiUZ4MUJZrRRu61qxsl/C6U1hVgWXZn0lSgNaI3Eqc4Rh5Cpj/cLGlBfIucYLTKuyP87QcV22YWl8JfP36v20AeZDtUOBcKmDNzvk+j70KhWi14Gm8f89RfcBr37EV5lUijH1i2m+mTp5GazELPMu1iSQgmtrHK2iT8A/EbfHZaWNdkZUHXvEK26L+yPjKYiAq/F5bVmgMA1MGFoBRvBablv1FzHSqiI+VymPeu5rZDhaORD0VgTXgDu/DqgpcAys0WNg/mfYN0YoVbmwAAAAASUVORK5CYII=";
export const addColumnAfterIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAACGklEQVRoge2ZO07DQBCGP/IQBaQEwRUIx4CIKhGSb0CLeN4h1NTQQU9yBSIEAire4gJQA00iCBQ7xlZkm8Q72FbkX1r5sbsz+9m7s+MEcuXKlesPnQHfyqWTKIFIG8ItUToFroHZ/wBJ0t6ltLlFESYNkBngRto9AvNJOf4Pe+owaYFAAMyEpWMAGxtB9uLooqA0iLRVtOmcpak1l5RjTXuqEKM41rSnDjGsY217V4zJhnjOmKQooRqX8JuDZE45SNaUg2RNOUjWlDWQlzScaqcoa8Ckor2hpQ0CUAYc4BiTpn8A73J+LHVlZZ/qIA3g2Wc3rDxLWzVpgRSAPZ+9O2ALqAJTUqrANnAvbfpAE6U1rgXiQnSBdaJ/SCgCG0BP+jQV/KuANDBPtwssBdS3gVbA/WUMTB+oW47BGqSMtybWY/jYxFszVgHAFsTBWxNh0ynKRwl4kHonzQ1xVY4HwFeM/p/AoZxbRTHbN/Ik/RcsfCxK/ZPFOKxB3qR/Ra7bPpt/FTcAVOT6LUu51igPRTWj0JpaVQsfv1MrzTdyLceahY0VOV7aDCTOH53fwL70d8PvPfHD76PUOzYgnZggrzII/4a4EQNkG6UNUUNuitLDpB2DagEnAfdrKKYoWmpinmwPk3aUItqWMG9CNWnUUgEzoD7emtnBRKRpKYvALl5KoprGa6vO8B9WmZlOYRr81H2X8gAcEfGp+wNAeinO2M4NUgAAAABJRU5ErkJggg==";
export const deleteColumnIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAACGElEQVRoge2aS07DMBCGv7bQDeUKHIByAkACFrBEUKFeoQIhHoeAAmeAFY/DIDY8BKXAjpYDlAVdUFh4TCJUESeNEwP5pZHbxjP2V8f2KA6YqQjsA23gIyFrAXVpOzbtJQjw3XbjBGlJ0Mk4gwZoCm9kYpP+d5KWcbt5yx1JTBmIa8pAXFMG4poyENc0lHYHApQzrfhnRsQ1kCJQBc6ABvAq1gBO5dpAaX0SSWMFeCI4rX8ElqM2YhMkDxz42rgENoBxYESsDGwCV756e0S4o2yCaIg3oMbPncsDq1JXw4SSLZAKHsRMCL9ZPJilMA3aACnizYlaBP818X0Ahk2dbIBU8eZElNWzAFxLjJU0l1+98hwCvQj+78CRfDa+vWyMyL3EHB8gRlliNEwdbIB0JGapTztBpjUq3zuu7exhpXOxXpogbSnHfL/lDI1vvi9pglxIOT9AjAUpz00dbC6/V0Rffm8kxoqpk60N8VHirkbwXxffJilviKD2Ep2izIbwmwO6qP1nMUyDNpNG/aT/DZV2FH6oW0CNRJeIT+ptp/H+Y4trYAu12ZXEJoBtvDnRQ0E4lcZrLaESwKDNsEnI28mvpI4VhlEr0Alwh9r9O8AtcCzXjCd2P6V1PmKs356ifMl1kOzE6tcqA3FNGYhrykBc078D0Q8Kpmx1pI+mpYz1pZo66b3mtBMnSFFg9OtOSdizQBidUH0CUBgWcHOkeHAAAAAASUVORK5CYII=";
export const addRowBelowIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAACH0lEQVRoge2ZzS5DQRTHf6hYYKkPoX0MxEoj6RvYCso71N6WHXvtI9CQEFY+4wEktq2NhtZizui47r3m3tuPEfNLJvd2Ps9/Zs7cMyl4PB6Px+PxeDz/nTOg62hqhBk8FiGkm0b9EPlhdy5pgxETOcHjw7RikHghruGFuIYX4hpeiGt4Ia7x14TsAS9JGuiQ2UUmwjJdW5FJoAwcAQ/AK9CS9yMpS2TzKFakBDzx+8XqSepaMUwh48CuMeYtsAUUgGlJBaAC3EmdDlDFYnWGKUSLeAPWifABYQLYANrSpvpb54O+d6/JOCXU7L4BCyF21IFaSP6iiOkAK3FCGgMU8QxMoRxb+8R6hB1xO2OTns9MxolJ2nFSyvR8Imo7xY2XA+6lvDzK43dVnvvAR4r278CBvFufYpp+rsij9DWfYbyilD8mHbyfQprS16z8rmPvZ/oAmJXfTZe+7EkmKPNkDmJrFTKM97W1RrkiV/JcytDHsjwv4yqdymB5Iy84Q3ngHDhJYYQ+fu9If/w+SHk5bqBLqXRDT4zZcV7KusCFne3fMD+IGxF14oRUsPwgmoZqMbrjsLI06BCljQo7gtSA45D8JSxDFE3Q4G7gPYsITVX6aqPCjrh/CHKolbAOGk1MMWa6JbsIUKF4FTW72me2USfSjKQisEMvJLEO44MExfRLhMkK9hcrq+0UxRzqFLum/yI0watuS9I9cChloY79CRmcF42xpu4LAAAAAElFTkSuQmCC";