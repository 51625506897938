import React from 'react';
import { createPortal } from "react-dom";
import './Modal.scss';
import useBodyClass from "../../../hooks/useBodyClass";
import classNames from "classnames";
import SceneTitle from '../../layout/SceneTitle/SceneTitle';
import { faArrowLeft, faTimes } from '@fortawesome/pro-light-svg-icons';
import IconButton from '../IconButton/IconButton';
import Button from "../Button/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Modal = ({
                   onClose,
                   children,
                   title,
                   className,
                   large,
                   mainButtonText='Confirmar',
                   mainButtonColor='success',
                   mainButtonDisabled = false,
                   mainButtonAction,
                   secondaryButtonText='Cancelar',
                   secondaryButtonColor='danger',
                   secondaryButtonDisabled = false,
                   secondaryButtonAction,
                   handleTitleClick = null,
                   titleTooltip = null,
                   showBackArrow=false,
}) => {

    useBodyClass('overflow-hidden');

    return createPortal(<>
        <div className={classNames("Modal", large &&'large',className)} >
            <div className='back-overlay' onClick={onClose} />
            
            <div className='inner-modal'>
                <IconButton
                    icon={faTimes}
                    onClick={onClose}
                    className={'close-btn'}
                    color='danger'
                />

                {handleTitleClick && showBackArrow && 
                    <button className='back-button' onClick={handleTitleClick}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>}

                {title && <SceneTitle secondary className="modal-title">{title}</SceneTitle>}
                <div className='modal-content'>
                    {children}
                </div>
                <div className='modal-footer'>
                    {secondaryButtonAction &&
                    <Button outline onClick={secondaryButtonAction} disabled={secondaryButtonDisabled}>
                        {secondaryButtonText}
                    </Button>}
                    {mainButtonAction &&
                    <Button onClick={mainButtonAction} disabled={mainButtonDisabled}>
                        {mainButtonText}
                    </Button>}
                </div>
            </div>
        </div>
    </>, document.body);
};

export default Modal;
