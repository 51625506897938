import React, {useCallback} from 'react';
import './WindTextarea.scss';
import classNames from "classnames";

const WindTextarea = ({ value, onChange, placeholder, type, label, className, inputProps={}, ...props }) => {

    const handleChange = useCallback((e)=>{
        onChange?.(e.target.value);
    },[onChange]);

    return (
        <div className={classNames( "WindTextarea", value&&"with-content", className)} {...props}>
            { (label)? <div>{label}</div>: null }
            <textarea
                className='textarea-el'
                value={ (typeof value === 'undefined' || value === null  )? '':value}
                onChange={handleChange}
                {...inputProps}
            />
            {!!placeholder && <span className='placeholder'>{placeholder}</span>}
        </div>
    );
};

export default WindTextarea;
