import React from 'react';
import './WindDatePicker.scss';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowLeftToLine,
    faArrowRight,
    faArrowRightToLine,
    faCalendar
} from "@fortawesome/pro-light-svg-icons";

const WindDatePicker = ({ className, value, onChange, label, disabled, withLabel=false }) => {
    let dateValue = value;
    if ( typeof(value) === "string" && value.length>0 ) {
        dateValue = new Date( value );
    }
    return (
        <div className={classNames("WindDatePicker", className)}>
            {!!label && <div className='label'>{label}</div>}
            <DatePicker
                locale={'es-mx'}
                calendarIcon={<FontAwesomeIcon icon={faCalendar}/>}
                nextLabel={<FontAwesomeIcon icon={faArrowRight}/>}
                next2Label={<FontAwesomeIcon icon={faArrowRightToLine}/>}
                prevLabel={<FontAwesomeIcon icon={faArrowLeft}/>}
                prev2Label={<FontAwesomeIcon icon={faArrowLeftToLine}/>}
                value={dateValue}
                onChange={onChange}
                disabled={disabled}
                clearIcon={null}
                format="dd/MM/yyyy"
            />
        </div>
    );
};

export default WindDatePicker;
