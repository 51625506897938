import React from 'react';
import './UserAvatar.scss';
import classNames from 'classnames';
import { useContext } from 'react';
import { ApiContext } from '../../../../../services/api/api-config';
import { getUserAvatarUrl } from '../../../../../services/modelUtils/userUtils';

const UserAvatar = ({ user, className }) => {
  const api = useContext(ApiContext);

  const style = { backgroundImage: `url(${getUserAvatarUrl(user, api)})` };

  return (
    <div className={classNames("UserAvatar", className)} style={style} />
  );
}
 
export default UserAvatar;