
import React, {useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {projectSelectorSGroups} from "../../../services/modelUtils/projectUtils";
import {ApiContext} from '../../../services/api/api-config';
import useFormState from '../../../hooks/useFormState';
import {getEmptyMemorandum, prepareMemorandumForServer} from '../../../services/modelUtils/memorandumUtils';
import {faSave} from '@fortawesome/pro-light-svg-icons';
import './MemorandumForm.scss';
import TopBar from '../../../components/utility/TopBar/TopBar';
import ToolBar from '../../../components/utility/ToolBar/ToolBar';
import {useSelector} from 'react-redux';
import SimpleCard from '../../../components/layout/SimpleCard/SimpleCard';
import RichTextEditor from '../../../components/formComponents/RichTextEditor/RichTextEditor';
import { getNotifier } from '../../../services/notifier';
import TideEntitySelect from '../../../components/utility/TideEntitySelect/TideEntitySelect';
import { memorandumDetailSGroups } from '../../../services/modelUtils/memorandumUtils';
import { paths } from '../../../services/routes/appRoutes';
import {useHistory} from "react-router-dom";

const MemorandumForm = () => {
  const api = useContext(ApiContext);
  const { form, setForm, bindInput } = useFormState(() => getEmptyMemorandum());
  const { id } = useParams();
  const richTextEditor = useRef();
  const history = useHistory();

  // ----- Autofocus first input -----
  const titleInputRef = useRef();
  useLayoutEffect(()=>titleInputRef.current.focus() ,[]);

  // ------ Preload project if needed ------
  const { search } = useLocation();

  useEffect(()=>{
    const preloadProjectId = (new URLSearchParams(search)).get('project');
    if( preloadProjectId ){
      api.projects.get( { id:preloadProjectId, params:{sGroups: projectSelectorSGroups}, customProp:'_' } ).then( project=>{
      if(project)
        setForm( form=>({...form, project, client: project.client}));
      })
    }
  },[api, search, setForm]);

  useEffect(()=>{
    if(id) {
        api.memoranda.get({ id, params: { sGroups: memorandumDetailSGroups } })
            .then(memorandum => {
              setForm( ()=>({...memorandum, project: memorandum.project, client: memorandum.project.client}));

            })
            .catch(() => getNotifier().error('No se encontró el proyecto solicitado'));
    }
},[api, id, setForm]);

  // ------ Save to server ------
  const loadingId = 'MemorandumForm.create.p'+id;
  const loading = useSelector(s=>!!s.loadingIds[loadingId]);

  const handleSave = useCallback(()=>{
    const {id} = form;
    const apiMethod = id ? 'update' : 'create';
    let memorandum;

    try{
      memorandum = prepareMemorandumForServer({...form}, form?.client?.id);
    }
    catch (e){
        return getNotifier().warning(e.message);
    }

    const params = {
      id,
      loadingId,
      params: memorandum,
    };

    api.memoranda[apiMethod](params)
        .then((memorandum)=>{
          getNotifier().success(`La minuta se ha ${id?'editado':'creado'} correctamente`);
          history.replace( paths.memorandumDetail.replace(':id', memorandum.id) );
        });
  }, [form, api, loadingId, history]);

  // ----- Filter projects selector -----
  const projectFilters = useMemo(() =>({
    client: form.client?.id,
    'order[updatedDate]':'DESC',
    sGroups: projectSelectorSGroups
  }), [form.client]);

  // ------ Toolbar definition ------
  const tools = useMemo(()=>[
    { icon: faSave, callback: handleSave, text: "Guardar minuta", disabled: loading  }
  ],[handleSave, loading]);

  const setTextEditorReference = useCallback((newTextEditor) => {
    richTextEditor.current = newTextEditor;
    form.getDescription = newTextEditor?.getContentHTML;
  },[form]);

  useEffect(() => {
    form.getDescription = richTextEditor.current.getContentHTML;
  }, [form]);

  // If the client change, delete the project if it's from another client
  const handleClientChange = useCallback((client)=>{
    setForm(form=>({
      ...form,
      client,
      project: form?.project?.client?.id === client.id? form.project : null
    }))
  },[setForm]);

  // If the project change, set the client
  const handleProjectChange = useCallback((project)=>{
    setForm(form=>({
      ...form,
      project,
      client: project?.client,
    }))
  },[setForm]);

  return (
    <div className={"MemorandumForm wind-scene"}>

      <TopBar
        title={ id ? "Editar minuta" : "Nueva minuta" }
        titleLinkBack
      />

      <ToolBar tools={tools} />

      <div className='center-container'>
        <div className='memorandum-header' >
          <input 
            className='title-input' 
            placeholder="Agrega un título a la minuta" 
            {...bindInput('title')} 
            ref={titleInputRef} 
          />
        </div>

        <SimpleCard className='general-info' title="Información general">
          <div className='fields'>
            <TideEntitySelect
              className='project-field'
              entity='clients'
              placeholder={"Compañía"}
              preload={true}
              value={form.client}
              onChange={handleClientChange}
            />

            <TideEntitySelect
              className='project-field'
              entity='projects'
              placeholder={"Proyecto"}
              value={form.project}
              onChange={handleProjectChange}
              additionalFilters={projectFilters}
              preload={true}
            />
          </div>
        </SimpleCard>

        <SimpleCard title='Contenido' className='main-info'>
          <div className='description'>
            <RichTextEditor
              ref={setTextEditorReference}
              html={form?.content}
              placeholder={'Agrega una descripción ...'}
            />
          </div>
        </SimpleCard>
      </div>
    </div>
  )
}

export default MemorandumForm;