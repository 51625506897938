import React, {useState} from 'react';
import './AdvancedLocalFilters.scss';
import _ from 'lodash';
import classNames from 'classnames';
import useCallbackCreator from "use-callback-creator";
import {filterTypes} from "../../../../../services/searchUtils";
import SearchBarTextFilter from "./filters/SearchBarTextFilter/SearchBarTextFilter";
import SearchBarOrderFilter from "./filters/SearchBarOrderFilter/SearchBarOrderFilter";
import SearchBarSelectFilter from "./filters/SearchBarSelectFilter/SearchBarSelectFilter";
import SearchBarDateFilter from './filters/SearchBarDateFilter/SearchBarDateFilter';
import { faFilter, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const filterDefinition = {
    [filterTypes.TEXT]: SearchBarTextFilter,
    [filterTypes.ORDER]: SearchBarOrderFilter,
    [filterTypes.SELECT]: SearchBarSelectFilter,
    [filterTypes.DATE]: SearchBarDateFilter,
}
const FilterComponent = ({type, ...props})=>{
    const Component = filterDefinition[type]||filterDefinition[filterTypes.TEXT];
    return <Component {...props} />
};

const AdvancedLocalFilters = ({ onFiltersChange, filters, filtersConfig, title, labels=true, more=true, longfield=false }) => {
    const [ toggleFilterClass, setToggleFilterClass ] = useState("toggle-filter");
    const [ toggleFilterIcon, setToggleFilterIcon ] = useState(faFilter);
    const [ toggleFilterText, setToggleFilterText ] = useState("Más filtros");

    const filterList = _.filter( filtersConfig?.filters, filter=>!filter.main );
    const handleFilterChange = useCallbackCreator((field, value)=>{
        const newFilters = {...filters};
        if (value === '' || typeof value === 'undefined')//Erase keys of empty filters
            delete newFilters[field];
        else // Or set the new value to filter
            newFilters[field] = value;
        onFiltersChange(newFilters);
    },[filters, onFiltersChange]);

    if(!filterList.length)
        return null;

    const firstChunkSize = 2;
    const remainingChunkSize = 4;

    const firstChunk = filterList.slice(0, firstChunkSize);
    const remainingChunk = _.chunk(filterList.slice(firstChunkSize), remainingChunkSize);

    const chunkedFilterList = [firstChunk, ...remainingChunk];

    const switchVisibleFilter = () => {
        if ( !toggleFilterClass ) {
            setToggleFilterClass("toggle-filter");
            setToggleFilterText("Más filtros");
            setToggleFilterIcon(faFilter);
        }
        else {
            setToggleFilterClass("");
            setToggleFilterText("Cerrar");
            setToggleFilterIcon(faXmark);
        }
    }

    return (
        <div className={"AdvancedLocalFilters"}>
            {chunkedFilterList.map((row, index) => 
                <div className={classNames("filterRow" , ( (index>0)? toggleFilterClass: null ))} key={index}>
                    {
                        (index === 0) ?
                        <div className={classNames("advancedFiltersTitle", ( longfield? "short-title": "" ))}>{title}</div>:null
                    }
                    {row.map(filter => 
                        <FilterComponent
                            key={filter.field}
                            type={filter.type}
                            value={filters[filter.field]}
                            onChange={handleFilterChange(filter.field)}
                            withLabel={labels}
                            config={filter}
                        />
                    )}
                    {
                        (index === 0 && more) ?
                        <button onClick={switchVisibleFilter} className="toggle-filter-button">
                            <FontAwesomeIcon icon={toggleFilterIcon} />&nbsp;{toggleFilterText}
                        </button>:null
                    }
                </div> 
            )}
        </div>
    );
};

export default AdvancedLocalFilters;
