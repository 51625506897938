import React, { Fragment } from 'react';
import SelectorMenuItem from '../../MenuItems/SelectorMenuItem/SelectorMenuItem';
import MenuItem from '../../MenuItems/MenuItem/MenuItem';
import {faTable, faTrash} from '@fortawesome/pro-light-svg-icons';
import _ from 'lodash';
import { addRowAboveIcon, addRowBelowIcon, deleteRowIcon, addColumnAfterIcon, deleteColumnIcon, addColumnBeforeIcon } from '../../../../../../services/iconsUtils';

const TablesMenuGroup = ({ editor }) => {
  const isInTable = editor.isActive('table');

  let tableSubMenuItems = [];
  const tableMenuItem = [
    {
      icon: faTable,
      title: 'insert_table',
      tooltipText: 'Insertar tabla',
      action: () => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
    }
  ];

  if(isInTable){
    tableSubMenuItems = [
      {
        icon: faTrash,
        title: 'delete_table',
        tooltipText: 'Borrar tabla',
        action: () => editor.chain().focus().deleteTable().run()
      },
      {
        base64Icon: addRowBelowIcon,
        title: 'add_row_below',
        tooltipText: 'Insertar renglón debajo',
        action: () => editor.chain().focus().addRowAfter().run()
      },
      {
        base64Icon: addRowAboveIcon,
        title: 'add_row_above',
        tooltipText: 'Insertar renglón arriba',
        action: () => editor.chain().focus().addRowBefore().run()
      },
      {
        base64Icon: deleteRowIcon,
        title: 'delete_row',
        tooltipText: 'Borrar renglón',
        action: () => editor.chain().focus().deleteRow().run()
      },
      {
        base64Icon: addColumnBeforeIcon,
        title: 'add_column_before',
        tooltipText: 'Insertar columna a la izquierda',
        action: () => editor.chain().focus().addColumnBefore().run()
      },
      {
        base64Icon: addColumnAfterIcon,
        title: 'add_column_after',
        tooltipText: 'Insertar columna a la derecha',
        action: () => editor.chain().focus().addColumnAfter().run()
      },
      {
        base64Icon: deleteColumnIcon,
        title: 'delete_column',
        tooltipText: 'Borrar columna',
        action: () => editor.chain().focus().deleteColumn().run()
      },
    ];
  }

  return (
    <>
    {_.map([...tableMenuItem, ...tableSubMenuItems], (item) => (
      <Fragment key={item.title}>
        {item.type === 'selector' && <SelectorMenuItem {...item} />}
        {!item.type && <MenuItem {...item} />}
      </Fragment>
    ))}
    </>
  );
}

export default TablesMenuGroup;