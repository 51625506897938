import React, {useContext} from 'react';
import {ApiContext} from "../../../../../../services/api/api-config";

const ImagePreview = ({ appFile }) => {

    const api = useContext(ApiContext);
    const url = api.appFiles.getUrl(appFile);

    return (
        <div className={"ImagePreview preview-window"}>
            <img src={url} alt={`Archivo ${appFile.originalName}`} className='preview-image'/>
        </div>
    );
};

export default ImagePreview;
