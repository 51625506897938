import React, {useCallback, useContext} from 'react';
import './FileDetailsModal.scss';
import Modal from "../../../../utility/Modal/Modal";
import InfoPair from "../../../../layout/InfoPair/InfoPair";
import moment from "moment";
import {bytesToString, isPreviewableExtensions, shouldShowInstantPreview} from "../../../../../services/fileUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImageSlash, faSearch} from "@fortawesome/pro-light-svg-icons";
import useBoolean from "../../../../../hooks/useBoolean";
import FilePreview from "../FilePreview/FilePreview";
import classNames from "classnames";
import {ApiContext} from "../../../../../services/api/api-config";

const FileDetailsModal = ({ appFile, onClose, onDelete }) => {

    const [showPreview, starPreview] = useBoolean( shouldShowInstantPreview(appFile) );
    const isPreviewable = isPreviewableExtensions(appFile.originalName);
    const extension = appFile.originalName.split('.').pop().toLowerCase();

    // -- pass delete event to parent --
    const [deleting, startDeleting, stopDeleting] = useBoolean();
    const handleDelete = useCallback(()=>{
        stopDeleting();
        onDelete(appFile);
    },[onDelete, stopDeleting, appFile]);

    // -- Download action --
    const api = useContext(ApiContext);
    const [downloaded, setDownloaded] = useBoolean();
    const downloadFile = useCallback(()=>{
        setDownloaded();
        window.location.href = api.appFiles.getDownloadUrl(appFile);
    },[api, appFile, setDownloaded]);

    return (!deleting?
        <Modal
            className={"FileDetailsModal"}
            title={appFile.originalName}
            onClose={onClose}
            large={showPreview}
            mainButtonText={ !downloaded? 'Descargar' : 'Descargado'}
            mainButtonAction={downloadFile}
            mainButtonDisabled={downloaded}
            secondaryButtonText={'Eliminar'}
            secondaryButtonAction={startDeleting}
        >
            <div className='general-info'>
                <InfoPair title='Creador' value={appFile?.createdBy?.fullName} />
                <InfoPair title='Fecha de carga' value={moment(appFile?.createdDate).format('DD/MM/YYYY')} />
                <InfoPair title='Tamaño' value={bytesToString(appFile?.size)} />
            </div>
            {showPreview ?
                <FilePreview appFile={appFile} />
                :
                <div className='preview-placeholder' onClick={isPreviewable?starPreview:undefined}>
                    <FontAwesomeIcon icon={isPreviewable ? faSearch : faImageSlash} className={classNames('icon', isPreviewable&&'search-icon')}/>
                    <span className='hint'>
                        {isPreviewable ? 'Click para cargar vista previa' : `No es posible visualizar archivos ${extension}`}
                    </span>
                </div>
            }
        </Modal>:
        <Modal
            onClose={stopDeleting}
            title={'Eliminar archivo'}
            mainButtonText={'Eliminar'}
            mainButtonAction={handleDelete}
            mainButtonColor={'danger'}
            secondaryButtonAction={stopDeleting}
        >
            ¿ Estás seguro que deseas eliminar el archivo <strong>{appFile.originalName}</strong> de manera permanente ?
        </Modal>
    );
};

export default FileDetailsModal;
