import { copyPropertyOrNull, copyPropertyOrThrow } from "../formUtils";

export const getEmptyQuotationRequestProduct = ()=>({
    id: Math.random(),
    name: null,
    quantity: 1,
    isOptional: false,
});

export const prepareProductForServer = ( form, clientId )=>{
    let product = {}
    product.client=clientId;
    copyPropertyOrThrow( form, product, "name", "Por favor completa el nombre de productos agregados" );
    copyPropertyOrThrow( form, product, "quantity", "Por favor completa la cantidad de productos agregados" );
    copyPropertyOrNull( form, product, "isOptional");

    if(form.quantity < 0 ) {
        throw new Error("La cantidad de los productos no pueden ser negativos");
    };

    product.quantity = Number(form.quantity);
    product.description = form.getDescription()||null;
    return product;
}