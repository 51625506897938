import moment from "moment";
import { Link } from "react-router-dom";
import { copyPropertyOrThrow } from "../formUtils";
import {projectDetailSGroups} from "./projectUtils";
import { paths } from "../../services/routes/appRoutes";

export const getEmptyMemorandum = () => ({
    title: "",
    content: "",
    folio: ""
});

export const prepareMemorandumForServer = ( form, clientId )=>{
    let memorandum = {};

    if(!form.getDescription())
        throw new Error('Agrega el contenido de la minuta');

    if(!clientId)
        throw new Error('Debes seleccionar el cliente');

    if(!form.project?.id)
        throw new Error('Debes seleccionar un proyecto');

    copyPropertyOrThrow( form, memorandum, "title", "Agrega un nombre a la minuta");
    memorandum.content = form.getDescription()||null;
    memorandum.client = clientId;
    memorandum.project = form.project?.id;

    return memorandum;
};

export const memorandumListSGroups = [
    'memorandum_read_id',
    'memorandum_read_title',
    'memorandum_read_created_date',
    'read_created_date',
    'read_created_by',
    'user_read_full_name',
];

export const memorandumDetailSGroups = [
    'memorandum_read_id',
    'memorandum_read_title',
    'memorandum_read_folio',
    'memorandum_read_content',
    'memorandum_read_project',
    'memorandum_read_created_date',
    'read_created_date',
    'read_created_by',
    'user_read_full_name',
    '',
    ...projectDetailSGroups
];

export const memorandumTableListSGroups = [
    'memorandum_read_id',
    'memorandum_read_title',
    'memorandum_read_created_date',
    'read_created_date',
    'read_created_by',
    'user_read_full_name',
    'memorandum_read_project',
    ...projectDetailSGroups
];

export const memorandumTableColumns = [
    {Header:"Título", id: 'title', 
        accessor: m => 
            <Link to={paths.memorandumDetail.replace(":id", m.id)} data-tooltip="Ver detalles de la minuta" data-test-id="see-memorandum-detail-link">
                {m.title}
            </Link>
    },
    {Header:"Creador", accessor:'createdBy.fullName'},
    {Header:"Proyecto", id: 'project', 
        accessor: m => 
            <Link to={paths.projectDetail.replace(":id", m.project?.id)} data-tooltip="Ver detalles del proyecto" data-test-id="see-project-detail-link">
                {m.project?.name}
            </Link>,
    },
    {Header:"Creación", id: 'createdDate', accessor: (q) => (q.createdDate? moment(q.createdDate).format('DD/MM/YYYY'):null)},
]

