import React, { useCallback, useEffect, useRef } from 'react';
import './Switch.scss';
import classNames from "classnames";
import switchAnimation from '../../../assets/animations/51052-switch-elastic.json';
import { Player } from '@lottiefiles/react-lottie-player';

const Switch = ({ value, onChange, className, ...props }) => {
  const lottie = useRef(null);
  const setLottieRef = useCallback(instance=>lottie.current = instance, []);

  const animationStyle = {
    cursor: 'pointer',
    width: '250%',
    position: 'relative',
    transform: 'translate(-30%, -38%)',
  };

  const handleClick = useCallback(()=>{
    (value) ? onChange?.(false) : onChange?.(true);
  },[value, onChange]);

  useEffect(()=>{
    if(value){
      lottie.current?.playSegments([0, 105], true);
    }else{
      lottie.current?.playSegments([105, 210], true);
    }
  },[value]);

  const handleLoad = useCallback((event)=>{
    if (event !== 'load') { return };

    if (value) { lottie.current?.playSegments([0, 105], true); }
  },[value]);

  return (
    <div
      className={classNames("Switch", className)} {...props}
      onClick={handleClick}
    >
      <Player
        className='switch-animation'
        onEvent={handleLoad}
        keepLastFrame={true}
        lottieRef={setLottieRef}
        src={switchAnimation}
        style={animationStyle}
        speed={2.5}
      />
    </div>
  );
}

export default Switch;
