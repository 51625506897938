import { copyPropertyOrNull, copyPropertyOrThrow, copyPropertyIdOrNull } from "../formUtils";

export const tenantTableSGroups = [
    'tenant_read',
];

export const getEmptyAdmin = () => {
    return {
        id: null,
        name: null,
        lastName: null,
        client: null,
        tenant:null,
        isActive: true,
        username: null,
        email: null,
        phone: null,
        role: null,
        sendAccess: true,
    };
};

export const prepareAdminForServer = ( form )=>{
    let user = {}

    copyPropertyOrThrow( form, user, "name", "Por favor llena el campo de nombre" );
    copyPropertyOrThrow( form, user, "lastName", "Por favor llena el campo de apellido" );

    copyPropertyIdOrNull( form, user, "tenant" );
    copyPropertyIdOrNull( form, user, "role" );
    copyPropertyOrThrow( form, user, "isActive", "Por favor selecciona si el usuario es activo" );
    copyPropertyOrThrow( form, user, "email", "Por favor llena el campo de email de usuario" );

    copyPropertyOrNull( form, user, "phone" );
    copyPropertyOrNull( form, user, "sendAccess" );

    user.username = user.username||user.email;
    
    return user;
}

export const prepareTenantForServer = ( form )=>{
    let tenant = {}
    copyPropertyOrThrow( form, tenant, "alias", "Por favor llena el campo de nombre del tenant" );    
    return tenant;
}

export const superAdminTableColumns = [
    {
        Header: 'Nombre',
        accessor: (user) => user.name,
        id: 'userName',
    },
    {
        Header: 'Email',
        accessor: 'email'
    },
]

export const userTableSGroups = [
    'user_read',
    'user_read_client',
        'client_read_name',
        'client_read_full_name',
        'client_read_id',
    'user_read_position'
];