import React, {useCallback, useContext, useEffect} from 'react'
import './TransactionFormModal.scss';
import {ApiContext} from "../../../services/api/api-config";
import {getNotifier} from "../../../services/notifier";
import {prepareCompleteTransactionForServer, transactionStatusList, transactionStatus, transactionTypeSelect} from "../../../services/modelUtils/transactionUtils";
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import IconButton from '../../../components/utility/IconButton/IconButton';
import WindInput from '../../../components/formComponents/WindInput/WindInput';
import WindDatePicker from '../../../components/formComponents/WindDatePicker/WindDatePicker';
import WindSelect from '../../../components/formComponents/WindSelect/WindSelect';
import TideEntitySelect from '../../../components/utility/TideEntitySelect/TideEntitySelect';
import Modal from '../../../components/utility/Modal/Modal';
import useFormState from '../../../hooks/useFormState';


const initialFormState = {
    concept: '',
    transactionDate: new Date(), 
    transactionType: transactionTypeSelect[0],
    amount:  '',
    status: transactionStatusList.find(s => s.value===transactionStatus.APPROVED),
    bankAccount: ''
};

const TransactionFormModal = ({onClose, onTransactionSaved, editData}) => {
    
    const api = useContext(ApiContext);
    const loadingId = 'TransactionFormModal.create';
    const title = editData ? 'Editar transacción' : 'Nueva transacción'; 

    const {form, bindSimple, setForm} = useFormState(() => initialFormState);

    useEffect(() => {
        if (editData) {
            const transformedData = {
                ...editData,
                transactionType: {value:  editData.transactionType === 'income' ? 'income' : 'outcome' , 
                    label: editData.transactionType === 'income' ? 'Ingreso': 'Egreso'},
                status: transactionStatusList.find(s => s.value===editData.status),
            } 
        
            setForm(transformedData);
        }
    }, [editData, setForm]);


    const onTransactionError = useCallback((action) => {
        return () => {
            getNotifier().error(`Ha ocurrido un error al ${action} la transacción`);
        };
    }, []);

    const onTransactionSuccess = useCallback((action) => {
        return () => {
            getNotifier().success(`La transacción se ha ${action} correctamente`);
            if (onTransactionSaved) {
                onTransactionSaved();
            }
            if (onClose) {
                onClose();
            }
        };
    }, [onTransactionSaved, onClose]);

    const handleDeleteTransaction = useCallback((trx)=>{

        api.transactions.delete({id: trx.id})
            .then(onTransactionSuccess("eliminado"))
            .catch(onTransactionError("eliminar"))
            
    }, [api, onTransactionSuccess, onTransactionError])

    const handleCreateTransaction = useCallback(()=>{
        let transaction;
        const action = editData ? 'modificado' : 'creado';
        try{
            transaction = prepareCompleteTransactionForServer(form);
        }
        catch (e){
            return getNotifier().warning(e.message);
        }

        const transactionPromise = editData ? 
            api.transactions.update({id: editData.id,params: transaction}) : 
            api.transactions.create({params: transaction, loadingId}) ; 

        transactionPromise.then(onTransactionSuccess(action)).catch(onTransactionError(action))

    }, [api, loadingId, editData,onTransactionSuccess, onTransactionError, form ]);


    return (
        <Modal className={'TransactionFormModal'}
            title={title}
            onClose={onClose}
            secondaryButtonAction={onClose}
            mainButtonAction={handleCreateTransaction}
            mainButtonText="Guardar"
            >

            <div className='row'>
                <WindInput
                    placeholder="Concepto"
                    {...bindSimple('concept')}
                />
            </div>


            <div className='row'>
                <WindDatePicker
                    label="Fecha de la transacción"
                    {...bindSimple('transactionDate')}
                />
            </div>

            <div className='row'>
                <WindSelect
                    placeholder="Tipo de transacción"
                    {...bindSimple('transactionType')}
                    options={transactionTypeSelect}
                />
            </div>

            <div className='row'>
                <WindInput
                    placeholder="Cantidad"
                    {...bindSimple('amount')}
                />
            </div>

            <div className='row'>
                <WindSelect
                    placeholder="Estado de la transacción"
                    {...bindSimple('status')}
                    options={transactionStatusList}
                />
            </div>

            <div className='row'>
                <div><label>Cuenta</label></div>
                <TideEntitySelect
                    entity='bankAccounts'
                    placeholder={"Cuenta"}
                    autoSelect={true}
                    {...bindSimple('bankAccount')}
                />
            </div>

            {editData && <IconButton
                    className={'deleteBtn'}
                    icon={faTrash}
                    color={ 'danger' }
                    onClick={() => { handleDeleteTransaction(editData) }}/>}
       </Modal>       
    )
}

export default TransactionFormModal;