import { useEffect } from "react";
import WindInput from "../../../../../components/formComponents/WindInput/WindInput";

const TotalData = ({form, setForm }) => {

    useEffect(() => {
        if (!form.invoiceProducts) return;
    
        const { subtotal, total, withheldTax, transferredTax } = form.invoiceProducts.reduce(
            (totals, product) => {
                const productSubtotal = parseFloat(product.total || 0);
    
                const withheldTaxes = product.taxes
                    .filter(tax => tax?.taxType?.value === "Retenido")
                    .reduce((acc, tax) => acc + parseFloat(tax.amount || 0), 0);
    
                const transferredTaxes = product.taxes
                    .filter(tax => tax?.taxType?.value === "Trasladado")
                    .reduce((acc, tax) => acc + parseFloat(tax.amount || 0), 0);
    
                const productTotal = productSubtotal + transferredTaxes - withheldTaxes

                return {
                    subtotal: (parseFloat(totals.subtotal) + productSubtotal).toFixed(2),
                    withheldTax: (parseFloat(totals.withheldTax) + withheldTaxes).toFixed(2),
                    transferredTax: (parseFloat(totals.transferredTax) + transferredTaxes).toFixed(2),
                    total: (parseFloat(totals.total) + productTotal).toFixed(2)
                };
            },
            { subtotal: 0, withheldTax: 0, transferredTax: 0, total:0 }
        );
    
        setForm(prevForm => ({
            ...prevForm,
            subtotal,
            total,
            withheldTax,
            transferredTax
        }));
    }, [form.invoiceProducts, setForm]);

    return (
        <>
        <div className="input-row">
            <WindInput
                placeholder={"Subtotal"}
                type={'number'}
                value={form.subtotal}
                inputProps={{ readOnly: true }}
            />

            <WindInput
                placeholder={"Impuestos Trasladados"}
                type={'number'}
                value={form.transferredTax}
                inputProps={{ readOnly: true }}
            />

            <WindInput
                placeholder={"Impuestos Retenidos"}
                type={'number'}
                value={form.withheldTax}
                inputProps={{ readOnly: true }}
            />

            <WindInput
                placeholder={"Total"}
                type={'number'}
                value={form.total}
                inputProps={{ readOnly: true }}
            />
            </div>
        </>

    );
}

export default TotalData;