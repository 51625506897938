import React, {forwardRef, useRef} from 'react';
import './WindInputFile.scss';
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-light-svg-icons";

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const WindInputFile = forwardRef((props, ref) => {
    const _ref = useRef(null);
    const inputFileRef = ref ?? _ref;
    const {
        onChange,
        className = '',
        inputProps = {},
        label = '',
        ...containerProps
    } = props;

    const handleChange = event => {
        onChange?.(event);
    }

    const removeFile = () => {
        onChange?.(null);
        inputFileRef.current.value = null;
    }

    return (
        <div
            className={classNames("WindInput WindInputFile", className)}
            {...containerProps}>
            {label.length > 0 &&
                <div>
                    <label>{label}</label>
                </div>
            }
            <input
                {...inputProps}
                className={classNames('input-el', inputProps.className)}
                onChange={handleChange}
                type="file"
                ref={inputFileRef}
            />
            <div className="icon" onClick={removeFile}>
                <FontAwesomeIcon icon={faXmark}/>
            </div>
        </div>
    );
});

export default WindInputFile;
