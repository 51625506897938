import React from 'react';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import store from "./services/redux/store";
import {Provider} from "react-redux";
import {createRoot} from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Provider store={store}>
    <App/>
</Provider>);

//serviceWorker.register();
