
export const conversationsSGroups = [
    'conversation_read',
        'channel_read_id',
        'channel_read_name',
        'channel_read_messages',
        'channel_read_enable_external_users',
        'channel_read_enable_internal_users',
            'channel_read_channel_users',
            'channel_user_read_id',
            'channel_user_read_user',
            'message_read_id',
            'message_read_message',
            'message_read_created_date',
            'message_read_app_file',
            'message_read_user',
            'user_read',
                'user_read_avatar',
                'user_read_full_name',
    'conversation_read_client',
        'client_read_id',
    'app_file_read',

];