import React from 'react';
import Modal from '../../../../../components/utility/Modal/Modal';

const RemoveQuotationRequestModal = ({ onRemove, onClose }) => {

  return (
    <Modal
      className={"RemoveQuotationRequestModal"}
      title={"Eliminar Solicitud de Cotización"}
      onClose={() => onClose()}

      mainButtonText='Eliminar'
      mainButtonColor='danger'
      mainButtonAction={onRemove}

      secondaryButtonColor='success'
      secondaryButtonAction={() => onClose()}
    >
      <div className="RemoveQuotationRequestModal__content">
        ¿Estás seguro de que deseas eliminar esta solicitud de cotización?
      </div>
    </Modal>
  );
}
 
export default RemoveQuotationRequestModal;
