import React from 'react';
import { useHistory } from 'react-router-dom';
import { moneyFormatter } from '../../../../../services/currencyUtils';
import InfoPair from '../../../../../components/layout/InfoPair/InfoPair';
import Button from '../../../../../components/utility/Button/Button';
import { paths } from '../../../../../services/routes/appRoutes';

import "../../ClientDetail.scss";


const ClientInfo = ({ client }) => {

  const history = useHistory();

  const handleEdit = () => {
    history.push(paths.clientEdit.replace(":id", client.id));
  }

  return (
      <div className="info-container">
        <div className="client-tab-header">
          <div className="single-title">Información general</div>
          <div><Button onClick={handleEdit} outline>Editar</Button></div>
        </div>
        

        <InfoPair title="Razón social" value={client?.companyName} className="info-pair-client" />
        <InfoPair title="Contacto principal" value={client?.companyOwner?.fullName||"- - -"} className="info-pair-client" />
        <InfoPair title="Total aprobado" value={"$ " + moneyFormatter(client?.clientStats?.approvedQuotesSum||0)} className="info-pair-client" />
        <InfoPair title="Total cotizado" value={"$ " + moneyFormatter(client?.clientStats?.allQuotesSum||0)} className="info-pair-client" />
        <InfoPair title="Iniciales" value={client?.initials||"- - -"} className="info-pair-client" />
      </div>
   
  );
}

export default ClientInfo;
