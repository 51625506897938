import _ from "lodash";
import moment from "moment";
import { copyPropertyOrNull, copyPropertyOrThrow, copyPropertyIdOrThrow } from "../formUtils";

export const prepareProjectForServer = ( form )=>{
    let project = {};

    copyPropertyOrThrow( form, project, "name", "Agrega un nombre al proyecto");
    copyPropertyIdOrThrow( form, project, "client", "Selecciona el cliente al que pertenece el proyecto");

    copyPropertyOrNull( form, project, "description");
    copyPropertyOrNull( form, project, "startDate");
    copyPropertyOrNull( form, project, "endDate");

    project.status = form.status.value||null;

    return project;
};

export const convertProjectToForm = ( project )=>{
    return {
        ...project,
        status: project.status? { value: project.status, label: projectStatusTrans[project.status] }:null,
        startDate: project.startDate ? moment(project.startDate).add('1', 'day').toDate() : null,
        endDate: project.endDate ? moment(project.endDate).add('1', 'day').toDate() : null,
    }
};

export const projectStatusTrans = {
    PENDING: "Pendiente",
    IN_DEVELOPMENT: "En desarrollo",
    CANCELED: "Cancelado",
    OPEN: "Abierto",
    CLOSED: "Cerrado",
};

export const projectStatusTransColor = {
    PENDING: "danger",
    IN_DEVELOPMENT: "info",
    CANCELED: "warning",
    OPEN: "success",
    CLOSED: "success",
};



export const projectStatusLabels = {
    PENDING: 'Pending',
    IN_DEVELOPMENT: 'In Development',
    CANCELED: 'Canceled',
    OPEN: 'Open',
    CLOSED: 'Closed',
};



export const getProjectStatusTrans = (status)=>projectStatusTrans[status]||status;

export const projectStatus = {
    PENDING: 'PENDING',
    IN_DEVELOPMENT: 'IN_DEVELOPMENT',
    CANCELED: 'CANCELED',
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
};


export const projectStatusTextColor = {
    PENDING: "#EB5757",
    IN_DEVELOPMENT: "#0066FF",
    CANCELED: "#F2994A",
    OPEN: "#6FCF97",
    CLOSED: "#6FCF97",
};

export const projectStatusBackgroundColor = {
    PENDING: "#f7cdcd",
    IN_DEVELOPMENT: "#B9D1FF",
    CANCELED: "#FFF4D1",
    OPEN: "#DBFFEA",
    CLOSED: "#DBFFEA",
};

export const projectStatusOptions = Object.keys(projectStatus).map((key) => {
    return { 
        value: projectStatus[key], 
        label: projectStatusTrans[key],
        bgColor: projectStatusBackgroundColor[projectStatus[key]],
        textColor: projectStatusTextColor[projectStatus[key]]
    };
});

export const projectStatusOptionsForSelect = _.map(projectStatus, (value) => {
    return { value: value, label: projectStatusTrans[value] };
});

export const projectListSGroups = [
    'project_read_id',
    'project_read_name',
    'project_read_status',
    'project_read_client',
        'client_read_id',
        'client_read_name',
        'client_read_full_name',
        'client_read_initials',
    'project_read_project_stats',
        'project_stats_read',
    'project_read_folio',
    'read_created_by',
        'user_read',
];

export const projectTableSGroups = [
    'project_read_id',
    'project_read_name',
    'project_read_status',
    'project_read_client',
        'client_read_name',
        'client_read_full_name',
    'project_read_project_stats',
        'project_stats_read',
    'project_read_updated_date',
    'project_read_folio',
    'read_created_by',
        'user_read'
];

export const projectDetailSGroups = [
    'project_read',
    'project_read_client',
        'client_read_id',
        'client_read_name',
        'client_read_full_name',
        'client_read_initials',
    'project_read_file_container',
    'project_read_quotes',
        'quote_read',
        'quote_read_pending_amount',
        'file_container_read_id',
        'file_container_read_client',
    'project_read_project_stats',
    'project_stats_read',
    'conversation_read',
    'project_read_folio',
    'read_created_by',
        'user_read',
];

export const projectSelectorSGroups = [
    'project_read_id',
    'project_read_name',
    'project_read_client',
        'client_read_id',
        'client_read_full_name',
        'client_read_name',
    'read_created_by',
        'user_read',
];
