import React from 'react';
import './MenuItem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const MenuItem = ({ icon, action, isActive, base64Icon=null, tooltipText=null }) => {
  return (
    <button
      className={classNames("MenuItem", (isActive && isActive()) && "is-active")}
      onClick={action}
      data-tooltip={tooltipText}
    >
      {!base64Icon ? 
        <FontAwesomeIcon icon={icon} /> : 
        <img src={base64Icon} style={{width: '19px'}} alt={tooltipText} />}
    </button>
  );
}
 
export default MenuItem;