import React from 'react';
import './SceneTitle.scss';
import classNames from "classnames";

const SceneTitle = ({ Tag = "h1", children, secondary, className, description, ...props }) => {

    if(secondary){
        Tag = "h2";
    }

    return (
        <Tag className={classNames("SceneTitle", className, secondary && "secondary")} {...props}>
            <div className='title-content'>{children}</div>
            { <div className='title-description'>{description}</div> || '' }
        </Tag>
    );
};

export default SceneTitle;
