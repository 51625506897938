import _ from 'lodash';
import React, { useEffect, useContext, useState } from 'react';
import './UserPermissionsForm.scss';
import useCallbackCreator from 'use-callback-creator';
import WindSwitch from '../../../../../components/formComponents/WindSwitch/WindSwitch';
import SimpleCard from '../../../../../components/layout/SimpleCard/SimpleCard';
import { ApiContext } from '../../../../../services/api/api-config';
import { usersDetailSGroups } from '../../../../../services/modelUtils/userUtils';
import { STATE_ACTIONS } from "tide-api";
import { getNotifier } from '../../../../../services/notifier';

const UserPermissionsForm = ({ user }) => {
  const [ permissionGroups, setPermissionGroups ] = useState([]);
  const api = useContext(ApiContext);

  // Load available permissions from the server
  useEffect(() => {
    api.permissionGroups.get().then(permissionGroups => {
      const filteredPermissions = permissionGroups.filter(permission => 
        !["Administrar categorías de transacciones", "Administrar minutas", "Administrar productos de cotización"].includes(permission.name)
      ); //Right now these exluded permissions have no use (12/06/2024)
  
      setPermissionGroups(filteredPermissions);
    });
  }, [api, setPermissionGroups]);

  const customProp = 'UserDetail.get.'+user.id;


  // ----- Update permissions
  const handleUserPermissionGroupsChange = useCallbackCreator((permissionGroupId, boolean) => {
    const newUserPermissionGroupsIds = user.permissionGroups.map(p => p.id);

    if (boolean) {
      newUserPermissionGroupsIds.push(permissionGroupId);
    } else {
      const index = newUserPermissionGroupsIds.findIndex(id => id === permissionGroupId);
      newUserPermissionGroupsIds.splice(index, 1);
    }

    api.users.update({
      id: user.id,
      customProp,
      params: { permissionGroups: newUserPermissionGroupsIds, sGroups: usersDetailSGroups },
      stateAction: STATE_ACTIONS.STATE_ACTION_SET
    })
      .then(() => getNotifier().success('Permisos actualizados.'));
  }, [user]);

  return (
    <SimpleCard
      className="UserPermissionsForm"
      title="Permisos"
    >
      <div className="UserPermissionsForm__content">
        { user?.permissionGroups && _.map(permissionGroups, (permissionGroup) => {
          const hasPermission =  _.map(user.permissionGroups, 'id').includes(permissionGroup.id);

          return (
            <WindSwitch
              className='UserPermissionsForm__permission-group'
              key={permissionGroup.id}
              placeholder={permissionGroup.name}
              value={hasPermission}
              onChange={handleUserPermissionGroupsChange(permissionGroup.id)}
            />
          );
        }) }
      </div>
    </SimpleCard>
  );
}
 
export default UserPermissionsForm;
