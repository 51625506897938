import React from "react";
import classNames from "classnames";
import StatusIndicator from "../StatusIndicator/StatusIndicator";

import "./HorizontalStatsIndicator.scss";

const HorizontalStatsIndicator = ({ stats }) => {
    
    return (
        <div className="HorizontalStatsIndicator">
            {
                ( typeof(stats) === 'object' && stats.length>0 )?
                stats.map(
                    (stat) => <div className={classNames("stats-item")} key={stat.text}>
                        <StatusIndicator text={stat.text} type={stat.type} number={stat.number} layout="short" />
                    </div>
                )
                :
                null
            }
        </div>
    );

}

export default HorizontalStatsIndicator;