import React, { Fragment } from 'react';
import SelectorMenuItem from '../../MenuItems/SelectorMenuItem/SelectorMenuItem';
import MenuItem from '../../MenuItems/MenuItem/MenuItem';
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faAlignJustify,
  faListOl,
  faListUl,
} from '@fortawesome/pro-duotone-svg-icons';
import useBoolean from '../../../../../../hooks/useBoolean';
import _ from 'lodash';
import useCallbackCreator from "use-callback-creator";

const ParagraphFormatGroup = ({ editor }) => {
  const [ textAlignSelector,,,toggleTextAlignSelector ] = useBoolean();

  const textAlign = editor.getAttributes('heading').textAlign || editor.getAttributes('paragraph').textAlign;

  const textAlignmentIcons = {
    left: faAlignLeft,
    center: faAlignCenter,
    right: faAlignRight,
    justify: faAlignJustify,
  }

  const handleTextAlignChange = useCallbackCreator((align) => {
    editor.chain().focus().setTextAlign(align).run();

    toggleTextAlignSelector();
  }, [editor, toggleTextAlignSelector]);


  const items = [
    {
      icon: faListOl,
      title: 'ordered_list',
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.isActive('orderedList'),
    },
    {
      icon: faListUl,
      title: 'bullet_list',
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive('bulletList'),
    },
    {
      defaultIcon: faAlignLeft,
      icon: textAlignmentIcons[textAlign]||faAlignLeft,
      title: 'text_align',
      action: () => toggleTextAlignSelector(),
      isOpen: textAlignSelector,
      type: 'selector',
      isActive: textAlign !== 'left',
      options: [
        {
          icon: faAlignLeft,
          title: 'Left',
          action: handleTextAlignChange('left'),
        },
        {
          icon: faAlignCenter,
          title: 'Center',
          action: handleTextAlignChange('center'),
        },
        {
          icon: faAlignRight,
          title: 'Right',
          action: handleTextAlignChange('right'),
        },
        {
          icon: faAlignJustify,
          title: 'Justify',
          action: handleTextAlignChange('justify'),
        }
      ],
    },
  ];

  return (
    <>
    {_.map(items, (item) => (
      <Fragment key={item.title}>
        {item.type === 'selector' && <SelectorMenuItem {...item} />}
        {!item.type && <MenuItem {...item} />}
      </Fragment>
    ))}
    </>
  );
}
 
export default ParagraphFormatGroup;