
import React, { useCallback, useMemo, useContext } from 'react';
import TopBar from "../../../components/utility/TopBar/TopBar";
import ToolBar from "../../../components/utility/ToolBar/ToolBar";
import {paths} from "../../../services/routes/appRoutes";
import {faFileExcel, faPlus} from "@fortawesome/pro-light-svg-icons";
import {useHistory} from "react-router-dom";
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import {filterTypes} from "../../../services/searchUtils";
import {memorandumTableColumns, memorandumTableListSGroups} from "../../../services/modelUtils/memorandumUtils";
import {ApiContext} from "../../../services/api/api-config";
import useSearchHistory from '../../../hooks/useSearchHistory';
import useUrlFilters from '../../../hooks/useUrlFilters';

const requestFilters={sGroups: memorandumTableListSGroups};
const filtersConfig = {
    placeholder: 'Buscar un minuta...', filters: [
        {main: true, field: 'title'},
        {type: filterTypes.TEXT, field: 'title', label:'Buscar por título'},
        {type: filterTypes.TEXT, field: 'project.name', label:'Buscar por proyecto'},
        {type: filterTypes.TEXT, field: 'client.fullName', label:'Buscar por cliente'},
    ]
};

const MemorandumList = () => {
    const urlFilters = useUrlFilters();
    
    const api = useContext(ApiContext);
    const history = useHistory();

    //Table config
    const table = useTideTable({
        entity: 'memoranda',
        columns: memorandumTableColumns,
        requestFilters,
        ...urlFilters
    });

    const { tableProps, filters, setFilters } = table;

    useSearchHistory(table);

    const exportToExcel = useCallback(() => {window.location.href = api.memoranda.getExportExcelUrl({pagination: false, ...requestFilters})}, [api]);
    const handleAdd = useCallback(()=>history.push(paths.memorandumNew), [history]);

    const tools = useMemo(()=>[
        { icon: faPlus, callback: handleAdd, text: "Agregar nueva minuta", testId: 'add-memorandum-button' },
        {icon: faFileExcel, callback: exportToExcel, text: "Descargar excel", testId: 'export-excel-button'},
    ],[handleAdd, exportToExcel]);

    return (
        <div className={"MemorandumList wind-scene"}>

            <TopBar
                title="Minutas"
                filters={filters}
                onFiltersChange={setFilters}
                filtersConfig={filtersConfig}
            />

            <ToolBar tools={tools} />

            <div className='center-container'>
                <TideReactTable {...tableProps} className="table-hoverable" />
            </div>
        </div>
    );
}

export default MemorandumList;