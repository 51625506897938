import _ from "lodash";
import { copyPropertyIdOrNull, copyPropertyIdOrThrow, copyPropertyOrNull, copyPropertyOrThrow } from "../formUtils";
import { prepareQuoteProductForServer } from "./quoteProductsUtils";
import { getEmptyQuoteProduct } from "./quoteProductsUtils";
import moment from "moment";
import { Link } from "react-router-dom";
import { paths } from "../routes/appRoutes";
import getQuoteStatusIcon from "../../components/utility/StatusIcon/StatusIcon";
import { moneyFormatter } from "../currencyUtils";

export const prepareQuoteForServer = ( form )=>{
    let quote = {}
    copyPropertyOrThrow( form, quote, "title", "Por favor llena el campo de título" );
    copyPropertyOrThrow( form, quote, "iva", "Por favor llena el campo de IVA" );
    copyPropertyOrThrow( form, quote, "status", "Por favor llena el campo de estado" );
    copyPropertyIdOrThrow( form, quote, "client", "Por favor selecciona una compañía" );
    copyPropertyIdOrThrow( form, quote, "project", "Por favor selecciona un proyecto" );

    //copyPropertyOrNull( form, quote, "folio" );
    copyPropertyOrNull( form, quote, "expirationDate" );
    copyPropertyIdOrNull( form, quote, "quotationRequest" );

    if(form.total < 0 || form.subtotal < 0) {
        throw new Error("El total o el subtotal no pueden ser negativos");
    }

    const quoteProducts = _.map( form.quoteProducts, (quoteProduct)=>prepareQuoteProductForServer(quoteProduct,quote.client) );
    quote.quoteProducts = quoteProducts;
    quote.total = form.total+'';
    quote.subtotal = form.subtotal+'';

    return quote;
}

export const getEmptyQuote = ()=>({
    quoteProducts:[ getEmptyQuoteProduct() ],
    iva: true,
    expirationDate: moment().add(1, 'month').toDate(),
    status: { value: quoteStatus.PENDING, label: quoteStatusTrans.PENDING }
});

export const getQuoteSubtotal = ( quote )=>{
    const products = quote.quoteProducts;

    const total = _.reduce(products, (sum, product) => {
        return sum + (product.price * product.quantity)
    }, 0);

    return total;
};

export const getIvaAmount = ( quote, subtotal )=> {
    if (!quote.iva) { return 0; }
    const ivaAmount = 0.16 * subtotal;

    return ivaAmount;
};

export const quoteStatus = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    COMPLETED: "COMPLETED",
    EXPIRED: "EXPIRED",
};

export const quoteStatusForFilterInPayments = [
    quoteStatus.PENDING,
    quoteStatus.APPROVED,
    quoteStatus.COMPLETED
]

export const quoteStatusTrans = {
    PENDING: "Pendiente",
    APPROVED: "Aprobada",
    REJECTED: "Rechazada",
    COMPLETED: "Completada",
    EXPIRED: "Vencida",
};
export const quoteStatusTransColor = {
    PENDING: "warning",
    APPROVED: "success",
    REJECTED: "danger",
    COMPLETED: "info",
    EXPIRED: "danger",
};

export const paymentQuoteStatus = {
    PENDING: "PENDING",
    PAYING: "PAYING",
    PAID: "PAID",
};

export const paymentQuoteStatusTrans = {
    PENDING: "Pendiente",
    PAYING: "Por pagar",
    PAID: "Pagada",
};

export const unPaidPaymentQuoteStatus = [
    paymentQuoteStatus.PENDING,
    paymentQuoteStatus.PAYING,
];

export const unPaidQuoteStatus = [
    quoteStatus.PENDING,
    quoteStatus.APPROVED,
    quoteStatus.COMPLETED,
];

export const approvedQuoteStatus = [
    quoteStatus.APPROVED, quoteStatus.COMPLETED
];

export const quoteIsApproved=(quote)=>{
   return(approvedQuoteStatus.includes(quote.status));
}

export const getQuoteStatusTrans = (status)=>quoteStatusTrans[status]||status;
export const getQuoteStatusColor = (status)=>quoteStatusTransColor[status]||"dark";

export const quoteStatusOptions = _.map(quoteStatus, (value) => {
    return { value: value, label: quoteStatusTrans[value] };
});

export const convertQuoteToForm = (quote) => {
    return {
        ...quote,
        client: quote.project?.client,
        status: { value: quote.status, label: quoteStatusTrans[quote.status]}
    };
}

export const quoteDetailSGroups = [
    'quote_read',
    'quote_read_quote_products',
        'quote_product_read',
    'quote_read_project',
        'project_read_id',
        'project_read_name',
        'project_read_client',
            'client_read_id',
            'client_read_name',
    'quote_read_quotation_request',
        'quotation_request_read_id',
        'quotation_request_read_title',
        'quotation_request_read_folio',
    'quote_read_reviewed_by',
        'user_read_full_name',
    'quote_read_reviewed_date',
    "quote_read_pending_amount",
    "quote_read_client",
    "quote_read_tenant",
    "client_read_id",
    "client_read_full_name",
        'client_read_fiscal_data',
    "read_created_by",
        "user_read",
    "tenant_read",
    "tenant_rfc_read",
    "fiscal_data_read"

];

export const quoteListSGroups = [
    "quote_read",
    "quote_read_created_date",
    "quote_read_pending_amount",
    "quote_read_paid_amount",
    "quote_read_client",
    "client_read_name",
    "client_read_full_name",
    "client_read_id",
    "quote_read_project",
    "project_read_name",
    "project_read_id",
    "read_created_by",
        "user_read",
]

export const quoteReviewDetails = {
    APPROVED: { title: 'Aprobó', dateTitle: 'Fecha de aprobación' },
    REJECTED: { title: 'Rechazó', dateTitle: 'Fecha de rechazo' },
    COMPLETED: { title: 'Completó', dateTitle: 'Fecha de completado' },
}

export const quoteTableColumns = [
    {
        Header:"Título",
        accessor: (quote)=>
            <Link to={paths.quoteDetail.replace(':id', quote.id)} data-tooltip={'Ver cotización'} >
                {quote.title}
            </Link>,
        id:'title'
    },
    {Header:"Aprobada", id:'approved', accessor: (q) => getQuoteStatusIcon(quoteIsApproved(q))},
    {Header:"Completada", accessor: (q) => getQuoteStatusIcon(q.status===quoteStatus.COMPLETED)},
    {Header:"Pagado", id:'payed',  accessor: (q) => `$ ${moneyFormatter (Number(q.paidAmount))}`},
    {Header:"Precio", id:'price', accessor: (q) => `$ ${moneyFormatter(Number(q.total))}` },
    {Header:"Creación", accessor: (q) => (q.createdDate? moment(q.createdDate).format('DD/MM/YYYY'):null)},
];

export const quoteListTableColumns = [
    {
        Header:"Título",
        accessor: (quote)=>
            <Link to={paths.quoteDetail.replace(':id', quote.id)} data-tooltip={'Ver cotización'}>
                {quote.title}
            </Link>,
        id:'title'
    },
    {Header:"Folio",
        id: 'folio',
        accessor: quote=><span className='nowrap'>{quote.folio}</span>
    },
    {Header:"Empresa", id: 'client',
        accessor: (quote)=>
        <Link to={paths.clientDetail.replace(':id', quote.client?.id)} data-tooltip={'Ver empresa'} >
            {quote.client?.fullName}
        </Link>,
    },
    {Header:"Proyecto", id: "project",
        accessor: (quote)=>
            <Link to={paths.projectDetail.replace(':id', quote.project?.id)} data-tooltip={'Ver proyecto'} >
                {quote.project?.name}
            </Link>,
    },
    {Header:"Estado", id:'status', accessor: (q) => quoteStatusTrans[q.status] || q.status },
    {Header:"Pagado", id:'payed',  accessor: (q) => <span className='nowrap'>$ {moneyFormatter (Number(q.paidAmount))}</span>, Cell: ({ value }) => <div className="text-right">{value}</div>},
    {Header:"Precio", id:'price', accessor: (q) => <span className='nowrap'>$ {moneyFormatter(Number(q.total))}</span>, Cell: ({ value }) => <div className="text-right">{value}</div> },
    {Header:"Creado", accessor: (q) => (q.createdBy?.fullName || 'Sin información')},
    {Header:"Creación", accessor: (q) => (q.createdDate? moment(q.createdDate).format('DD/MM/YYYY'):null)},
];
