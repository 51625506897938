// src/components/Collapse/Collapse.js
import React, { useState } from 'react';
import './Collapse.scss';

const Collapse = ({ title, children, autoOpen }) => {
    const [isOpen, setIsOpen] = useState(autoOpen);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="collapse-container">
            <div className="collapse-header" onClick={toggleCollapse}>
                <h4>{title}</h4>
                <span>{isOpen ? '-' : '+'}</span>
            </div>
            {isOpen && (
                <div className="collapse-content">
                    {children}
                </div>
            )}
        </div>
    );
};

export default Collapse;
