import { useEffect } from "react";

const scriptExists = (src) => {
  let nodes = document.getElementsByTagName( "script" );
  let scripts = Array.from(nodes); 
  for ( let i=0; i<scripts.length; i++ ) {
    if( scripts[i].src === src ) return true;
  }
  return false;
}

const useScript = (src) => {
    useEffect(
        () => {
          if ( scriptExists(src) ) return false;
          const node = document.createElement('script');
          node.src = src;
          node.type = 'text/javascript';
          node.async = true;
          document.body.appendChild(node);
        },
        [src]
    )
}

export default useScript;
