import { copyPropertyOrNull, copyPropertyOrThrow } from "../formUtils";

export const getEmptyQuoteProduct = ()=>({
    id: Math.random(),
    name: null,
    price: null,
    quantity: 1,
    isOptional: false,
});

export const prepareQuoteProductForServer = ( form, clientId )=>{
    let productQuote = {}
    productQuote.client = clientId;
    copyPropertyOrThrow( form, productQuote, "name", "Por favor completa el nombre de productos agregados" );
    copyPropertyOrThrow( form, productQuote, "price", "Por favor completa el precio de productos agregados" );
    copyPropertyOrThrow( form, productQuote, "quantity", "Por favor completa la cantidad de productos agregados" );

    copyPropertyOrNull( form, productQuote, "isOptional");

    productQuote.quantity = Number(form.quantity);
    productQuote.description = form.getDescription()||null;
    return productQuote;
}
