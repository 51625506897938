import React, {useCallback, useContext} from "react"
import Modal from "../../../../components/utility/Modal/Modal";
import WindInput from "../../../../components/formComponents/WindInput/WindInput";
import useFormState from "../../../../hooks/useFormState";
import { getEmptyAdmin, prepareAdminForServer, prepareTenantForServer } from "../../../../services/modelUtils/superAdminUtils";
import { ApiContext } from "../../../../services/api/api-config";
import { getNotifier } from "../../../../services/notifier";
import "./TenantFormModal.scss"

const TenantFormModal = ({onClose, onTenantSaved}) => {

    const api = useContext(ApiContext);
    const { form,  bindSimple } = useFormState(() =>getEmptyAdmin());

    const close = useCallback(() => {
        if (onClose)
            onClose()
    }, [onClose]);

    const saveTenant = useCallback(async () => {
        try {
          const tenant = prepareTenantForServer({ ...form });
          const createdTenant = await api.tenants.create({ params: tenant });
          const role = await api.roles.get({params:{name:"GENERAL_ADMIN"}});
        
          if (createdTenant) {
            form.tenant = createdTenant.id;
            form.role = role[0].id;
            const user = prepareAdminForServer({ ...form });
            const createdUser = await api.users.create({ params: user });
      
            if (createdUser) {
              getNotifier().success("Tenant creado exitosamente");
              if(onTenantSaved) onTenantSaved()
              close()
            } else {
              throw new Error("Error al crear usuario");
            }
          } else {
            throw new Error("Error al crear tenant");
          } 
        } catch (error) {
          getNotifier().error(error.message);
        }
      }, [form, api, onTenantSaved, close]);

    return(
        <Modal
        title="Nuevo tenant"
        onClose={close}
        secondaryButtonAction={close}
        mainButtonText="Guardar"
        mainButtonAction={ saveTenant }
        >
            <div className='TenantForm'>

                <h3 className={"subtitle"}>Tenant</h3>
                
                <WindInput
                    className={"inputs"}
                    placeholder="Nombre*"
                    {...bindSimple('alias')}
                />

                <h3 className={"subtitle"}>Usuario administrador</h3>

                <div className="input-row">
                    <WindInput
                        className={"inputs"}
                        placeholder="Nombre*"
                        {...bindSimple('name')}
                    />
                    <WindInput
                        className={"inputs"}
                        placeholder="Apellido*"
                        {...bindSimple('lastName')}
                    />
                </div>

                <div className="input-row">
                    <WindInput
                        className={"inputs"}
                        placeholder="Email*"
                        {...bindSimple('email')}
                    />
                    <WindInput
                        className={"inputs"}
                        placeholder="Teléfono"
                        {...bindSimple('phone')}
                    />
                </div>
            </div>

        </Modal>
    )
}

export default TenantFormModal;