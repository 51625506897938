import React, {useCallback, useContext} from 'react';
import useFormState from "../../../../../hooks/useFormState";
import TideEntitySelect from "../../../../../components/utility/TideEntitySelect/TideEntitySelect";
import WindTextarea from "../../../../../components/formComponents/WindTextarea/WindTextarea";
import {getNotifier} from "../../../../../services/notifier";
import { prepareProjectForServer,  projectStatusOptions} from "../../../../../services/modelUtils/projectUtils";
import {ApiContext} from "../../../../../services/api/api-config";
import WindDatePicker from '../../../../../components/formComponents/WindDatePicker/WindDatePicker';
import WindSelectStatus from '../../../../../components/formComponents/WindSelectStatus/WindSelectStatus';
import WindInput from '../../../../../components/formComponents/WindInput/WindInput';
import Modal from '../../../../../components/utility/Modal/Modal';
import { useHistory } from 'react-router-dom';
import { paths } from '../../../../../services/routes/appRoutes';

import "./ProjectNewForm.scss"

const ProjectNewForm = ({ onClose, onProjectSaved }) => {

    const api = useContext(ApiContext);
    const { form, bindSimple } = useFormState(()=>({  }));
    const history = useHistory();

    const loadingId = 'ProjectForm.create';
    const handleSave = useCallback(()=>{
        const { id } = form;
        const apiMethod = 'create';

        let project;
        try{
            project = prepareProjectForServer(form);
        }
        catch (e){
            return getNotifier().warning(e.message);
        }
        const params = { id, params:project, loadingId };
        api.projects[apiMethod](params)
            .then((project)=>{
                getNotifier().success(`El proyecto se ha creado correctamente`);
                onProjectSaved();
            })
            .catch((e)=>{ getNotifier().error(e.message) })
    }, [form, api, onProjectSaved]);

    const addClient = () => {
        history.push( paths.clientNew );
    }

    const addAction = {
        text: "+ Nueva",
        callback: addClient
    }

    return (
        <div className='ProjectNewForm'>
            <Modal 
                onClose={onClose}
                title="Nuevo proyecto"
                mainButtonText="Crear"
                mainButtonAction={handleSave}
                secondaryButtonAction={onClose}
            >
                <div className="field-group">
                    <WindInput 
                        className="project-field"
                        placeholder="Nombre del proyecto"
                        label="Nombre"
                        {...bindSimple("name")}
                    />
                </div>
                <div className="field-group">
                    <TideEntitySelect
                        className='project-field'
                        entity='clients'
                        placeholder={"Compañía"}
                        label="Cliente"
                        action={addAction}
                        {...bindSimple('client')}
                    />
                    <WindSelectStatus
                        className='project-field'
                        placeholder={"Estado"}
                        {...bindSimple('status')}
                        options={projectStatusOptions}
                    />
                </div>
                <div className="field-group">
                    <WindDatePicker
                        className='project-field'
                        label={'Fecha de inicio'}
                        {...bindSimple('startDate')}
                    />
                    <WindDatePicker
                        className='project-field'
                        label={'Fecha de fin'}
                        {...bindSimple('endDate')}
                    />
                </div>
                <div className="field-group">
                    <WindTextarea className={" description-input"} placeholder='Agrega una descripción breve del proyecto' label="Descripción" {...bindSimple('description')}/>
                </div>
            </Modal>

        </div>
       
    );
};

export default ProjectNewForm;
