import { faFileExcel } from '@fortawesome/pro-light-svg-icons';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import ToolBar from '../../../components/utility/ToolBar/ToolBar';
import TopBar from '../../../components/utility/TopBar/TopBar';
import { ApiContext } from '../../../services/api/api-config';
import { accountStatementDetailSGroups } from '../../../services/modelUtils/bankStatementUtils';
import BankStatementMainDetails from './components/BankStatementMainDetails/BankStatementMainDetails';
import BankTransactionsList from './components/BankTransactionsList/BankTransactionsList';
import { bankTransactionStatus } from '../../../services/modelUtils/bankTransactionUtils';
import { getNotifier } from '../../../services/notifier';

const BankStatementDetail = () => {

  const api = useContext(ApiContext);
  const { id } = useParams();

  // ----- Load bankStatement data
  const [bankStatement, setBankStatement] = useState();
  const apiConfig = useMemo(() => ({id, params: {sGroups: accountStatementDetailSGroups}}), [id]);
  useEffect(() => {
      api.accountStatements.get(apiConfig).then(setBankStatement);
  }, [api, apiConfig]);

  const updateBankStatementAfterBankTransactionChange = useCallback((newBankTransaction) => {
    const updatedBankTransactions = bankStatement.bankTransactions.map(bankTransaction => {
      if (bankTransaction.id === newBankTransaction.id) {
        return newBankTransaction;
      }
      return bankTransaction;
    }
    );

    setBankStatement({ ...bankStatement, bankTransactions: updatedBankTransactions });
  }, [bankStatement, setBankStatement]);

  const confirmBankTransaction = useCallback((id) => {
    api.bankTransactions.update({ id, params: { status: bankTransactionStatus.CONFIRMED } }).then((newBankTransaction) => {
      getNotifier().success('La transacción ha sido confirmada 💸');
      updateBankStatementAfterBankTransactionChange(newBankTransaction);
    });
  }, [api, updateBankStatementAfterBankTransactionChange]);

  const denyBankTransaction = useCallback((id) => {
    api.bankTransactions.update({ id, params: { status: bankTransactionStatus.DENIED } }).then((newBankTransaction) => {
      getNotifier().success('La transacción ha sido denegada 💸');
      updateBankStatementAfterBankTransactionChange(newBankTransaction);
    });
  }, [api, updateBankStatementAfterBankTransactionChange]);

  const onTransactionChange = useCallback((transaction) => {
    api.bankTransactions.update({ id: transaction.id, params: {transaction: transaction.id} }).then((newBankTransaction) => {
      getNotifier().success('La transacción ha sido actualizada 💸');
      updateBankStatementAfterBankTransactionChange(newBankTransaction);
    });
  }, [api, updateBankStatementAfterBankTransactionChange]);

  // ------ Toolbar definition ------
  const exportToExcel = useCallback(() => {}, []);
  const tools = useMemo(() => [
    {icon: faFileExcel, callback: exportToExcel, text: "Descargar excel", testId: 'export-excel-button'},
  ], [exportToExcel]);

  return (
    <div className={"BankStatementDetail wind-scene"}>

      <TopBar
        title="Estado bancario"
        titleLinkBack
      />
      <ToolBar tools={tools} />

      <div className='center-container'>
        <div className="general-content">
          <BankStatementMainDetails bankStatement={bankStatement} />
        </div>
        <div className="bank-transactions-list-container">
          <BankTransactionsList
            bankStatement={bankStatement}
            onConfirm={confirmBankTransaction}
            onDeny={denyBankTransaction}
            onTransactionSelect={onTransactionChange}
          />
        </div>
      </div>
    </div>
  );
}
 
export default BankStatementDetail;
