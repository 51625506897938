import React, {useMemo, useContext, useCallback, useState} from 'react';
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import {transactionsTableSGroups, getTransactionsTableColumns} from "../../../services/modelUtils/transactionUtils";
import {filterTypes} from "../../../services/searchUtils";
import {ApiContext} from "../../../services/api/api-config";
import './TransactionList.scss';
import useSearchHistory from '../../../hooks/useSearchHistory';
import useUrlFilters from '../../../hooks/useUrlFilters';
import TransactionFormModal from '../TransactionFormModal/TransactionFormModal';
import SearchBar from '../../../components/search/SearchBar/SearchBar';
import SectionHeader from '../../../components/utility/SectionHeader/SectionHeader';
import { getFinanceHeaderInfo } from '../../../constants/SectionHeaderInfo';
import useBoolean from '../../../hooks/useBoolean';
import { SecurityContext } from '../../../services/SecurityManager';

const requestFilters={sGroups: transactionsTableSGroups};
const filtersConfig = {
    placeholder: 'Buscar una transacción...', filters: [
        {main: true, field: 'concept'},
        {type: filterTypes.TEXT, field: 'concept', label:'Por concepto'},
        {type: filterTypes.TEXT, field: 'bankAccount.name', label:'Por cuenta bancaria'},
        {type: filterTypes.TEXT, field: 'invoice.folio', label:'Buscar por factura'},
        {type: filterTypes.ORDER, field: 'order[amount]', label:'Ordenar por monto'},
    ]
};

const TransactionList = () => {
  const urlFilters = useUrlFilters();
  const api = useContext(ApiContext);

  const security = useContext(SecurityContext);
  const headerInfo = getFinanceHeaderInfo(security)

  const [showingForm, showForm, hideForm] = useBoolean(false);
  const [dataToEdit, setDataToEdit] = useState()

  const showFormWithConcept = useCallback((rowData) => {      
    setDataToEdit(rowData)
    showForm();
  }, [showForm]);

  const transactionsTableColumns = useMemo(()=> getTransactionsTableColumns(showFormWithConcept),[showFormWithConcept])

  //Table config
  const table = useTideTable({
    entity: 'transactions',
    columns: transactionsTableColumns,
    requestFilters,
    ...urlFilters
  });

  const { tableProps, filters, setFilters } = table;

  useSearchHistory(table);

  const exportToExcel = useCallback(() => {window.location.href = api.transactions.getExportExcelUrl({pagination: false, ...requestFilters})}, [api]);


  const onClose = useCallback(() => {
    table.reload();
    if(dataToEdit) setDataToEdit(undefined);
    hideForm()
  }, [table, dataToEdit, hideForm]);

  
  const sceneTools = useMemo( () => [
    { text: "Exportar", callback: exportToExcel, outline: true },
    { text: "Agregar", callback: ()=>showForm() },
  ], [exportToExcel, showForm]);


  return (
    <div className={"TransactionList wind-scene"}>

      <SectionHeader pageInfo={headerInfo} tools={sceneTools}/>
      <SearchBar 
          title="Todas las transacciones" 
          filters={filters} 
          filtersConfig={filtersConfig} 
          onFiltersChange={setFilters}
          labels={false}
          />

      <div className='center-container'>
        <TideReactTable {...tableProps} className="table-align-left table-hoverable" />
      </div>

      {showingForm && <TransactionFormModal onClose={onClose} onTransactionSaved={() => table.reload()} editData={dataToEdit}/>}

    </div>
  );
}

export default TransactionList;