import React, {useCallback, useContext, useEffect, useMemo } from 'react';
import './QuoteForm.scss';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import TopBar from '../../../components/utility/TopBar/TopBar';
import useFormState from '../../../hooks/useFormState';
import { ApiContext } from '../../../services/api/api-config';
import { getEmptyQuote, getQuoteSubtotal, prepareQuoteForServer, getIvaAmount } from '../../../services/modelUtils/quoteUtils';
import { getNotifier } from '../../../services/notifier';
import { paths } from "../../../services/routes/appRoutes";
import GeneralInfo from './components/GeneralInfo/GeneralInfo';
import QuoteProductForm from './components/QuoteProductForm/QuoteProductForm';
import { getEmptyQuoteProduct } from '../../../services/modelUtils/quoteProductsUtils';
import _ from 'lodash';
import {projectSelectorSGroups} from "../../../services/modelUtils/projectUtils";
import {quoteDetailSGroups, convertQuoteToForm} from "../../../services/modelUtils/quoteUtils";
import SceneTitle from '../../../components/layout/SceneTitle/SceneTitle';
import WindInput from '../../../components/formComponents/WindInput/WindInput';
import CenteredButtons from '../../../components/utility/CenteredButtons/CenteredButtons';
import useCallbackCreator from 'use-callback-creator';

const QuoteForm = () => {
  const api = useContext(ApiContext);
  const history = useHistory();
  const { form, setForm,  bindSimple } = useFormState(() =>getEmptyQuote());
  const { id } = useParams();

  const cloning = history.location.pathname.indexOf('clone') !== -1;

  const subtotal = getQuoteSubtotal(form);
  const total = subtotal + getIvaAmount(form, subtotal);

  // ----- Load quote from server -----
  useEffect(()=>{
    if(id)
      api.quotes.get({ id, params:{sGroups: quoteDetailSGroups}, customProp:'_' })
        .then(quote => setForm(convertQuoteToForm(quote)));
  },[api, id, setForm]);

  // ------ Preload project if needed ------
  const { search } = useLocation();
  useEffect(()=>{
    const preloadProjectId = (new URLSearchParams(search)).get('project');
    if( preloadProjectId ){
      api.projects.get( { id:preloadProjectId, params:{sGroups: projectSelectorSGroups}, customProp:'_' } ).then( project=>{
        if(project)
          setForm( form=>({...form, project, client: project.client}));
      })
    }
  },[api, search, setForm]);

  // ------ Save to server ------
  const loadingId = 'QuoteForm.create';
  const handleSave = useCallback(()=>{
    const {id} = form;
    const create = !id || cloning;
    const apiMethod = create? 'create':'update';
    let quote;

    try{
        quote = prepareQuoteForServer({...form, total: total, subtotal: subtotal, status: form.status?.value});
    }
    catch (e){
        return getNotifier().warning(e.message);
    }

    const params = {
      id,
      loadingId,
      params: quote,
    };

    api.quotes[apiMethod](params)
        .then((quote)=>{
          getNotifier().success(`La cotización se ha ${create?'creado':'editado'} correctamente`);
            history.replace( paths.quoteDetail.replace(':id', quote.id) );
        }); 
  }, [form, api, history, total, subtotal, cloning]);

  // ------ Form state handlers ------
  const addQuoteProduct = useCallback(()=>{
    setForm({
      ...form,
      quoteProducts: [...form.quoteProducts, getEmptyQuoteProduct()]
    });
  },[setForm, form]);

  const removeQuoteProduct = useCallbackCreator((index)=>{
    const newQuoteProducts = [...form.quoteProducts];
    newQuoteProducts.splice(index, 1);

    setForm({ ...form, quoteProducts: newQuoteProducts });
  },[setForm, form]);

  const handleQuoteProductChange = useCallbackCreator((index, quoteProduct)=>{
    const newQuoteProducts = [...form.quoteProducts];
    newQuoteProducts[index] = quoteProduct;
    setForm({ ...form, quoteProducts: newQuoteProducts });
  }, [setForm, form]);

  const footerButtons = useMemo ( () => [
    { text: "Cancelar", callback: () => window.history.back(), outline: true },
    { text: "Guardar", callback: handleSave }
  ], [handleSave] );

  let title = "Nueva cotización";
  if( id ){
    if( cloning )
      title = "Clonar cotización";
    else
      title = "Editar cotización";
  }

  return (
    <div className={"QuoteForm wind-scene"}>

      <TopBar
        title={title}
        titleLinkBack
      />

      <SceneTitle className='title' description="Completa los campos y genera una nueva cotización">{title}</SceneTitle>

      <div className='center-container'>
          <div className="single-title">Información general</div>
          <div className="fields">
            <WindInput label="Nombre" placeholder="Agregar nombre de cotización" className="project-field width-50" {...bindSimple("title")} />
          </div>
          
          <GeneralInfo
            bindSimple={bindSimple}
            total={total}
            subtotal={subtotal}
            form={form}
            setForm={setForm}
          />

          {
            _.map(form.quoteProducts, (quoteProduct, index) => (
              <QuoteProductForm
                key={quoteProduct.id || index}
                onRemove={removeQuoteProduct(index)}
                quoteProduct={quoteProduct}
                onChange={handleQuoteProductChange(index)}
                handleAddProduct={addQuoteProduct}
                unique={form.quoteProducts.length === 1}
                last={form.quoteProducts.length === index + 1}
              />
            ))
          }
        <CenteredButtons buttons={footerButtons} />
        
      </div>
    </div>
  );
}

export default QuoteForm;
