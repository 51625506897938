import React, { useCallback, useMemo, useContext } from 'react';
import TopBar from "../../../components/utility/TopBar/TopBar";
import {paths} from "../../../services/routes/appRoutes";
import {useHistory} from "react-router-dom";
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import {clientTableColumns, clientTableSGroups} from "../../../services/modelUtils/clientUtils";
import {filterTypes} from "../../../services/searchUtils";
import './ClientList.scss';
import useSearchHistory from '../../../hooks/useSearchHistory';
import useUrlFilters from '../../../hooks/useUrlFilters';
import SceneTitle from '../../../components/layout/SceneTitle/SceneTitle';
import { ApiContext } from '../../../services/api/api-config';
import SceneLinkMenu from '../../../components/utility/SceneLinkMenu/SceneLinkMenu';

const requestFilters={sGroups: clientTableSGroups};

const filtersConfig = {
    placeholder: 'Buscar un cliente...', filters: [
        {main: true, field: 'name'},
        {type: filterTypes.TEXT, field: 'users.fullName', label:'Buscar por usuarios'},
        {type: filterTypes.ORDER, field: 'order[name]', label:'Ordenar por nombre'}
    ],
};

const ClientList = () => {
    const api = useContext(ApiContext);
    const urlFilters = useUrlFilters();
    const history = useHistory();

    //Table config
    const table = useTideTable({
        entity: 'clients',
        columns: clientTableColumns,
        requestFilters,
        ...urlFilters
    });

    const { tableProps, filters, setFilters } = table;

    useSearchHistory(table);
    const exportToExcel = useCallback(() => {
        window.location.href = api.quotes.getExportExcelUrl({ pagination: false, ...requestFilters, ...filters })
    }, [api,  filters]);

    // ----- Link to add projects
    const handleAdd = useCallback(()=>history.push(paths.clientNew), [history]);

    const sceneTools = useMemo( () => {
        let tools = [
            { text: "Exportar", callback: exportToExcel, outline: true },
            { text: "Crear", callback: handleAdd, outline: false},
        ];
        return tools;
    }, [exportToExcel, handleAdd] );

    return (
        <div className={"ClientList wind-scene"}>

            <TopBar
                title="Clientes"
                filters={filters}
                onFiltersChange={setFilters}
                filtersConfig={filtersConfig}
            />

            <SceneTitle className='title' description="Revisa el estado de tus clientes, sus proyectos y cotizaciones">Mis clientes</SceneTitle>
            <SceneLinkMenu tools={sceneTools} />

            <div className='center-container'>
                <TideReactTable {...tableProps} className="table-hoverable" />
            </div>
        </div>
    );
};

export default ClientList;
