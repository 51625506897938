
import useBoolean from "../../../../../hooks/useBoolean"
import TideEntitySelect from "../../../../../components/utility/TideEntitySelect/TideEntitySelect"
import { satCatalogSGroups } from "../../../../../services/modelUtils/taxDocumentUtils"
import WindDatePicker from "../../../../../components/formComponents/WindDatePicker/WindDatePicker"
import WindInput from "../../../../../components/formComponents/WindInput/WindInput"
const InoviceGeneralData = ({bindSimple, form, setForm}) => {

    const [showingExchangeRate, showExchangeRate, hideExchangeRate] = useBoolean(false)

    const validateCurrency = (value) => {
        setForm({
            ...form, 
            currency: value 
        });

        value.catalogKey !== "MXN" ? showExchangeRate() : hideExchangeRate()
    } 

    return(
        <>
            <div className="input-row">
                <div className="entity-select-label">
                    <label>{"Uso de CFDI"}</label>
                    <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Uso de CFDI'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_UsoCFDI"
                        }}
                        preload
                        filterBy={'description'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        {...bindSimple('cfdiUsage')}
                    />
                </div>

                <div className="entity-select-label">
                    <label>{"Tipo de Comprobante"}</label>
                    <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Tipo de comprobante'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_TipoDeComprobante",
                            "description": "Ingreso"
                        }}
                        preload
                        filterBy={'catalogKey'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        {...bindSimple('receiptType')}
                    />
                </div>
            </div>

            <div className="input-row">
                <div className="entity-select-label">
                <label>{"Forma de Pago"}</label>
                    <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Forma de Pago'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_FormaPago",
                        }}
                        preload
                        filterBy={'description'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        {...bindSimple('paymentType')}
                    />
                </div>


                <div className="entity-select-label">
                <label>{"Método de Pago"}</label>
                    <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Método de Pago'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_MetodoPago"
                        }}
                        preload
                        filterBy={'description'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        {...bindSimple('paymentMethod')}
                    />       
                </div>
            </div>

            <div className="input-row">
                <div className="entity-select-label">
                    <label>{"Exportación"}</label>
                    <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Exportación'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_Exportacion"
                        }}
                        preload
                        filterBy={'description'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        {...bindSimple('export')}
                    />
                </div>


            
            <WindDatePicker
                    label='Fecha de emisión'
                    {...bindSimple('issueDate')}
                />
            </div>

            <div className="input-row">
                <WindInput
                    placeholder={"Serie"}
                    type={'text'}
                    {...bindSimple('serie') } 
                />
                <WindInput
                    placeholder={"Folio"}
                    type={'text'}
                    {...bindSimple('folio') }
                />
            </div>


            <div className="input-row">
                <div className="entity-select-label">
                    <label>{"Moneda"}</label>
                    <TideEntitySelect
                        entity='satCatalogs'
                        placeholder='Moneda'
                        additionalFilters={{
                            sGroups: satCatalogSGroups,
                            "catalog": "c_Moneda",
                            "pagination": false,
                        }}
                        preload
                        filterBy={'catalogKey'}
                        labelCreator = {(satCatalogs) => 
                            `${satCatalogs.catalogKey} - ${satCatalogs.description}`
                        }
                        onChange={validateCurrency}
                        value={form.currency}
                    />  
                </div>

                {showingExchangeRate &&
                    <WindInput
                        placeholder={"Tipo de Cambio"}
                        type={'number'}
                        {...bindSimple('exchangeRate')}
                    />
                }
            </div>

        </>

    )

}

export default InoviceGeneralData
