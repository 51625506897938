import { useCallback, useEffect, useContext } from "react";
import Modal from "../../../../../components/utility/Modal/Modal"
import WindInput from "../../../../../components/formComponents/WindInput/WindInput"
import { getEmptyTenantRfc } from "../../../../../services/modelUtils/tenantRfcUtils";
import useFormState from "../../../../../hooks/useFormState";
import { prepareTenantRfcForServer } from "../../../../../services/modelUtils/tenantRfcUtils";
import { getNotifier } from "../../../../../services/notifier";
import { ApiContext } from "../../../../../services/api/api-config";

const SatConnectionModal = ({onClose, onActionSuccess, rfc}) => {
    const { form, setForm, bindSimple } = useFormState( () => getEmptyTenantRfc() );
    const api = useContext(ApiContext);

    useEffect(() => {
        setForm(prevForm => ({ ...prevForm, rfc: rfc.rfc }));
    }, [rfc, setForm]); 

    const close = useCallback(() => {
        if (onClose) onClose()
    }, [onClose]);

    const save = async() =>{
        try {
            const tenantRfcDocument = prepareTenantRfcForServer(form, true);
            await api.satwsconnect['update']({ id: rfc.id, params: tenantRfcDocument} )

            getNotifier().success('Se ha enviado correctamente. Por favor espere unos minuitos para utilizar este RFC.');
            close()
            onActionSuccess()

        } catch (err) {
            return getNotifier().error(err.message||err.detail);
        }
    }

    return(
        <Modal title="Conexión al SAT"
        onClose={close}
        secondaryButtonAction={close}
        mainButtonAction={save}
        mainButtonText="Guardar"
        >
            <p>Ingresa tu clave CIEC</p>
    
            <WindInput 
                className={'component'}
                placeholder={"CIEC"}
                type={'password'}
                {...bindSimple('ciec')}
            />
    
        </Modal>
    )

}

export default SatConnectionModal
