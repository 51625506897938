import { useCallback, useMemo, useState } from "react";
import useTideTable from "../../../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../../../components/utility/TideReactTable/TideReactTable";
import {  taxDocumentTableListSGroups } from "../../../../../services/modelUtils/taxDocumentUtils";
import { moneyFormatter } from "../../../../../services/currencyUtils";
import moment from "moment";
import InvoicesSwitch from "../InvoicesSwitch/InvoicesSwitch";

const requestFilters={sGroups: taxDocumentTableListSGroups};
const requestOptions = { customProp:'InvoicesRelatedSelector' };

const InvoiceSelector = ({ bankTransaction, reloadRelated }) => {

    const [ filters, setFilters ] = useState({});
    let table = useMemo( () => {}, []);

    const handleUpdate = useCallback ( () => {
        table.reload();
        reloadRelated();
    }, [table,reloadRelated] );

    const invoicesColumns = useMemo( () => [
        {
            Header:"Cliente",
            id: 'folio',
            accessor: txd => <span>{ txd.client?.fullName || '. . .' }</span>
        },
        {
            Header: "Concepto",
            id: 'concepto',
            accessor: txd => {
                let concepto = txd.taxDocumentItems?.map( item => item.description ).join(" | ")||"- - -";
                return ( <span>{concepto}</span> );
            } 
        },
        
        {
            Header: "Monto",
            id: 'Monto',
            accessor: td => {
                return ( <span>{`$${moneyFormatter(td.total)}`}</span> );
            } 
        },
        {
            Header: 'Fecha',
            id: 'issuedAt',
            accessor: (td) => {
                return ( <span>{ (td.issuedAt? moment(td.issuedAt).format('DD/MM/YYYY HH:mm'):null) }</span> );
            } 
        },
        {
            Header:"Relacionar",
            
            accessor: (td, row, column) => { 
                return <InvoicesSwitch taxDocument={td} bankTransaction={bankTransaction} handleUpdate={ (updatedValue) => {
                    setFilters({});
                    handleUpdate();
                  } }  />;
            },
        }
    ], [bankTransaction, handleUpdate] );

    //Table config
    table = useTideTable( {
        entity: 'taxDocuments',
        columns: invoicesColumns,
        requestFilters,
        requestOptions,
        ...filters,
    } );

    const { tableProps } = table; 

    return (
        <div>
            <TideReactTable {...tableProps} className="table-hoverable" />
        </div>
    );

}

export default InvoiceSelector;