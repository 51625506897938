import { faSave } from '@fortawesome/pro-light-svg-icons';
import React, { useContext, useMemo, useState } from 'react';
import { useCallback } from 'react';
import DragDropFile from '../../../components/formComponents/DragDropFile/DragDropFile';
import SimpleCard from '../../../components/layout/SimpleCard/SimpleCard';
import ToolBar from '../../../components/utility/ToolBar/ToolBar';
import TopBar from '../../../components/utility/TopBar/TopBar';
import useFormState from '../../../hooks/useFormState';
import { ApiContext } from '../../../services/api/api-config';
import { prepareAccountStatementForServer } from '../../../services/modelUtils/bankStatementUtils';
import { getNotifier } from '../../../services/notifier';
import './BankStatementForm.scss';
import BankStatementGeneralInfo from './components/BankStatementGeneralInfo/BankStatementGeneralInfo';

const BankStatementForm = () => {
  const api = useContext(ApiContext);
  const { form, setForm, bindSimple } = useFormState({});
  const [file, setFile] = useState(null);

  const saveBankStatement = useCallback(() => {
    if (!file) { return getNotifier().error('Debes seleccionar un archivo'); }
    let accountStatement;

    try{
      accountStatement = prepareAccountStatementForServer(form);
    }
    catch (e){
        return getNotifier().warning(e.message);
    }

    const params = {
      params: accountStatement,
      files: [file],
    };

    api.accountStatements.create(params).then(() => {
      getNotifier().success('Se ha guardado correctamente');
      setForm({});
      setFile(null);
    });
  }, [api, form, setForm, setFile, file]);


  // ------ Toolbar definition ------
  const tools = useMemo(()=>[
    { icon: faSave, callback: saveBankStatement, text: "Guardar estado bancario", testId: "save-bank-statement-button"},
  ],[saveBankStatement]);

  return (
    <div className={"BankStatementForm wind-scene"}>

      <TopBar
        title="Estado bancario"
        titleLinkBack
      />

      <ToolBar tools={tools} />

      <div className='center-container'>
        <SimpleCard className='general-info' title="Información general">
          <BankStatementGeneralInfo
            bindSimple={bindSimple}
            form={form}
            setForm={setForm}
          />
        </SimpleCard>

        <SimpleCard className='importer-container' title="Importar estado de cuenta">
          <DragDropFile file={file} onChange={setFile} onRemove={() => setFile(null)} />
        </SimpleCard>
      </div>
    </div>
  );
}
 
export default BankStatementForm;
