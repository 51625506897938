export const fontSizeOptions = [
  { value: 1, label: 'Título 1' },
  { value: 2, label: 'Título 2' },
  { value: 3, label: 'Subtítulo 3' },
  { value: 4, label: 'Párrafo'},
]

export const fontFamilyOptions = [
  { value: "Inter", label: "Inter" },
  { value: "Comic Sans MS, Comic Sans", label: "Comic Sans" },
  { value: "monospace", label: "monospace" },
  { value: "serif", label: "Serif" },
  { value: "cursive", label: "Cursive" },
];
