import classNames from 'classnames';
import React from 'react';
import SceneTitle from '../../layout/SceneTitle/SceneTitle';
import InfoPairBlock from '../../layout/InfoPairBlock/InfoPairBlock';
import Button from '../Button/Button';
import './DetailHeader.scss';

const DetailHeader = ({ title, subtitle, className, data, circle=null, tools }) => {
  return (
    <div className={classNames("DetailHeader", className)}>
      <div className="detail-header-title">
        { circle && <div className="circle"></div> }
        <SceneTitle secondary circle>{title}</SceneTitle>
        { subtitle && <div className="subtitle">{subtitle}</div> }
      </div>

      <div className="detail-header-title">
        {
          ( Array.isArray(data) ) &&
          data.map( (info) => <span key={info.title}><InfoPairBlock title={info.title} value={info.value}  /></span>)
        }
      </div>

      {
        tools && 
        <div className="toolbox">
          { tools.map( (tool) => 
            <span key={tool.text}>
              <Button onClick={tool.callback} outline={tool.outline?true:false} leftIcon={ tool.icon? tool.icon: null } >
                  {tool.text}
              </Button>
            </span> 
          ) }
        </div>
      }
      
      
    </div>
  );
}
 
export default DetailHeader;
