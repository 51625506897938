import React, { useCallback, useMemo, useContext, useState} from "react";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import { tenantTableSGroups } from "../../../services/modelUtils/superAdminUtils";
import TopBar from "../../../components/utility/TopBar/TopBar";
import SceneLinkMenu from "../../../components/utility/SceneLinkMenu/SceneLinkMenu";
import useBoolean from "../../../hooks/useBoolean";
import TenantFormModal from "./components/TenantFormModal";
import SceneTitle from "../../../components/layout/SceneTitle/SceneTitle";
import './TenantList.scss'
import Button from "../../../components/utility/Button/Button";
import { ApiContext } from "../../../services/api/api-config";
import { Link } from "react-router-dom";
import ImpersonateFormModal from "./components/ImpersonateFormModal";

const requestFilters = { tenant: null, sGroups: tenantTableSGroups };

const TenantList = () => {
    const [showingImpersonateForm, showImpersonateForm, hideImpersonateForm] = useBoolean(false);
    const [showingForm, showForm, hideForm] = useBoolean(false);
    const [fetchedUsers, setFetchedUsers] = useState(null)
    const api = useContext(ApiContext);

    const impersonateSelect =useCallback( async(tenant) =>{
        const fetchedUsers = await api.users.get({params:{"tenant.id":tenant.id}})
        setFetchedUsers(fetchedUsers)
        showImpersonateForm()
    },[api, showImpersonateForm])

    const tenantTableColumns = useMemo(() => [
        {
            Header: 'Nombre',
            accessor: (tenant) => <Link to={'#'}>{tenant.alias}</Link>,
            id: 'tenantName',
        },
        {
            Header: '',
            accessor: (tenant) => <Button onClick={() => impersonateSelect(tenant)} outline>Iniciar sesión</Button>,
            //accessor: (tenant) => <Button onClick={() => impersonate(tenant)} outline>Iniciar sesión</Button>,
            id: 'tenantLogin',
        },
    ], [impersonateSelect]);

    const table = useTideTable({
        entity: 'tenants',
        columns: tenantTableColumns,
        requestFilters,
    });

    const { tableProps } = table;

    const tools = useMemo(() => [
        { text: "Crear", callback: showForm, outline: false },
    ], [showForm]);

    return (
        <div className={"TenantList wind-scene"}>
            <TopBar />
            <SceneTitle description="Consulta la información de los tenants">Tenants</SceneTitle>
            <SceneLinkMenu tools={tools} />
            
            <TideReactTable {...tableProps} />

            {showingForm &&
                <TenantFormModal
                    onClose={hideForm}
                    onTenantSaved={() => table.reload()}
                />
            }

            {showingImpersonateForm && 
                <ImpersonateFormModal
                    onClose={hideImpersonateForm}                
                    fetchedUsers={fetchedUsers}
                />
            }

        </div>
    );
};

export default TenantList;
