import React, {useEffect, useContext} from "react";
import useFormState from "../../../../../hooks/useFormState";
import Button from "../../../../../components/utility/Button/Button";
import useBoolean from "../../../../../hooks/useBoolean";
import TideEntitySelect from "../../../../../components/utility/TideEntitySelect/TideEntitySelect";
import WindDatePicker from "../../../../../components/formComponents/WindDatePicker/WindDatePicker";
import WindTextarea from "../../../../../components/formComponents/WindTextarea/WindTextarea";
import { convertProjectToForm, prepareProjectForServer } from "../../../../../services/modelUtils/projectUtils";
import Conversations from "../../../../../components/crmComponents/Conversations/Conversations";
import { moneyFormatter } from "../../../../../services/currencyUtils";
import { getNotifier } from "../../../../../services/notifier";
import { ApiContext } from "../../../../../services/api/api-config";
import moment from "moment";
import { faPen, faCancel, faSave } from "@fortawesome/pro-light-svg-icons";

import "./ProjectInfo.scss"

const ProjectInfo = ({ project, handleUpdate }) => {
    const api = useContext(ApiContext);
    const [ isEditing, setIsEditing, cancelEditing ] = useBoolean(false)
    const [showConversations, setShowConversations, hideConversations] = useBoolean(false);
    const { form, setForm,  bindSimple } = useFormState(()=>({}));

    useEffect( () => {
        setForm(convertProjectToForm(project));
    }, [ setForm, project ] )
    

    const handleSave = () => {
        const { id } = form;
        const apiMethod ='update';

        let project;
        try{
            project = prepareProjectForServer(form);
        }
        catch (e){
            return getNotifier().warning(e.message);
        }
        const params = { id, params:project };
        api.projects[apiMethod](params)
            .then((project)=>{
                getNotifier().success(`El proyecto se ha editado correctamente`);
                handleUpdate();
            })
            .catch((e)=>{ getNotifier().error(e.message) })
        cancelEditing();
    }

    return (
        <div className="ProjectInfo">
            <div className="project-info-header">
                <div className="project-info-hedaer-title">Información General</div>
                <div className="project-info-hedaer-tools">
                    { !isEditing && <Button onClick={setIsEditing} outline={true} leftIcon={faPen}>Editar</Button> }
                    { isEditing && <Button onClick={cancelEditing} outline={true} leftIcon={faCancel}>Cancelar</Button> }
                    { isEditing && <Button onClick={handleSave} outline={true} leftIcon={faSave}>Guardar</Button> }
                </div>
            </div>
            <div className="project-info-fields">
                <div className="project-info-field">
                    <label>Cliente</label>
                    {
                        !isEditing && <div>{project.client.fullName}</div>
                    }
                    
                    {
                        isEditing && <div>
                            <TideEntitySelect
                                className='project-field width-50'
                                entity='clients'
                                placeholder={"Compañía"}
                                {...bindSimple('client')}
                            />
                        </div>
                    }
                </div>

                <div className="project-info-field">
                    <label>Folio</label>
                    <div>{ project.folio }</div>
                </div>

                <div className="project-info-field">
                    <label>Costo total</label>
                    <div>$ { moneyFormatter(project.projectStats?.approvedQuotesSum) }</div>
                </div>

                <div className="project-info-field">
                    <label>Pendiente</label>
                    <div>$ { moneyFormatter(project.projectStats?.approvedQuotesSum - project.projectStats?.paymentsSum) }</div>
                </div>

                <div className="project-info-field">
                    <label>Inicio del proyecto</label>
                    {
                        !isEditing && <div>{moment(project.startDate).format("DD/MM/YYYY")}</div>
                    }
                    
                    {
                        isEditing && <div>
                            <WindDatePicker
                                className='project-field width-50'
                                {...bindSimple('startDate')}
                            />
                        </div>
                    }
                </div>
                <div className="project-info-field">
                    <label>Última actualización</label>
                    <div>{moment(project?.updatedDate).format("DD/MM/YYYY")}</div>
                </div>

                <div className="project-info-field">
                    <label>Fin del proyecto</label>
                    {
                        !isEditing && <div>{moment(project.endDate).format("DD/MM/YYYY")}</div>
                    }
                    
                    {
                        isEditing && <div>
                            <WindDatePicker
                                className='project-field width-50'
                                {...bindSimple('endDate')}
                            />
                        </div>
                    }
                </div>

                <div className="project-info-field">
                    <label>Creado por</label>
                    <div>{project?.createdBy?.fullName && project?.createdBy?.username}</div>
                </div>

                <div className="project-info-field">
                    <label>Descripción</label>
                    {
                        !isEditing && <div>{project.description}</div>
                    }
                    
                    {
                        isEditing && <div>
                            <WindTextarea className={" description-input width-50"} placeholder='Agrega la descripción del proyecto' {...bindSimple('description')}/>
                        </div>
                    }
                </div>
            </div>

            <div>

            <Button onClick={setShowConversations} outline={true}>Comentarios</Button>
            {showConversations &&   <Conversations 
                    id={project?.conversation?.id} 
                    conversationTitle={project?.name} 
                    closeConversations={hideConversations}
                    clientId={project?.client?.id}
                />}
            </div>
        </div>
    );

}

export default ProjectInfo;