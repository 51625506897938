import React from 'react';
import classNames from 'classnames';

import './InfoPairBlock.scss';

const InfoPairBlock = ({value, title, right=false, valueClassName=""} ) => {

    return (
        <div className={"InfoPairBlock"}>
            <div className={classNames("info-title", (right? "info-title-right": "") )}>{title}</div>
            <div className={classNames("info-value", (right? "info-value-right": ""), valueClassName )}>{value}</div>
        </div>
    );
};

export default InfoPairBlock;
