import React, { useCallback } from 'react';
import './LinkMenuItem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import _ from 'lodash';

const LinkMenuItem = ({ icon, action, isActive, isOpen, onSave, value, onInputChange}) => {
  const handleInputChange = useCallback((e) => {
    onInputChange(e.target.value)
  }, [onInputChange]);

  const handleInputSave = useCallback((e) => {
    e.preventDefault();

    onSave(value);
  }, [onSave, value]);

  return (
    <div className="LinkMenuItem">
      <button
        className={classNames("LinkMenuItem__button", (isActive && isActive()) && "is-active")}
        onClick={action}
      >
        <FontAwesomeIcon icon={icon} />
      </button>
      <div className={classNames("anchor_conatiner", isOpen && "is-open")}>
        <label htmlFor="link_input">Link: </label>
        <input
          id="link_input"
          className="LinkMenuItem__input"
          placeholder="Enter URL"
          value={typeof value === 'undefined' || _.isNull(value) ? '':value}
          type="text"
          onChange={handleInputChange}
        />
        <button onClick={handleInputSave}>
          <FontAwesomeIcon icon={faCheckCircle} />
        </button>
      </div>
    </div>
  );
}
 
export default LinkMenuItem;