import React from "react";
import Button from "../Button/Button";
import classNames from "classnames";
import { Link } from "react-router-dom"
import DropdownSingleMenu from "../DropdownSingleMenu/DropdownSingleMenu";

import "./SceneLinkMenu.scss"

const SceneLinkMenu = ({ links, tools, children }) => {
    
    return(
        <div className="SceneLinkMenu">
            
            <div className="links-content">
                {
                    ( typeof(links) === 'object' && links.length > 0 )? 
                    links.map( (link) => 
                        <span key={link.text}><Link to={link.to} className={classNames("link-menu", link.active?'link-menu-active':'' )}>
                            {link.text}
                            {
                                ( link.number && typeof(link.number) )? 
                                <span className={classNames("link-menu-num-indicator")}>{ link.number }</span>
                                : null 
                            }
                        </Link></span>
                    )
                    : ''
                }
                {children}
            </div>
            <div>
                {
                    ( typeof(tools) === 'object' && tools.length > 0 )? 
                    tools.map( (tool) => {
                            if ( tool.callback !== null ) {
                                return <span key={tool.text}><Button onClick={tool.callback} outline={tool.outline}>{tool.text}</Button></span>
                            }
                            else if ( tool.dropdown ) {
                                return <span key={tool.text}><DropdownSingleMenu text={tool.text} options={tool.options} /></span>
                            }
                            return null;
                        }
                    )
                    : ''
                }
            </div>
        </div>
    );

}

export default SceneLinkMenu;