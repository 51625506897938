import React, { useCallback, useMemo, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import {filterTypes} from "../../../services/searchUtils";
import {ApiContext} from "../../../services/api/api-config";
import useSearchHistory from '../../../hooks/useSearchHistory';
import useUrlFilters from '../../../hooks/useUrlFilters';
import { taxDocumentsTableColumns, taxDocumentTableListSGroups, taxDocumentIsIssued, taxDocumentStatusSearch } from '../../../services/modelUtils/taxDocumentUtils';
import SearchBar from '../../../components/search/SearchBar/SearchBar';
import SectionHeader from '../../../components/utility/SectionHeader/SectionHeader';
import { getFinanceHeaderInfo } from '../../../constants/SectionHeaderInfo';
import useBoolean from '../../../hooks/useBoolean';
import ReportModal from './components/ReportModal';
import { SecurityContext } from '../../../services/SecurityManager';
import { paths } from '../../../services/routes/appRoutes';

const requestFilters={sGroups: taxDocumentTableListSGroups};
const filtersConfig = {
    placeholder: 'Buscar por RFC...', filters: [
        {main: true, field: 'rfc'},
        {type: filterTypes.TEXT, field: 'rfc', label:'Buscar por RFC'},
        {type: filterTypes.TEXT, field: 'issuerName', label:'Buscar por emisor'},
        {type: filterTypes.TEXT, field: 'receiverName', label:'Buscar por receptor'},
        {type: filterTypes.SELECT, field: 'status', label:'Estatus', noLabel: true, isMulti: false, options: taxDocumentStatusSearch},
        {type: filterTypes.SELECT, field: 'isIssuer', label:'Clase', noLabel: true, isMulti: false, options: taxDocumentIsIssued},
        {type: filterTypes.TEXT, field: 'folio', label:'Buscar por folio'},
        {type: filterTypes.TEXT, field: 'uuidFolioFiscal', label:'Buscar por UUID'},
        {type: filterTypes.DATE, field: 'issuedAt[after]', label:'Fecha de emisión (Desde)'},
        {type: filterTypes.DATE, field: 'issuedAt[before]', label:'Fecha de emisión (Hasta)'},
    ]
};

const InvoicesList = () => {
    const urlFilters = useUrlFilters(); 
    
    const api = useContext(ApiContext);
    const [showingReportModal, showReportModal, hideReportModal] = useBoolean(false);
    const security = useContext(SecurityContext);
    const headerInfo = getFinanceHeaderInfo(security)
    const history = useHistory();
    //Table config
    const table = useTideTable({
        entity: 'taxDocuments',
        columns: taxDocumentsTableColumns,
        requestFilters,
        ...urlFilters
    });

    const customGetRowProps = (row) => {
        const originalProps = row.getRowProps();
        let className = originalProps.className||'';
        if( row.original.isIgnored ) {
            className += " tr-ignored";
        }
        // Modify or add custom properties to the row
        return {
          ...originalProps,
          className: className,
          table: table
        };
    };

    const { tableProps, filters, setFilters } = table;

    useSearchHistory(table);

    const exportToExcel = useCallback(() => {window.location.href = api.taxDocuments.getExportExcelUrl({pagination: false, ...requestFilters, ...filters})}, [api, filters]);

    const generateReport = useCallback(()=>{ showReportModal();   }, [showReportModal])

    const createInvoice = useCallback(()=>{
        history.push(paths.invoicesForm)
    }, [history])

    //window.location.href =  api.taxDocuments.getCompleteReport()
    const sceneTools = useMemo( () => [
            { text: "Facturar", callback: createInvoice},
            { text: "Exportar", callback: exportToExcel, outline: true },
            { text: "Generar Reporte", callback: generateReport, outline: true },
        ], [exportToExcel, generateReport, createInvoice] );

    return (
        <div className={"invoicesList wind-scene"}>

            <SectionHeader pageInfo={headerInfo} tools={sceneTools}/>    
            
            <SearchBar
                labels={false}
                title="Todas las facturas" 
                filters={filters} 
                filtersConfig={filtersConfig} 
                onFiltersChange={setFilters}  
                />
            
            <div className='center-container'>
                <TideReactTable {...tableProps} customGetRowProps={customGetRowProps} className="table-hoverable" />
            </div>

            {showingReportModal && 
                <ReportModal onClose={hideReportModal}/>
            }


        </div>
    );
}

export default InvoicesList;