import React, {useContext, useEffect, useState} from 'react';
import {ApiContext} from "../../../../../../services/api/api-config";
import {getNotifier} from "../../../../../../services/notifier";

const TextPreview = ({appFile}) => {

    const api = useContext(ApiContext);
    const url = api.appFiles.getUrl(appFile);

    const [text, setText] = useState('');

    useEffect(()=>{
        fetch(url).then(resp=>{
            if(resp.status !== 200)
                throw new Error('Unable to load file');
            return resp.text();
        }).then(setText)
            .catch(()=>{
                getNotifier().error('No fue posible cargar la vista previa del archivo '+appFile.originalName);
            });
    },[url, appFile.originalName]);


    return (
        <div className={"TextPreview"}>
            <pre className='text-pre'>
                {text}
            </pre>
        </div>
    );
};

export default TextPreview;
