import React from 'react';
import './BankTransactionsList.scss';
import SimpleCard from '../../../../../components/layout/SimpleCard/SimpleCard';
import { bankTransactionStatus, bankTransactionStatusIcons } from '../../../../../services/modelUtils/bankTransactionUtils';
import { faCheck, faEye, faMagnifyingGlass, faMinusSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import _ from 'lodash';
import { moneyFormatter } from '../../../../../services/currencyUtils';
import IconButton from '../../../../../components/utility/IconButton/IconButton';
import useBoolean from '../../../../../hooks/useBoolean';
import TideEntitySelect from '../../../../../components/utility/TideEntitySelect/TideEntitySelect';

const BankTransactionsList = ({ bankStatement, onConfirm, onDeny, onTransactionSelect }) => {
  const bankTransactions = bankStatement?.bankTransactions || [];

  const [transactionsSelect,,,toggleTransactionsSelect] = useBoolean(false);

  return (
    <SimpleCard
      title="Cotejo de transacciones"
      className={'BankTransactionsList'}
    >
      <div className="form-row">
        <div className='table-container table-responsive'>
          <table className="table">
            <thead>
              <tr>
                <th>Estado</th>
                <th>Concepto</th>
                <th>Cantidad</th>
                <th>Fecha</th>
                <th>Transacción</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {_.map(bankTransactions, (bankTransaction, index) => (
                <tr key={bankTransaction.id||index}>
                  <td>
                    <div className={`badge badge-${bankTransactionStatusIcons[bankTransaction?.status] ? bankTransactionStatusIcons[bankTransaction?.status].color : 'info'}`}>
                      <FontAwesomeIcon
                        icon={bankTransactionStatusIcons[bankTransaction?.status] ? bankTransactionStatusIcons[bankTransaction?.status].icon : faEye}
                      />
                    </div>
                  </td>
                  <td>{bankTransaction.concept||'Sin concepto'}</td>
                  <td>${moneyFormatter(bankTransaction.amount)}</td>
                  <td>{moment(bankTransaction.date).format('DD/MM/YYYY')}</td>
                  <td>
                    <div className="transaction-container">
                      <IconButton
                        icon={faMagnifyingGlass}
                        color={'dark'}
                        onClick={() => toggleTransactionsSelect()}
                      />
                      {transactionsSelect && (
                        <div className="transactions-select">
                          <TideEntitySelect
                            entity='transactions'
                            value={null}
                            onChange={onTransactionSelect}
                            placeholder='Transacción ...'
                            labelCreator={(transaction) => `${transaction.folio||'Sin folio'} - ${transaction.concept||'Sin concepto'}`}
                          />
                        </div>
                      )}
                      {bankTransaction.transaction?.id}
                    </div>
                  </td>
                  <td>
                    <div className="btn-group">
                      {(bankTransaction.status === bankTransactionStatus.PENDING || bankTransaction.status === bankTransactionStatus.DENIED ) && (
                        <IconButton
                          icon={faCheck}
                          color={'success'}
                          onClick={() => onConfirm(bankTransaction?.id)}
                          className={'btn-check'}
                        />
                      )}

                      {(bankTransaction.status === bankTransactionStatus.PENDING || bankTransaction.status === bankTransactionStatus.CONFIRMED ) && (
                        <IconButton
                          icon={faMinusSquare}
                          color={'danger'}
                          onClick={() => onDeny(bankTransaction?.id)}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </SimpleCard>
  );
}
 
export default BankTransactionsList;
