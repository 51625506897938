import React from 'react';
import './QuoteProduct.scss';
import InfoPair from "../../../../../components/layout/InfoPair/InfoPair";
import {moneyFormatter} from "../../../../../services/currencyUtils";
import WindSwitch from '../../../../../components/formComponents/WindSwitch/WindSwitch';

const QuoteProduct = ({ quoteProduct }) => {
    return (
        <div className={"QuoteProduct"}>
            
                <InfoPair title={'Nombre del producto'} value={quoteProduct.name} />
                {quoteProduct.quantity > 1 &&
                <InfoPair title={'Cantidad'} value={quoteProduct.quantity} />}
                <InfoPair title={'Precio'} value={'$ '+moneyFormatter(quoteProduct.price)} />
                {quoteProduct.quantity > 1 &&
                <InfoPair title={'Total'} value={'$ '+moneyFormatter(quoteProduct.quantity*quoteProduct.price)} />}
                <div className="switch-optional">
                    <div className="switch-optional-label">Opcional</div>
                    <WindSwitch
                        className='project-field switch-optional-control'
                        value={quoteProduct?.isOptional}
                        onChange={()=>{}}
                    />
                </div>
                
                <InfoPair title={'Descripción'} value={quoteProduct?.description || "-"} isHtml={true} />

        </div>
    );
};

export default QuoteProduct;
