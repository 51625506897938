
import React, {useMemo} from 'react';
import { clientQuotesTableColumns, clientQuotesSGroups } from '../../../../../services/modelUtils/clientUtils';
import TideReactTable from '../../../../../components/utility/TideReactTable/TideReactTable';
import useTideTable from '../../../../../components/utility/TideReactTable/useTideTable';
import { moneyFormatter } from '../../../../../services/currencyUtils';
import {paymentQuoteStatus, quoteStatus} from "../../../../../services/modelUtils/quoteUtils";
import InfoPairBlock from '../../../../../components/layout/InfoPairBlock/InfoPairBlock';

import "../../ClientDetail.scss"

export const ClientAccountStatement = ({ client }) => {
    const customProp = 'Client.AccountStatement.get.'+client?.id;

    const tableParameters = useMemo(() => {
        return {
            entity: 'quotes',
            columns: clientQuotesTableColumns,
            requestFilters: {
                'project.client': client.id,
                sGroups: clientQuotesSGroups,
                'order[createdDate]':'DESC',
                status: [quoteStatus.APPROVED, quoteStatus.COMPLETED],
                paymentStatus:[paymentQuoteStatus.PAYING, paymentQuoteStatus.PENDING]
            },
            requestOptions: {
                customProp
            }
        }
    }, [client, customProp]);

    const table = useTideTable(tableParameters);

    return (
        <div className="info-container">
            <div className="single-title">Estado de cuenta</div>
            <div className="account-statement-blocks">
                <InfoPairBlock 
                    title="Pendiente" 
                    value={`$${moneyFormatter((Number(client?.clientStats?.pendingPaymentsSum)) || 0)}`} 
                    valueClassName="danger" />
                
                <InfoPairBlock 
                    title="Pagado" 
                    value={`$${moneyFormatter(Number(client?.clientStats?.paymentsSum) || 0)}`} 
                    valueClassName="success" />
                
                <InfoPairBlock 
                    title="Proyectos abiertos" 
                    value={client?.clientStats?.approvedProjectsCount || 0} 
                    valueClassName="right" />
                
                <InfoPairBlock 
                    title="Proyectos pendientes" 
                    value={client?.clientStats?.pendingQuotesCount || 0} 
                    valueClassName="right" />

            </div>
                

            {client?.id && <TideReactTable {...table.tableProps} className="table-hoverable" />}
        </div>
    );
};
